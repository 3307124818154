import { DirectUpload } from '@rails/activestorage'
import FlashMessageUtils from './flash_message_utils'

export default class Uploader {
  loadComplete

  constructor(file, url) {
    this.uploader = new DirectUpload(file, url, this)
  }

  directUpload(file, url, name) {
    return new Promise((res, rej) => {
      this.uploader.create((error, blob) => {
        if (error) {
          rej(FlashMessageUtils.showWarning(error))
        } else {
          const hiddenField = document.createElement('input')
          hiddenField.setAttribute('type', 'hidden')
          hiddenField.setAttribute('value', blob.signed_id)
          hiddenField.classList.add('photos')
          hiddenField.name = name
          res({
            loadComplete: this.loadComplete,
            hiddenField: hiddenField,
          })
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    if (event.loaded === event.total) {
      this.loadComplete = true
    }
  }
}
