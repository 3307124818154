import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['cancelButton']

  toggleCancelButtonDisabled(event) {
    const text = event.target.selectedOptions[0].text

    this.cancelButtonTarget.disabled = Boolean(!text)
  }
}
