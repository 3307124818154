import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import Dropzone from 'dropzone'
import qs from 'qs'
import FlashMessageUtils from '../../../utils/flash_message_utils'
import Uploader from '../../../utils/uploader_utils'
import AxiosUtils from '../../../utils/axios_utils'
import { ACCEPTED_IMAGE_FILES } from '../../../constants/uploads'
const moment = require('moment')

export default class extends Controller {
  static targets = ['submit', 'dropzone', 'form', 'input', 'addMoreMessage']

  fileList

  initialize() {
    this.fileList = []
  }

  connect() {
    this.dropzoneInit()
  }

  dropzoneInit() {
    const fileInputField = this.inputTarget
    const url = fileInputField.dataset.directUploadUrl
    const name = fileInputField.name

    Dropzone.autoDiscover = false
    this.dropzone = new Dropzone(this.dropzoneTarget, {
      headers: { 'X-CSRF-Token': AxiosUtils.getMetaValue('csrf-token') },
      previewTemplate: document.getElementById('preview-template').innerHTML,
      parallelUploads: 10,
      uploadMultiple: true,
      maxFilesize: 10,
      acceptedFiles: ACCEPTED_IMAGE_FILES,
      autoProcessQueue: true,
      thumbnailHeight: 96,
      thumbnailWidth: 96,
      url,
      thumbnail: (file, dataUrl) => {
        if (file.previewElement) {
          file.previewElement.classList.remove('dz-file-preview')
          let images = file.previewElement.querySelectorAll('[data-dz-thumbnail]')
          images.forEach((i) => {
            i.alt = file.name
            i.src = dataUrl
          })

          setTimeout(() => {
            file.previewElement.classList.add('dz-image-preview')
          }, 1)
        }

        if (file.status !== 'error') {
          this.uploadFile(file, url, name)
        }
      },
    })

    this.dropzone.uploadFiles = function (files) {
      const THIS = this

      let minSteps = 6
      let maxSteps = 60
      let timeBetweenSteps = 100
      let bytesPerStep = files.length < 5 ? 50000 : 50

      for (var i = 0; i < files.length; i++) {
        let file = files[i]
        let totalSteps = Math.round(Math.min(maxSteps, Math.max(minSteps, file.size / bytesPerStep)))

        for (let step = 0; step < totalSteps; step++) {
          let duration = timeBetweenSteps * (step + 1)
          setTimeout(
            (function (file, totalSteps, step) {
              return function () {
                file.upload = {
                  progress: (100 * (step + 1)) / totalSteps,
                  total: file.size,
                  bytesSent: ((step + 1) * file.size) / totalSteps,
                }

                THIS.emit('uploadprogress', file, file.upload.progress, file.upload.bytesSent)
                if (file.upload.progress == 100) {
                  file.status = Dropzone.SUCCESS
                  THIS.emit('success', file, 'success', null)
                  THIS.emit('complete', file)
                  THIS.processQueue()
                }
              }
            })(file, totalSteps, step),
            duration
          )
        }
      }
    }
  }

  uploadFile = (file, url, name) => {
    const uploader = new Uploader(file, url)

    uploader.directUpload(file, url, name).then((val) => {
      if (val.loadComplete === true) {
        this.fileList.push(val.loadComplete)

        this.addMoreMessageTarget.classList.remove('cnd-hidden')
        this.formTarget.appendChild(val.hiddenField)
      }
    })
  }

  addMore() {
    this.dropzoneTarget.click()
  }
}
