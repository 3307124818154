import { Controller } from 'stimulus'
import * as axios from 'axios'
import qs from 'qs'

import AxiosUtils from '../utils/axios_utils'
import FlashMessageUtils from '../utils/flash_message_utils'

export default class extends Controller {
  static targets = [
    'amountField',
    'cancelAccountModal',
    'changePlanSubmitBtn',
    'availableBalance',
    'moreInfo',
    'negativeBalanceSection',
    'paySubmitBtn',
    'planInfo',
    'rateChangeWarning',
    'borrowingPlanSelect',
    'status',
    'summaryForm',
    'loadMoreTransactions',
    'transactionsHistory',
    'tab',
    'panel',
  ]

  initialize() {
    this.nextPage = 1
    this.activeTab = this.activeTab()
  }

  connect() {
    this.loadSelectedTab(this.activeTab)
    let target = document.querySelector('#tab_' + this.activeTab)
    this.loadMoreLink = document.querySelector('#loadMore')
    this.setIndicator(target)
    this.loadTransactions()
  }

  bindAmountField() {
    const value = parseFloat(this.amountFieldTarget.value)
    this.paySubmitBtnTarget.disabled = isNaN(value) || value < 1
  }

  postPay() {
    const data = { 'pay[amount]': this.amountFieldTarget.value }
    axios({
      method: 'post',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: qs.stringify(data),
      url: '/account/pay',
    })
      .then((res) => {
        if (res.data.success) {
          this.setFormSuccessState(res.data)
        } else {
          FlashMessageUtils.showWarning(res.data.message)
        }
      })
      .catch((error) => {
        FlashMessageUtils.showWarning(error)
      })
  }

  setFormSuccessState(data) {
    const availableBalance = this.availableBalanceTarget
    availableBalance.innerHTML = data.available_balance
    const amount = this.amountFieldTarget

    if (data.available_balance_numeric === '0.0') {
      this.negativeBalanceSectionTarget.classList.add('cnd-hidden')
      availableBalance.classList.remove('text-danger')
      availableBalance.classList.remove('text-success')
      availableBalance.classList.add(data.status_css)
    } else {
      amount.value = -data.available_balance_numeric
    }

    FlashMessageUtils.showSuccess(data.message)
  }

  handlePayButtonClick() {
    this.disableForm()

    axios.get('/account_details').then((res) => {
      if (res.data.status === 'No card details') {
        if (confirm('Please fill in your payment card details.')) {
          this.scrollToPaymentSection()
        }
        this.enableForm()
      } else if (confirm(`We will collect ${this.amountFieldTarget.value} from your payment card.`)) {
        this.postPay()
      } else {
        this.enableForm()
      }
    })
  }

  enableForm() {
    this.paySubmitBtnTarget.disabled = false
    this.paySubmitBtnTarget.value = 'Pay'
  }

  disableForm() {
    this.paySubmitBtnTarget.disabled = true
    this.paySubmitBtnTarget.value = 'Paying...'
  }

  static scrollToPaymentSection() {
    const section = document.querySelector('#payment-details-heading')
    window.scrollTop = section.offsetTop - section.offsetHeight
  }

  selectPlan() {
    const selectText = this.borrowingPlanSelectTarget.options[this.borrowingPlanSelectTarget.selectedIndex].text

    if (selectText.indexOf('Featherweight') >= 0) {
      this.rateChangeWarningTarget.classList.remove('cnd-hidden')
      this.changePlanSubmitBtnTarget.disabled = false
    } else if (selectText.indexOf('Heavyweight') >= 0) {
      this.planInfoTarget.classList.remove('cnd-hidden')
      this.changePlanSubmitBtnTarget.disabled = false
    } else {
      this.changePlanSubmitBtnTarget.disabled = true
      this.rateChangeWarningTarget.classList.add('cnd-hidden')
      if (this.hasPlanInfoTarget) {
        this.planInfoTarget.classList.add('cnd-hidden')
      }
    }
  }

  cancelAccount() {
    this.cancelAccountModalTarget.classList.remove('cnd-hidden')
  }

  closeModal() {
    this.cancelAccountModalTarget.classList.add('cnd-hidden')
  }

  moreInfo() {
    this.moreInfoTarget.classList.toggle('cnd-dropdown--open')
  }

  activeTab() {
    return document.getElementById('acctSummaryTabs').getAttribute('data-active-tab')
  }

  loadTransactionList(data, load_more = true) {
    var currentTarget = this.transactionsHistoryTarget
    if (data.transactions_exists) {
      this.loadMoreLink.classList.remove('cnd-hidden')
      load_more
        ? currentTarget.insertAdjacentHTML('beforeend', data.transactions_history)
        : (currentTarget.innerHTML = data.transactions_history)
      this.loadMoreLink.text = L10n.t('web-account-summary.load-more')
    } else {
      this.loadMoreLink.classList.add('cnd-hidden')
      // Set panel to blank if active tab has no transactions
      if (!load_more || this.nextPage == 2) {
        currentTarget.innerHTML = '<div></div>'
      }
    }
  }

  loadSelectedTab(target) {
    this.tabTargets.forEach((t) => {
      if (target === t.dataset.tab) {
        t.classList.add('is-active')
      } else {
        t.classList.remove('is-active')
      }
    })

    this.panelTargets.forEach((p) => {
      if (target === p.dataset.panel) {
        p.classList.remove('cnd-hidden')
      } else {
        p.classList.add('cnd-hidden')
      }
    })
  }

  setIndicator(target) {
    this.indicator = document.querySelector('.nav-indicator')

    this.indicator.style.width = `${target.offsetWidth}px`
    this.indicator.style.left = `${target.offsetLeft}px`
    this.indicator.style.backgroundColor = 'black'
  }

  loadTransactions() {
    this.loadMoreLink.text = 'Loading...'

    axios
      .get('/account_summary/transaction_history', {
        params: {
          page: this.nextPage++,
          country_code: this.activeTab,
        },
      })
      .then((res) => {
        const data = res.data
        this.loadTransactionList(data)
      })
  }

  // Loads account specific summary
  loadTab(e) {
    this.activeTab = e.target.dataset.tab
    this.loadSelectedTab(e.target.dataset.tab)
    this.setIndicator(e.target)

    this.loadMoreLink.text = 'Loading...'
    this.nextPage = 1
    this.loadMoreLink.innerHTML = '<div></div>'
    this.loadTransactions()
  }
}
