/* global $ */
import { Controller } from 'stimulus'
import * as axios from 'axios'
import FlashMessageUtils from '../utils/flash_message_utils'

export default class extends Controller {
  static targets = ['bsb', 'branch', 'bank', 'form', 'submitBtn']

  getBankDetails() {
    axios
      .get('/bank_state_branches', {
        responseType: 'json',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params: {
          code: this.bsbTarget.value,
        },
      })
      .then((res) => {
        this.branchTarget.value = res.data.branch
        this.bankTarget.value = res.data.bank
      })
  }

  // Form submission error
  static updateError() {
    FlashMessageUtils.showWarning('Sorry something went wrong, try again later')
  }

  // Avoid form redirect
  handleFormSubmit(e) {
    e.preventDefault()
    $(this.formTarget)
      .on('ajax:success', (e, xhr) => {
        FlashMessageUtils.showSuccess(L10n.t('web-account.messages.you-have-successfully-updated-your-prof'))
        this.submitBtnTarget.classList.add('cnd-hidden')
      })
      .on('ajax:error', (e, xhr) => {
        FlashMessageUtils.showWarning(xhr.responseJSON.error_message)
      })
  }
}
