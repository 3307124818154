import { Controller } from 'stimulus'

import * as axios from 'axios'
import qs from 'qs'

import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'

export default class extends Controller {
  static targets = ['loadingIcon']

  initialize() {
    const code = this.element.dataset.code
    const noCallback = this.element.dataset.noCallback

    if (code && !noCallback) {
      this.oauthCallback(code)
    }
  }

  oauthCallback(code) {
    const data = {
      code: code,
      oauth_action: this.element.dataset.oauthAction,
      redirect_uri: this.element.dataset.redirectUri,
      state: this.element.dataset.state,
    }

    this.loadingIconTarget.classList.remove('cnd-hidden')

    axios({
      method: 'post',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: qs.stringify(data),
      url: `/members/${this.element.dataset.provider}/oauth_callbacks`,
    })
      .then((res) => {
        if (_.get(window.webkit, 'messageHandlers.cordova_iab.postMessage')) {
          // ionic
          window.webkit.messageHandlers.cordova_iab.postMessage(
            JSON.stringify({
              message: 'uber-auth-successful',
              token: res.data.token,
            })
          )
        } else if (window.UberAuthSuccessEvent) {
          // Flutter webview
          window.UberAuthSuccessEvent.postMessage(
            JSON.stringify({
              message: 'uber-auth-successful',
              token: res.data.token,
            })
          )
        } else if (this.element.dataset.state === 'app') {
          // System webview
          window.location.replace(`ubercarshare.com://?token=${res.data.token}&oauth_action=${res.data.oauth_action}`)
        } else if (this.element.dataset.state === 'unava') {
          // Navatile
          this.handleUnavaCallback(res.data.token, res.data.oauth_action)
        } else {
          window.location.replace(res.data.path || '/login')
        }
      })
      .catch((error) => {
        this.loadingIconTarget.classList.add('cnd-hidden')

        FlashMessageUtils.showWarning(_.get(error, 'response.data.message') || error.message)
      })
  }

  handleUnavaCallback(token, action) {
    const { navaRootUrl, navaRedirectUrl } = this.element.dataset
    const redirectUrl = navaRedirectUrl || navaRootUrl
    let url = new URL(redirectUrl)

    if (!url.searchParams.has('oauth_token')) {
      url.searchParams.append('oauth_token', token)
    }

    if (!url.searchParams.has('oauth_action')) {
      url.searchParams.append('oauth_action', action)
    }

    window.location.replace(url.toString())
  }
}
