/*global Sentry*/
/*global ahoy*/

import { Controller } from 'stimulus'

import * as axios from 'axios'
import AxiosUtils from '../utils/axios_utils'
import EnvironmentUtils from '../utils/environment_utils'
import FlashMessageUtils from '../utils/flash_message_utils'
import DeviceUtils from '../utils/device_utils'

import FaceTec from '../facetec/facetec'

export default class extends Controller {
  static targets = [
    'banner',
    'dialog',
    'eligibility',
    'facetecError',
    'idDetails',
    'idVerification',
    'loading',
    'mobileVerification',
    'personalDetails',
    'submitApplication',
    'uverify',
    'verification',
    'webview',
    'webviewContainer',
    'improveImageGuideDialog',
  ]

  memberId
  applicantUuid
  verificationType
  hasFaceTecInitialised

  connect() {
    const data = JSON.parse(this.data.get('initial-value'))
    this.render({ data })

    // If verification method is Uverify, skip Facetec
    if (this.hasUverifyTarget) {
      this.setLoading(false)
      this.webviewTarget.addEventListener('load', this.onWebviewChange.bind(this))
      return
    }

    window.addEventListener('load', () => {
      const serverURL = this.data.get('server-url')
      const deviceKey = this.data.get('device-key')
      const browserKey = JSON.parse(this.data.get('browser-key'))

      if (DeviceUtils.isUnsupportedBrowser()) {
        this.hasFaceTecInitialised = false
        this.facetecErrorTarget.innerHTML =
          'We ran into an issue with your browser. Try Chrome, Firefox or Safari instead.'
        this.facetecErrorTarget.classList.remove('cnd-hidden')
        this.setLoading(false)

        return
      }

      if (!FaceTec.isBrowserSupported()) {
        this.idVerificationTarget.setAttribute('unsupported-browser', true)
        this.setLoading(false)

        return
      }

      FaceTec.initialize(serverURL, deviceKey, browserKey)
        .then(() => (this.hasFaceTecInitialised = true))
        .catch((error) => {
          Sentry.captureException(error)

          this.hasFaceTecInitialised = false
          this.facetecErrorTarget.innerHTML = 'FaceTec SDK failed to initialise'
          this.facetecErrorTarget.classList.remove('cnd-hidden')
        })
        .finally(() => this.setLoading(false))
    })
  }

  handleBanner(event) {
    const name = _.get(event, 'detail.name')

    if (name === 'lets-go') {
      this.submitVerification('start')
    }
  }

  submitEligibility(event) {
    const { experiencedManualDriver, restrictedLicence, verificationType } = event.detail
    const licenceCountry = this.uverifyTarget.getAttribute('member-country')?.toUpperCase()
    let licenceText, unrestricedText

    switch (licenceCountry) {
      case 'US':
        licenceText = 'license'
        unrestricedText = 'Unconditional'
        break
      case 'CA':
        licenceText = 'licence'
        unrestricedText = 'Unconditional'
        break
      default:
        licenceText = 'licence'
        unrestricedText = 'Unrestricted'
    }

    if (!restrictedLicence) {
      this.submitVerification('eligibility', {
        verification_type: verificationType,
        restricted_licence: restrictedLicence,
        experienced_manual_driver: experiencedManualDriver,
      })
    } else {
      this.showDialog(
        "You can't borrow cars just yet",
        `Members must have an ${unrestricedText.toLowerCase()} ${licenceText} to borrow cars. However, you can continue if you're listing a car`,
        [
          {
            name: 'no',
            label: 'No, I am not listing a car',
          },
          {
            name: 'yes',
            primary: true,
            label: 'Yes, I am listing a car',
          },
        ]
      )
        .then((response) => {
          if (response === 'yes') {
            return this.showDialog(
              "Looks like you're listing a car",
              `You can still list your car, however we require members to hold an ${unrestricedText.toLowerCase()} ${licenceText} to borrow cars.`,
              [
                {
                  name: 'continue',
                  label: 'Continue',
                },
              ]
            )
          }

          return this.showDialog(
            `${unrestricedText} ${licenceText} required`,
            `You'll need a full driver's ${licenceText} to drive with Uber Carshare.`,
            [
              {
                name: 'ok',
                label: 'OK',
              },
            ]
          )
        })
        .then((response) => {
          if (response === 'continue') {
            this.submitVerification('eligibility', {
              verification_type: verificationType,
              restricted_licence: restrictedLicence,
            })
          }
        })
    }
  }

  showPhotoGuideDialog() {
    return new Promise((resolve) => {
      const $improveImageGuideDialog = this.improveImageGuideDialogTarget
      const $buttons = $improveImageGuideDialog.querySelector('.cnd-dialog__btns')
      const $overlayBackground = $improveImageGuideDialog.querySelector('.cnd-overlay__bg')

      $overlayBackground.onclick = () => {
        $improveImageGuideDialog.classList.add('cnd-hidden')
        resolve('cancel')
      }

      $buttons.onclick = (event) => {
        const name = event.target.getAttribute('name')
        if (name) {
          $improveImageGuideDialog.classList.add('cnd-hidden')
          resolve(name)
        }
      }
      $improveImageGuideDialog.classList.remove('cnd-hidden')
    })
  }

  showDialog(header, content, buttons) {
    return new Promise((resolve) => {
      const $dialog = this.dialogTarget
      $dialog.querySelector('.cnd-dialog__header').innerHTML = header
      $dialog.querySelector('.cnd-dialog__content').innerHTML = content

      const $buttons = $dialog.querySelector('.cnd-dialog__btns')
      $buttons.innerHTML = buttons
        .map(
          (button) => `<div class="cnd-btn cnd-btn--block cnd-btn--${button.primary ? 'solid' : 'grey'}" name="${
            button.name
          }">
            ${button.label}
          </div>`
        )
        .join('\n')
      $buttons.onclick = (event) => {
        const name = event.target.getAttribute('name')

        if (name) {
          $dialog.classList.add('cnd-hidden')
          resolve(name)
        }
      }
      $dialog.classList.remove('cnd-hidden')
    })
  }

  verifyID() {
    if (EnvironmentUtils.isAnEndToEndTestingProcess()) {
      this.submitVerification('id_verification', {
        face_scan: '',
        id_back: '',
        id_front: '',
        passport: '',
      })
    }

    if (!this.hasFaceTecInitialised) return

    const allowFailedMatch = this.data.get('allow-failed-match') === 'true'
    const isInternational = this.verificationType == 'international_licence'

    FaceTec.idCheck(this.memberId, isInternational, allowFailedMatch)
      .then((idScanResult) => {
        this.submitVerification('id_verification', {
          face_scan: idScanResult.faceScan,
          id_back: idScanResult.idBack || '',
          id_front: idScanResult.idFront || '',
          passport: idScanResult.passport || '',
          metadata: idScanResult.metadata,
        })
      })
      .catch((errorMessage) => {
        Sentry.captureException(new Error(errorMessage))

        this.facetecErrorTarget.innerHTML =
          'You need to take a picture of your ID and a selfie to confirm your booking.'
        this.facetecErrorTarget.classList.remove('cnd-hidden')
      })
  }

  startPolling() {
    const licenceCountry = this.uverifyTarget.getAttribute('member-country')?.toUpperCase()
    const params = {
      applicant_uuid: this.applicantUuid,
      uverify_result_id: this.uverifyResultId,
      is_international_user: this.verificationType == 'international_licence',
      country: licenceCountry,
    }

    this.setLoading(true)

    axios
      .post('/verification/start_polling', params, AxiosUtils.getHeaders())
      .then((response) => {
        if (response?.status == 200) {
          this.submitVerification('id_verification')
          this.setLoading(false)
        }
      })
      .catch((error) => {
        FlashMessageUtils.showWarning(error.message)

        window.scrollTo(0, 0)
      })
      .finally(() => this.setLoading(false))
  }

  addYourId() {
    this.showPhotoGuideDialog().then((response) => {
      if (response === 'continue') {
        this.initializeWebview()
      }
    })
  }

  initializeWebview() {
    const locale = this.webviewContainerTarget.dataset.locale
    const licenceCountry = this.uverifyTarget.getAttribute('member-country')?.toUpperCase()
    this.successUrl = new URL('/verification/webview_callback', window.location.origin)

    if (DeviceUtils.isNavatile()) {
      this.successUrl.searchParams.append('isWebView', true)
    }

    const params = {
      applicant_uuid: this.applicantUuid,
      is_international_user: this.verificationType == 'international_licence',
      success_url: this.successUrl.toString(),
      country: licenceCountry,
      locale: locale,
    }

    this.setLoading(true)

    axios
      .post('/verification/initialise_webview', params, AxiosUtils.getHeaders())
      .then((response) => {
        const data = response?.data || {}

        if (data.status == 'FAILED') throw new Error(data.error_message)

        this.uverifyResultId = data.uverify_result_id
        const verificationUrl = new URL(data.url)

        this.showWebview(verificationUrl.toString())
      })
      .catch((error) => {
        FlashMessageUtils.showWarning(error.message)

        window.scrollTo(0, 0)
      })
      .finally(() => this.setLoading(false))
  }

  onWebviewChange(event) {
    let href

    try {
      href = event.target.contentWindow?.location?.href
    } catch (_) {
      // if the page is cross origin (au10tix), we return. this is expected.
      return
    }

    if (!href || !this.successUrl) return

    const url = new URL(href)
    const entityId = url.searchParams.get('entity_uuid')

    if (url.origin != this.successUrl.origin) return
    if (url.pathname != this.successUrl.pathname) return
    if (entityId != this.applicantUuid) return

    this.hideWebview()
    this.startPolling()
  }

  showWebview(url) {
    this.webviewContainerTarget.classList.remove('cnd-hidden')
    this.webviewTarget.src = url
  }

  hideWebview() {
    this.webviewTarget.src = ' '
    this.webviewContainerTarget.classList.add('cnd-hidden')
  }

  submitIdDetails(event) {
    const {
      firstName,
      lastName,
      licenceNumber,
      licenceCardNumber,
      licenceCountry,
      passportNumber,
      passportCountry,
      passportExpiry,
      dob,
      country,
    } = event.detail
    const address = event.detail.address || {}

    this.submitVerification('id_details', {
      first_name: firstName,
      last_name: lastName,
      street: address.address1 || '',
      unit_number: address.address2 || '',
      state: address.state || '',
      country: address.country || country,
      post_code: address.postcode || '',
      suburb: address.suburb || '',
      licence_number: licenceNumber,
      licence_card_number: licenceCardNumber,
      licence_country: licenceCountry || address.country,
      dob,
      passport_number: passportNumber,
      passport_country: passportCountry,
      passport_expiry: passportExpiry,
    })
  }

  submitPersonalDetails(event) {
    const { gender, experiencedManualDriver, defendant, drivenUnderInfluence, disqualifiedOrSuspended } = event.detail

    const setPayloadValue = (value) => {
      if (value === undefined) return

      return value ? 'Yes' : 'No'
    }

    this.submitVerification('personal_details', {
      gender: gender,
      experienced_manual_driver: experiencedManualDriver,
      defendant: setPayloadValue(defendant),
      drived_under_influence: setPayloadValue(drivenUnderInfluence),
      disqualified_or_suspended: setPayloadValue(disqualifiedOrSuspended),
    })
  }

  verifyMobile(event) {
    const { mobileNumber, memberCountry, name, verificationCode } = event.detail

    switch (name) {
      case 'send':
      case 'resend':
        this.sendVerificationSMS(mobileNumber, memberCountry)
        break

      case 'verify':
        this.verifyNumber(verificationCode)
        break
    }
  }

  sendVerificationSMS(mobileNumber, memberCountry) {
    this.setLoading(true)
    this.renderMobileVerification({ showCode: false })

    axios
      .post('/mobile_verification', { mobile: mobileNumber, country: memberCountry }, AxiosUtils.getHeaders())
      .then(() => this.renderMobileVerification({ showCode: true }))
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data.message') || 'Mobile verification failed'
        this.renderMobileVerification({ errorMessage })
      })
      .finally(() => this.setLoading(false))
  }

  verifyNumber(code) {
    this.setLoading(true)

    axios
      .put('/mobile_verification', { code }, AxiosUtils.getHeaders())
      .then(() => this.submitVerification('mobile_verification'))
      .catch((error) => {
        const errorMessage = _.get(error, 'response.data.message') || 'Mobile verification failed'
        this.renderMobileVerification({ showCode: true, errorMessage })
      })
      .finally(() => this.setLoading(false))
  }

  submitApplication(event) {
    const { declaration, howDidYouFindUs, name, memberCountry } = event.detail
    let link

    if (name === 'membership-agreement') {
      switch (memberCountry) {
        case 'us':
          link = '/us/en/legal/terms-of-service'
          break
        case 'ca':
          link = '/ca/en/legal/terms-of-service'
          break
        default:
          link = '/terms-of-use/member-agreement'
      }
      window.open(link, '_self')
      return
    }

    if (name === 'privacy-policy') {
      switch (memberCountry) {
        case 'us':
          link = 'https://www.uber.com/legal/en/document/?country=united-states&lang=en&name=privacy-notice'
          break
        case 'ca':
          link = 'https://www.uber.com/legal/en/document/?name=privacy-notice&country=canada&lang=en'
          break
        case 'au':
          link = '/au/en/legal/privacy-policy'
          break
        default:
          link = '/legal/privacy-policy'
      }
      window.open(link, '_self')
      return
    }

    // On submit of application we track the view and send a param to define completion of v2 application.
    // This is used to check lead source attribution, see: LeadSources::InferImplicitLeadSources.
    ahoy.trackView({ v2_application_complete: true })

    this.submitVerification('submit_application', {
      accept_terms_and_conditions: declaration,
      lead_source_id: howDidYouFindUs,
    })
  }

  submitVerification(step, payload = {}) {
    this.setLoading(true)

    return axios
      .put(`/verification/${step}`, { verification: payload }, AxiosUtils.getHeaders())
      .then((response) => {
        const data = _.get(response, 'data') || {}

        if (data.error) throw new Error(data.error)

        this.render(response)
      })
      .catch((error) => {
        FlashMessageUtils.showWarning(error.message)

        window.scrollTo(0, 0)
      })
      .finally(() => this.setLoading(false))
  }

  setLoading(isLoading) {
    const $loading = this.loadingTarget

    if (isLoading) {
      $loading.classList.remove('cnd-hidden')
    } else {
      $loading.classList.add('cnd-hidden')
    }
  }

  generateUberDeeplink = (redirectUrl) => {
    return `uber://webmode?contentId=CAR_SHARE_${Date.now()}&attachLocation=true&urlString=${redirectUrl}`
  }

  render = (response) => {
    const { applicant = {}, member = {} } = _.get(response, 'data') || {}
    const uberRequest = this.data.get('uber-request')
    const navaRootUrl = this.data.get('nava-root-url')
    const navaRedirectUrl = this.data.get('nava-redirect-url')
    const pendingTripRequest = _.get(applicant, 'trip_request.status') === 'active'

    if (applicant.status === 'complete') {
      if (navaRedirectUrl) {
        location.replace(this.generateUberDeeplink(navaRedirectUrl))
      } else if (uberRequest === 'true' && navaRootUrl) {
        location.replace(this.generateUberDeeplink(navaRootUrl))
      } else {
        location.href = pendingTripRequest ? '/members/verification/pending_trip_request' : '/search'
      }

      return
    }

    this.applicantUuid = applicant.uuid
    this.memberId = member.id

    const currentStep = applicant.verification_step || 'eligibility'
    const progress = this.getProgress(applicant)

    const $verification = this.verificationTarget
    $verification.setAttribute('current-step', currentStep)
    $verification.setAttribute('disable-scroll', currentStep === 'eligibility')

    const $banner = this.bannerTarget
    $banner.setAttribute('current-step', currentStep)
    $banner.setAttribute('progress', progress)
    $banner.setAttribute('trip-expiry', _.get(applicant, 'trip_request.expiry') || '')
    $banner.setAttribute('trip-start-at', _.get(applicant, 'trip_request.vlocal_start_at') || '')

    const $eligibility = this.eligibilityTarget
    if (typeof applicant.verification_type === 'string') {
      this.verificationType = applicant.verification_type
      $eligibility.setAttribute('verification-type', applicant.verification_type)
    }

    if (typeof applicant.restricted_licence === 'boolean') {
      $eligibility.setAttribute('restricted-licence', applicant.restricted_licence)
    }

    if (member.licence_country) {
      this.submitApplicationTarget.setAttribute('licence-country', member.licence_country)
    }

    if (typeof member.experienced_manual_driver === 'boolean') {
      $eligibility.setAttribute('experienced-manual-driver', member.experienced_manual_driver)
    }

    // If verification method is Uverify, skip idVerification initialization (it doesn't exist)
    if (this.hasIdVerificationTarget) {
      const $idVerification = this.idVerificationTarget
      $idVerification.setAttribute('verification-type', applicant.verification_type)
    }

    const $idDetails = this.idDetailsTarget
    $idDetails.setAttribute('id-type', applicant.verification_type == 'local_licence' ? 'licence' : 'passport')
    $idDetails.setAttribute('first-name', member.first_name || '')
    $idDetails.setAttribute('last-name', member.last_name || '')
  }

  renderMobileVerification = ({ showCode = false, errorMessage = '' }) => {
    const $mobileVerification = this.mobileVerificationTarget
    $mobileVerification.setAttribute('show-verification-code', showCode)
    $mobileVerification.setAttribute('show-error', !!errorMessage)
    $mobileVerification.setAttribute('error-message', errorMessage || '')
  }

  getProgress(applicant) {
    if (applicant.status === 'approved') return 100

    const steps = [
      'eligibility',
      'id_verification',
      'id_details',
      'personal_details',
      'mobile_verification',
      'submit_application',
      'application_submitted',
    ]

    const currentStepIndex = steps.findIndex((step) => step === applicant.verification_step)

    return 20 + Math.floor((currentStepIndex + 1) * (80 / (steps.length + 1)))
  }
}
