import { Controller } from 'stimulus'
import qs from 'qs'
import DateUtils from '../../utils/date_utils'
import DeviceUtils from '../../utils/device_utils'

export default class extends Controller {
  static targets = [
    'filtersContainer',
    'dateTimePickerModal',
    'bodyTypeModal',
    'bodyTypeSection',
    'filtersModal',
    'dateTimeFilter',
    'dateTimeFilterText',
    'calendarIcon',
    'hourglassIcon',
    'additionalFilter',
    'bodyTypeFilter',
    'parkingFilter',
    'featureFilter',
    'keyTypeFilter',
    'transmissionFilter',
    'transmissionAutomaticFilter',
    'transmissionManualFilter',
    'doorsFilter',
    'seatsFilter',
    'favoriteFilter',
    'mainBodyTypeFilter',
    'featuresModal',
    'mainFeatureFilter',
    'safetiesModal',
    'safetyFilter',
    'mainSafetyFilter',
    'priceModal',
    'mainPriceFilter',
    'mainLowerPriceRange',
    'mainUpperPriceRange',
    'lowerPriceRange',
    'upperPriceRange',
    'greenCarsFilter',
  ]

  initialize() {
    // trigger filter setter functions on init to allow
    // for preselected filters to be displayed on filter buttons
    this.setAdditionalFilters()

    // trigger start and end setter function on init
    this.setFilterDatetime()

    this.setFilterBodyType()

    this.setFilter('mainFeatureFilter', 'Features')
    this.setFilter('mainSafetyFilter', 'Safety')
    this.setFilterPriceRange()
  }

  get getVehicleSearchController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById('vehicle-search-container'),
      'search--vehicle-search'
    )
  }

  showModal(e) {
    e.preventDefault()

    // resets the filter values based on query string
    this.getVehicleSearchController.setFilterValues()

    let modal = e.target.dataset.value
    const element = this[`${modal}Target`]

    if (element) {
      element.classList.remove('cnd-hidden')
      document.body.classList.add('cnd-overflow-hidden')
      this.filtersContainerTarget.classList.add('cnd-z-30')
    }
  }

  hideModal(e) {
    let modal = e.target.dataset.value
    if (e.target.type !== 'submit') {
      const startTimeIso = this.getVehicleSearchController.searchParams.start_time_iso
      const endTimeIso = this.getVehicleSearchController.searchParams.end_time_iso

      if (
        modal == 'dateTimePickerModal' &&
        (startTimeIso == '' || endTimeIso == '' || startTimeIso == undefined || endTimeIso == undefined)
      ) {
        return
      }
    }

    if (e.target.type == 'button') {
      e.preventDefault()
    }

    const element = this[`${modal}Target`]

    if (element) {
      element.classList.add('cnd-hidden')
      document.body.classList.remove('cnd-overflow-hidden')
      this.filtersContainerTarget.classList.remove('cnd-z-30')
    }
  }

  applyFilters(e) {
    const modal = e.target.dataset.value
    this.setFilterValue(modal)
  }

  showFavorites(e) {
    const selectedFavoriteFilter = this.favoriteFilterTarget.dataset.value
    if (selectedFavoriteFilter == 'true') {
      this.favoriteFilterTarget.classList.remove('cnd-filter-btn--active')
      this.favoriteFilterTarget.classList.add('cnd-favorite__inactive')
      this.favoriteFilterTarget.dataset.value = 'false'
    } else {
      this.favoriteFilterTarget.classList.add('cnd-filter-btn--active')
      this.favoriteFilterTarget.classList.remove('cnd-favorite__inactive')
      this.favoriteFilterTarget.dataset.value = 'true'
    }
  }

  resetFilters(e) {
    const setByTargets = function (elements, value, attr = 'checked') {
      return elements.map((el) => {
        el[attr] = value
        el.setAttribute(attr, value)
      })
    }

    const showDateTimeFilterText = () => {
      this.dateTimeFilterTextTargets.forEach((el) => {
        el.classList.remove('cnd-hidden')
      })
    }

    const resetLabelByTarget = (element, label) => {
      element.lastChild.data = label
      element.classList.remove('cnd-filter-btn--active')

      if (this.dateTimeFilterTarget.lastChild.data == 'Add dates') {
        showDateTimeFilterText()
      }
    }

    const modal = e.target.dataset.value

    switch (modal) {
      case 'dateTimePickerModal':
        resetLabelByTarget(this.dateTimeFilterTarget, 'Add dates')
        break

      case 'bodyTypeModal':
        setByTargets(this.bodyTypeFilterTargets, false)
        setByTargets(this.mainBodyTypeFilterTargets, false)
        resetLabelByTarget(this.bodyTypeFilterTarget, this.bodyTypeText)
        this.setAdditionalFilters()
        break

      case 'filtersModal':
        setByTargets(this.mainBodyTypeFilterTargets, false)
        setByTargets(this.bodyTypeFilterTargets, false)
        resetLabelByTarget(this.bodyTypeFilterTarget, this.bodyTypeText)

        setByTargets(this.mainFeatureFilterTargets, false)
        resetLabelByTarget(this.mainFeatureFilterTarget, 'Features')

        setByTargets(this.mainSafetyFilterTargets, false)
        resetLabelByTarget(this.mainSafetyFilterTarget, 'Safety')

        setByTargets(this.parkingFilterTargets, false)
        setByTargets(this.featureFilterTargets, false)

        setByTargets(this.keyTypeFilterTargets, false)
        setByTargets(this.parkingFilterTargets, false)

        setByTargets(
          this.transmissionAutomaticFilterTargets,
          window.cndVars.inexperiencedManualDriver == 'true' ? true : false
        )
        setByTargets(this.transmissionManualFilterTargets, false)

        setByTargets(this.greenCarsFilterTargets, false)

        this.seatsFilterTarget.value = ''
        this.doorsFilterTarget.value = ''

        this.hasUpperPriceRangeTarget && this.makeTargetDirty(this.upperPriceRangeTarget, false)
        this.hasLowerPriceRangeTarget && this.makeTargetDirty(this.lowerPriceRangeTarget, false)
        this.setPriceRangeDefaultValue()
        this.setFilterPriceRange()

        this.setAdditionalFilters()
        break
      case 'featuresModal':
        setByTargets(this.mainFeatureFilterTargets, false)
        setByTargets(
          this.featureFilterTargets.filter((el) => el.dataset.category === 'features'),
          false
        )
        resetLabelByTarget(this.mainFeatureFilterTarget, 'Features')
        this.setAdditionalFilters()
        break
      case 'safetiesModal':
        setByTargets(this.mainSafetyFilterTargets, false)
        setByTargets(
          this.featureFilterTargets.filter((el) => el.dataset.category === 'safety'),
          false
        )
        resetLabelByTarget(this.mainSafetyFilterTarget, 'Safety')
        this.setAdditionalFilters()
        break
      case 'priceModal':
        this.makeTargetDirty(this.upperPriceRangeTarget, false)
        this.makeTargetDirty(this.lowerPriceRangeTarget, false)
        this.setPriceRangeDefaultValue()
        this.setFilterPriceRange()
        this.setAdditionalFilters()
        break
    }

    // trigger filter and url reset only on select of reset button and perform search
    if (e.target.name === 'clearModal') {
      this.getVehicleSearchController.getSearchResults()
      this.getVehicleSearchController.updateQuerystring()
    }
  }

  setFilterValue(modal) {
    if (modal === 'dateTimePickerModal') {
      this.setFilterDatetime()
    }
    if (modal === 'bodyTypeModal') {
      this.setFilterBodyType()
      this.syncCheckboxes(this.bodyTypeFilterTargets, this.mainBodyTypeFilterTargets)
      this.setAdditionalFilters()
    }
    if (modal === 'filtersModal') {
      this.syncCheckboxes(this.mainBodyTypeFilterTargets, this.bodyTypeFilterTargets)
      this.setFilterBodyType()
      this.syncCheckboxes(this.featureFilterTargets, this.mainFeatureFilterTargets)
      this.setFilter('mainFeatureFilter', 'Feature')
      this.syncCheckboxes(this.featureFilterTargets, this.mainSafetyFilterTargets)
      this.setFilter('mainSafetyFilter', 'Safety')
      this.hasUpperPriceRangeTarget && this.makeTargetDirty(this.upperPriceRangeTarget, true)
      this.hasLowerPriceRangeTarget && this.makeTargetDirty(this.lowerPriceRangeTarget, true)
      this.hasLowerPriceRangeTarget &&
        this.syncPriceRangeValue(this.mainLowerPriceRangeTarget, this.lowerPriceRangeTarget)
      this.hasUpperPriceRangeTarget &&
        this.syncPriceRangeValue(this.mainUpperPriceRangeTarget, this.upperPriceRangeTarget)
      this.setFilterPriceRange()
      this.setAdditionalFilters()
    }
    if (modal === 'featuresModal') {
      this.setFilter('mainFeatureFilter', 'Feature')
      this.syncCheckboxes(this.mainFeatureFilterTargets, this.featureFilterTargets)
      this.setAdditionalFilters()
    }
    if (modal === 'safetiesModal') {
      this.setFilter('mainSafetyFilter', 'Safety')
      this.syncCheckboxes(this.mainSafetyFilterTargets, this.featureFilterTargets)
      this.setAdditionalFilters()
    }
    if (modal === 'priceModal') {
      this.makeTargetDirty(this.upperPriceRangeTarget, true)
      this.makeTargetDirty(this.lowerPriceRangeTarget, true)
      this.syncPriceRangeValue(this.lowerPriceRangeTarget, this.mainLowerPriceRangeTarget)
      this.syncPriceRangeValue(this.upperPriceRangeTarget, this.mainUpperPriceRangeTarget)
      this.setFilterPriceRange()
      this.setAdditionalFilters()
    }
  }

  setFilterDatetime() {
    const startDatetime = this.getVehicleSearchController.search.startDatetimeMoment
    const endDatetime = this.getVehicleSearchController.search.endDatetimeMoment
    const startDateFormat = startDatetime.month() === endDatetime.month() ? 'D' : 'D MMM'
    const formattedStart = startDatetime.format(startDateFormat)
    const formattedEnd = endDatetime.format('D MMM')
    const formattedDatetimes = `${formattedStart} - ${formattedEnd}`

    this.dateTimeFilterTarget.lastChild.data = formattedDatetimes
    this.dateTimeFilterTarget.classList.add('cnd-filter-btn--active')

    if (!this.hideDateTimePicker()) {
      this.dateTimePickerModalTarget.querySelector('.cnd-dismissible__icon').classList.add('cnd-hidden')
      this.dateTimePickerModalTarget.classList.remove('cnd-hidden')
    } else {
      this.dateTimeFilterTextTargets.forEach((el) => {
        el.classList.add('cnd-hidden')
      })
      this.dateTimePickerModalTarget.querySelector('.cnd-dismissible__icon').classList.remove('cnd-hidden')
    }

    if (this.element.dataset.fuzzyFilter) {
      this.calendarIconTarget.classList.add('cnd-hidden')
      this.hourglassIconTarget.classList.remove('cnd-hidden')
    } else {
      this.calendarIconTarget.classList.remove('cnd-hidden')
      this.hourglassIconTarget.classList.add('cnd-hidden')
    }
  }

  hideDateTimePicker() {
    const query = qs.parse(window.location.search.slice(1))

    return query.start_time_iso && query.end_time_iso
  }

  setFilterBodyType() {
    const carTypeFilters = [
      { name: 'Van', displayName: 'Van' },
      { name: 'Ute', displayName: L10n.t('web-search-filters.car-type.ute') },
      { name: 'Convertible', displayName: 'Convertible' },
      { name: 'SUV / 4WD', displayName: L10n.t('web-search-filters.car-type.suv-4wd') },
      { name: 'Small Car', displayName: 'Small Car' },
      { name: 'People Mover', displayName: L10n.t('web-search-filters.car-type.people-mover') },
      { name: 'Large Car', displayName: 'Large Car' },
      { name: 'Medium Car', displayName: 'Medium Car' },
      { name: 'Station Wagon', displayName: L10n.t('web-search-filters.car-type.station-wagon') },
    ]

    let selectedBodyTypeFilters = this.getCheckedValues(this.getVehicleSearchController.bodyTypeFilterTargets)

    selectedBodyTypeFilters = selectedBodyTypeFilters.map((bodyTypeFilter) => {
      const localisedBodyTypeFilter = carTypeFilters.find((filter) => filter.name === bodyTypeFilter)
      return localisedBodyTypeFilter ? localisedBodyTypeFilter.displayName : bodyTypeFilter
    })

    let displaySelections

    if (selectedBodyTypeFilters.length > 1 && this.bodyTypeText === 'Car type') {
      displaySelections = `${selectedBodyTypeFilters[0]} +${selectedBodyTypeFilters.length - 1}`
    } else if (selectedBodyTypeFilters.length > 2) {
      displaySelections = `${selectedBodyTypeFilters[0]}, ${selectedBodyTypeFilters[1]} +${
        selectedBodyTypeFilters.length - 2
      }`
    } else if (selectedBodyTypeFilters.length == 2) {
      displaySelections = `${selectedBodyTypeFilters[0]}, ${selectedBodyTypeFilters[1]}`
    } else if (selectedBodyTypeFilters.length == 1) {
      displaySelections = selectedBodyTypeFilters[0]
    } else {
      displaySelections = this.bodyTypeText
    }

    this.bodyTypeFilterTarget.lastChild.data = displaySelections
    if (displaySelections !== this.bodyTypeText) {
      this.bodyTypeFilterTarget.classList.add('cnd-filter-btn--active')
    } else {
      this.bodyTypeFilterTarget.classList.remove('cnd-filter-btn--active')
    }
  }

  setFilter(filterTarget, defaultText) {
    const selectedFilters = this.getCheckedValues(eval(`this.getVehicleSearchController.${filterTarget}Targets`))

    let displaySelections

    if (selectedFilters.length > 1) {
      displaySelections = `${selectedFilters[0]} + ${selectedFilters.length - 1}`
    } else if (selectedFilters.length == 1) {
      displaySelections = selectedFilters[0]
    } else {
      displaySelections = defaultText
    }

    eval(`this.${filterTarget}Target`).lastChild.data = displaySelections
    if (displaySelections !== defaultText) {
      eval(`this.${filterTarget}Target`).classList.add('cnd-filter-btn--active')
    } else {
      eval(`this.${filterTarget}Target`).classList.remove('cnd-filter-btn--active')
    }
  }

  setFilterPriceRange() {
    const lowerPriceRange = this.getVehicleSearchController.lowerPriceRangeTarget
    const upperPriceRange = this.getVehicleSearchController.upperPriceRangeTarget

    let displaySelections

    if (lowerPriceRange.dataset.dirty === 'true' && upperPriceRange.dataset.dirty === 'true') {
      displaySelections = `$${lowerPriceRange.value} - $${upperPriceRange.value}/day`
    } else {
      displaySelections = 'Price'
    }

    this.mainPriceFilterTarget.lastChild.data = displaySelections
    if (displaySelections !== 'Price') {
      this.mainPriceFilterTarget.classList.add('cnd-filter-btn--active')
    } else {
      this.mainPriceFilterTarget.classList.remove('cnd-filter-btn--active')
    }
  }

  setAdditionalFilters() {
    const bodyTypes = this.getCheckedValues(this.mainBodyTypeFilterTargets)
    const selectedSeats = this.seatsFilterTarget.value !== '' ? [1] : []
    const selectedDoors = this.doorsFilterTarget.value !== '' ? [1] : []
    const priceRange = this.hasLowerPriceRangeTarget && this.lowerPriceRangeTarget.dataset.dirty === 'true' ? [1] : []

    const selectedFilters = [
      ...bodyTypes,
      ...this.getCheckedValues(this.featureFilterTargets),
      ...this.getCheckedValues(this.keyTypeFilterTargets),
      ...this.getCheckedValues(this.transmissionFilterTargets),
      ...this.getCheckedValues(this.greenCarsFilterTargets),
      ...this.getCheckedValues(this.parkingFilterTargets),
      ...selectedSeats,
      ...selectedDoors,
      ...priceRange,
    ].length

    if (selectedFilters) {
      const filterCount = selectedFilters > 9 ? '9+' : selectedFilters

      this.additionalFilterTarget.lastChild.data = `Filters (${filterCount})`
      this.additionalFilterTarget.classList.add('cnd-filter-btn--active')
    } else {
      this.additionalFilterTarget.lastChild.data = 'Filters'

      if (this.additionalFilterTarget.classList.contains('cnd-filter-btn--active')) {
        this.additionalFilterTarget.classList.remove('cnd-filter-btn--active')
      }

      return false
    }
  }

  getCheckedValues(fieldset) {
    const checked = []

    for (const field of fieldset) {
      if (field.checked) {
        checked.push(field.getAttribute('value'))
      }
    }

    return checked
  }

  toggleBadge(event) {
    const isSelected = String(event.target.getAttribute('selected')) === 'true'
    event.target.setAttribute('selected', !isSelected)
    event.target.checked = !isSelected
  }

  syncCheckboxes(parent, child) {
    parent.forEach((parentElement) => {
      child.forEach((childElement) => {
        if (childElement.value == parentElement.value) {
          childElement.checked = parentElement.checked
        }
      })
    })
  }

  makeTargetDirty(target, isDirty) {
    target.dataset.dirty = isDirty
  }

  syncPriceRangeValue(fromTarget, toTarget) {
    fromTarget.value = toTarget.value
    fromTarget.dispatchEvent(new Event('input'))
  }

  setPriceRangeDefaultValue() {
    const defaultTargets = {
      lowerPriceRange: 20,
      upperPriceRange: 500,
      mainLowerPriceRange: 20,
      mainUpperPriceRange: 500,
    }

    for (const [key, value] of Object.entries(defaultTargets)) {
      const currentTarget = eval(`this.${key}Target`)
      currentTarget.value = value
      currentTarget.dispatchEvent(new Event('input'))
    }
  }

  get bodyTypeText() {
    return this.bodyTypeFilterTarget.dataset.originalText || 'Body type'
  }
}
