// Utils
import moment from 'moment'
import DateUtils from './date_utils'

export default class MobiscrollUtils {
  static DEFAULT_CALENDAR_OPTIONS = {
    theme: 'cnd',
    display: 'center',
    steps: {
      minute: 15,
      zeroBased: true,
    },
    timeWheels: 'HHii',
    timeFormat: 'HH:ii',
    showLabel: true,
    showScrollArrows: true,
    firstDay: 1,
    calendarText: 'Date',
    dateFormat: 'dd/mm/yy',
    controls: ['calendar', 'time'],
    min: DateUtils.newDatetimeNative(),
    onMonthLoaded(e, inst) {
      // Update date on month/year change
      const oldVal = inst.getVal(true)
      const newVal = moment(oldVal).month(e.month).year(e.year)

      if (newVal.isSame(oldVal)) {
        return
      }

      return inst.setVal(newVal.toDate(), false, false, true)
    },
  }

  static DEFAULT_RANGE_OPTIONS = {
    theme: 'cnd',
    display: 'inline',
    steps: {
      minute: 15,
      zeroBased: true,
    },
    timeWheels: 'HHii',
    timeFormat: 'HH:ii',
    showLabel: true,
    firstDay: 1,
    calendarText: 'Date',
    dateFormat: 'dd/mm/yy',
    fromText: 'Start',
    toText: 'End',
    showScrollArrows: true,
    buttons: ['cancel', 'set'],
    controls: ['calendar', 'time'],
    onSetDate: function (e, inst) {
      let oldVal = inst.getVal(true)

      // Exit if no start/end datetime
      if (!oldVal[0] || !oldVal[1]) return

      let startMoment = moment(oldVal[0])
      let endMoment = moment(oldVal[1])

      // Update start datetime if in past
      if (moment().isAfter(startMoment)) {
        startMoment = DateUtils.newDatetimeMoment()
      }

      // Set 1 hour as minimum range
      let minMoment = startMoment.clone().add(1, 'hour')
      if (endMoment.isBefore(minMoment)) {
        inst.setVal([startMoment.toDate(), minMoment.toDate()], false, false, true)
      }
    },
  }

  static DEFAULT_TIME_OPTIONS = {
    theme: 'cnd',
    display: 'inline',
    steps: {
      minute: 15,
      zeroBased: true,
    },
    timeWheels: 'HHii',
    timeFormat: 'HH:ii',
    showLabel: true,
    firstDay: 1,
    showScrollArrows: true,
    cssClass: 'time-picker',
    controls: ['time'],
    onSetDate: function (e, inst) {
      let oldVal = inst.getVal(true)

      // Exit if no start/end datetime
      if (!oldVal[0] || !oldVal[1]) return

      let startMoment = moment(oldVal[0])
      let endMoment = moment(oldVal[1])

      // Update start datetime if in past
      if (moment().isAfter(startMoment)) {
        startMoment = DateUtils.newTimeMoment()
      }

      // Set 1 hour as minimum range
      let minMoment = startMoment.clone().add(1, 'hour')
      if (endMoment.isBefore(minMoment)) {
        inst.setVal([startMoment.toDate(), minMoment.toDate()], false, false, true)
      }
    },
  }

  static DEFAULT_DATE_RANGE_OPTIONS = {
    theme: 'cnd',
    display: 'inline',
    steps: {
      minute: 15,
      zeroBased: true,
    },
    timeWheels: 'HHii',
    timeFormat: 'HH:ii',
    showLabel: false,
    showSelector: false,
    firstDay: 1,
    calendarText: 'Date',
    dateFormat: 'dd/mm/yy',
    fromText: 'Start',
    toText: 'End',
    showScrollArrows: true,
    cssClass: 'range-picker',
    min: DateUtils.newDatetimeNative(),
    controls: ['calendar'],
    onSetDate: function (e, inst) {
      let oldVal = inst.getVal(true)

      // Exit if no start/end datetime
      if (!oldVal[0] || !oldVal[1]) return

      let startMoment = moment(oldVal[0])
      let endMoment = moment(oldVal[1])

      // Update start datetime if in past
      if (moment().isAfter(startMoment)) {
        startMoment = DateUtils.newDatetimeMoment()
      }

      // Set 1 hour as minimum range
      let minMoment = startMoment.clone().add(1, 'hour')
      if (endMoment.isBefore(minMoment)) {
        inst.setVal([startMoment.toDate(), minMoment.toDate()], false, false, true)
      }
    },
  }

  static newCalendarInstance(el, extraOptions) {
    return $(el).mobiscroll().calendar(this.extendCalendarOptions(extraOptions)).mobiscroll('getInst')
  }

  static extendCalendarOptions(options) {
    return Object.assign({}, this.DEFAULT_CALENDAR_OPTIONS, options)
  }

  static setCalendarValue(el, value, updateInput = true) {
    return $(el).mobiscroll('setVal', value, updateInput)
  }

  static newRangeInstance(el, extraOptions) {
    return $(el).mobiscroll().range(this.extendRangeOptions(extraOptions)).mobiscroll('getInst')
  }

  static extendRangeOptions(options) {
    return Object.assign({}, this.DEFAULT_RANGE_OPTIONS, options)
  }

  static newTimeInstance(el, extraOptions, initOption) {
    return $(el).mobiscroll().calendar(this.extendTimeOptions(extraOptions)).mobiscroll(initOption)
  }

  static extendTimeOptions(options) {
    return Object.assign({}, this.DEFAULT_TIME_OPTIONS, options)
  }

  static newDateRangeInstance(el, extraOptions, initOption) {
    return $(el).mobiscroll().range(this.extendDateRangeOptions(extraOptions)).mobiscroll(initOption)
  }

  static extendDateRangeOptions(options) {
    return Object.assign({}, this.DEFAULT_DATE_RANGE_OPTIONS, options)
  }
}
