import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['button']

  initialize() {
    this.buttonTarget.setAttribute('aria-expanded', this.isOpen)
    this.buttonTarget.setAttribute('data-action', 'click->components--dropdown#toggle')
  }

  toggle() {
    this.element.classList.toggle('cnd-dropdown--open')
    this.buttonTarget.setAttribute('aria-expanded', this.isOpen)
    if (this.buttonTarget.getAttribute('data-text-hidden')) {
      this.changeDropdownText()
    }
  }

  changeDropdownText() {
    let text = this.buttonTarget.getAttribute('data-text-visible')
    if (!this.isOpen) {
      text = this.buttonTarget.getAttribute('data-text-hidden')
    }
    this.buttonTarget.innerText = text
  }

  get isOpen() {
    return this.element.classList.contains('cnd-dropdown--open')
  }
}
