import { Controller } from 'stimulus'
import { ACCEPTED_DOCUMENTS } from '../../constants/uploads'

import MobiscrollUtils from '../../utils/mobiscroll_utils'

export default class extends Controller {
  static targets = ['form', 'servicedDate', 'dropzone', 'submitBtn', 'confirmed']

  initialize() {
    if (this.servicedDateTargets.length > 0) {
      MobiscrollUtils.newCalendarInstance(this.servicedDateTargets, {
        headerText: 'Date of service',
        controls: ['calendar'],
        max: moment(),
        min: window.cndUtils.xMonthsBeforeCurrentDate(12),
        onSet: (e, inst) => {
          const dateTime = moment(inst.getVal())

          this.servicedDateTarget.value = dateTime.format('DD/MM/YYYY')
          this.enableSubmit()
        },
      })
    }

    this.dropzoneTarget.addEventListener('initializedFileUpload', () => this.setDropzoneConfig())
  }

  setDropzoneConfig() {
    this.dropzoneTarget.cnd.setOptions({
      acceptedFiles: ACCEPTED_DOCUMENTS,
    })

    this.dropzoneTarget.addEventListener('addedfile', () => {
      this.submitBtnTarget.disabled = true
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
    })

    this.dropzoneTarget.addEventListener('queuecomplete', () => {
      this.isFileUploaded = true
      this.enableSubmit()
    })
  }

  validateForm() {
    if (!this.isFileUploaded || !this.servicedDateTarget.value || !this.confirmedTarget.checked) {
      return false
    }

    return true
  }

  enableSubmit() {
    let isValid = this.validateForm()

    if (isValid) {
      window.onbeforeunload = null
      this.submitBtnTarget.disabled = false
    } else {
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
      this.submitBtnTarget.disabled = true
    }
  }
}
