import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    const { step, stepMax } = this.element.dataset

    this.addClass(this.element, 'cnd-flex cnd-w-screen sm:cnd-w-full cnd--ml-4 sm:cnd-ml-0')

    for (let i = 1; i <= stepMax; i++) {
      const $step = document.createElement('div')
      this.addClass($step, 'cnd-h-3 cnd-flex-1 cnd-bg-blue')

      if (i < stepMax) {
        this.addClass($step, 'cnd-mr-1')
      }

      if (i > step) {
        this.addClass($step, 'cnd-opacity-25')
      }

      this.element.appendChild($step)
    }
  }

  addClass($el, classString) {
    classString.split(/\s+/).forEach((_class) => $el.classList.add(_class))
  }
}
