import FaceTecStrings from './strings'

class FaceTecConfig {
  PublicFaceScanEncryptionKey = `-----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEA5PxZ3DLj+zP6T6HFgzzk
M77LdzP3fojBoLasw7EfzvLMnJNUlyRb5m8e5QyyJxI+wRjsALHvFgLzGwxM8ehz
DqqBZed+f4w33GgQXFZOS4AOvyPbALgCYoLehigLAbbCNTkeY5RDcmmSI/sbp+s6
mAiAKKvCdIqe17bltZ/rfEoL3gPKEfLXeN549LTj3XBp0hvG4loQ6eC1E1tRzSkf
GJD4GIVvR+j12gXAaftj3ahfYxioBH7F7HQxzmWkwDyn3bqU54eaiB7f0ftsPpWM
ceUaqkL2DZUvgN0efEJjnWy5y1/Gkq5GGWCROI9XG/SwXJ30BbVUehTbVcD70+ZF
8QIDAQAB
-----END PUBLIC KEY-----`

  currentCustomization
  userID = ''
  metadata = {}
  result = {}
  isInternational = false

  initialize(BaseURL, DeviceKeyIdentifier, ProductionKeyText) {
    this.BaseURL = BaseURL
    this.DeviceKeyIdentifier = DeviceKeyIdentifier
    this.ProductionKeyText = ProductionKeyText

    this.currentCustomization = this.getDefaultCustomization()
    FaceTecSDK.setCustomization(this.currentCustomization)

    return new Promise((resolve, reject) =>
      FaceTecSDK.initializeInProductionMode(
        this.ProductionKeyText,
        this.DeviceKeyIdentifier,
        this.PublicFaceScanEncryptionKey,
        (initializedSuccessfully) => {
          FaceTecSDK.configureLocalization(FaceTecStrings)

          const message = FaceTecSDK.getFriendlyDescriptionForFaceTecSDKStatus(FaceTecSDK.getStatus())

          initializedSuccessfully ? resolve(message) : reject(new Error(message))
        }
      )
    )
  }

  getDefaultCustomization() {
    const sdkImageDirectory = '/facetec/FaceTec_images/'

    // For Color Customization
    const outerBackgroundColor = 'rgba(255, 255, 255, 0.5)'
    const frameColor = '#ffffff'
    const borderColor = '#417FB2'
    const ovalColor = '#417FB2'
    const dualSpinnerColor = '#417FB2'
    const textColor = '#417FB2'
    const buttonAndFeedbackBarColor = '#417FB2'
    const buttonAndFeedbackBarTextColor = '#ffffff'
    const buttonColorPressed = '#417FB2'

    // For Frame Corner Radius Customization
    let frameCornerRadius = '20px'

    // For Cancel Button Customization
    const cancelButtonImage = `${sdkImageDirectory}FaceTec_cancel.png`
    const cancelButtonLocation = FaceTecSDK.FaceTecCancelButtonLocation.TopLeft

    // For image Customization
    const securityWatermarkImage = FaceTecSDK.FaceTecSecurityWatermarkImage.FaceTec

    // Set a default customization
    const defaultCustomization = new FaceTecSDK.FaceTecCustomization()

    // Set Frame Customization
    defaultCustomization.frameCustomization.borderCornerRadius = frameCornerRadius
    defaultCustomization.frameCustomization.backgroundColor = frameColor
    defaultCustomization.frameCustomization.borderColor = borderColor

    // Set Overlay Customization
    defaultCustomization.overlayCustomization.showBrandingImage = true
    defaultCustomization.overlayCustomization.backgroundColor = outerBackgroundColor

    // Set Guidance Customization
    defaultCustomization.guidanceCustomization.backgroundColors = frameColor
    defaultCustomization.guidanceCustomization.foregroundColor = textColor
    defaultCustomization.guidanceCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor
    defaultCustomization.guidanceCustomization.buttonBackgroundDisabledColor = buttonColorPressed
    defaultCustomization.guidanceCustomization.buttonBackgroundHighlightColor = buttonColorPressed
    defaultCustomization.guidanceCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor
    defaultCustomization.guidanceCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor
    defaultCustomization.guidanceCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor
    defaultCustomization.guidanceCustomization.retryScreenImageBorderColor = borderColor
    defaultCustomization.guidanceCustomization.retryScreenOvalStrokeColor = borderColor

    // Set Oval Customization
    defaultCustomization.ovalCustomization.strokeColor = ovalColor
    defaultCustomization.ovalCustomization.progressColor1 = dualSpinnerColor
    defaultCustomization.ovalCustomization.progressColor2 = dualSpinnerColor

    // Set Feedback Customization
    defaultCustomization.feedbackCustomization.backgroundColor = buttonAndFeedbackBarColor
    defaultCustomization.feedbackCustomization.textColor = buttonAndFeedbackBarTextColor

    // Set Cancel Customization
    defaultCustomization.cancelButtonCustomization.customImage = cancelButtonImage
    defaultCustomization.cancelButtonCustomization.location = cancelButtonLocation

    // Set Security Watermark Customization
    defaultCustomization.securityWatermarkCustomization.setSecurityWatermarkImage(securityWatermarkImage)

    // Set Result Screen Customization
    defaultCustomization.resultScreenCustomization.backgroundColors = frameColor
    defaultCustomization.resultScreenCustomization.foregroundColor = textColor
    defaultCustomization.resultScreenCustomization.activityIndicatorColor = buttonAndFeedbackBarColor
    defaultCustomization.resultScreenCustomization.resultAnimationBackgroundColor = buttonAndFeedbackBarColor
    defaultCustomization.resultScreenCustomization.resultAnimationForegroundColor = buttonAndFeedbackBarTextColor
    defaultCustomization.resultScreenCustomization.uploadProgressFillColor = buttonAndFeedbackBarColor

    // Set ID Scan Customization
    defaultCustomization.idScanCustomization.selectionScreenBackgroundColors = frameColor
    defaultCustomization.idScanCustomization.selectionScreenForegroundColor = textColor
    defaultCustomization.idScanCustomization.reviewScreenBackgroundColors = frameColor
    defaultCustomization.idScanCustomization.reviewScreenForegroundColor = buttonAndFeedbackBarTextColor
    defaultCustomization.idScanCustomization.reviewScreenTextBackgroundColor = buttonAndFeedbackBarColor
    defaultCustomization.idScanCustomization.captureScreenForegroundColor = buttonAndFeedbackBarTextColor
    defaultCustomization.idScanCustomization.captureScreenTextBackgroundColor = buttonAndFeedbackBarColor
    defaultCustomization.idScanCustomization.buttonBackgroundNormalColor = buttonAndFeedbackBarColor
    defaultCustomization.idScanCustomization.buttonBackgroundDisabledColor = buttonColorPressed
    defaultCustomization.idScanCustomization.buttonBackgroundHighlightColor = buttonColorPressed
    defaultCustomization.idScanCustomization.buttonTextNormalColor = buttonAndFeedbackBarTextColor
    defaultCustomization.idScanCustomization.buttonTextDisabledColor = buttonAndFeedbackBarTextColor
    defaultCustomization.idScanCustomization.buttonTextHighlightColor = buttonAndFeedbackBarTextColor
    defaultCustomization.idScanCustomization.captureScreenBackgroundColor = frameColor
    defaultCustomization.idScanCustomization.captureFrameStrokeColor = borderColor

    // Set Initial Loading Customization
    defaultCustomization.initialLoadingAnimationCustomization.backgroundColor = buttonAndFeedbackBarTextColor
    defaultCustomization.initialLoadingAnimationCustomization.foregroundColor = buttonAndFeedbackBarColor

    return defaultCustomization
  }

  setCustomization(callback) {
    this.currentCustomization = callback(this.currentCustomization)
    FaceTecSDK.setCustomization(this.currentCustomization)
  }
}

const FaceTecConfigInstance = new FaceTecConfig()

export default FaceTecConfigInstance
