import Config from './config'
import NetworkingHelpers from './networking_helpers'

class FaceTec {
  promise
  sessionResult
  faceScanResultCallback
  idScanResult
  idScanResultCallback

  initialize(baseURL, deviceKeyIdentifier, browserKey) {
    FaceTecSDK.setResourceDirectory('/facetec/FaceTecSDK.js/resources')
    FaceTecSDK.setImagesDirectory('/facetec/FaceTec_images')

    return Config.initialize(baseURL, deviceKeyIdentifier, browserKey)
  }

  idCheck(userId, isInternational, allowFailedMatch) {
    Config.isInternational = isInternational
    Config.allowFailedMatch = allowFailedMatch

    return new Promise((resolve, reject) => {
      this.promise = { resolve, reject }

      NetworkingHelpers.getSessionToken()
        .then((sessionToken) => {
          Config.userId = String(userId)

          new FaceTecSDK.FaceTecSession(this, sessionToken)
        })
        .catch(() => {
          this.promise.reject('Session could not be started due to an unexpected issue during the network request.')
        })
    })
  }

  processSessionResultWhileFaceTecSDKWaits(sessionResult, faceScanResultCallback) {
    this.sessionResult = sessionResult
    this.faceScanResultCallback = faceScanResultCallback

    if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
      NetworkingHelpers.cancelPendingRequests()
      faceScanResultCallback.cancel()

      this.promise.reject(
        `Session was not completed successfully, cancelling. Session Status: ${
          FaceTecSDK.FaceTecSessionStatus[sessionResult.status]
        }`
      )

      this.faceScanResultCallback = null
      return
    }

    NetworkingHelpers.getEnrollmentResponseFromZoomServer(sessionResult, faceScanResultCallback)
      .then((responseJSON) => {
        if (
          responseJSON.success ||
          responseJSON.errorMessage === 'An enrollment already exists for this externalDatabaseRefID.'
        ) {
          this.faceScanSuccess()
        } else {
          this.faceScanResultCallback.retry()
        }
      })
      .catch((error) => {
        this.faceScanResultCallback.cancel()
        this.promise.reject(error.message)
      })
  }

  faceScanSuccess() {
    this.isRetry = false
    Config.metadata = {}
    Config.result = {}
    FaceTecSDK.FaceTecCustomization.overrideResultScreenSuccessMessage = 'Liveness\nConfirmed'
    this.faceScanResultCallback.succeed(FaceTecSDK.FaceTecIDScanNextStep.SelectIDCard)
  }

  processIDScanResultWhileFaceTecSDKWaits(idScanResult, idScanResultCallback) {
    this.idScanResult = idScanResult
    this.idScanResultCallback = idScanResultCallback

    if (idScanResult.status !== FaceTecSDK.FaceTecIDScanStatus.Success) {
      NetworkingHelpers.cancelPendingRequests()
      idScanResultCallback.cancel()
      return
    }

    NetworkingHelpers.getPhotoIDMatchResponseFromZoomServer(idScanResult, idScanResultCallback)
      .then((responseJSON) => {
        if (responseJSON.success || (this.isRetry && Config.allowFailedMatch)) {
          this.idMatchComplete(responseJSON)
        } else if (Config.isInternational && Config.result.idFront) {
          this.isRetry = true
          idScanResultCallback.retry(FaceTecSDK.FaceTecIDScanRetryMode.Front, 'Passport not fully visible')
        } else {
          this.isRetry = true
          idScanResultCallback.retry(FaceTecSDK.FaceTecIDScanRetryMode.FrontAndBack, 'Driver licence not fully visible')
        }
      })
      .catch((error) => {
        faceScanResultCallback.cancel()
        this.promise.reject(error.message)
      })
  }

  idMatchComplete(responseJSON) {
    try {
      const result = {}

      if (Config.isInternational) {
        if (!Config.result.idFront) {
          this.faceScanResultCallback.succeed(FaceTecSDK.FaceTecIDScanNextStep.SelectPassport)

          this.setMetadata('licence', responseJSON)
          Config.result.idFront = this.idScanResult.frontImages[0]
          Config.result.idBack = this.idScanResult.backImages[0]

          this.isRetry = false
          return
        }

        this.setMetadata('passport', responseJSON)
        result.passport = this.idScanResult.frontImages[0]
        result.idFront = Config.result.idFront
        result.idBack = Config.result.idBack
      } else {
        this.setMetadata('licence', responseJSON)
        result.idFront = this.idScanResult.frontImages[0]
        result.idBack = this.idScanResult.backImages[0]
      }

      result.faceScan = this.sessionResult.auditTrail[0]
      result.metadata = JSON.stringify(Config.metadata)

      Config.result = result

      this.isSuccess = true
      FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage(
        responseJSON.success ? 'Your face matched your ID' : 'ID scan complete'
      )
      this.idScanResultCallback.succeed()
    } catch (e) {
      this.idScanResultCallback.cancel()
    }
  }

  setMetadata(idType, responseJSON) {
    Config.metadata[idType] = {
      matched: responseJSON.success,
      matchLevel: responseJSON.matchLevel,
    }
  }

  onFaceTecSDKCompletelyDone() {
    if (this.isSuccess) {
      this.promise.resolve(Config.result)
    } else {
      let statusString = 'Unsuccess. '

      if (this.idScanResult && this.idScanResult.status) {
        const { Success, Unsuccess, UserCancelled, TimedOut, ContextSwitch, CameraError, CameraNotEnabled, Skipped } =
          FaceTecSDK.FaceTecIDScanStatus

        statusString +=
          {
            [Success]: 'The ID Scan was successful.',
            [Unsuccess]: 'The ID Scan was not successful.',
            [UserCancelled]: 'User cancelled ID Scan.',
            [TimedOut]: 'Timeout during ID Scan.',
            [ContextSwitch]: 'Context Switch during ID Scan.',
            [CameraError]: 'Error setting up the ID Scan Camera.',
            [CameraNotEnabled]: 'Camera Permissions were not enabled.',
            [Skipped]: 'ID Scan was skipped.',
          }[this.idScanResult.status] || ''
      } else if (this.sessionResult && this.sessionResult.status) {
        if (
          this.sessionResult.status == FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully &&
          !this.idScanResult
        ) {
          statusString += 'Session was completed but an unexpected issue occurred during the network request.'
        } else {
          const {
            SessionCompletedSuccessfully,
            MissingGuidanceImages,
            Timeout,
            ContextSwitch,
            ProgrammaticallyCancelled,
            OrientationChangeDuringSession,
            LandscapeModeNotAllowed,
            UserCancelled,
            UserCancelledFromNewUserGuidance,
            UserCancelledFromRetryGuidance,
            UserCancelledWhenAttemptingToGetCameraPermissions,
            LockedOut,
            CameraNotEnabled,
            NonProductionModeDeviceKeyIdentifierInvalid,
            DocumentNotReady,
            SessionInProgress,
            CameraNotRunning,
            InitializationNotCompleted,
            UnknownInternalError,
            UserCancelledViaClickableReadyScreenSubtext,
            NotAllowedUseIframeConstructor,
            StillLoadingResources,
            NotAllowedUseNonIframeConstructor,
            IFrameNotAllowedWithoutPermission,
            ResourcesCouldNotBeLoadedOnLastInit,
            UserCancelledFullScreenMode,
          } = FaceTecSDK.FaceTecSessionStatus

          statusString +=
            {
              [SessionCompletedSuccessfully]:
                'The Session was performed successfully and a FaceScan was generated. Pass the FaceScan to the Server for further processing.',
              [MissingGuidanceImages]: 'The Session was cancelled because not all guidance images were configured.',
              [Timeout]:
                'The Session was cancelled because the user was unable to complete a Session in the default allotted time or the timeout set by the developer.',
              [ContextSwitch]:
                'The Session was cancelled due to the app being terminated, put to sleep, an OS notification, or the app was placed in the background.',
              [ProgrammaticallyCancelled]: 'The developer programmatically called the Session cancel API.',
              [OrientationChangeDuringSession]:
                'The Session was cancelled due to a device orientation change during the Session.',
              [LandscapeModeNotAllowed]:
                'The Session was cancelled because device is in landscape mode. The user experience of devices in these orientations is poor and thus portrait is required.',
              [UserCancelled]: 'The user pressed the cancel button and did not complete the Session.',
              [UserCancelledFromNewUserGuidance]: 'The user pressed the cancel button during New User Guidance.',
              [UserCancelledFromRetryGuidance]: 'The user pressed the cancel button during Retry Guidance.',
              [UserCancelledWhenAttemptingToGetCameraPermissions]:
                'The user cancelled out of the the FaceTec Browser SDK experience while attempting to get camera permissions.',
              [LockedOut]: 'The Session was cancelled because the user was in a locked out state.',
              [CameraNotEnabled]: 'The Session was cancelled because camera was not enabled.',
              [NonProductionModeDeviceKeyIdentifierInvalid]:
                'This status will never be returned in a properly configured or production app. This status is returned if your Key is invalid or network connectivity issues occur during a session when the application is not in production.',
              [DocumentNotReady]:
                'The Session was cancelled because the FaceTec Browser SDK cannot be rendered when the document is not ready.',
              [SessionInProgress]: 'The Session was cancelled because there was another Session in progress.',
              [CameraNotRunning]: 'The Session was cancelled because the selected camera is not active.',
              [InitializationNotCompleted]:
                'The Session was cancelled because initialization has not been completed yet.',
              [UnknownInternalError]:
                'The Session was cancelled because of an unknown and unexpected error.  The FaceTec Browser SDK leverages a variety of platform APIs including camera, storage, security, networking, and more. This return value is a catch-all for errors experienced during normal usage of these APIs.',
              [UserCancelledViaClickableReadyScreenSubtext]:
                'The Session cancelled because user pressed the Get Ready screen subtext message.  Note: This functionality is not available by default, and must be requested from FaceTec in order to enable.',
              [NotAllowedUseIframeConstructor]:
                'The Session was cancelled, the FaceTec Browser SDK was opened in an Iframe without an Iframe constructor.',
              [NotAllowedUseNonIframeConstructor]:
                'The Session was cancelled, the FaceTec Browser SDK was not opened in an Iframe with an Iframe constructor.',
              [IFrameNotAllowedWithoutPermission]:
                'The Session was cancelled, the FaceTec Browser SDK was not opened in an Iframe without permission.',
              [StillLoadingResources]: 'FaceTec SDK is still loading resources.',
              [ResourcesCouldNotBeLoadedOnLastInit]: 'FaceTec SDK could not load resources.',
              [UserCancelledFullScreenMode]:
                'The Session was cancelled because a full screen mode change was detected in an IFrame',
            }[this.sessionResult.status] || ''
        }
      } else {
        statusString += 'FaceTec session result is empty.'
      }

      this.promise.reject(statusString.trim())
    }
  }

  isBrowserSupported() {
    try {
      const testBrowserSupport = new Function(
        "'use strict'; const testConstSupport = 0; var testWorkerSupport = Worker.length; var testWasmSupport = WebAssembly.Module;"
      )
      testBrowserSupport()

      return true
    } catch (e) {
      return false
    }
  }
}

const FaceTecInstance = new FaceTec()

export default FaceTecInstance
