import { Controller } from 'stimulus'
import * as axios from 'axios'
import qs from 'qs'
import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'

export default class extends Controller {
  static targets = ['nameContainer', 'saveBtn', 'nameField', 'name']

  memberId

  initialize() {
    this.memberId = this.element.getAttribute('data-member-id')
  }

  editName() {
    this.nameContainerTarget.classList.add('cnd-hidden')
    this.saveBtnTarget.classList.remove('cnd-hidden')
    this.nameFieldTarget.classList.remove('cnd-hidden')
    this.nameFieldTarget.focus()
  }

  save() {
    const params = { preferred_name: this.nameFieldTarget.value }

    axios
      .put(`./members/${this.memberId}`, params, AxiosUtils.getHeaders())
      .then((res) => {
        const preferredName = JSON.parse(res.config.data).preferred_name
        this.nameTarget.innerHTML = preferredName
        this.nameContainerTarget.classList.remove('cnd-hidden')
        this.saveBtnTarget.classList.add('cnd-hidden')
        this.nameFieldTarget.classList.add('cnd-hidden')
        FlashMessageUtils.showSuccess('Successfully updated!')
      })
      .catch((error) => {
        const data = error.response.data
        this.saveBtnTarget.innerHTML = 'Try again'
        FlashMessageUtils.showWarning(data.message)
      })
  }

  cancel() {
    this.nameContainerTarget.classList.remove('cnd-hidden')
    this.saveBtnTarget.classList.add('cnd-hidden')
    this.nameFieldTarget.classList.add('cnd-hidden')
  }
}
