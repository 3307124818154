import { Controller } from 'stimulus'

import * as axios from 'axios'

import FirebaseAuthUtils from '../utils/firebase_auth_utils'
import AxiosUtils from '../utils/axios_utils'
import LoaderUtils from '../utils/loader_utils'

export default class extends Controller {
  static targets = ['ssoContainer', 'ssoErrors']

  initialize() {
    if (this.hasSsoContainerTarget) {
      this.initializeSSO()
    }
  }

  initializeSSO() {
    FirebaseAuthUtils.initialiseFirebaseUI({
      el: this.ssoContainerTarget,
      onSuccess: this.successCallback,
    })
  }

  successCallback = (authResult, _redirectUrl) => {
    LoaderUtils.showPageLoader()

    const handleTurboAuthError = this.handleTurboAuthError.bind(this)

    authResult.user.getIdToken().then(function (idToken) {
      // Get auth token and post to usual sign in path
      const providerId = authResult.user.providerData[0].providerId
      const params = {
        email: authResult.user.email || authResult.user.providerData.email,
        firebase_login_token: idToken,
        fingerprint: window.cndBrowserFingerprint,
        provider: providerId,
      }

      axios({
        method: 'post',
        url: '/member_sessions',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
        },
        params: params,
      })
        .then((res) => {
          if (res.data.login_with_uber) {
            window.location.replace(`/login_with_uber/${res.data.member_id}`)
          } else {
            window.location.replace(res.data.path || '/login')
          }
        })
        .catch(function (error) {
          handleTurboAuthError(error.response.data.message)
        })
    })

    // Return false to prevent automatic redirect to signInSuccessUrl
    // (use the redirect path from the api response instead)
    false
  }

  handleTurboAuthError(message) {
    if (message) {
      this.ssoErrorsTarget.innerText = message
    }
    this.initializeSSO()
  }
}
