import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'panel']

  initialize() {
    this.activeTab = this.activeTab()
  }

  connect() {
    this.loadSelectedTab(this.activeTab)
    let target = document.querySelector('#tab_' + this.activeTab)
    this.setIndicator(target)
  }

  activeTab() {
    return document.getElementById('paymentPreferencesTabs').getAttribute('data-active-tab')
  }

  loadSelectedTab(target) {
    this.tabTargets.forEach((t) => {
      if (target === t.dataset.tab) {
        t.classList.add('is-active')
      } else {
        t.classList.remove('is-active')
      }
    })

    this.panelTargets.forEach((p) => {
      if (target === p.dataset.panel) {
        p.classList.remove('cnd-hidden')
      } else {
        p.classList.add('cnd-hidden')
      }
    })
  }

  setIndicator(target) {
    this.indicator = document.querySelector('.nav-indicator')

    this.indicator.style.width = `${target.offsetWidth}px`
    this.indicator.style.left = `${target.offsetLeft}px`
    this.indicator.style.backgroundColor = 'black'
  }

  loadTab(e) {
    this.activeTab = e.target.dataset.tab
    this.loadSelectedTab(e.target.dataset.tab)
    this.setIndicator(e.target)
  }
}
