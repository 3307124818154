import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image']

  images

  initialize() {
    if (!this.hasImageTarget) return

    this.images = this.imageTargets

    this.load()
    this.addEventListeners()
  }

  load() {
    if (!this.images) return

    this.images.forEach((image) => {
      if (!this.isALazyImage(image) || !this.isImageVisible(image)) return

      image.src = image.dataset.src
      image.onload = () => image.classList.add('loaded')
    })
  }

  addEventListeners() {
    self = this

    window.addEventListener(
      'scroll',
      _.throttle((e) => {
        if (!e.target.images) return

        self.images = [...e.target.images]
        self.load()
      }, 16)
    )

    window.addEventListener(
      'resize',
      _.throttle((e) => {
        if (!e.target.images) return

        self.images = [...e.target.images]
        self.load()
      }, 16)
    )
  }

  isALazyImage(image) {
    return !image.classList.contains('loaded') && image.classList.contains('lazy-load')
  }

  isImageVisible(image) {
    const rect = image.getBoundingClientRect()

    const windowHeight = window.innerHeight || document.documentElement.clientHeight
    const windowWidth = window.innerWidth || document.documentElement.clientWidth

    const verticalInView = rect.top <= windowHeight && rect.top + rect.height >= 0
    const horizontalInView = rect.left <= windowWidth && rect.left + rect.width >= 0

    return verticalInView && horizontalInView
  }
}
