// global google
import { Controller } from 'stimulus'

// Utils
import EventUtils from '../../utils/event_utils'
import LocationUtils from '../../utils/location_utils'

export default class extends Controller {
  static targets = [
    'address',
    'country',
    'emptyValueError',
    'notServiceableError',
    'hint',
    'latitude',
    'longitude',
    'map',
    'mapbox',
    'number',
    'postCode',
    'state',
    'street',
    'suburb',
    'vehicle',
  ]

  initialize() {
    // eslint-disable-next-line no-undef
    const geocoder = new MapboxGeocoder({
      accessToken: process.env.MAPBOX_ACCESS_TOKEN,
      types: this.setAvailableLocationTypes(),
      placeholder: this.mapboxTarget.dataset.placeholder || '',
      countries: this.mapboxTarget.dataset.countryCode || 'au',
      language: 'en',
    })

    this.mapboxTarget.appendChild(geocoder.onAdd())
    this.mapboxInputField = this.mapboxTarget.getElementsByClassName('mapboxgl-ctrl-geocoder--input')[0]
    this.mapboxInputField.classList.add('cnd-autocomplete')
    this.mapboxInputField.classList.remove('mapboxgl-ctrl-geocoder--input')
    if (!this.element.classList.contains('cnd-hidden')) {
      this.mapboxInputField.setAttribute('required', true)
    }

    if (this.mapboxTarget.dataset.customKlasses) {
      this.mapboxInputField.className += ` ${this.mapboxTarget.dataset.customKlasses}`
    }

    if (this.mapboxTarget.dataset.inputTarget) {
      this.mapboxInputField.dataset.target = this.mapboxTarget.dataset.inputTarget
    }

    if (this.mapboxTarget.dataset.inputAction) {
      this.mapboxInputField.dataset.action = this.mapboxTarget.dataset.inputAction
    }

    this.mapboxInputField.addEventListener('keypress', (e) => {
      const key = e.which || e.keyCode
      if (key === 13) e.preventDefault()
    })

    if (this.mapboxTarget.dataset.location) {
      this.mapboxInputField.value = this.mapboxTarget.dataset.location
    }

    geocoder.on('result', ({ result }) => {
      const address = LocationUtils.parseMapboxResults(result)

      if (address.formattedAddress != this.addressTarget.value) {
        this.update(address)
        let currentPage = this.mapboxTarget.dataset.currentPage
        if (currentPage == 'new_listing_page' || currentPage == 'lockbox_insturction_page') {
          this.validatePostcode(address)
        }
      } else {
        if (this.mapboxTarget.dataset.currentPage == 'new_listing_page') {
          this.updateFormattedAddress(address)
        }
      }

      // Hack for https://github.com/mapbox/mapbox-gl-geocoder/issues/309
      geocoder._typeahead.selected = null
    })

    if (this.hasMapTarget) {
      this.mapTarget.addEventListener('markerUpdated', (e) => {
        const longLat = e.args

        geocoder.geocoderService
          .reverseGeocode({
            query: [longLat.lng, longLat.lat],
            limit: 1,
          })
          .send()
          .then((response) => {
            const address = LocationUtils.parseMapboxResults(response.body.features[0])

            this.update(address)
          })
      })
    }
  }

  setAvailableLocationTypes() {
    return 'locality,postcode,address,place,poi'
  }

  update(address) {
    if (!address) {
      return
    }

    if (address.formattedAddress) {
      this.addressTarget.value = address.formattedAddress

      this.updateFormattedAddress(address)
    }

    if (this.hasLatitudeTarget) {
      this.latitudeTarget.value = address.latitude
    }

    if (this.hasLongitudeTarget) {
      this.longitudeTarget.value = address.longitude
    }

    if (this.hasNumberTarget) {
      this.numberTarget.value = address.number
    }

    if (this.hasStreetTarget) {
      this.streetTarget.value = address.street
    }

    if (this.hasSuburbTarget) {
      this.suburbTarget.value = address.suburb
    }

    if (this.hasStateTarget) {
      this.stateTarget.value = address.state
    }

    if (this.hasPostCodeTarget) {
      this.postCodeTarget.value = address.postCode

      document.dispatchEvent(
        new CustomEvent('updateEarningsEstimate', {
          detail: {
            post_code: this.postCodeTarget.value,
            vehicle: this.hasVehicleTarget ? this.vehicleTarget.value : null,
          },
        })
      )
    }

    if (this.hasCountryTarget) {
      this.countryTarget.value = address.country
    }

    if (this.hasMapTarget) {
      EventUtils.dispatch(this.mapTarget, 'locationUpdated', {
        latitude: address.latitude,
        longitude: address.longitude,
      })
    } else {
      EventUtils.dispatch(this.addressTarget, 'locationUpdated', {
        location: address.formattedAddress,
        latitude: address.latitude,
        longitude: address.longitude,
        postCode: address.postCode,
      })
    }
  }

  validatePostcode(address) {
    if (address.postCode) {
      const supportedPostcodesList = JSON.parse(this.mapboxTarget.dataset.supportedPostcodes)
      const blacklistedPostcodesList = JSON.parse(this.mapboxTarget.dataset.blacklistedPostcodes)

      switch (this.mapboxTarget.dataset.countryCode) {
        case 'AU': {
          this.postcodeValid()
          return
        }
        case 'US': {
          if (blacklistedPostcodesList.includes(address.postCode)) {
            this.postcodeInvalid('not_serviceable')
          } else if (supportedPostcodesList.includes(address.postCode)) {
            this.postcodeValid()
          } else {
            this.postcodeInvalid('not_serviceable')
          }
          return
        }
        case 'CA': {
          let postcodePrefix = address.postCode.substring(0, 3)
          let sanitizedPostcode = address.postCode.replaceAll(' ', '')

          if (blacklistedPostcodesList.includes(sanitizedPostcode)) {
            this.postcodeInvalid('not_serviceable')
          } else if (supportedPostcodesList.includes(postcodePrefix)) {
            this.postcodeValid()
          } else {
            this.postcodeInvalid('not_serviceable')
          }
          return
        }
      }
    } else {
      this.postcodeInvalid('empty_value')
    }
  }

  postcodeValid() {
    this.emptyValueErrorTarget.classList.add('cnd-hidden')
    if (this.hasNotServiceableErrorTarget) {
      this.notServiceableErrorTarget.classList.add('cnd-hidden')
    }
    this.hintTarget.classList.remove('cnd-hidden')
  }

  postcodeInvalid(type) {
    if (type == 'empty_value') {
      if (this.hasNotServiceableErrorTarget) {
        this.notServiceableErrorTarget.classList.add('cnd-hidden')
      }
      this.emptyValueErrorTarget.classList.remove('cnd-hidden')
    } else if (type == 'not_serviceable') {
      this.emptyValueErrorTarget.classList.add('cnd-hidden')
      if (this.hasNotServiceableErrorTarget) {
        this.notServiceableErrorTarget.classList.remove('cnd-hidden')
      }
    }
    this.hintTarget.classList.add('cnd-hidden')
  }

  updateFormattedAddress(address) {
    if (this.mapboxTarget.dataset.postcodeLookup) {
      let components = []

      if (address.suburb) {
        components.push(address.suburb)
      }

      if (address.state) {
        components.push(address.state)
      }

      if (address.postCode) {
        components.push(address.postCode)
      }

      this.mapboxInputField.value = components.join(' ')
    } else {
      this.mapboxInputField.value = address.formattedAddress
    }
  }
}
