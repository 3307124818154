import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['tab', 'panel']

  showTab(e) {
    this.tabTargets.forEach((t) => {
      if (e.target === t) {
        t.classList.add('active')
      } else {
        t.classList.remove('active')
      }
    })

    this.panelTargets.forEach((p) => {
      if (e.target.dataset.tab === p.dataset.panel) {
        p.classList.remove('cnd-hidden')
      } else {
        p.classList.add('cnd-hidden')
      }
    })
  }
}
