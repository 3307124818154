import { Controller } from 'stimulus'

// Constants
import { ISSUES_MAP } from '../../constants/reviews'

export default class extends Controller {
  static targets = ['issue']

  initialize() {
    this.updateIssues()
  }

  updateIssues() {
    this.issueTargets.forEach((el) => {
      el.innerHTML = this.getIssueHTML(el.dataset.val, el.dataset.type)
    })
  }

  getIssueHTML(i, type) {
    const NOT_REGEX = /(not_)/
    let text = '',
      bad = NOT_REGEX.test(i),
      css = ''
    text = ISSUES_MAP[type][i.replace(NOT_REGEX, '')]
    css = bad ? 'cnd-icon-row__icon--x cnd-border-grey-dark' : 'cnd-icon-row__icon--check'
    return `<i class="fa ${css} cnd-mr-2"></i>${text}`
  }
}
