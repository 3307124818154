import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['noSpareKey', 'noSpareKeyWarning', 'insurableValueModal', 'kilometresModal']

  showSpareKeyWarning() {
    this.noSpareKeyWarningTarget.classList.toggle('cnd-hidden', !this.noSpareKeyTarget.checked)
  }

  showInsurableValueModal() {
    this.insurableValueModalTarget.classList.remove('cnd-hidden')
    this.insurableValueModalTarget.classList.add('cnd-show')
  }

  hideInsurableValueModal() {
    this.insurableValueModalTarget.classList.remove('cnd-show')
    this.insurableValueModalTarget.classList.add('cnd-hidden')
  }

  showKilometresModal() {
    this.kilometresModalTarget.classList.remove('cnd-hidden')
    this.kilometresModalTarget.classList.add('cnd-show')
  }

  hideKilometresModal() {
    this.kilometresModalTarget.classList.remove('cnd-show')
    this.kilometresModalTarget.classList.add('cnd-hidden')
  }
}
