import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['discoveredAnIssueNextButton', 'discoveredAnIssueRequirementsList', 'discoveredAnIssueForm']

  initialize() {
    this.toggleDiscoveredAnIssueNextButton()
  }

  submitDiscoveredAnIssueForm() {
    this.discoveredAnIssueFormTarget.submit()
  }

  toggleDiscoveredAnIssueNextButton() {
    const discoveredIssues = document.querySelectorAll('input[name="issues[]"]:checked')

    if (discoveredIssues.length == 0) {
      this.discoveredAnIssueNextButtonTarget.disabled = true
      this.discoveredAnIssueRequirementsListTarget.classList.add('cnd-hidden')
    } else {
      this.discoveredAnIssueNextButtonTarget.disabled = false
      this.discoveredAnIssueRequirementsListTarget.classList.remove('cnd-hidden')
    }
  }
}
