import moment from 'moment'

export default class DateUtils {
  static DATETIME_FORMAT = 'DD/MM/YYYY HH:mm'
  static DATETIME_ISO_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
  static DATETIME_FORMAL_FORMAT = 'ddd DD, HH:mm'
  static DATETIME_LABEL_FORMAT = 'DD MMM YYYY'
  static TIME = 'HH:mm'
  static DAY = 'ddd'
  static DAY_TIME = 'ddd, HH:mm'
  static DAY_SHORT_DATE = 'ddd, DD MMM'
  static DAY_SHORT_DATETIME = 'ddd, DD MMM HH:mm'

  static defaultStartMoment() {
    return this.newDatetimeMoment()
  }

  static defaultEndMoment() {
    return this.newDatetimeMoment().add(3, 'hours')
  }

  static newDatetimeMoment() {
    const _moment = moment()
    const remainder = 15 - (_moment.minute() % 15)

    return _moment.add(remainder, 'minutes')
  }

  static defaultStartTime() {
    const now = this.newDateTime()
    return new Date(now.setHours(now.getHours() + 3))
  }

  static defaultEndTime() {
    const now = this.newDateTime()
    const threeHours = 3 * 60 * 60 * 1000
    return new Date(now.setTime(now.getTime() + threeHours))
  }

  static newDateTime() {
    const now = new Date()
    const minuteToNextQuarter = 15 - (now.getMinutes() % 15)
    now.setTime(now.getTime() + minuteToNextQuarter * 60 * 1000)

    return now
  }

  static newDatetimeNative({ hoursToAdd = 0 } = {}) {
    return this.newDatetimeMoment().add(hoursToAdd, 'hours').toDate()
  }

  static newDatetimeFormatted({ hoursToAdd = 0 } = {}) {
    return this.newDatetimeMoment().add(hoursToAdd, 'hours').format(this.DATETIME_FORMAT)
  }

  static format(date) {
    return moment(date).format(this.DATETIME_FORMAT)
  }

  static displayRange(start, end) {
    const startMoment = moment(start)
    const endMoment = moment(end)

    // Formats
    const timeRange = `${startMoment.format(this.TIME)} - ${endMoment.format(this.TIME)}`
    const timeWithDayRange = `${startMoment.format(this.DAY_TIME)} - ${endMoment.format(this.DAY_TIME)}`
    const datetimeRange = `${startMoment.format(this.DAY_SHORT_DATETIME)} - ${endMoment.format(
      this.DAY_SHORT_DATETIME
    )}`

    // Compare
    const isSameDay = endMoment.isSame(startMoment, 'day')
    const isToday = this._isToday(startMoment) && this._isToday(endMoment)
    const isWithinAWeek = this._isWithinAWeek(startMoment) && this._isWithinAWeek(endMoment)

    // Display
    if (isSameDay) {
      if (isToday) {
        return `Today ${timeRange}`
      } else if (isWithinAWeek) {
        return `${endMoment.format(this.DAY)}, ${timeRange}`
      }

      return `${endMoment.format(this.DAY_SHORT_DATE)} ${timeRange}`
    }

    return isWithinAWeek ? timeWithDayRange : datetimeRange
  }

  static _isToday(val) {
    return moment().isSame(val, 'day')
  }

  static _isWithinAWeek(val) {
    return moment().diff(val, 'day') > -6
  }
}
