import firebase from 'firebase/compat/app'
import 'firebase/compat/auth'

import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

import LoaderUtils from '../utils/loader_utils'

export default class FirebaseAuthUtils {
  static initialiseFirebase() {
    if (!firebase.apps.length) {
      firebase.initializeApp(window.cndVars.firebase_config)
    } else {
      firebase.app()
    }

    return firebaseui.auth.AuthUI.getInstance() || new firebaseui.auth.AuthUI(firebase.auth())
  }

  static initialiseFirebaseUI({ el, onSuccess }) {
    let googleFullLabel = el.dataset.googleFullLabel || 'Continue with Google'
    let appleFullLabel = el.dataset.appleFullLabel || 'Continue with Apple'

    var uiConfig = {
      callbacks: {
        uiShown: function () {
          LoaderUtils.hidePageLoader()
        },
        signInSuccessWithAuthResult: onSuccess,
        signInFailure: function (error) {
          // Unrecoverable error eg merging anonymous users
          Sentry.captureException(error)
        },
      },
      signInOptions: [
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          fullLabel: googleFullLabel,
          buttonColor: '#ffffff',
        },
        {
          provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
          fullLabel: 'Continue with Facebook',
          buttonColor: '#ffffff',
        },
        {
          provider: 'apple.com',
          fullLabel: appleFullLabel,
          buttonColor: '#ffffff',
        },
      ],
      signInFlow: 'popup',
      tosUrl: null,
      privacyPolicyUrl: null,
    }

    // The start method will wait until the DOM is loaded.
    var ui = this.initialiseFirebase()
    ui.start(el, uiConfig)
  }
}
