import { Controller } from 'stimulus'

import TargetUtils from '../../utils/target_utils'

export default class extends Controller {
  static targets = [
    'parkingTypeRadio',
    'dedicatedInstructions',
    'streetInstructions',
    'parkingPermitted',
    'parkingLocked',
    'permitArea',
    'accessInstruction',
    'goodParkingPlace',
    'ngParkingPlace',
    'permitAreaInput',
    'accessInstructionInput',
    'parkingInfo',
    'idealParkText',
    'notToParkLabel',
    'notToParkSubLabel',
    'toParkLabel',
    'toParkSubLabel',
    'accessInstructionRequired',
    'accessInstructionOptional',
  ]

  initialize() {
    this.targetUtils = new TargetUtils(this)
    this.toggleParkingType()
    this.toggleParkingPermitted()
    this.toggleParkingLocked()
  }

  toggleParkingType() {
    this.toggleInstructions()
    this.toggleParkingText()
    if (this.selectedParkingType()) {
      this.parkingInfoTarget.classList.remove('cnd-hidden')
    }
  }

  toggleInstructions() {
    if (this.selectedParkingType() === '0') {
      this.targetUtils.hide('dedicatedInstructionsTarget')
      this.targetUtils.show('streetInstructionsTarget')
    } else {
      this.targetUtils.hide('streetInstructionsTarget')
      this.targetUtils.show('dedicatedInstructionsTarget')
    }
  }

  toggleParkingText() {
    if (this.selectedParkingType() === '0') {
      this.toParkLabelTarget.innerHTML = L10n.t('owner-listing-instructions.street-parking.wheretopark')
      this.toParkSubLabelTarget.innerHTML = L10n.t('owner-listing-instructions.street-parking.whereisiteasiestto')
      this.notToParkLabelTarget.innerHTML = L10n.t('owner-listing-instructions.street-parking.wherenottopark')
      this.notToParkSubLabelTarget.innerHTML = L10n.t('owner-listing-instructions.street-parking.wherearethereparki')

      this.goodParkingPlaceTarget.placeholder = 'e.g. Victoria Street'
      this.ngParkingPlaceTarget.placeholder = 'e.g. Avoid timed parking on Edward Street'
      this.idealParkTextTarget.innerHTML = L10n.t('owner-listing-instructions.parking.you-and-your-borrowers-will-ne')

      // Enable/Disable input fields
      this.parkingPermittedTargets.forEach((target) => (target.disabled = false))
      this.parkingLockedTargets.forEach((target) => (target.disabled = true))
      this.permitAreaInputTarget.disabled = false
      this.accessInstructionInputTarget.disabled = true
    } else {
      this.toParkLabelTarget.innerHTML = L10n.t('owner-listing-instructions.dedicated-parking.howtopark')
      this.toParkSubLabelTarget.innerHTML = L10n.t('owner-listing-instructions.dedicated-parking.describewherean')
      this.notToParkLabelTarget.innerHTML = L10n.t('owner-listing-instructions.dedicated-parking.hownottopark')
      this.notToParkSubLabelTarget.innerHTML = L10n.t('owner-listing-instructions.dedicated-parking.tellborrowerssp')

      this.goodParkingPlaceTarget.placeholder = L10n.t('owner-listing-instructions.dedicated-parking.egreverseintosp')
      this.ngParkingPlaceTarget.placeholder = L10n.t('owner-listing-instructions.dedicated-parking.egdontparkinvis')
      this.idealParkTextTarget.innerHTML = L10n.t('owner-listing-instructions.parking.youandyourborrowerswillne-1')

      // Enable/Disable input fields
      this.parkingPermittedTargets.forEach((target) => (target.disabled = true))
      this.parkingLockedTargets.forEach((target) => (target.disabled = false))
      this.permitAreaInputTarget.disabled = true
      this.accessInstructionInputTarget.disabled = false
    }
  }

  toggleParkingPermitted() {
    const parkingPermitted = this.parkingPermittedTargets.filter((e) => e.checked)[0]

    if (parkingPermitted.value == 'true') {
      this.targetUtils.show('permitAreaTarget')
    } else {
      this.targetUtils.hide('permitAreaTarget')
    }
  }

  toggleParkingLocked() {
    const parkingLocked = this.parkingLockedTargets.filter((e) => e.checked)[0]

    if (parkingLocked.value == 'true') {
      this.targetUtils.hide('accessInstructionOptionalTarget')
      this.targetUtils.show('accessInstructionRequiredTarget')
    } else {
      this.targetUtils.hide('accessInstructionRequiredTarget')
      this.targetUtils.show('accessInstructionOptionalTarget')
    }
  }

  selectedParkingType() {
    let result = this.parkingTypeRadioTargets.filter((e) => e.checked)[0]
    return result && result.value
  }
}
