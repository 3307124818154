import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['profileType', 'countryCode', 'state']

  initialize() {
    this.updateProfileType()
  }

  updateProfileType() {
    const profileType = this.profileTypeTargets.filter((e) => e.checked)[0]

    $('.' + profileType.dataset.showElement).removeClass('cnd-hidden')
    $('.' + profileType.dataset.hideElement).addClass('cnd-hidden')
    $('.' + profileType.dataset.hideElement + ' input').val('')
  }
}
