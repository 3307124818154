import { Controller } from 'stimulus'
import axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
import qs from 'qs'
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode'
import FlashMessageUtils from '../../utils/flash_message_utils'
import FormatUtils from '../../utils/format_utils'

export default class extends Controller {
  static targets = [
    'backToAll',
    'removeTollTag',
    'removeTollTagDropdown',
    'removeTollTagChecked',
    'scanBarcode',
    'scanBarcodeDropdown',
    'scanBarcodeChecked',
    'submitBarCode',
    'stickTag',
    'stickTagDropdown',
    'stickTagChecked',
    'findDiagnosticPort',
    'findDiagnosticPortDropdown',
    'findDiagnosticPortChecked',
    'plugTracker',
    'plugTrackerDropdown',
    'plugTrackerChecked',
    'secureGps',
    'secureGpsDropdown',
    'secureGpsChecked',
    'testGpsDropdown',
    'testGpsChecked',
    'insertBatteriesDropdown',
    'insertBatteriesChecked',
    'hideGpsDropdown',
    'hideGpsChecked',
    'takePhotoDropdown',
    'takePhotoChecked',
    'findLocationDropdown',
    'findLocationChecked',
    'openShackleDropdown',
    'openShackleChecked',
    'attachLockboxDropdown',
    'attachLockboxChecked',
    'writeInstruction',
    'writeInstructionDropdown',
    'writeInstructionChecked',
    'instructionForm',
    'lockboxInstruction',
    'lockboxAddress',
    'placeKeyInsideDropdown',
    'placeKeyInsideChecked',
    'placeOnWindowDropdown',
    'placeOnWindowChecked',
    'mainContent',
    'confirmContent',
    'qrInputText',
    'qrInputSection',
    'addManuallyButton',
    'manuallyDoneButton',
    'shackleCode',
    'lockboxCode',
    'lockboxCodeInstruction',
    'installingBatteriesDropdown',
    'installingBatteriesChecked',
    'shackleInstruction',
    'lockboxCodeInstruction',
    'installBatteriesTemplate',
  ]

  vehicleId
  stepId
  lockboxMake

  initialize() {
    this.vehicleId = parseInt(this.element.getAttribute('data-vehicle-id'))
    this.stepId = this.element.getAttribute('data-step-id')
    this.lockboxMake = this.element.getAttribute('data-lockbox-make')
  }

  connect() {
    this.openNextStep()
  }

  submitStep(e) {
    const dataset = e.currentTarget.dataset

    const data = {
      'kit_setup_step[checked]': true,
      'kit_setup_step[sub_step_id]': dataset.subStepId,
    }

    axios({
      method: 'put',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: qs.stringify(data),
      url: window.location.pathname,
    })
      .then((_result) => {
        this.markStepCompleted(dataset.id)
      })
      .catch((error) => FlashMessageUtils.showWarning(error.response.data.error_message))
  }

  confirmBarCode(event, code) {
    this.mainContentTarget.classList.add('cnd-hidden')
    this.confirmContentTarget.classList.remove('cnd-hidden')
    this.data.set('code', code)
    let contentHead = L10n.t('kit-setup-menu.messages.is')
    let contentTail = L10n.t('kit-setup-menu.messages.the-correct-code')
    this.confirmContentTarget.querySelector('p').innerHTML = `${contentHead} <b>${code}</b> ${contentTail}`
  }

  tryAgain() {
    this.confirmContentTarget.classList.add('cnd-hidden')
    this.mainContentTarget.classList.remove('cnd-hidden')
    this.mainContentTarget.querySelector("input[type='file']").value = ''
  }

  submitBarCode(event) {
    const dataset = event.target.dataset

    const data = {
      'kit_setup_step[device_type]': dataset.deviceType,
      'kit_setup_step[sub_step_id]': dataset.subStepId,
      'kit_setup_step[serial_number]': this.data.get('code'),
      'kit_setup_step[checked]': true,
    }

    axios({
      method: 'post',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: qs.stringify(data),
      url: `${window.location.pathname}/create_device`,
    })
      .then((result) => {
        if (dataset.deviceType === 'Lockbox' && result.data.device_make === 'igloohome') {
          this.lockboxMake = 'igloohome'
          this.addInstallingBatteriesStep()
        }
        this.markStepCompleted(dataset.id)
      })
      .catch((error) => FlashMessageUtils.showWarning(error.response.data.error_message))
  }

  openScanner(cameraEvent) {
    const controller = this
    this.qrInputSectionTarget.classList.add('cnd-hidden')
    this.addManuallyButtonTarget.classList.remove('cnd-hidden')
    this.manuallyDoneButtonTarget.classList.add('cnd-hidden')
    this.html5QrCode = new Html5Qrcode('reader')
    const reader = document.getElementById('reader')
    const qrCodeSuccessCallback = (decodedText, _decodedResult) => {
      controller.confirmBarCode(cameraEvent, decodedText)
      controller.html5QrCode.stop()
    }
    const config = {
      fps: 10,
      qrbox: { width: reader.offsetWidth - 20, height: 200 },
      formatsToSupport: [Html5QrcodeSupportedFormats.QR_CODE],
      experimentalFeatures: {
        useBarCodeDetectorIfSupported: true,
      },
    }

    this.html5QrCode.start({ facingMode: 'environment' }, config, qrCodeSuccessCallback)
  }

  addManually(event) {
    if (this.qrInputSectionTarget.classList.contains('cnd-hidden')) {
      this.qrInputSectionTarget.classList.remove('cnd-hidden')
      this.addManuallyButtonTarget.classList.add('cnd-hidden')
      this.manuallyDoneButtonTarget.classList.remove('cnd-hidden')
      this.html5QrCode && this.html5QrCode.stop()
    } else {
      this.data.set('code', this.qrInputTextTarget.value)
      this.submitBarCode(event)
    }
  }

  uploadSecondaryGpsImage(event) {
    const dataset = event.target.dataset

    if (event.target.files.length == 0) {
      return
    }

    const imageFile = event.target.files[0]
    const formData = new FormData()
    formData.append('secondary_gps_image', imageFile)
    formData.append('kit_setup_step[sub_step_id]', dataset.subStepId)
    formData.append('kit_setup_step[checked]', true)

    axios({
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: formData,
      url: `${window.location.pathname}/secondary_gps_image`,
    })
      .then((_result) => {
        this.markStepCompleted(dataset.id)
      })
      .catch((error) => FlashMessageUtils.showWarning(error.response.data.error_message))
  }

  submitInstruction(e) {
    if (e.currentTarget.classList.contains('cnd-opacity-50')) {
      return
    }

    const dataset = e.currentTarget.dataset
    const form = this.instructionFormTarget
    const formData = new FormData(form)
    formData.append('manage_cars_lockbox_form[sub_step_id]', dataset.subStepId)
    formData.append('manage_cars_lockbox_form[checked]', true)

    axios({
      method: 'PUT',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: formData,
      url: `${window.location.pathname}/update_lockbox_instruction`,
    })
      .then((_result) => {
        this.updateLockboxCode(dataset.id)
      })
      .catch((error) => FlashMessageUtils.showWarning(error.response.data.error_message))
  }

  updateLockboxCode(target) {
    axios
      .get('/lockbox', {
        params: {
          vehicle_id: this.vehicleId,
        },
      })
      .then((response) => {
        this.updateLockboxCodeInstruction(response.data[0])
        this.markStepCompleted(target)
      })
      .catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.error)
      })
  }

  updateLockboxOnCarCode(e) {
    let event = {
      ...e,
      currentTarget: e.currentTarget,
    }

    axios
      .get('/lockbox', {
        params: {
          vehicle_id: this.vehicleId,
        },
      })
      .then((response) => {
        this.updateLockboxCodeInstruction(response.data[0])
        this.submitStep(event)
      })
      .catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.error)
      })
  }

  openShackleStep(e) {
    if (this.lockboxMake === 'igloohome') {
      return this.updateShackleCodeInstruction(e)
    }

    let event = {
      ...e,
      currentTarget: e.currentTarget,
    }

    axios
      .post('../profile/lockbox_shackle_code', null, AxiosUtils.getHeaders())
      .then((res) => {
        this.shackleCodeTarget.innerHTML = FormatUtils.numberWithSpaces(res.data)
        this.submitStep(event)
      })
      .catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.message)
      })
  }

  markStepCompleted(target) {
    eval(`this.${target}DropdownTarget`).classList.remove('cnd-dropdown--open', 'sub-step-dropdown')
    eval(`this.${target}CheckedTarget`).classList.remove('cnd-hidden')
    this.openNextStep()
  }

  openNextStep() {
    const nextStep = document.querySelector('.sub-step-dropdown')
    if (nextStep) {
      nextStep.classList.add('cnd-dropdown--open')
    } else {
      this.backToAllTarget.click()
    }
  }

  toggleDoneButton() {
    if (this.lockboxAddressTarget.value && this.lockboxInstructionTarget.value) {
      this.writeInstructionTarget.classList.remove('cnd-opacity-50')
    } else {
      this.writeInstructionTarget.classList.add('cnd-opacity-50')
    }
  }

  barcodeNotRecognized() {
    const target = this.mainContentTarget.querySelector('p')
    if (target) {
      target.innerHTML = L10n.t('kit-setup-menu.messages.we-couldnt-find')
    }
  }

  updateLockboxCodeInstruction(data) {
    if (data.provider == 'igloohome') {
      let prevImage = this.placeKeyInsideDropdownTarget.querySelector('.cnd-card__image img')
      let sentrilockTooltip = this.placeKeyInsideDropdownTarget.querySelector('.cnd-dialog__content.original')
      let igloohomeTooltip = this.placeKeyInsideDropdownTarget.querySelector('.cnd-dialog__content.alternate')

      prevImage.src = '/assets/kit_setup_steps/sub_steps/igloohome_place_key_inside.gif'
      sentrilockTooltip.classList.add('cnd-hidden')
      igloohomeTooltip.classList.remove('cnd-hidden')

      let placeKeyInsideTextFirst = L10n.t('kit-setup-lockbox-on-property-steps.place-key-inside.touch-the-return')
      let placeKeyInsideTextSecond = L10n.t('kit-setup-lockbox-on-property-steps.place-key-inside.and-pull-from')
      this.lockboxCodeInstructionTarget.innerHTML = `<p>
          ${placeKeyInsideTextFirst}
        </p>
        <p>
          <h1 class="cnd-header cnd-text-2xl">
            <span class="cnd-mr-2" data-target="manage--kit-setup.lockboxCode">${data.code}</span>
          </h1>
        </p>
        <p>${placeKeyInsideTextSecond}</p>`
    } else {
      this.lockboxCodeTarget.innerHTML = data.code
    }
  }

  updateShackleCodeInstruction(e) {
    let event = {
      ...e,
      currentTarget: e.currentTarget,
    }

    axios
      .get('/lockbox', {
        params: {
          vehicle_id: this.vehicleId,
        },
      })
      .then((response) => {
        let data = response.data[0]
        let youtubeVideo = this.openShackleDropdownTarget.querySelector('.cnd-dropdown .cnd-card__image iframe')
        youtubeVideo.src = 'https://www.youtube.com/embed/gJbpt6PAmqY'

        let openShackleIgloohomeText = L10n.t('kit-setup-lockbox-on-property-steps.open-shackle.press-on')
        let openShackleIgloohomeDescription = L10n.t(
          'kit-setup-lockbox-on-property-steps.open-shackle.igloohome-description-1'
        )
        this.shackleInstructionTarget.innerHTML = `<p>${openShackleIgloohomeText}</p>
        <p>
          <h1 class="cnd-header cnd-text-2xl">
            <span class="cnd-mr-2 cnd-ml-2" data-target="manage--kit-setup.shackleCode">${data.code}</span>
          </h1>
        </p>
        <p>${openShackleIgloohomeDescription}</p>`

        this.submitStep(event)
      })
      .catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.error)
      })
  }

  addInstallingBatteriesStep() {
    if (this.stepId === 'lockbox_on_car_steps') {
      this.placeOnWindowDropdownTarget.parentNode.insertAdjacentHTML(
        'beforebegin',
        this.installBatteriesTemplateTarget.innerHTML
      )

      let placeOnWindowText = this.placeOnWindowDropdownTarget.querySelector('h4').childNodes[0].textContent
      placeOnWindowText = placeOnWindowText.replace('2.', '3.')
      this.placeOnWindowDropdownTarget.querySelector('h4').childNodes[0].textContent = placeOnWindowText

      let placeKeyInsideText = this.placeKeyInsideDropdownTarget.querySelector('h4').childNodes[0].textContent
      placeKeyInsideText = placeKeyInsideText.replace('3.', '4.')
      this.placeKeyInsideDropdownTarget.querySelector('h4').childNodes[0].textContent = placeKeyInsideText
    } else if (this.stepId === 'lockbox_on_property_steps') {
      this.findLocationDropdownTarget.parentNode.insertAdjacentHTML(
        'beforebegin',
        this.installBatteriesTemplateTarget.innerHTML
      )

      let prevImage = this.findLocationDropdownTarget.querySelector('.cnd-card__image img')
      prevImage.src = '/assets/kit_setup_steps/sub_steps/igloohome_find_location.jpg'

      let findLocationText = this.findLocationDropdownTarget.querySelector('h4').childNodes[0].textContent
      findLocationText = findLocationText.replace('2.', '3.')
      this.findLocationDropdownTarget.querySelector('h4').childNodes[0].textContent = findLocationText

      let openShackleText = this.openShackleDropdownTarget.querySelector('h4').childNodes[0].textContent
      openShackleText = openShackleText.replace('3.', '4.')
      this.openShackleDropdownTarget.querySelector('h4').childNodes[0].textContent = openShackleText

      let attachLockboxText = this.attachLockboxDropdownTarget.querySelector('h4').childNodes[0].textContent
      attachLockboxText = attachLockboxText.replace('4.', '5.')
      this.attachLockboxDropdownTarget.querySelector('h4').childNodes[0].textContent = attachLockboxText

      let writeInstructionText = this.writeInstructionDropdownTarget.querySelector('h4').childNodes[0].textContent
      writeInstructionText = writeInstructionText.replace('5.', '6.')
      this.writeInstructionDropdownTarget.querySelector('h4').childNodes[0].textContent = writeInstructionText

      let placeKeyInsideText = this.placeKeyInsideDropdownTarget.querySelector('h4').childNodes[0].textContent
      placeKeyInsideText = placeKeyInsideText.replace('6.', '7.')
      this.placeKeyInsideDropdownTarget.querySelector('h4').childNodes[0].textContent = placeKeyInsideText
    }
  }
}
