import { Controller } from 'stimulus'
import axios from 'axios'
import type { AxiosResponse } from 'axios'

import { trackEvent } from '../utils/track_event'

type DailyEarning = {
  estimates: string
  location_level: string
  location_text: string
}

type Estimates = {
  avg_owner_income_daily: number
  med_owner_income_daily: number
  p90_owner_income_daily: number
}

type TrackingProperties = {
  earnings_estimate?: string
  estimate_available: boolean
  estimate_class?: string
  estimate_location?: string
  registration_entered: string
}

type Vehicle = {
  body_type: string
  make: string
  model: string
  plate_number: string
  transmission_type: string
}

export default class extends Controller {
  declare readonly containerTarget: Element
  declare readonly estimateAverageTarget: Element
  declare readonly estimateHighTargets: Element[]
  declare readonly locationTargets: Element[]
  declare readonly locationTextTarget: Element
  declare readonly panelTargets: Element[]
  declare readonly panelLoadingTarget: Element
  declare readonly panelPopulatedTarget: Element
  declare readonly panelPromptTarget: Element
  declare readonly panelUnpopulatedTarget: Element
  declare readonly trackedUserTarget: HTMLInputElement
  declare readonly vehicleBodyTargets: Element[]
  declare readonly vehicleNameTarget: Element

  static targets = [
    'container',
    'estimateAverage',
    'estimateHigh',
    'location',
    'locationText',
    'panel',
    'panelLoading',
    'panelPopulated',
    'panelPrompt',
    'panelUnpopulated',
    'trackedUser',
    'vehicleBody',
    'vehicleName',
  ]

  public initialize = (): void => {
    document.addEventListener('hideEarningsEstimate', (): void => this.containerTarget.classList.add('cnd-hidden'))
    document.addEventListener('showEarningsEstimate', (): void => this.containerTarget.classList.remove('cnd-hidden'))
    document.addEventListener('updateEarningsEstimate', this.updateEarningsEstimate)
  }

  private annualEstimate = (dailyEstimate: number): string =>
    (dailyEstimate * 365).toLocaleString('en-US', { maximumFractionDigits: 0 })

  private fetchDailyEarning = async ({
    postCode,
    vehicle,
  }: {
    postCode: string
    vehicle: Vehicle
  }): Promise<DailyEarning | undefined> => {
    try {
      const { data }: AxiosResponse<DailyEarning | undefined> = await axios.get('/earnings_estimate', {
        params: {
          body_type_class: vehicle.body_type,
          post_code: postCode,
          transmission: vehicle.transmission_type,
        },
      })
      return data
    } catch (error) {
      console.log(`Error fetching earnings estimate: ${error}`)
    }
  }

  private hidePanels = (): void => this.panelTargets.forEach((target: Element) => target.classList.add('cnd-hidden'))

  private onEstimateInsufficientData = ({ postCode, vehicle }: { postCode: string; vehicle: Vehicle }): void => {
    this.showPanel({ target: this.panelUnpopulatedTarget })
    this.locationTargets.forEach((target: Element) => (target.innerHTML = postCode))
    this.updateVehicleUI({ vehicle })
  }

  private onEstimateSuccess = ({ dailyEarning, vehicle }: { dailyEarning: DailyEarning; vehicle: Vehicle }): void => {
    const estimates: Estimates = JSON.parse(dailyEarning.estimates)
    this.showPanel({ target: this.panelPopulatedTarget })
    const estimateHigh = this.annualEstimate(estimates.p90_owner_income_daily)
    this.estimateHighTargets.forEach((target: Element) => (target.innerHTML = estimateHigh))
    this.estimateAverageTarget.innerHTML = this.annualEstimate(estimates.avg_owner_income_daily)
    this.locationTargets.forEach((target: Element) => (target.innerHTML = dailyEarning.location_text))
    this.toggleLocationInTitle({ locationLevel: dailyEarning.location_level })
    this.updateVehicleUI({ vehicle })
  }

  private showPanel = ({ target }: { target: Element }): void => {
    this.hidePanels()
    target.classList.remove('cnd-hidden')
  }

  private toggleLocationInTitle = ({ locationLevel }: { locationLevel: DailyEarning['location_level'] }): void => {
    if (locationLevel === '1 post_code') {
      this.locationTextTarget.classList.remove('cnd-hidden')
    } else {
      this.locationTextTarget.classList.add('cnd-hidden')
    }
  }

  private trackEstimate = ({ dailyEarning, vehicle }: { dailyEarning?: DailyEarning; vehicle: Vehicle }): void => {
    const trackedUser = JSON.parse(this.trackedUserTarget.value)
    const properties: TrackingProperties = {
      ...trackedUser,
      earnings_estimate: dailyEarning?.estimates,
      estimate_available: !!dailyEarning,
      estimate_class: dailyEarning?.location_level,
      estimate_location: dailyEarning?.location_text,
      registration_entered: vehicle.plate_number,
    }

    trackEvent({ category: 'Listing', event: 'Registration Submitted', properties })
  }

  private updateEarningsEstimate = async (event: Event): Promise<void> => {
    const { detail } = event as CustomEvent
    const postCode = detail.post_code
    const vehicle = JSON.parse(detail.vehicle)
    if (!postCode || !vehicle) return
    this.showPanel({ target: this.panelLoadingTarget })
    const dailyEarning = await this.fetchDailyEarning({
      postCode,
      vehicle,
    })
    this.trackEstimate({ dailyEarning, vehicle })
    if (dailyEarning) {
      this.onEstimateSuccess({ dailyEarning, vehicle })
    } else {
      this.onEstimateInsufficientData({ postCode, vehicle })
    }
  }

  private updateVehicleUI = ({ vehicle }: { vehicle: Vehicle }): void => {
    this.vehicleBodyTargets.forEach((target: Element) => (target.innerHTML = vehicle.body_type))
    this.vehicleNameTarget.innerHTML = `${vehicle.make} ${vehicle.model}`
  }
}
