import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['largeSticker', 'personas']

  initialize() {
    this.showPersonas()
  }

  showPersonas() {
    this.personasTarget.classList.toggle('cnd-hidden', !this.largeStickerTarget.checked)
  }
}
