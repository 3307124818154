/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Add polyfills to support to support Internet Explorer 11+ and Safari 9+
import '@stimulus/polyfills'

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'

import * as ActiveStorage from '@rails/activestorage'
ActiveStorage.start()

const application = Application.start()
const context = require.context('../src/controllers', true, /\.(js|ts)$/)
application.load(definitionsFromContext(context))

window.addEventListener('pagehide', () => {
  application.controllers.forEach((controller) => {
    if (typeof controller.pageHide === 'function') {
      controller.pageHide()
    }
  })
})

// TailwindCSS
import '../styles/application.css'

import '../src/fingerprint'

import '../src/datadog_browser_monitoring'
