import { DirectUpload } from '@rails/activestorage'

export default class DirectUploadUtils {
  containerEl
  directUpload
  percentComplete

  constructor(file, url, containerEl) {
    this.directUpload = new DirectUpload(file, url, this)
    this.containerEl = containerEl
  }

  upload() {
    this.percentComplete = 0

    return new Promise((resolve, reject) => {
      this.directUpload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          resolve(blob)
        }
      })
    })
  }

  directUploadWillStoreFileWithXHR(request) {
    request.upload.addEventListener('progress', (event) => this.directUploadDidProgress(event))
  }

  directUploadDidProgress(event) {
    this.percentComplete = ((event.loaded / event.total) * 100).toFixed()

    this.containerEl.dispatchEvent(
      new CustomEvent('uploadProgress', {
        detail: {
          bytesSent: event.loaded,
          percentComplete: this.percentComplete,
        },
      })
    )
  }
}
