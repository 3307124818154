const AU_PLATE_NUMBER_FORMAT = /^[a-zA-Z0-9]{1,9}$/
const AUTOGRAB_TEST_PLATE_NUMBER_FORMAT = /REG4SUCCESS/

const validateAuPlateNumberFormat = (plateNumber) => {
  return (
    validatePlateNumberFormat(plateNumber, AUTOGRAB_TEST_PLATE_NUMBER_FORMAT) ||
    validatePlateNumberFormat(plateNumber, AU_PLATE_NUMBER_FORMAT)
  )
}

const validatePlateNumberFormat = (plateNumber, regex) => {
  return regex.test(plateNumber)
}

export { validateAuPlateNumberFormat }
