import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['smoochSupportIntegrationId', 'openChat', 'smoochJwt', 'memberId']

  smooch

  initialize() {
    if (this.hasSmoochSupportIntegrationIdTarget && !this.smooch) {
      this.smooch = require('smooch').default

      if (this.hasSmoochJwtTarget) {
        this.smooch.init({
          integrationId: this.smoochSupportIntegrationIdTarget.dataset.val,
          externalId: this.memberIdTarget.dataset.val,
          jwt: this.smoochJwtTarget.dataset.val,
          locale: 'en-AU',
          canUserSeeConversationList: false,
        })
      } else {
        this.smooch.init({
          integrationId: this.smoochSupportIntegrationIdTarget.dataset.val,
          locale: 'en-AU',
          canUserSeeConversationList: false,
        })
      }

      if (this.hasOpenChatTarget) {
        this.smooch.open()
      }
    }
  }

  open(event) {
    event.preventDefault()
    this.smooch.open()
  }
}
