export default class DeviceUtils {
  static isMobile() {
    return navigator.userAgent.match(/(iPhone)|(iPod)|(android)|(webOS)/i)
  }

  static isTablet() {
    // As we cannot definitively detect an android tablet device, we check on whether the
    // screen size is between 768 and 479 as these are typical teblet widths and mobile widths
    // start at the 479px mark
    return navigator.userAgent.match(/(iPad)/i) || (window.screen.width < 769 && window.screen.width > 480)
  }

  static isBot() {
    return navigator.userAgent.match('(googlebot|bot|crawl|spider)')
  }

  static isUnsupportedBrowser() {
    return navigator.userAgent.match('DuckDuckGo')
  }

  static isNavatile() {
    return !!DeviceUtils.appBridgeUnified()
  }

  static appBridgeUnified() {
    let hostAppBridgeIos = window?.webkit?.messageHandlers?.hostAppBridge
    let hostAppBridgeAndroid = window?.hostAppBridge
    return hostAppBridgeAndroid ?? hostAppBridgeIos
  }
}
