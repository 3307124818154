import { Controller } from 'stimulus'

import StripeUtils from '../utils/stripe_utils'

import brandontext from 'styles/assets/fonts/brandontext/brandontext-regular.woff'

export default class extends Controller {
  static targets = ['amount', 'cardElement', 'cardErrors', 'cardholderName', 'payNow', 'tokenNumber']

  initialize() {
    try {
      this.stripe = StripeUtils.initialiseStripe(this.data.get('stripe-key'))

      this.createCardElements()
    } catch (error) {
      this.cardErrorsTarget.innerHTML = 'Failed to initialise Stripe'
      this.cardErrorsTarget.classList.remove('cnd-hidden')

      Sentry.captureException(error)
    }

    this.amountTarget.addEventListener('change', this.enableDisablePayNow)
    this.cardholderNameTarget.addEventListener('blur', this.onChangeCard)
  }

  createCardElements() {
    const elements = this.stripe.elements({
      fonts: [
        {
          family: 'BrandonText',
          src: `url('${brandontext}')`,
        },
      ],
    })

    if (this.hasCardElementTarget) {
      this.card = elements.create('card', {
        hidePostalCode: true,
        style: StripeUtils.elementStyles,
        classes: StripeUtils.elementClasses,
      })
      this.card.mount(this.cardElementTarget)
      this.card.addEventListener('change', this.onChangeCard)

      StripeUtils.addCardElementEventListener(this.card, this.cardErrorsTarget)
    }
  }

  onChangeCard = (event) => {
    if (event.target === this.cardholderNameTarget && this.tokenNumberTarget.value) {
      event.complete = true
    }

    this.cardErrorsTarget.classList.add('cnd-hidden')
    this.tokenNumberTarget.value = ''
    this.enableDisablePayNow()

    if (event.complete) {
      this.stripe.createToken(this.card, { name: this.cardholderNameTarget.value }).then((result) => {
        if (result.error) {
          this.cardErrorsTarget.innerHTML = result.error.message
          this.cardErrorsTarget.classList.remove('cnd-hidden')
        } else {
          this.tokenNumberTarget.value = result.token.id
          this.enableDisablePayNow()
        }
      })
    }
  }

  enableDisablePayNow = () => {
    this.payNowTarget.disabled =
      !this.amountTarget.value || !this.cardholderNameTarget.value || !this.tokenNumberTarget.value
  }
}
