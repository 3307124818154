import { datadogRum } from '@datadog/browser-rum'

if (process.env.DATADOG_APP_ID) {
  var sessionSampleRate
  if (process.env.RAILS_ENV == 'production') {
    sessionSampleRate = 2
  } else {
    sessionSampleRate = 20
  }

  datadogRum.init({
    allowedTracingUrls: [/https:\/\/.*\.ubercarshare\.com/],
    applicationId: process.env.DATADOG_APP_ID,
    clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: 'turbo-member',
    env: process.env.RAILS_ENV,
    version: process.env.RELEASE_VERSION,
    sessionSampleRate: sessionSampleRate,
    sessionReplaySampleRate: 5,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })

  datadogRum.startSessionReplayRecording()
}
