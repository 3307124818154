import { Controller } from 'stimulus'

import * as axios from 'axios'

export default class extends Controller {
  static targets = ['input', 'clear', 'loader', 'results']

  hide(target) {
    target.classList.add('cnd-hidden')
  }

  show(target) {
    target.classList.remove('cnd-hidden')
  }

  updateHTML(target, html) {
    target.innerHTML = html
  }

  linkTemplate(article) {
    return `
      <a href="${article.html_url}" target="_blank" rel="noopener noreferrer"
        class="cnd-zendesk-search__result">
        ${article.title}
      </a>`
  }

  noResultsTemplate(message) {
    return `<p>${message}</p>`
  }

  sortResults(results, msg) {
    let i = 0
    let html = ''
    const max = results.length >= 5 ? 5 : results.length

    if (msg == null) msg = ''
    if (results.length !== 0) {
      while (i < max) {
        html += this.linkTemplate(results[i])
        i++
      }
      return html
    }
    return this.noResultsTemplate(`${msg} Try another keyword or contact us below.`)
  }

  showLoading() {
    this.hide(this.clearTarget)
    this.show(this.loaderTarget)
  }

  showResults(results) {
    this.show(this.clearTarget)
    this.hide(this.loaderTarget)
    this.updateHTML(this.resultsTarget, results)
    this.show(this.resultsTarget)
  }

  hideResults() {
    this.hide(this.clearTarget)
    this.hide(this.loaderTarget)
    this.updateHTML(this.resultsTarget, '')
    this.hide(this.resultsTarget)
  }

  clear(e) {
    e.preventDefault()
    this.inputTarget.value = ''
    this.hideResults()
  }

  keyup(e) {
    e.preventDefault()

    const query = e.currentTarget.value
    if (this.timeout) clearTimeout(this.timeout)

    if (query) {
      this.showLoading()
      return (this.timeout = setTimeout(() => this.search(query), 500))
    }
    return this.hideResults()
  }

  keydown(e) {
    if (e.keyCode === 13) {
      e.preventDefault()

      const query = e.currentTarget.value
      location.href = `${window.cndVars.zendeskBaseUrl}/search?utf8=%E2%9C%93&query=${query}&commit=Search`
    }
  }

  search(query) {
    if (this.searching) return
    this.searching = true

    axios
      .get(`${window.cndVars.zendeskApiUrl}/api/v2/help_center/articles/search.json?query=${query}`)
      .then((res) => {
        const results = this.sortResults(res.data.results, 'No results found.')
        this.showResults(results)
      })
      .catch(() => {
        const results = this.sortResults([], 'Something went wrong.')
        this.showResults(results)
      })
      .finally(() => {
        this.searching = false
      })
  }
}
