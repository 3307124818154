import { Controller } from 'stimulus'
import Cookies from 'js-cookie'

export default class extends Controller {
  componentId

  COOKIE_NAME = 'cnd-dismissibles'

  initialize() {
    this.componentId = this.element.dataset.cookie

    if (this.componentId) {
      const cookie = this.getCookie()

      if (cookie && cookie.indexOf(this.componentId) >= 0) {
        return
      }
    }

    this.element.classList.remove('cnd-hidden')
  }

  hide() {
    if (this.componentId) {
      const cookie = this.getCookie()

      Cookies.set(this.COOKIE_NAME, cookie.concat(this.componentId))
    }

    this.element.classList.add('cnd-hidden')
  }

  getCookie() {
    return JSON.parse(Cookies.get(this.COOKIE_NAME) || '[]')
  }
}
