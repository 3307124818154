import { Controller } from 'stimulus'
import * as axios from 'axios'

export default class extends Controller {
  static targets = [
    'abn',
    'businessNameContainer',
    'businessName',
    'loadingIcon',
    'errorMessage',
    'errorMessageContainer',
    'australianBusiness',
    'internationalBusiness',
    'countryCode',
    'tin',
    'taxType',
    'addressDescription',
    'nextButton',
    'submitButton',
    'mobileMissingBanner',
  ]

  searchByAbn() {
    this.hideErrorMessage()
    this.hideBusinessName()

    let tempValue = this.abnTarget.value.replace(/ /g, '')

    if (/^[0-9]{11}$/.test(tempValue)) {
      this.formatAbnValue(tempValue)

      const params = { abn: tempValue }

      this.showLoading()

      axios
        .get(`/tax_details/abn_lookup`, { params })
        .then((res) => {
          this.loadingIconTarget.classList.add('cnd-hidden')

          if (res.data.abn_status != 'Active') {
            this.showErrorMessage('Please input an active ABN')
          } else if (res.data.entity_name) {
            this.businessNameContainerTarget.classList.remove('cnd-hidden')
            this.businessNameTarget.value = res.data.entity_name
          } else {
            this.showErrorMessage(res.data.message)
          }
          this.enableSubmit()
        })
        .catch((error) => {
          this.loadingIconTarget.classList.add('cnd-hidden')

          this.showErrorMessage(error.response.data.message)
          this.enableSubmit()
        })
    } else {
      if (this.abnTarget.dataset.required != 'false' || tempValue != '') {
        this.showErrorMessage('Please input a valid ABN')
      }
      this.enableSubmit()
    }
  }

  showLoading() {
    this.loadingIconTarget.classList.remove('cnd-hidden')
  }

  formatAbnValue(abn) {
    let tempValue = abn

    this.abnTarget.value =
      tempValue.substring(0, 2) +
      ' ' +
      tempValue.substring(2, 5) +
      ' ' +
      tempValue.substring(5, 8) +
      ' ' +
      tempValue.substring(8, 11)
  }

  hideBusinessName() {
    this.businessNameContainerTarget.classList.add('cnd-hidden')
    this.businessNameTarget.value = ''
  }

  showErrorMessage(errorMessage) {
    this.errorMessageTarget.innerHTML = errorMessage
    this.abnTarget.classList.add('cnd-border-red')
    this.errorMessageContainerTarget.classList.remove('cnd-hidden')
  }

  hideErrorMessage() {
    this.errorMessageContainerTarget.classList.add('cnd-hidden')
    this.abnTarget.classList.remove('cnd-border-red')
  }

  navigateToMobileSettings() {
    if (window.UCSAppWebview) {
      window.UCSAppWebview.postMessage(
        JSON.stringify({
          message: 'redirect',
          route: '/settings/your-profile',
          closeWebview: true,
        })
      )
    } else {
      return (window.location = '/personal_information')
    }
  }

  showAustralianBusinessFields() {
    this.internationalBusinessTarget.classList.add('cnd-hidden')
    this.australianBusinessTarget.classList.remove('cnd-hidden')
    this.addressDescriptionTarget.classList.remove('cnd-hidden')
    this.abnTarget.setAttribute('required', true)
    this.abnTarget.removeAttribute('disabled')
    this.tinTarget.setAttribute('disabled', true)
    this.tinTarget.removeAttribute('required')
    this.countryCodeTarget.setAttribute('disabled', true)
    this.countryCodeTarget.removeAttribute('required')
    this.taxTypeTarget.value = 'australian_business'

    this.enableSubmit()
  }

  showInternationalBusinessFields() {
    this.australianBusinessTarget.classList.add('cnd-hidden')
    this.internationalBusinessTarget.classList.remove('cnd-hidden')
    this.addressDescriptionTarget.classList.add('cnd-hidden')
    this.abnTarget.setAttribute('disabled', true)
    this.abnTarget.removeAttribute('required')
    this.tinTarget.setAttribute('required', true)
    this.tinTarget.removeAttribute('disabled')
    this.countryCodeTarget.setAttribute('required', true)
    this.countryCodeTarget.removeAttribute('disabled')
    this.taxTypeTarget.value = 'international_business'

    this.enableSubmit()
  }

  enableNextButton() {
    this.nextButtonTarget.disabled = false
  }

  validateForm() {
    const mobileNotMissing = !this.hasMobileMissingBannerTarget
    const allRequiredFieldsFilled = Array.from(document.querySelectorAll('[required]')).every(
      (x) => x.validity.valid === true
    )

    if (this.taxTypeTarget.value == 'international_business') {
      return mobileNotMissing && allRequiredFieldsFilled
    } else {
      const abnInputNoError = this.errorMessageContainerTarget.classList.contains('cnd-hidden')

      return mobileNotMissing && abnInputNoError && allRequiredFieldsFilled
    }
  }

  enableSubmit(e) {
    if (e && e.target && e.target.name == 'manage_tax_details_form[abn]') return

    const isValid = this.validateForm()

    if (isValid) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}
