import { Controller } from 'stimulus'
import EventUtils from '../utils/event_utils'
import SearchUtils from '../utils/search_utils'
import mapboxgl from '!mapbox-gl'

export default class extends Controller {
  static targets = ['container']

  map
  marker
  loaded

  initialize() {
    const init = this.element.getAttribute('data-initialize')
    if (init === 'false') return

    let lng = this.element.getAttribute('data-longitude')
    let lat = this.element.getAttribute('data-latitude')

    // Default location
    if (!lat || lat === '' || !lng || lng === '') {
      const defaultLocation = SearchUtils.getDefaultLocations('AU')[0]
      lat = defaultLocation.latitude
      lng = defaultLocation.longitude
    }

    // Draggable marker
    const draggable = this.element.getAttribute('data-draggable')
    const isDraggable = draggable === 'true'

    this.createMap({ lat: parseFloat(lat), lng: parseFloat(lng) }, isDraggable)
  }

  createMap(location, isDraggable = false) {
    mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN

    this.map = new mapboxgl.Map({
      container: this.containerTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 17,
      scrollZoom: false,
      center: [location.lng, location.lat],
      attributionControl: false,
    })

    this.map.addControl(new mapboxgl.AttributionControl(), 'bottom-right')
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right')
    this.map.addControl(new mapboxgl.FullscreenControl(), 'top-right')

    const markerEl = document.createElement('div')
    markerEl.className = 'marker-car'
    this.marker = new mapboxgl.Marker(markerEl)
      .setLngLat([location.lng, location.lat])
      .setDraggable(false)
      .addTo(this.map)

    // Support marker dragging
    this.map.on('load', () => {
      if (isDraggable) this.addDragSupport(location)
    })

    this.loaded = true
  }

  addDragSupport(location) {
    this.marker.setDraggable(true)
    this.addCircleToMarker(location)

    this.marker.on('dragend', () => {
      const lngLat = this.marker.getLngLat()
      EventUtils.dispatch(this.containerTarget, 'markerUpdated', lngLat)
    })
  }

  addCircleToMarker(location, hasChanged = false) {
    const CIRCLE = 'circle'

    const metersToPixelsAtMaxZoom = (meters, latitude) => {
      const METERS_PER_PIXELS = 0.075 // ~0.075m/px
      return meters / METERS_PER_PIXELS / Math.cos((latitude * Math.PI) / 180)
    }

    // Marker circle
    if (hasChanged) {
      this.map.removeLayer(CIRCLE)
      this.map.removeSource(CIRCLE)
    }

    this.map.addSource(CIRCLE, {
      type: 'geojson',
      data: {
        type: 'FeatureCollection',
        features: [
          {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [location.lng, location.lat],
            },
          },
        ],
      },
    })

    this.map.addLayer({
      id: CIRCLE,
      type: CIRCLE,
      source: CIRCLE,
      paint: {
        'circle-radius': {
          stops: [
            [0, 0],
            [20, metersToPixelsAtMaxZoom(10, location.lat)],
          ],
          base: 2,
        },
        'circle-color': '#1f9bc4',
        'circle-opacity': 0.2,
        'circle-stroke-width': 1,
        'circle-stroke-color': '#1f9bc4',
      },
    })
  }

  update(e) {
    e.preventDefault()
    const lngLat = new mapboxgl.LngLat(e.args.longitude, e.args.latitude)
    if (!this.loaded) this.createMap(lngLat)
    this.map.resize()
    this.marker.setLngLat(lngLat)
    this.addCircleToMarker(lngLat, true)
    this.map.panTo(lngLat)
  }
}
