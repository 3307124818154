import Overlay from './overlay_controller'

export default class extends Overlay {
  static targets = ['container', 'videoPlayer']

  get videoId() {
    return this.data.get('video-id')
  }

  showOverlay(e) {
    if (this.videoId !== null) {
      this.showVideo(e)
      this.containerTarget.classList.remove('cnd-hidden')
    }
  }

  showVideo(e) {
    e.stopPropagation()

    this.videoPlayerTarget.innerHTML = `<iframe src="//www.youtube.com/embed/${this.videoId}?autoplay=1&autohide=2&border=0&wmode=opaque&enablejsapi=1
      &controls=0&showinfo=0" frameborder="0"></iframe>`
  }

  hideOverlay() {
    this.videoPlayerTarget.innerHTML = ''
    this.containerTarget.classList.add('cnd-hidden')
  }
}
