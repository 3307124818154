export default class FlashMessageUtils {
  static show(type, message, time) {
    const el = document.getElementById('flash-message')
    el.classList.add(type)
    el.style.display = 'block'
    el.innerHTML = message
    setTimeout(function () {
      el.style.display = 'none'
      el.classList.remove(type)
      el.innerHTML = ''
    }, time)
  }

  static showSuccess(message) {
    FlashMessageUtils.show('success', message, 6000)
  }

  static showWarning(message) {
    FlashMessageUtils.show('warning', message, 999999)
  }
}
