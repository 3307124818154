import { Controller } from 'stimulus'
import Siema from 'siema'

export default class extends Controller {
  static targets = ['slide', 'track', 'dots', 'dot', 'arrows', 'nextButton', 'prevButton']

  initialize() {
    this.touchScreen = 'ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0
    let touchOnly = this.element.dataset.touchOnly // This restricts carousel behaviour to touch screens

    if (touchOnly && !this.touchScreen) {
      document.body.classList.add('cnd-no-touch')
      return
    }

    this.carousel = new Siema({
      draggable: this.touchScreen, // Only allow dragging on touchscreens
      selector: this.trackTarget,
      onChange: () => {
        this.updateDots() // Show dot indicators on all screens
        this.updateArrows() // Show arrows on non-touch screens only
        this.updateSlides()
      },
    })

    this.position = this.element.dataset.position
    this.initializeDots()
    this.initializeArrows()
    this.initializeSlides()
  }

  initializeDots() {
    let dots = this.createDotsContainer()
    this.slideTargets.forEach((_, i) => {
      let dot = this.createDot(i)
      dots.appendChild(dot)
    })
    this.element.appendChild(dots)
    this.updateDots()
  }

  initializeSlides() {
    this.slideTargets.forEach((element) => {
      element.classList.add('cnd-carousel__slide')
    })
    this.updateSlides()
  }

  createDotsContainer() {
    let dots = document.createElement('div')
    dots.classList.add('cnd-carousel__dots')
    dots.dataset.target = 'components--carousel.dots'

    if (this.position === 'inside') {
      dots.classList.add('cnd--mt-16')
    }

    return dots
  }

  createDot(i) {
    let dot = document.createElement('div')
    dot.classList.add('cnd-carousel__dots__dot')
    dot.dataset.target = 'components--carousel.dot'
    dot.dataset.slide = i
    return dot
  }

  initializeArrows() {
    if (this.touchScreen && !this.showArrowsAlways) return
    const arrows = this.createArrowsContainer()
    arrows.appendChild(this.createArrow('prev'))
    arrows.appendChild(this.createArrow('next'))
    this.element.appendChild(arrows)
    this.updateArrows()
  }

  createArrowsContainer() {
    let arrows = document.createElement('div')
    arrows.classList.add('cnd-carousel__arrows')
    arrows.dataset.target = 'components--carousel.arrows'
    return arrows
  }

  createArrow(dir) {
    let arrow = document.createElement('button')
    arrow.classList.add('cnd-carousel__arrow', `cnd-carousel__arrow--${dir}`)
    arrow.dataset.target = `components--carousel.${dir}Button`
    arrow.dataset.action = `components--carousel#${dir}`
    return arrow
  }

  goToSlide(event) {
    this.carousel.goTo(event.currentTarget.dataset.slide)
  }

  next() {
    this.carousel.next()
  }

  prev() {
    this.carousel.prev()
  }

  updateDots() {
    if (this.activeDot) this.activeDot.classList.remove('active')
    this.activeDot = this.dotTargets[this.carousel.currentSlide]
    this.activeDot.classList.add('active')
  }

  updateArrows() {
    if (this.touchScreen && !this.showArrowsAlways) return
    this.prevButtonTarget.classList.toggle('cnd-opacity-25', this.carousel.currentSlide === 0)
    this.nextButtonTarget.classList.toggle(
      'cnd-opacity-25',
      this.carousel.currentSlide === this.slideTargets.length - 1
    )
  }

  updateSlides() {
    if (this.activeSlide) this.activeSlide.classList.remove('active')
    this.activeSlide = this.slideTargets[this.carousel.currentSlide]
    this.activeSlide.classList.add('active')
  }

  get showArrowsAlways() {
    return this.element.dataset.showArrowsAlways === 'true'
  }
}
