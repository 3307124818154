export default class VerificationUtils {
  static autoTabToNextInput(stripeKey) {
    const field = event.target

    if (event.keyCode === 8) {
      // Backspace
      if (field.previousElementSibling) {
        const previousField = field.previousElementSibling
        previousField.value = ''
        previousField.focus()
      }
    } else if (field.value.length === 1) {
      if (field.nextElementSibling) {
        const nextField = field.nextElementSibling
        nextField.focus()
      }
    } else if (field.value.length > 1) {
      // Replace the value with the last key pressed
      field.value = event.key
      if (field.nextElementSibling) {
        const nextField = field.nextElementSibling
        nextField.focus()
      }
    }
  }
}
