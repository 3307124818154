import { Controller } from 'stimulus'

import lightGallery from 'lightgallery'
import lgThumbnail from 'lightgallery/plugins/thumbnail'
import lgZoom from 'lightgallery/plugins/zoom'
import lgRotate from 'lightgallery/plugins/rotate'

import 'lightgallery/css/lightgallery-bundle.css'

export default class extends Controller {
  connect() {
    this.gallery = lightGallery(this.element, {
      plugins: [lgZoom, lgThumbnail, lgRotate],
      flipHorizontal: false,
      flipVertical: false,
      actualSize: false,
      showZoomInOutIcons: true,
      closeOnTap: false,
      mobileSettings: { controls: true },
      licenseKey: process.env.LIGHT_GALLERY_KEY,
      selector: '.cnd-preview-thumb',
    })
  }

  disconnect() {
    if (this.gallery) {
      this.gallery.destroy()
    }
  }
}
