import { Controller } from 'stimulus'

const moment = require('moment')

export default class extends Controller {
  static targets = [
    'odometerReadingCorrect',
    'odometerReadingIncorrect',
    'ownerOverrideOdometerContainer',
    'ownerOverrideEndOdometer',
    'ownerOverrideStartOdometer',
    'ownerEstimateDistance',
    'latestOdometerReading',
    'endAtCorrect',
    'endAtIncorrect',
    'ownerOverrideEndTimeContainer',
    'ownerOverrideEndTime',
    'ownerOverrideEndTimePicker',
    'submit',
  ]

  startOdometerReading

  initialize() {
    this.initializeOwnerOverrideEndTimePicker()

    if (this.ownerOverrideEndTimeTarget.value) {
      this.showOwnerOverrideEndTime()
    }

    this.startOdometerReading = this.ownerOverrideStartOdometerTarget.value

    if (this.ownerOverrideEndOdometerTarget.value) {
      this.showOwnerOverrideOdometerReading()
    }
  }

  initializeOwnerOverrideEndTimePicker() {
    window.mbscHelper.newCalendarInstanceNative(this.ownerOverrideEndTimePickerTarget, {
      headerText: 'Actual return time {value}',
      defaultValue: window.cndUtils.defaultStartMoment().toDate(),
      min: moment(window.cndVars.reservation.startTimeIso).toDate(),
      defaultValue: moment(window.cndVars.reservation.defaultEndTimeIso).toDate(),
      onSet: (e, inst) => {
        const ownerOverrideEndTime = moment(inst.getVal())
        const offsetHours = parseInt(window.cndVars.reservation.utcOffset)

        this.ownerOverrideEndTimeTarget.value = ownerOverrideEndTime.utcOffset(offsetHours, true).format()
        this.toggleSubmitButton()
      },
    })
  }

  showOwnerOverrideEndTime() {
    this.endAtIncorrectTarget.classList.remove('cnd-btn--transparent', 'cnd-btn--transparent--red')
    this.endAtIncorrectTarget.classList.add('cnd-btn--solid', 'cnd-btn--solid--red')
    this.endAtCorrectTarget.classList.remove('cnd-btn--solid', 'cnd-btn--solid--green')
    this.endAtCorrectTarget.classList.add('cnd-btn--transparent', 'cnd-btn--transparent--green')
    this.ownerOverrideEndTimeContainerTarget.classList.remove('cnd-hidden')
    this.toggleSubmitButton()
  }

  hideOwnerOverrideEndTime() {
    this.endAtIncorrectTarget.classList.remove('cnd-btn--solid', 'cnd-btn--solid--red')
    this.endAtIncorrectTarget.classList.add('cnd-btn--transparent', 'cnd-btn--transparent--red')
    this.endAtCorrectTarget.classList.remove('cnd-btn--transparent', 'cnd-btn--transparent--green')
    this.endAtCorrectTarget.classList.add('cnd-btn--solid', 'cnd-btn--solid--green')
    this.ownerOverrideEndTimeContainerTarget.classList.add('cnd-hidden')
    this.ownerOverrideEndTimePickerTarget.value = ''
    this.ownerOverrideEndTimeTarget.value = ''
    this.toggleSubmitButton()
  }

  showOwnerOverrideOdometerReading() {
    this.odometerReadingIncorrectTarget.classList.remove('cnd-btn--transparent', 'cnd-btn--transparent--red')
    this.odometerReadingIncorrectTarget.classList.add('cnd-btn--solid', 'cnd-btn--solid--red')
    this.odometerReadingCorrectTarget.classList.remove('cnd-btn--solid', 'cnd-btn--solid--green')
    this.odometerReadingCorrectTarget.classList.add('cnd-btn--transparent', 'cnd-btn--transparent--green')
    this.ownerOverrideOdometerContainerTarget.classList.remove('cnd-hidden')
    this.toggleSubmitButton()
  }

  hideOwnerOverrideOdometerReading() {
    this.odometerReadingIncorrectTarget.classList.remove('cnd-btn--solid', 'cnd-btn--solid--red')
    this.odometerReadingIncorrectTarget.classList.add('cnd-btn--transparent', 'cnd-btn--transparent--red')
    this.odometerReadingCorrectTarget.classList.remove('cnd-btn--transparent', 'cnd-btn--transparent--green')
    this.odometerReadingCorrectTarget.classList.add('cnd-btn--solid', 'cnd-btn--solid--green')
    this.ownerOverrideOdometerContainerTarget.classList.add('cnd-hidden')
    this.ownerOverrideEndOdometerTarget.value = ''
    this.ownerOverrideStartOdometerTarget.value = this.startOdometerReading
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    if (this.endTimeNotSet() || this.odometerReadingNotSet()) {
      this.submitTarget.setAttribute('disabled', true)
    } else {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  toggleSubmitButtonOwnerEstimate() {
    if (!this.ownerOverrideEndOdometerTarget.value && !this.ownerEstimateDistanceTarget.value) {
      this.submitTarget.setAttribute('disabled', true)
    } else {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  endTimeNotSet() {
    return !this.ownerOverrideEndTimeTarget.value && this.endAtIncorrectTarget.classList.contains('cnd-btn--solid')
  }

  odometerReadingNotSet() {
    const odometerReadingIncorrectSelected = this.odometerReadingIncorrectTarget.classList.contains('cnd-btn--solid')
    const noOdometerValues = !this.ownerOverrideEndOdometerTarget.value || !this.ownerOverrideStartOdometerTarget.value
    return noOdometerValues && odometerReadingIncorrectSelected
  }
}
