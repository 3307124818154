import { Controller } from 'stimulus'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'

export default class extends Controller {
  static targets = ['container']

  dismiss() {
    this.containerTarget.classList.add('cnd-hidden')

    axios({
      method: 'post',
      url: '/snooze-popup-message',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
    })
  }
}
