import { Controller } from 'stimulus'

const moment = require('moment')

export default class extends Controller {
  static targets = [
    'odometerReadingCorrect',
    'odometerReadingIncorrect',
    'ownerOverrideEndOdometer',
    'ownerEstimateDistance',
    'latestOdometerReading',
    'endAtCorrect',
    'endAtIncorrect',
    'ownerOverrideEndTimeContainer',
    'ownerOverrideEndTime',
    'ownerOverrideEndTimePicker',
    'submit',
  ]

  startOdometerReading

  initialize() {
    this.initializeOwnerOverrideEndTimePicker()
    if (this.ownerOverrideEndTimeTarget.value) {
      this.showOwnerOverrideEndTime()
    } else {
      this.hideOwnerOverrideEndTime()
    }

    this.calculateDistanceEstimate()
  }

  initializeOwnerOverrideEndTimePicker() {
    window.mbscHelper.newCalendarInstanceNative(this.ownerOverrideEndTimePickerTarget, {
      headerText: 'Actual return time {value}',
      defaultValue: window.cndUtils.defaultStartMoment().toDate(),
      min: moment(window.cndVars.reservation.startTimeIso).toDate(),
      defaultValue: moment(window.cndVars.reservation.defaultEndTimeIso).toDate(),
      onSet: (e, inst) => {
        const ownerOverrideEndTime = moment(inst.getVal())
        const offsetHours = parseInt(window.cndVars.reservation.utcOffset)

        this.ownerOverrideEndTimeTarget.value = ownerOverrideEndTime.utcOffset(offsetHours, true).format()
        this.toggleSubmitButton()
      },
    })
  }

  showOwnerOverrideEndTime() {
    this.endAtIncorrectTarget.classList.remove('cnd-btn--transparent', 'cnd-btn--transparent--red')
    this.endAtIncorrectTarget.classList.add('cnd-btn--solid', 'cnd-btn--solid--red')
    this.endAtCorrectTarget.classList.remove('cnd-btn--solid', 'cnd-btn--solid--green')
    this.endAtCorrectTarget.classList.add('cnd-btn--transparent', 'cnd-btn--transparent--green')
    this.ownerOverrideEndTimeContainerTarget.classList.remove('cnd-hidden')
    this.toggleSubmitButton()
  }

  hideOwnerOverrideEndTime() {
    this.endAtIncorrectTarget.classList.remove('cnd-btn--solid', 'cnd-btn--solid--red')
    this.endAtIncorrectTarget.classList.add('cnd-btn--transparent', 'cnd-btn--transparent--red')
    this.endAtCorrectTarget.classList.remove('cnd-btn--transparent', 'cnd-btn--transparent--green')
    this.endAtCorrectTarget.classList.add('cnd-btn--solid', 'cnd-btn--solid--green')
    this.ownerOverrideEndTimeContainerTarget.classList.add('cnd-hidden')
    this.ownerOverrideEndTimePickerTarget.value = ''
    this.ownerOverrideEndTimeTarget.value = ''
    this.toggleSubmitButton()
  }

  calculateDistanceEstimate() {
    if (!this.hasLatestOdometerReadingTarget) {
      if (!this.ownerOverrideEndOdometerTarget.value) {
        this.ownerEstimateDistanceTarget.disabled = true
        this.ownerEstimateDistanceTarget.value = ''
      } else {
        this.ownerEstimateDistanceTarget.disabled = false
      }
      return
    }

    const distance = this.ownerOverrideEndOdometerTarget.value - parseInt(this.latestOdometerReadingTarget.textContent)

    if (this.ownerOverrideEndOdometerTarget.value && distance > 0) {
      this.ownerEstimateDistanceTarget.value = distance
      this.ownerEstimateDistanceTarget.max = distance
      this.ownerEstimateDistanceTarget.disabled = false
    } else {
      this.ownerEstimateDistanceTarget.value = ''
      this.ownerEstimateDistanceTarget.max = null
      this.ownerEstimateDistanceTarget.disabled = true
    }

    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    if (this.endTimeNotSet() || this.distanceEstimateNotSet()) {
      this.submitTarget.setAttribute('disabled', true)
    } else {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  toggleSubmitButtonOwnerEstimate() {
    if (!this.ownerOverrideEndOdometerTarget.value && !this.ownerEstimateDistanceTarget.value) {
      this.submitTarget.setAttribute('disabled', true)
    } else {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  endTimeNotSet() {
    return !this.ownerOverrideEndTimeTarget.value && this.endAtIncorrectTarget.classList.contains('cnd-btn--solid')
  }

  distanceEstimateNotSet() {
    if (!this.hasOwnerEstimateDistanceTarget) {
      return false
    }

    return !this.ownerOverrideEndOdometerTarget.value || !this.ownerEstimateDistanceTarget.value
  }
}
