import { Controller } from 'stimulus'

import * as axios from 'axios'
import AxiosUtils from '../utils/axios_utils'
import FlashMessageUtils from '../utils/flash_message_utils'

export default class extends Controller {
  static targets = [
    'addPromoCodeBtn',
    'basicCover',
    'button',
    'buttonContainer',
    'checkoutButton',
    'creditCard',
    'dcl',
    'dclInput',
    'dclText',
    'dclValue',
    'taxValue',
    'difference',
    'drivingCreditValue',
    'form',
    'joinForm',
    'mobileCreditCard',
    'mobilityMutualOverlay',
    'upfrontTripCost',
    'prepaidTotal',
    'promotionBorder',
    'promotionContainer',
    'promotionHiddenInput',
    'promotionInput',
    'promotionInputOverlay',
    'promotionInvalid',
    'promotionText',
    'promotionValue',
    'signupForm',
    'successPanel',
    'memberAgreementOverlay',
    'coverOptions',
    'coverOption',
  ]

  initialize() {
    const dataset = this.element.dataset
    this.vehicleId = parseInt(dataset.vehicleId)
    this.startAt = dataset.startAt
    this.endAt = dataset.endAt
    this.overrideDeposit = dataset.overrideDeposit
    this.isGuest = dataset.guest == 'true'
    this.isHeavyweightCover = dataset.heavyweightCover == 'true'

    const creditCardTargets = [
      this.hasCreditCardTarget && this.creditCardTarget,
      this.hasMobileCreditCardTarget && this.mobileCreditCardTarget,
    ].filter((target) => target) // filter target that exists
    creditCardTargets.forEach((target) => {
      target.addEventListener('cardupdated', this.submitForm)
      target.addEventListener('carderror', () => {
        this.hideMobilityMutualOverlay()
        this.hideMemberAgreementOverlay()
      })
      target.addEventListener('disablebutton', this.disableCheckoutButton)
      target.addEventListener('enablebutton', this.enableCheckoutButton)
    })

    if (this.hasCreditCardTarget) {
      this.buttonContainerTarget.addEventListener('mouseover', () => {
        if (this.checkoutButtonTarget.disabled) {
          this.creditCardTarget.cnd.panel.classList.add('cnd-shadow-outline')
        }
      })
      this.buttonContainerTarget.addEventListener('mouseleave', () => {
        this.creditCardTarget.cnd.panel.classList.remove('cnd-shadow-outline')
      })
    }

    this.isRedesign = dataset.redesign === 'true'
    if (this.isRedesign) {
      document.body.classList.add('checkout-redesign')
      this.element.setAttribute('step', 'select-cover')
    }
    this.enableCheckoutButton()
  }

  pageHide() {
    this.enableCheckoutButton()
  }

  navigateBack(event) {
    event.preventDefault()
    event.stopPropagation()
    window.history.back()
  }

  updateHTML(target, html) {
    target.innerHTML = html
  }

  _calculateTripCost(dcl, promotionCode) {
    if (this.submitting) return
    this.submitting = true

    let params = {
      vehicle_id: this.vehicleId,
      start_time_iso: this.startAt,
      end_time_iso: this.endAt,
      override_deposit: this.overrideDeposit,
    }

    if (dcl) {
      Object.assign(params, { dcl: parseInt(dcl) })
    }

    if (promotionCode) {
      Object.assign(params, { promotion_code: promotionCode })
    }

    this.upfrontTripCostTargets
      .concat(this.differenceTargets)
      .concat(this.prepaidTotalTargets)
      .forEach((el) => this.updateHTML(el, 'Loading...'))

    axios
      .get('/cost-estimates/display', { params })
      .then((res) => {
        const data = res.data

        if (this.hasTaxValueTarget) {
          this.updateHTML(this.taxValueTarget, data.prepaid_total_tax)
        }

        if (!data.dcl) {
          this.dclTarget.classList.add('cnd-hidden')
        } else {
          this.dclTarget.classList.remove('cnd-hidden')
          this.updateHTML(this.dclValueTarget, data.dcl)
          this.updateHTML(this.dclTextTarget, data.dcl_text)
        }

        this.differenceTargets.forEach((el) => {
          this.updateHTML(el, data.difference)
        })

        const currencyToNumber = (currency) => Number(currency.replace(/[^0-9\.-]+/g, ''))
        const numberToCurrency = (number) => `$${number.toFixed(2)}`

        const upfrontTripCost =
          currencyToNumber(data.hire_cost) +
          currencyToNumber(data.driving_credits) +
          currencyToNumber(data.promotion_discount)

        this.upfrontTripCostTargets.forEach((el) => this.updateHTML(el, numberToCurrency(upfrontTripCost)))

        this.prepaidTotalTargets.forEach((el) => {
          if (data.country_code == 'AU') {
            this.updateHTML(el, data.payment_breakdown.table.amount_to_charge)
          } else {
            this.updateHTML(el, data.prepaid_total)
          }
        })

        if (data.driving_credits != '$0.00') {
          this.updateHTML(this.drivingCreditValueTarget, data.driving_credits)
        }

        document.dispatchEvent(
          new CustomEvent('estimateUpdated', {
            detail: data.payment_breakdown.table,
          })
        )

        if (promotionCode) {
          this.promotionContainerTarget.classList.remove('cnd-hidden')
          this.updateHTML(this.promotionValueTarget, data.promotion_discount)
          this.updateHTML(this.promotionTextTarget, promotionCode)
        } else {
          this.promotionContainerTarget.classList.add('cnd-hidden')
        }

        this.promotionBorderTarget.classList.toggle('cnd-hidden', !(promotionCode || this.hasDrivingCreditValueTarget))
      })
      .finally(() => {
        this.submitting = false
      })
  }

  updateCoverSelection(e) {
    if (this.submitting) return
    if (this.coverOptionsTarget.dataset.value == e.target.dataset.value) {
      return
    }
    this.coverOptionsTarget.dataset.value = e.target.dataset.value
    this.coverOptionTargets.forEach((target) => target.classList.remove('checked'))
    e.target.classList.add('checked')

    this.calculateTripCostFromComponent(e.target.dataset.value)
    this.enableCheckoutButton()
  }

  calculateTripCostFromComponent(dclValue) {
    const dcl = parseInt(dclValue)
    this.dclInputTarget.value = dcl

    if (!window.cndVars.loggedIn) {
      this._addProtectionSelectionToFormAction(dcl)
    }

    let promotionCode = this.promotionHiddenInputTarget.value

    this._calculateTripCost(dcl, promotionCode)
  }

  _addProtectionSelectionToFormAction(dclId) {
    const action = decodeURIComponent(this.signupFormTarget.action)

    const baseUrl = action.split(/\?(.+)/)[0]
    const referrerUrl = new URL(document.referrer)

    referrerUrl.searchParams.set('protection', dclId)

    this.signupFormTarget.action = baseUrl + '?referer=' + encodeURIComponent(referrerUrl.href)
  }

  calculateTripCost(e) {
    const dcl = e.currentTarget.dataset.val
    this.dclInputTarget.value = dcl
    this._calculateTripCost(dcl)
  }

  applyPromotion(e) {
    e.preventDefault()

    if (!this.promotionInvalidTarget.classList.contains('cnd-hidden')) {
      this.promotionInvalidTarget.classList.add('cnd-hidden')
      this.promotionInvalidTarget.innerHTML = ''
    }

    const promoCode = this.promotionInputTarget.value

    if (promoCode.length === 0 || promoCode.match(/[^0-9a-z]/i)) {
      this.promotionInvalidTarget.innerHTML = 'Code is invalid.'
      this.promotionInvalidTarget.classList.remove('cnd-hidden')

      return false
    }

    const dclValue = this.dclInputTarget.value
    const dcl = dclValue != 0 ? dclValue : null

    axios
      .get(`members/promotions/${promoCode}/verify`, {
        params: { vehicle_id: this.vehicleId, vlocal_start_at: this.startAt },
      })
      .then((res) => {
        const data = res.data

        if (data.can_be_applied) {
          this.setPromotionCode(promoCode, data, dcl)
        } else {
          this.promotionInvalidTarget.innerHTML = data.message || 'Sorry - that code is invalid or has expired.'
          this.promotionInvalidTarget.classList.remove('cnd-hidden')
        }
      })
      .catch((_error) => {
        this.promotionInvalidTarget.innerHTML = 'Something went wrong, please try again.'
        this.promotionInvalidTarget.classList.remove('cnd-hidden')
      })

    return false
  }

  setPromotionCode(promoCode, data, dcl) {
    this.promotionInputOverlayTarget.classList.add('cnd-hidden')

    this._calculateTripCost(dcl, data.code)
    this.promotionInputTarget.value = ''
    this.promotionHiddenInputTarget.value = promoCode
    this.successPanelTarget.classList.remove('cnd-panel--red')
    this.successPanelTarget.querySelector('svg').classList.add('cnd-hidden')

    const message = data.message || 'Nice one - your code has been added.'
    this.successPanelTarget.querySelector('p').innerHTML = message

    this.successPanelTarget.classList.remove('cnd-hidden')
    this.addPromoCodeBtnTarget.classList.add('cnd-hidden')
  }

  clearError(_e) {
    this.promotionInvalidTarget.innerHTML = ''
    this.promotionInvalidTarget.classList.add('cnd-hidden')
  }

  removePromotion(_e) {
    const dclValue = this.dclInputTarget.value
    const dcl = dclValue != 0 ? dclValue : null

    this.promotionContainerTarget.classList.add('cnd-hidden')
    this.promotionInputTarget.value = ''
    this._calculateTripCost(dcl)
    this.promotionHiddenInputTarget.value = ''

    this.successPanelTarget.classList.add('cnd-hidden')
    this.addPromoCodeBtnTarget.classList.remove('cnd-hidden')
  }

  submitAndDisable(e) {
    // This is to address an old Microsoft Edge bug where disabled buttons
    // accept clicks if they contain any HTML elements (eg a <span> like here).
    if (this.checkoutButtonTarget.disabled == true) {
      e.preventDefault()
      return false
    } else {
      this.disableCheckoutButton()

      // Method fired by the checkout form element
      if (this.hasCreditCardTarget) {
        this.creditCardTarget.cnd.cardSubmit(e)
      } else {
        this.submitForm()
      }

      return true
    }
  }

  showJoinForm(e) {
    e.preventDefault()

    this.joinFormTarget.classList.remove('cnd-hidden')
  }

  hideJoinForm(e) {
    e.preventDefault()

    this.joinFormTarget.classList.add('cnd-hidden')
  }

  hideMobilityMutualOverlay = () => {
    this.hasMobilityMutualOverlayTarget && this.mobilityMutualOverlayTarget.classList.add('cnd-hidden')
  }

  hideMemberAgreementOverlay = () => {
    this.hasMemberAgreementOverlayTarget && this.memberAgreementOverlayTarget.classList.add('cnd-hidden')
  }

  goToPayment() {
    this.element.setAttribute('step', 'confirm-cost')
    window.scrollTo(0, 0)
  }

  goBack() {
    if (this.element.getAttribute('step') === 'confirm-cost') {
      this.element.setAttribute('step', 'select-cover')
    } else {
      window.history.back()
    }
  }

  trackTooltipClick = (e) => {
    const eventName = e.currentTarget.dataset.eventName
    /*global analytics */
    if (typeof analytics !== 'undefined' && typeof eventName !== 'undefined' && eventName.length > 0) {
      analytics.track(`Checkout:${eventName}`)
    }
  }

  showMobilityMutualOverlay = (e) => {
    if (this.isMobile()) {
      this.disableCheckoutButton()

      if (this.hasMobileCreditCardTarget) {
        this.mobileCreditCardTarget.cnd.cardSubmit()
      } else {
        this.submitForm()
      }
    } else if (this.hasMobilityMutualOverlayTarget) {
      this.mobilityMutualOverlayTarget.classList.remove('cnd-hidden')
    } else {
      this.submitAndDisable(e)
    }
  }

  showMemberAgreementOverlay = (e) => {
    if (this.hasMemberAgreementOverlayTarget) {
      this.memberAgreementOverlayTarget.classList.remove('cnd-hidden')
    } else {
      this.submitAndDisable(e)
    }
  }

  updateMemberAgreement = (e) => {
    let target = e.currentTarget
    axios
      .put('/member_agreements', { country_code: target.dataset.country }, AxiosUtils.getHeaders())
      .then(() => {
        this.hideMemberAgreementOverlay()
        if (target.dataset.nextAction === 'showMobilityMutualOverlay') {
          this.showMobilityMutualOverlay()
        } else {
          this.submitAndDisable(e)
        }
      })
      .catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.message)
      })
  }

  submitForm = () => {
    if (this.coverSelected()) {
      this.formTarget.submit()
    }
  }

  isMobile = () => {
    return window.innerWidth < 768 // less than md
  }

  enableCheckoutButton = () => {
    if (this.isHeavyweightCover || this.coverSelected()) {
      this.checkoutButtonTargets.forEach((target) => (target.disabled = false))
      if (this.isGuest) {
        this.element.querySelector('#checkoutButton').disabled = false
      }
    } else {
      this.disableCheckoutButton()
    }
  }

  disableCheckoutButton = () => {
    this.checkoutButtonTargets.forEach((target) => (target.disabled = true))
    if (this.isGuest) {
      this.element.querySelector('#checkoutButton').disabled = true
    }
  }

  trackPricingTooltip() {
    axios.get(`/trips/track_pricing_info?vehicle_id=${this.vehicleId}`, AxiosUtils.getHeaders())
  }

  coverSelected() {
    return (
      this.hasCoverOptionsTarget &&
      this.coverOptionsTarget.dataset.value != '' &&
      this.coverOptionsTarget.dataset.value != null
    )
  }
}
