export default class TargetUtils {
  context

  constructor(context) {
    this.context = context
  }

  hide(target) {
    this.context[target].classList.add('cnd-hidden')
  }

  show(target) {
    this.context[target].classList.remove('cnd-hidden')
  }

  toggle(target) {
    this.context[target].classList.toggle('cnd-hidden')
  }
}
