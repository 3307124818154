import _ from 'lodash'

export default class LocationUtils {
  static offsetLocation(lat, lng, skipOffset) {
    lat = parseFloat(lat)
    lng = parseFloat(lng)

    if (!skipOffset) {
      // Earth's radius
      const R = 6378137

      // Offsets (in meters, with random +/- number between 100 and 200)
      const d = 100
      const de = (Math.round(Math.random()) * 2 - 1) * (d + Math.floor(Math.random() * d))
      const dn = de

      // Coordinate offsets (in radians)
      const dLat = dn / R
      const dLng = de / (R * Math.cos((Math.PI * lat) / 180))

      // OffsetPosition, decimal degrees
      lat = lat + (dLat * 180) / Math.PI
      lng = lng + (dLng * 180) / Math.PI
    }

    return { latitude: lat, longitude: lng }
  }

  /**
   * Get Geocoded Address
   *
   * Finds the first "street_address" in an array of geocoded addresses provided by the google maps geocoder API
   * then parses that address into an address object we can actually use.
   *
   * @ref https://developers.google.com/maps/documentation/geocoding/intro#GeocodingResponses
   *
   * @param {array} addresses
   */
  static getGeocodedAddress(addresses) {
    let result = false

    let address = LocationUtils.findElementByType(addresses, 'street_address')

    if (!address) {
      address = LocationUtils.findElementByType(addresses, 'premise')
    }

    if (address) {
      result = {
        latitude: address.geometry.location.lat(),
        longitude: address.geometry.location.lng(),
        number: LocationUtils.findElementByType(address.address_components, 'street_number', 'short_name'),
        street: LocationUtils.findElementByType(address.address_components, 'route', 'short_name'),
        suburb: LocationUtils.findElementByType(address.address_components, 'locality', 'short_name'),
        state: LocationUtils.findElementByType(address.address_components, 'administrative_area_level_1', 'short_name'),
        postCode: LocationUtils.findElementByType(address.address_components, 'postal_code', 'short_name'),
        country: LocationUtils.findElementByType(address.address_components, 'country', 'short_name'),
        formatted_address: address.formatted_address,
      }

      return result
    } else {
      const place = addresses[0]

      if (place && place.geometry) {
        const components = {}

        place.address_components.forEach((component) => {
          components[component.types[0]] = component.short_name
        })

        result = {
          latitude: place.geometry.location.lat(),
          longitude: place.geometry.location.lng(),
          number: components['street_number'],
          street: components['route'],
          suburb: components['locality'],
          state: components['administrative_area_level_1'],
          postCode: components['postal_code'],
          country: components['country'],
          formatted_address: place.formatted_address,
        }
      }
    }

    return result
  }

  /**
   * Find Element by Type
   *
   * Filters the given array by the type parameter provided and optionally returns the given property.
   *
   * @param {array} elements
   * @param {string} type
   * @param {string} prop
   */
  static findElementByType(elements, type, prop) {
    let result = _.find(elements, (element) => {
      return -1 < element.types.indexOf(type)
    })

    if (result) {
      if (prop && result.hasOwnProperty(prop)) {
        return result[prop]
      }

      return result
    }

    return false
  }

  static parseMapboxResults(data) {
    let geoData = {
      formattedAddress: data.place_name,
      latitude: data.center[1],
      longitude: data.center[0],
    }

    data.context.forEach((feature) => {
      if (feature.id.indexOf('locality') !== -1) {
        geoData.suburb = feature.text
      }

      if (geoData.suburb == undefined && feature.id.indexOf('place') !== -1) {
        geoData.suburb = feature.text
      }

      if (feature.id.indexOf('postcode') !== -1) {
        geoData.postCode = feature.text
      }

      if (feature.id.indexOf('region') !== -1) {
        geoData.state = feature.short_code.split('-')[1]
      }

      if (feature.id.indexOf('country') !== -1) {
        geoData.country = feature.short_code.toUpperCase()
      }
    })

    if (data.place_type.indexOf('address') !== -1) {
      geoData.number = data.address
      geoData.street = data.text
    }

    if (data.place_type.indexOf('postcode') !== -1) {
      geoData.postCode = data.text
    }

    if (data.place_type.indexOf('locality') !== -1 || data.place_type.indexOf('place') !== -1) {
      geoData.suburb = data.text
    }

    if (data.place_type.indexOf('region') !== -1) {
      geoData.state = data.text
    }

    return geoData
  }
}
