import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import DirectUploadUtils from '../../utils/direct_upload_utils'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
import { ACCEPTED_FILES } from '../../constants/uploads'

export default class extends Controller {
  dropzone
  fileInputName
  uploadedFiles
  fileInputSelector
  uploadUrl
  directUploadSucceeded = []

  initialize() {
    Dropzone.autoDiscover = false

    this.uploadedFiles = JSON.parse(this.element.dataset.uploadedFiles)
    this.fileInputName = this.element.dataset.fileInputName
    this.fileInputSelector = `input[name="${this.fileInputName}"]`
    this.uploadUrl = this.element.dataset.uploadUrl
    this.deleteUrl = this.element.dataset.deleteUrl
    this.isSingleUpload = this.element.dataset.singleUpload == 'true'

    if (!this.fileInputName || !this.uploadUrl) {
      this.element.classList.add('cnd-hidden')
    }

    if (!this.element.dropzone) {
      this.initializeDropzone()
    }
  }

  initializeDropzone() {
    const config = {
      acceptedFiles: ACCEPTED_FILES,
      autoProcessQueue: true,
      maxFilesize: 15, // MB
      parallelUploads: 100,
      previewsContainer: this.element.querySelector('.dropzone-previews'),
      resizeWidth: 1400,
      thumbnailHeight: 96,
      thumbnailWidth: 96,
      uploadMultiple: true,
      url: this.uploadUrl,
    }

    this.dropzone = new Dropzone(this.element, config)
    this.dropzone._uploadData = this.handleUpload.bind(this)

    this.element.classList.add('dropzone')

    // Expose Dropzone events
    this.dropzone.on('addedfile', (file) => this.handleAddFile(file))
    this.dropzone.on('removedfile', (file) => this.handleRemoveFile(file))
    this.dropzone.on('thumbnail', () => this.element.dispatchEvent(new Event('thumbnail')))
    this.dropzone.on('queuecomplete', () => this.element.dispatchEvent(new Event('queuecomplete')))

    // expose component methods
    this.element.cnd = {
      getAddedFiles: this.getAddedFiles.bind(this),
      getUploadedFiles: this.getUploadedFiles.bind(this),
      processQueue: this.processQueue.bind(this),
      reset: this.reset.bind(this),
      setOptions: this.setOptions.bind(this),
    }

    // Add uploaded files
    if (this.uploadedFiles.length > 0) {
      this.addUploadedFiles(this.uploadedFiles)
    }

    this.element.dispatchEvent(new Event('initializedFileUpload'))
  }

  handleUpload(files, dataBlocks) {
    dataBlocks.forEach((dataBlock, i) => {
      if (!dataBlock.data.name) {
        dataBlock.data.name = files[i].name
      }

      this.uploadFile(dataBlock.data, this.uploadUrl, files[i].previewElement)
        .then((blob) => {
          this.dropzone._finished(files, '', null)

          // Create a hidden input field with the signed id of the uploaded file so that the containing form knows what
          // files were uploaded via the DirectUpload library.
          const hiddenField = document.createElement('input')
          hiddenField.setAttribute('type', 'hidden')
          hiddenField.setAttribute('value', blob.signed_id)
          hiddenField.name = this.fileInputName
          this.element.appendChild(hiddenField)
          this.directUploadSucceeded.push(files[i])

          if (this.getAddedFiles().length === this.directUploadSucceeded.length) {
            this.element.dispatchEvent(new Event('directUploadSuccess'))
          }
        })
        .catch((error) => {
          if (error.indexOf('401') !== -1) {
            location.reload()
          } else {
            console.error(error)
          }
        })
    })
  }

  uploadFile(file, uploadUrl, element) {
    const directUpload = new DirectUploadUtils(file, uploadUrl, element)
    return directUpload.upload()
  }

  handleRemoveFile(file) {
    if (file.id) {
      axios
        .delete(this.deleteUrl, {
          responseType: 'json',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
          },
          params: {
            photo_id: file.id,
          },
        })
        .then((res) => {
          this.element.dispatchEvent(new Event('removedfile'))
        })
    } else {
      this.element.dispatchEvent(new Event('removedfile'))
    }
  }

  handleAddFile(file) {
    // stop loading bar from bouncing uncontrollably
    //file.previewElement.querySelector('.dz-progress').classList.add('cnd-hidden');
    this.toggleUploadButtons()

    if (this.isSingleUpload && this.dropzone.files.length > 1) {
      this.dropzone.removeAllFiles()
      this.dropzone.addFile(file)
    }

    // if file isn't an image than replace img tag with file icon
    if (!file.type.match(/image.*/)) {
      file.previewElement.querySelector('.dz-image').innerHTML =
        `<div class="cnd-flex cnd-align-center cnd-h-full cnd-p-4 cnd-w-full"><div class="cnd-file-icon cnd-h-full cnd-w-full"></div></div>`
    }

    const removeBtn = document.createElement('button')

    removeBtn.setAttribute('type', 'button')
    removeBtn.setAttribute('style', 'border-radius:50%;right:-12px;top:-12px;')
    removeBtn.classList.add('dz-remove', 'cnd-absolute', 'cnd-bg-white', 'cnd-h-6', 'cnd-m-0', 'cnd-w-6')
    removeBtn.innerHTML = '<div class="cnd-remove-circle-icon cnd-h-full cnd-w-full"></div>'
    removeBtn.addEventListener('click', () => {
      this.dropzone.removeFile(file)
      this.toggleUploadButtons()
    })

    file.previewElement.appendChild(removeBtn)

    // Since we are using the DirectUpload library (via the Uploader utility) we don't know the status of the file
    // upload as that's been abstracted away. So we rely on the DirectUpload utility to send progress events to us via
    // its own custom "uploadProgress" event. In order to listen for "uploadProgress" events we bind an event listener
    // to the preview thumbnail div. This creates a 1-1 relationship between the file upload and our div so multiple
    // files can be uploaded at the same time, but each div will only listen to a single upload.
    file.previewElement.addEventListener('uploadProgress', (e) => {
      // DirectUpload doesn't let you cancel uploads, so delete the "remove" button
      if (file.previewElement.querySelector('.dz-remove')) {
        file.previewElement.querySelector('.dz-remove').remove()
      }

      // add upload bar styling
      file.previewElement.classList.add('dz-processing')
      file.previewElement.querySelector('.dz-progress').classList.remove('cnd-hidden')
      this.dropzone.emit('uploadprogress', file, e.detail.percentComplete, e.detail.bytesSent)
    })

    this.element.dispatchEvent(new Event('addedfile'))
  }

  toggleUploadButtons() {
    // show/hide browse files text
    this.element.querySelector('.dz-message').classList.toggle('cnd-hidden', this.dropzone.files.length > 0)

    const maxFiles = this.dropzone.options.maxFiles
    const uploadCount = this.getAddedFiles().length
    const hideAddBtn = !(this.dropzone.files.length > 0 || (maxFiles && maxFiles > 1 && maxFiles > uploadCount))

    // show add more files button unless max file count is reacched
    this.element.querySelector('.dz-add').classList.toggle('cnd-hidden', hideAddBtn)
  }

  getAddedFiles() {
    return this.dropzone.getAcceptedFiles()
  }

  getUploadedFiles() {
    return this.element.querySelectorAll(this.fileInputSelector)
  }

  addUploadedFiles(uploadedFiles) {
    for (let i = 0; i < uploadedFiles.length; i++) {
      const jsonData = JSON.parse(uploadedFiles[i])

      let file = {
        type: jsonData.content_type,
        name: jsonData.filename,
        size: jsonData.byte_size,
        id: jsonData.id,
      }

      this.dropzone.emit('addedfile', file)
      this.dropzone.emit('thumbnail', file, jsonData.icon_url)
      this.dropzone.emit('complete', file)
      this.dropzone.files.push(file)
    }
  }

  processQueue() {
    this.dropzone.processQueue()
  }

  reset() {
    this.dropzone.removeAllFiles(true)
    this.element.querySelector('.dz-message').classList.remove('cnd-hidden')
    this.getUploadedFiles().forEach((el) => el.remove())
  }

  setOptions(options) {
    Object.keys(options).forEach((key) => {
      this.dropzone.options[key] = options[key]
    })
  }

  openFileBrowser() {
    return this.dropzone.hiddenFileInput.click()
  }
}
