import { Controller } from 'stimulus'

// Axios
import qs from 'qs'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'

// Constants
import { ISSUES_MAP } from '../../constants/reviews'

let targetsWithBinding = [
  'reviewOf',
  'reviewDeclined',

  'vehicleReviewPublicFeedback',
  'vehicleReviewPrivateFeedback',

  'vehicleReviewPublicReply',
  'vehicleReviewPublicReplyBy',

  'borrowerReviewPublicFeedback',
  'borrowerReviewPrivateFeedback',
]

export default class extends Controller {
  static targets = targetsWithBinding.concat([
    'alert',
    'hidden',

    'vehicleReview',
    'vehicleReviewIcon',
    'vehicleReviewIssues',
    'vehicleReviewHeading',
    'vehicleReviewFlagForm',
    'vehicleReviewFlagComment',
    'vehicleReviewPublicReplyForm',
    'vehicleReviewPublicReplyInput',

    'borrowerReview',
    'borrowerReviewIcon',
    'borrowerReviewIssues',
    'borrowerReviewHeading',
    'borrowerReviewFlagForm',
    'borrowerReviewFlagComment',

    'reviewDeclined',
  ])

  initialize() {
    let dataset = this.element.dataset

    this.reservationId = JSON.parse(dataset.reservationId)

    this.forOwner = dataset.reviewFor === 'owner'
    this.forBorrower = dataset.reviewFor === 'borrower'
    this.isReviewable = JSON.parse(dataset.reviewable)

    this.vehicleReview = JSON.parse(dataset.vehicleReview)
    this.borrowerReview = JSON.parse(dataset.borrowerReview)

    this.reviewDeclined = this.forOwner ? "the borrower's" : 'your'

    this.STATUS_TYPE = {
      APPROVED: 'approved',
      DECLINED: 'declined',
      SUBMITTED: 'submitted',
      FLAGGED: 'flagged_for_review',
    }

    this.setup()
  }

  hide(target) {
    target.classList.add('cnd-hidden')
  }

  show(target) {
    target.classList.remove('cnd-hidden')
  }

  updateHTML(target, html) {
    target.innerHTML = html
  }

  getTypeFromEvent(e) {
    return e.currentTarget.dataset.type
  }

  setup() {
    ;['vehicle', 'borrower'].forEach((type) => {
      let reviewType = `${type}Review`
      if (this[reviewType] === null) return
      let ratingText = this[reviewType].rating ? 'up' : 'down'

      this[reviewType]['ratingText'] = ratingText
      this[`${reviewType}Target`].classList.add(ratingText)
      this[`${reviewType}IconTarget`].classList.add(`fa-thumbs-${ratingText}`)

      this.updateIssues(type)
    })

    if (this.borrowerReview) {
      this.borrowerReviewPublicFeedback = this.borrowerReview['public_feedback']
      this.borrowerReviewPrivateFeedback = this.borrowerReview['private_feedback']
    }
    if (this.vehicleReview) {
      this.vehicleReviewPublicFeedback = this.vehicleReview['public_feedback']
      this.vehicleReviewPrivateFeedback = this.vehicleReview['private_feedback']

      if (this.vehicleReview.comment) {
        this.vehicleReviewPublicReply = this.vehicleReview.comment['public_reply']
      }
    }

    if (this.forOwner) {
      this.reviewOf = 'borrower'

      if (this.borrowerReview) {
        this.borrowerReview['self'] = true

        this.updateHTML(
          this.borrowerReviewHeadingTarget,
          `You gave the borrower a thumbs ${this.borrowerReview.ratingText}`
        )
      }
      if (this.vehicleReview) {
        if (this.vehicleReview.comment) {
          this.vehicleReviewPublicReplyBy = 'Your'
        }
        this.updateHTML(
          this.vehicleReviewHeadingTarget,
          `The borrower gave you a thumbs ${this.vehicleReview.ratingText}`
        )
      }
    } else if (this.forBorrower) {
      this.reviewOf = 'vehicle'

      if (this.borrowerReview) {
        this.updateHTML(
          this.borrowerReviewHeadingTarget,
          `The owner gave you a thumbs ${this.borrowerReview.ratingText}`
        )
      }
      if (this.vehicleReview) {
        this.vehicleReview['self'] = true

        if (this.vehicleReview.comment) {
          this.vehicleReviewPublicReplyBy = "Owner's"
        }

        this.updateHTML(this.vehicleReviewHeadingTarget, `You gave the owner a thumbs ${this.vehicleReview.ratingText}`)
      }
    }

    this.showHiddenTargets()
    this.updateTargetsWithBinding()
  }

  updateTargetsWithBinding() {
    targetsWithBinding.forEach((target) => {
      this[`${target}Targets`].forEach((el) => {
        this[target] ? this.updateHTML(el, this[target]) : el.classList.add('muted')
      })
    })
  }

  showHiddenTargets() {
    this.hiddenTargets.forEach((el) => {
      if (this[el.dataset.condition](el.dataset.type)) this.show(el)
    })
  }

  showAlert(text, isWarning = false) {
    this.updateHTML(this.alertTarget, text)
    this.alertTarget.classList.toggle('warning', isWarning)
    this.alertTarget.classList.toggle('success', !isWarning)
    this.show(this.alertTarget)
  }

  hideAlert() {
    this.updateHTML(this.alertTarget, '')
    this.alertTarget.classList.remove('warning', 'success')
    this.alertTarget.classList.remove('warning', 'success')
    this.hide(this.alertTarget)
  }

  updateIssues(type) {
    let review = this[`${type}Review`]
    if (!review || !review.issues) return
    let issues = []
    review.issues.forEach((issue) => {
      issues.push(this.getIssueHTML(issue, type))
    })
    this.updateHTML(this[`${type}ReviewIssuesTarget`], issues.join(''))
  }

  getIssueHTML(i, type) {
    const NOT_REGEX = /(not_)/
    let text = '',
      bad = NOT_REGEX.test(i)
    text = ISSUES_MAP[type][i.replace(NOT_REGEX, '')]
    return `<span class="tag"><i class="fa ${bad ? 'fa-times' : 'fa-check'}"></i>${text}</span>`
  }

  actionOnReview(type, data, flag = false) {
    type = `${type}Review`

    let url = `/trips/${this.reservationId}/reviews/${this[type].id}`
    if (flag) url += '/flag'

    axios({
      url: url,
      method: 'put',
      data: qs.stringify(data),
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
    })
      .then(
        (res) => {
          this[type] = res.data
          this.hide(flag ? this.borrowerReviewFlagFormTarget : this.vehicleReviewPublicReplyFormTarget)
          this.showAlert(flag ? 'Public comment flagged.' : 'Public reply submitted.')
        },
        () => {
          let error = flag ? 'flag public comment' : 'submit public reply'
          this.showAlert(`Unable to ${error}. Please try again.`, true)
        }
      )
      .finally(() => this.setup())
  }

  showFlagForm(e) {
    e.preventDefault()
    this.hide(e.currentTarget)
    this.show(this[`${this.getTypeFromEvent(e)}ReviewFlagFormTarget`])
  }

  hasValidReview(type) {
    return !!this[type]
  }

  hasReviewNoteByType(type) {
    return (
      this.hasValidReview(type) &&
      ((this[type].status === this.STATUS_TYPE.SUBMITTED && this[type].self) ||
        (this[type].status === this.STATUS_TYPE.APPROVED && this[type].self) ||
        this[type].status === this.STATUS_TYPE.DECLINED)
    )
  }

  hasVehicleReviewNote() {
    return this.hasReviewNoteByType('vehicleReview')
  }

  hasBorrowerReviewNote() {
    return this.hasReviewNoteByType('borrowerReview')
  }

  isStatusForType(type, status) {
    return this.hasValidReview(type) && this[type].status === status
  }

  isVehicleReviewSubmittedAndSelf() {
    return (
      this.isStatusForType('vehicleReview', this.STATUS_TYPE.SUBMITTED) && this.vehicleReview && this.vehicleReview.self
    )
  }

  isVehicleReviewApprovedAndSelf() {
    return (
      this.isStatusForType('vehicleReview', this.STATUS_TYPE.APPROVED) && this.vehicleReview && this.vehicleReview.self
    )
  }

  isVehicleReviewDeclined() {
    return this.isStatusForType('vehicleReview', this.STATUS_TYPE.DECLINED)
  }

  isBorrowerReviewSubmittedAndSelf() {
    return (
      this.isStatusForType('borrowerReview', this.STATUS_TYPE.SUBMITTED) &&
      this.borrowerReview &&
      this.borrowerReview.self
    )
  }

  isBorrowerReviewApprovedAndSelf() {
    return (
      this.isStatusForType('borrowerReview', this.STATUS_TYPE.APPROVED) &&
      this.borrowerReview &&
      this.borrowerReview.self
    )
  }

  isBorrowerReviewDeclined() {
    return this.isStatusForType('borrowerReview', this.STATUS_TYPE.DECLINED)
  }

  hasVehicleReview() {
    return this.hasValidReview('vehicleReview') && (this.forBorrower || !!this.borrowerReview || !this.isReviewable)
  }

  hasBorrowerReview() {
    return this.hasValidReview('borrowerReview') && (this.forOwner || !!this.vehicleReview || !this.isReviewable)
  }

  hasVehicleReviewPublicReply() {
    return this.hasVehicleReview() && !!this.vehicleReview.comment && !!this.vehicleReviewPublicReply
  }

  canSubmitPublicReply() {
    return this.forOwner && !this.hasVehicleReviewPublicReply()
  }

  isVehicleReviewFlagged() {
    return this.forOwner && this.hasVehicleReview() && this.vehicleReview.status === this.STATUS_TYPE.FLAGGED
  }

  isNotVehicleReviewFlagged() {
    return this.forOwner && this.hasVehicleReview() && this.vehicleReview.status !== this.STATUS_TYPE.FLAGGED
  }

  isBorrowerReviewFlagged() {
    return this.forBorrower && this.hasBorrowerReview() && this.borrowerReview.status === this.STATUS_TYPE.FLAGGED
  }

  isNotBorrowerReviewFlagged() {
    return this.forBorrower && this.hasBorrowerReview() && this.borrowerReview.status !== this.STATUS_TYPE.FLAGGED
  }

  hasNoReviews() {
    return !this.isReviewable && !this.borrowerReview && !this.vehicleReview
  }

  submitReply(e) {
    e.preventDefault()
    this.actionOnReview('vehicle', {
      public_reply: this['vehicleReviewPublicReplyInputTarget'].value,
    })
  }

  flagReview(e) {
    e.preventDefault()
    let type = this.getTypeFromEvent(e)
    this.actionOnReview(
      type,
      {
        status: this.STATUS_TYPE.FLAGGED,
        flagged_for_review_comment: this[`${type}ReviewFlagCommentTarget`].value,
      },
      true
    )
  }
}
