import { Controller } from 'stimulus'
import TargetUtils from '../utils/target_utils'

export default class extends Controller {
  static targets = ['input', 'preview', 'existing']

  targetUtils

  initialize() {
    this.targetUtils = new TargetUtils(this)
  }

  onSelect() {
    const THIS = this
    const file = this.inputTarget.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      THIS.showImage(e)
    }
  }

  showImage(e) {
    const image = new Image()
    image.src = e.target.result

    if (this.hasExistingTarget) {
      this.targetUtils.hide('existingTarget')
    }

    if (this.hasPreviewTarget) {
      this.previewTarget.src = image.src
      this.targetUtils.show('previewTarget')
    }
  }
}
