import { Controller } from 'stimulus'
import DateUtils from '../utils/date_utils'
import DeviceUtils from '../utils/device_utils'
import MobiscrollUtils from '../utils/mobiscroll_utils'

export default class extends Controller {
  static targets = [
    'dateTimePickerModal',
    'dateTimeFilter',
    'dateTimeFilterText',
    'startDateTimeText',
    'endDateTimeText',
    'startDateTimeISO',
    'endDateTimeISO',
    'startDate',
    'endDate',
    'startTime',
    'endTime',
    'alert',
    'submit',
    'pickupDateLabel',
    'returnDateLabel',
    'startTimeLabel',
    'endTimeLabel',
    'fuzzyFilterOptions',
    'fuzzyFilter',
    'fuzzyFilterCard',
  ]

  unavailabilityHours = [
    { start: '23:00', end: '23:59' },
    { start: '00:00', end: '05:59' },
  ]

  initialize() {
    this.startDateTimeISO = this.unavailabilityHours.reduce((dateTime, slot) => {
      const [startHr, startMin] = slot.start.split(':').map((x) => parseInt(x, 10))
      const start = new Date(dateTime).setHours(startHr, startMin)
      const [endHr, endMin] = slot.end.split(':').map((x) => parseInt(x, 10))
      const end = new Date(dateTime).setHours(endHr, endMin)

      if (dateTime.getTime() >= start && dateTime.getTime() <= end) {
        dateTime.setTime(end + 60 * 1000)
      }

      return dateTime
    }, DateUtils.defaultStartTime())

    this.endDateTimeISO = new Date(this.startDateTimeISO)

    if (this.startDateTimeISO.getHours() >= 21) {
      this.endDateTimeISO.setHours(22, 45)
    } else {
      const threeHours = 3 * 60 * 60 * 1000
      this.endDateTimeISO.setTime(this.startDateTimeISO.getTime() + threeHours)
    }
    this.startTime = this.formatTime(this.startDateTimeISO)
    this.endTime = this.formatTime(this.endDateTimeISO)
    this.selectionStarted = false
    this.apply()
    this.initializeRangePickers()
    this.checkDatetimeValidity()
  }

  showModal() {
    if (this.dateTimePickerModalTarget) {
      this.dateTimePickerModalTarget.classList.remove('cnd-hidden')
      document.body.classList.add('cnd-overflow-hidden')
      this.highlightSelectedDate()
    }
  }

  hideModal(e) {
    if (e.target.type == 'button') {
      e.preventDefault()
    }

    if (this.dateTimePickerModalTarget) {
      this.dateTimePickerModalTarget.classList.add('cnd-hidden')
      document.body.classList.remove('cnd-overflow-hidden')
    }
  }

  initializeRangePickers(type = 'getInst') {
    this.dateRangeSelector = MobiscrollUtils.newDateRangeInstance(
      this.startDateTarget,
      {
        months: DeviceUtils.isTablet() ? 2 : 1,
        defaultValue: [this.startDateTimeISO, this.endDateTimeISO],
        onSet: (_e, inst) => {
          if (inst.startVal && inst.startVal !== '') {
            this.startDateTimeISO = this.parseDate(inst.startVal)
          }

          if (inst.endVal && inst.endVal !== '') {
            this.endDateTimeISO = this.parseDate(inst.endVal)
          }

          if (inst.startVal && inst.endVal && inst.startVal !== '' && inst.endVal !== '') {
            this.selectionStarted = false
          } else {
            this.selectionStarted = true
          }

          this.mergeTimes()
          this.toggleMultiDays()
          this.highlightSelectedDate()

          if (this.startDateTimeISO && this.endDateTimeISO) {
            this.checkDatetimeValidity()
          }
        },
      },
      type
    )

    this.startTimeSelector = MobiscrollUtils.newTimeInstance(
      this.startTimeTarget,
      {
        headerText: DeviceUtils.isMobile()
          ? `<strong>Pickup</strong> ${this.startDateTimeISO.toLocaleString('en-GB', {
              month: 'short',
              day: 'numeric',
              year: 'numeric',
            })}`
          : '',
        invalid: this.unavailabilityHours,
        display: this.setDisplay(),
        defaultValue: this.startDateTimeISO,
        onSet: (_e, inst) => {
          this.startTime = this.formatTime(inst.getVal())
          this.mergeTimes()

          if (this.startDateTimeISO && this.endDateTimeISO) {
            this.checkDatetimeValidity()
          }
        },
      },
      type
    )

    this.endTimeSelector = MobiscrollUtils.newTimeInstance(
      this.endTimeTarget,
      {
        headerText: DeviceUtils.isMobile() ? 'Available from 6am - 11pm' : '',
        defaultValue: this.endDateTimeISO,
        invalid: this.unavailabilityHours,
        display: this.setDisplay(),
        onSet: (_e, inst) => {
          this.endTime = this.formatTime(inst.getVal())
          this.mergeTimes()

          if (this.startDateTimeISO && this.endDateTimeISO) {
            this.checkDatetimeValidity()
          }
        },
      },
      type
    )
  }

  toggleMultiDays() {
    if (!(this.startDateTimeISO && this.endDateTimeISO)) {
      return
    }

    const spanInDays = (this.endDateTimeISO.getTime() - this.startDateTimeISO.getTime()) / (24 * 60 * 60 * 1000)
    const spansMultipleDays = spanInDays > 1

    if (this.hasFuzzyFilterOptionsTarget) {
      this.fuzzyFilterOptionsTarget.setAttribute('multi-day', spansMultipleDays)
    }

    if (this.hasFuzzyFilterCardTarget) {
      this.fuzzyFilterCardTarget.setAttribute('multi-day', spansMultipleDays)
    }
  }

  highlightSelectedDate() {
    document.querySelectorAll('.mbsc-cal-day').forEach((element) => {
      element.addEventListener('mouseenter', () => {
        if (this.selectionStarted) {
          this.pickupDateLabelTarget.classList.remove('cnd-date-selection')
          this.returnDateLabelTarget.classList.add('cnd-date-selection')
        } else {
          this.pickupDateLabelTarget.classList.add('cnd-date-selection')
          this.returnDateLabelTarget.classList.remove('cnd-date-selection')
        }
      })

      element.addEventListener('mouseleave', () => {
        this.pickupDateLabelTarget.classList.remove('cnd-date-selection')
        this.returnDateLabelTarget.classList.remove('cnd-date-selection')
      })
    })
  }

  clearDateTimes() {
    this.endDateTimeISOTarget.value = ''
    this.endDateTimeTextTarget.value = ''
    this.startDateTimeISOTarget.value = ''
    this.startDateTimeTextTarget.value = ''
    // clear errors and disable submit button
    this.alertTarget.classList.add('cnd-hidden')
    this.submitTarget.setAttribute('disabled', 'disabled')
    this.dateRangeSelector.clear()
    this.startTimeSelector.clear()
    this.endTimeSelector.clear()
  }

  mergeTimes() {
    if (this.startDateTimeISO && this.startTime && this.startTime !== '') {
      let [hour, minutes] = this.startTime.split(':').map((x) => parseInt(x))

      this.startDateTimeISO.setHours(hour)
      this.startDateTimeISO.setMinutes(minutes)
    }

    if (this.endDateTimeISO && this.endTime && this.endTime !== '') {
      let [hour, minutes] = this.endTime.split(':').map((x) => parseInt(x))

      this.endDateTimeISO.setHours(hour)
      this.endDateTimeISO.setMinutes(minutes)
    }
  }

  checkDatetimeValidity() {
    if (!(this.startDateTimeISO && this.endDateTimeISO)) {
      return false
    }

    const now = new Date()
    const reservationSpanHrs = (this.endDateTimeISO.getTime() - this.startDateTimeISO.getTime()) / (60 * 60 * 1000)
    let errorMsg

    if (this.startDateTimeISO < now) {
      errorMsg = 'Pickup time cannot be in the past.'
    } else if (this.endDateTimeISO < now) {
      errorMsg = 'Return time cannot be in the past.'
    } else if (this.endDateTimeISO < this.startDateTimeISO) {
      errorMsg = 'Return must occur after pickup time.'
    } else if (reservationSpanHrs < 1) {
      errorMsg = 'Reservation must be for at least 1 hour.'
    }

    if (errorMsg) {
      // display error message
      this.alertTarget.innerText = errorMsg
      this.alertTarget.classList.remove('cnd-hidden')

      // disable submit button
      this.submitTarget.setAttribute('disabled', 'disabled')

      return false
    }

    this.clearErrorsAndEnableButtons()

    return true
  }

  clearErrorsAndEnableButtons() {
    this.alertTarget.classList.add('cnd-hidden')

    if (this.startDateTimeISO && this.endDateTimeISO) {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  onFuzzyFilterChange(event) {
    const { value } = event.detail

    this.element.dataset.fuzzyFilter = value
    this.fuzzyFilterTarget.value = value
    this.fuzzyFilterOptionsTarget.value = value
  }

  parseDate(date) {
    if (!date || date === '') {
      return
    }

    const [day, month, year] = date.split('/').map((p) => parseInt(p, 10))
    return new Date(year, month - 1, day)
  }

  formatDateTime(dateTime) {
    if (!dateTime) {
      return ''
    }

    let time
    const hours = dateTime.getHours()
    const minutes = dateTime.getMinutes().toString().padStart(2, '0')
    const dateOptions = { month: 'short', day: 'numeric' }

    if (hours < 12) {
      time = `${hours}:${minutes}am`
    } else {
      time = `${hours % 12 || 12}:${minutes}pm`
    }

    return `${dateTime.toLocaleString('en-GB', dateOptions)}, ${time}`
  }

  formatTime(dateTime) {
    if (!dateTime) {
      return
    }

    return dateTime.toLocaleString('en-GB', { hour: 'numeric', minute: 'numeric' })
  }

  setDisplay() {
    if (DeviceUtils.isMobile()) {
      return 'center'
    } else {
      return 'inline'
    }
  }

  apply() {
    this.startDateTimeISOTarget.value = this.startDateTimeISO.toISOString() || ''
    this.startDateTimeTextTarget.value = this.formatDateTime(this.startDateTimeISO)
    this.endDateTimeISOTarget.value = this.endDateTimeISO.toISOString() || ''
    this.endDateTimeTextTarget.value = this.formatDateTime(this.endDateTimeISO)
  }
}
