import { Controller } from 'stimulus'
import * as axios from 'axios'

import StripeUtils from '../../utils/stripe_utils'
import AxiosUtils from '../../utils/axios_utils'
import EventUtils from '../../utils/event_utils'

export default class extends Controller {
  static targets = [
    'messages',
    'errorMessages',
    'submitButton',
    'purchaseForm',
    'cardDetails',
    'cardAddress',
    'cardNumber',
    'cardStreet',
    'cardUnitNumber',
    'cardSuburb',
    'cardState',
    'cardCountry',
    'cardPostCode',
    'cardErrors',
    'expiryErrors',
    'cvcErrors',
    'cardElement',
    'expiryElement',
    'cvcElement',
  ]

  cardNumber

  cardExpiry

  cardCvc

  stripe

  elements

  existingCard

  expectNewCardDetails

  initialize() {
    this.clearMessages()
    this.setInitialState()
    StripeUtils.getStripeKey()
      .then((response) => {
        this.stripe = StripeUtils.initialiseStripe(response.data.stripe_key)

        this.createCardElements()

        this.addEventListeners()

        this.handleFormSubmit()
      })
      .catch((error) => {
        this.messagesTarget.innerHTML = error
      })
  }

  setInitialState() {
    this.existingCard = this.element.getAttribute('data-existing-card')
    if (this.existingCard.length !== 0) {
      this.cardDetailsTarget.classList.add('cnd-hidden')
      this.expectNewCardDetails = false
    } else {
      this.expectNewCardDetails = true
    }
  }

  handleFormSubmit() {
    const THIS = this
    const form = this.purchaseFormTarget
    form.addEventListener('submit', (event) => {
      event.preventDefault()
      const fullAddress = [this.cardAddressTarget.value, this.cardNumberTarget.value, this.cardStreetTarget.value]
        .filter((el) => el)
        .join(' ')

      const additionalData = {
        amount: form.elements.amount.value,
        name: form.elements.holder.value,
        address_line1: fullAddress,
        address_line2: this.cardUnitNumberTarget.value,
        address_city: this.cardSuburbTarget.value,
        address_state: this.cardStateTarget.value,
        address_country: this.cardCountryTarget.value,
        address_zip: this.cardPostCodeTarget.value,
      }

      if (THIS.expectNewCardDetails) {
        THIS.stripe.createToken(THIS.cardNumber, additionalData).then((result) => {
          if (result.error) {
            THIS.errorMessagesTarget.innerHTML = result.error.message
          } else {
            additionalData.holder = result.token.card.name
            additionalData.holder_address = result.token.card.address_line1
            additionalData.number = result.token.id
            additionalData.exp_month = result.token.card.exp_month
            additionalData.exp_year = result.token.card.exp_year
            if (['Visa', 'MasterCard'].includes(result.token.card.brand)) {
              THIS.submitPurchase(additionalData)
            } else {
              THIS.errorMessagesTarget.innerHTML = 'Sorry we only accept VISA and Mastercard at the moment'
            }
          }
        })
      } else {
        THIS.submitPurchase(additionalData)
      }
    })
  }

  submitPurchase(cardata) {
    this.addButtonSubmitState()
    axios
      .post('/credit_card_transactions', cardata, AxiosUtils.getHeaders())
      .then(() => {
        this.messagesTarget.innerHTML = 'Funds transferred successfully'
        this.removeButtonSubmitState()
        EventUtils.dispatch(window, 'CND:StripeFundsCleared')
      })
      .catch((error) => {
        this.errorMessagesTarget.innerHTML = error.response.data.error.message
        this.removeButtonSubmitState()
      })
  }

  changeCardDetails(e) {
    e.preventDefault()
    this.clearMessages()
    this.expectNewCardDetails = !this.expectNewCardDetails
    this.cardDetailsTarget.classList.toggle('cnd-hidden')
  }

  clearMessages() {
    this.messagesTarget.innerHTML = ''
    this.errorMessagesTarget.innerHTML = ''
  }

  addButtonSubmitState() {
    this.clearMessages()
    this.submitButtonTarget.classList.add('cc-stripe-submit-button-active')
    this.submitButtonTarget.innerHTML = 'Saving ...'
  }

  removeButtonSubmitState() {
    this.submitButtonTarget.classList.remove('cc-stripe-submit-button-active')
    this.submitButtonTarget.innerHTML = 'Update card'
  }

  addEventListeners() {
    StripeUtils.addCardElementEventListener(this.cardNumber, this.cardErrorsTarget)

    StripeUtils.addCardElementEventListener(this.cardExpiry, this.expiryErrorsTarget)

    StripeUtils.addCardElementEventListener(this.cardCvc, this.cvcErrorsTarget)
  }

  createCardElements() {
    const elements = this.stripe.elements()

    this.cardNumber = elements.create('cardNumber', {
      style: StripeUtils.elementStyles,
      classes: StripeUtils.elementClasses,
    })
    this.cardNumber.mount(this.cardElementTarget)

    this.cardExpiry = elements.create('cardExpiry', {
      style: StripeUtils.elementStyles,
      classes: StripeUtils.elementClasses,
    })
    this.cardExpiry.mount(this.expiryElementTarget)

    this.cardCvc = elements.create('cardCvc', {
      style: StripeUtils.elementStyles,
      classes: StripeUtils.elementClasses,
    })
    this.cardCvc.mount(this.cvcElementTarget)
  }
}
