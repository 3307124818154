import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'
import Uploader from '../../utils/uploader_utils'
import { ACCEPTED_IMAGE_FILES } from '../../constants/uploads'

export default class extends Controller {
  static targets = [
    'dropzone',
    'form',
    'upload',
    'submit',
    'overlay',
    'reservationIdInput',
    'totalCostInput',
    'totalLitresInput',
    'reasonInput',
    'additionalInformationInput',
    'fuelReceipts',
    'noFuelReceiptsText',
  ]

  fileList = []

  initialize() {
    this.dropzoneInit()
    Dropzone.autoDiscover = false
  }

  dropzoneInit() {
    const fileInputField = this.uploadTarget
    const url = fileInputField.dataset.directUploadUrl
    const name = fileInputField.name

    this.dropzoneTarget.dropzone = undefined
    this.dropzone = new Dropzone(this.dropzoneTarget, {
      headers: { 'X-CSRF-Token': AxiosUtils.getMetaValue('csrf-token') },
      previewTemplate: document.getElementById('preview-template').innerHTML,
      parallelUploads: 10,
      uploadMultiple: false,
      maxFilesize: 10,
      acceptedFiles: ACCEPTED_IMAGE_FILES,
      autoProcessQueue: true,
      thumbnailHeight: 96,
      thumbnailWidth: 96,
      maxFiles: 1,
      url,
      thumbnail: (file, dataUrl) => {
        if (file.previewElement) {
          file.previewElement.classList.remove('dz-file-preview')

          let images = file.previewElement.querySelectorAll('[data-dz-thumbnail]')

          images.forEach((i) => {
            i.alt = file.name
            i.src = dataUrl
          })

          setTimeout(() => {
            file.previewElement.classList.add('dz-image-preview')
          }, 1)
        }

        if (file.status !== 'error') {
          this.uploadFile(file, url, name)
        }
      },
    })

    this.dropzone.uploadFiles = function (files) {
      const THIS = this

      let minSteps = 6
      let maxSteps = 60
      let timeBetweenSteps = 100
      let bytesPerStep = files.length < 5 ? 50000 : 50

      for (var i = 0; i < files.length; i++) {
        let file = files[i]
        let totalSteps = Math.round(Math.min(maxSteps, Math.max(minSteps, file.size / bytesPerStep)))

        for (let step = 0; step < totalSteps; step++) {
          let duration = timeBetweenSteps * (step + 1)

          setTimeout(
            (function (file, totalSteps, step) {
              return function () {
                file.upload = {
                  progress: (100 * (step + 1)) / totalSteps,
                  total: file.size,
                  bytesSent: ((step + 1) * file.size) / totalSteps,
                }

                THIS.emit('uploadprogress', file, file.upload.progress, file.upload.bytesSent)

                if (file.upload.progress == 100) {
                  file.status = Dropzone.SUCCESS

                  THIS.emit('success', file, 'success', null)
                  THIS.emit('complete', file)

                  THIS.processQueue()
                }
              }
            })(file, totalSteps, step),
            duration
          )
        }
      }
    }
  }

  uploadFile(file, url, name) {
    const uploader = new Uploader(file, url)

    uploader.directUpload(file, url, name).then((val) => {
      if (val.loadComplete === true) {
        this.fileList.push(val.loadComplete)

        this.toggleSubmit()

        this.formTarget.appendChild(val.hiddenField)
      }
    })
  }

  submitHandler() {
    this.submitTarget.disabled = true

    let photoDataArr = []
    let photos = document.querySelectorAll('.photos')

    photos.forEach((photo) => photoDataArr.push(photo.value))

    let data = {
      reservation_id: this.reservationIdInputTarget.value,
      photos: photoDataArr,
      fuel_purchase_total_cost: this.totalCostInputTarget.value,
      fuel_purchase_total_litres: this.totalLitresInputTarget.value,
    }

    if (this.hasReasonInputTarget) {
      data['fuel_purchase_reason'] = this.reasonInputTarget.value
    }

    if (this.hasAdditionalInformationInputTarget) {
      data['fuel_purchase_additional_information'] = this.additionalInformationInputTarget.value
    }

    axios({
      method: 'post',
      url: this.formTarget.action,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: {
        fuel_purchases_form: data,
      },
    })
      .then((res) => {
        this.dropzone.removeAllFiles(true)

        this.submitTarget.disabled = true

        this.refreshFuelPurchasePanel(res.data.partial_data)

        this.hideNoFuelReceiptsText()

        FlashMessageUtils.showSuccess(res.data.notice)

        this.hideOverlay()
      })
      .catch((error) => {
        this.submitTarget.disabled = true
        FlashMessageUtils.showWarning(error.response.data.error)
        this.hideOverlay()
      })
  }

  refreshFuelPurchasePanel(partialData) {
    var fuelPurchasePanel = document.getElementsByClassName('fuel-purchase-panel')[0]

    if (fuelPurchasePanel) {
      fuelPurchasePanel.innerHTML = partialData
    }
  }

  showOverlay() {
    window.scrollTo(0, 0)
    this.overlayTarget.classList.remove('cnd-hidden')
  }

  hideOverlay() {
    this.formTarget.reset()
    this.fileList = []

    this.submitTarget.setAttribute('disabled', true)

    if (this.hasAdditionalInformationInputTarget) {
      this.additionalInformationInputTarget.classList.add('cnd-hidden')
    }

    this.overlayTarget.classList.add('cnd-hidden')

    this.dropzone.removeAllFiles(true)

    document.querySelectorAll(`${this.formTarget.id}.photos`).forEach((el) => el.remove())
  }

  hideNoFuelReceiptsText() {
    if (this.hasNoFuelReceiptsTextTarget) {
      this.noFuelReceiptsTextTarget.classList.add('cnd-hidden')
    }
  }

  toggleAdditionalInformation() {
    const reasonNotOther = this.reasonInputTarget.value !== 'Other'

    this.additionalInformationInputTarget.classList.toggle('cnd-hidden', reasonNotOther)
  }

  toggleSubmit() {
    let disabled =
      this.fileList.length === 0 ||
      this.fileList.length !== this.dropzone.files.length ||
      !this.totalCostInputTarget.value ||
      !this.totalLitresInputTarget.value

    if (this.hasReasonInputTarget) {
      disabled = disabled || !this.reasonInputTarget.value

      if (this.reasonInputTarget.value === 'Other') {
        disabled = disabled || !this.additionalInformationInputTarget.value
      }
    }

    this.submitTarget.toggleAttribute('disabled', disabled)
  }
}
