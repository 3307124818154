import { Controller } from 'stimulus'
import * as axios from 'axios'
import qs from 'qs'
import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'

export default class extends Controller {
  static targets = [
    'additionalInfo',
    'reason',
    'form',
    'submitButton',
    'imageInput',
    'previewImage',
    'selectImageButton',
    'uploadMessage',
    'reservationIdInput',
    'reservationVehicleIdInput',
  ]

  onReasonChange() {
    this.selectImageButtonTarget.disabled = this.selectImageButtonTarget.innerHTML === 'Loading...' ? true : false
    if (this.reasonTarget.options[this.reasonTarget.selectedIndex].text === 'Other') {
      this.additionalInfoTarget.classList.remove('cnd-hidden')
    }
  }

  selectImage(e) {
    e.preventDefault()
    this.imageInputTarget.click()
  }

  getBase64(file) {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      return reader.result
    }
  }

  uploadImage(e) {
    const THIS = this
    const file = this.imageInputTarget.files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (function (event) {
      return function (e) {
        THIS.previewImageTarget.innerHTML += `<img src="${e.target.result}" title="${event.name}" width="300" />`
      }
    })(file)

    this.getBase64(file)
    this.selectImageButtonTarget.innerHTML = 'Loading...'
    this.selectImageButtonTarget.disabled = true

    reader.addEventListener('loadend', (e) => {
      this.submitHandler()
    })
  }

  submitHandler() {
    const THIS = this
    const file = THIS.imageInputTarget.files[0]
    const data = {
      photo: file,
      reason: THIS.reasonTarget.options[THIS.reasonTarget.selectedIndex].text,
      reservation_id: THIS.reservationIdInputTarget.value,
      vehicle_id: THIS.reservationVehicleIdInputTarget.value,
      additional_information: THIS.additionalInfoTarget.value,
    }

    const formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key])
    }

    axios({
      method: 'post',
      data: formData,
      url: '/zendesk/send_fuel_receipt',
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
    })
      .then((res) => {
        if (res.status === 200) {
          THIS.selectImageButtonTarget.disabled = false
          THIS.selectImageButtonTarget.innerHTML = 'Send another fuel receipt'
          THIS.uploadMessageTarget.innerHTML = 'Successfully sent fuel receipt'
          THIS.uploadMessageTarget.classList.remove('cnd-hidden')
        } else {
          FlashMessageUtils.showWarning(res.data.message)
        }
      })
      .catch((error) => {
        FlashMessageUtils.showWarning(error)
      })
  }
}
