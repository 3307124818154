import { Controller } from 'stimulus'
import * as axios from 'axios'
import { validateAuPlateNumberFormat } from '../../utils/vehicle_utils'

export default class extends Controller {
  static targets = [
    'addPromoCodeBtn',
    'badge',
    'badgeInput',
    'businessRadio',
    'businessWarning',
    'dedicatedRadio',
    'errorMessage',
    'errorMessageContainer',
    'firstName',
    'lastName',
    'loadingIcon',
    'make',
    'mapboxError',
    'mapboxInput',
    'model',
    'nonBusinessRadio',
    'nonDedicatedRadio',
    'notFoundIcon',
    'oldCarWarning',
    'plateNumber',
    'plateState',
    'postCode',
    'promotionApplyBtn',
    'promotionInput',
    'promotionInputOverlay',
    'promotionInvalid',
    'promotionText',
    'regoNotice',
    'removePromoCodeBtn',
    'setupButton',
    'successIcon',
    'successPanel',
    'transmission',
    'vehicleFacets',
    'vehicleInfoEmpty',
    'vehicleName',
    'vehicleNameContainer',
    'vehicleRego',
    'year',
    'yearInput',
    'vehicle',
    'odometerTag',
    'odometerCloseToLimit',
    'odometerAboveLimit',
  ]

  initialize() {
    this.toggleBusinessRadio()

    if (this.makeTarget.value) {
      if (this.hasVehicleRegoTarget) {
        this.enableFacets()
      }
      this.updateMake()
    }

    this.toggleSetupButton()
  }

  findVehicleByPlate() {
    const plateNumber = this.plateNumberTarget.value
    const plateState = this.plateStateTarget.value
    const params = { plate_state: plateState }

    this.notFoundIconTarget.classList.add('cnd-hidden')
    this.successIconTarget.classList.add('cnd-hidden')
    this.regoNoticeTarget.classList.add('cnd-hidden')
    this.errorMessageContainerTarget.classList.add('cnd-hidden')
    this.errorMessageContainerTarget.querySelector('.facets').classList.add('cnd-hidden')
    this.vehicleNameContainerTarget.classList.add('cnd-hidden')
    this.vehicleInfoEmptyTarget.classList.add('cnd-hidden')
    this.oldCarWarningTarget.classList.add('cnd-hidden')

    if (plateNumber === '' || plateState === '') {
      this.regoNoticeTarget.classList.remove('cnd-hidden')
      this.errorMessageTarget.innerHTML = 'You need to provide both plate number and state.'
      this.errorMessageContainerTarget.classList.remove('cnd-hidden')
      return
    }

    if (!validateAuPlateNumberFormat(plateNumber)) {
      this.regoNoticeTarget.classList.remove('cnd-hidden')
      this.errorMessageTarget.innerHTML = 'Please provide a valid plate number.'
      this.errorMessageContainerTarget.classList.remove('cnd-hidden')
      return
    }

    this.showLoading()

    axios
      .get(`/autograb/vehicles/${plateNumber}`, { params })
      .then((res) => {
        if (res.data.vehicle) {
          const vehicle = res.data.vehicle
          const vehicleName = `${vehicle.year} ${vehicle.make} ${vehicle.model}`
          this.vehicleTarget.value = JSON.stringify({ ...vehicle, plate_number: plateNumber })

          document.dispatchEvent(
            new CustomEvent('updateEarningsEstimate', {
              detail: {
                post_code: this.postCodeTarget.value,
                vehicle: this.vehicleTarget.value,
              },
            })
          )

          this.loadingIconTarget.classList.add('cnd-hidden')
          this.successIconTarget.classList.remove('cnd-hidden')
          this.vehicleNameTarget.innerHTML = vehicleName
          this.vehicleNameContainerTarget.classList.remove('cnd-hidden')

          if (vehicle.year < 2001) {
            this.oldCarWarningTarget.classList.remove('cnd-hidden')
          }
        } else {
          this.loadingIconTarget.classList.add('cnd-hidden')
          this.plateNumberTarget.classList.add('cnd-border-red')
          this.notFoundIconTarget.classList.remove('cnd-hidden')
          this.vehicleInfoEmptyTarget.classList.remove('cnd-hidden')
        }
      })
      .catch((error) => {
        this.errorMessageTarget.innerHTML = error.response.data.message
        this.loadingIconTarget.classList.add('cnd-hidden')
        this.plateNumberTarget.classList.add('cnd-border-red')
        this.notFoundIconTarget.classList.remove('cnd-hidden')
        this.errorMessageContainerTarget.classList.remove('cnd-hidden')

        if (error.response.data.message === "We couldn't find your car.") {
          this.errorMessageContainerTarget.querySelector('.facets').classList.remove('cnd-hidden')
        } else {
          this.errorMessageContainerTarget.querySelector('.facets').classList.add('cnd-hidden')
        }
      })
      .finally(() => {
        this.toggleSetupButton()
      })
  }

  showLoading() {
    this.notFoundIconTarget.classList.add('cnd-hidden')
    this.successIconTarget.classList.add('cnd-hidden')
    this.oldCarWarningTarget.classList.add('cnd-hidden')
    this.vehicleNameContainerTarget.classList.add('cnd-hidden')
    this.plateNumberTarget.classList.remove('cnd-border-red')
    this.loadingIconTarget.classList.remove('cnd-hidden')
    this.errorMessageContainerTarget.classList.add('cnd-hidden')
    this.errorMessageContainerTarget.querySelector('.facets').classList.add('cnd-hidden')
    this.vehicleInfoEmptyTarget.classList.add('cnd-hidden')
  }

  updateMake() {
    const countryCode = this.makeTarget.dataset.countryCode
    const make = this.makeTarget.value

    if (make) {
      this.searchFacets({ country_code: countryCode, make: make }, (res) => {
        this.removeCurrentOptions(this.modelTarget.querySelectorAll('option'))
        this.removeCurrentOptions(this.yearTarget.querySelectorAll('option'))
        this.removeCurrentOptions(this.badgeTarget.querySelectorAll('option'))
        if (this.hasTransmissionTarget) {
          this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
        }

        res.forEach((option) => {
          let optionElement = document.createElement('option')
          optionElement.text = option
          optionElement.value = option

          this.modelTarget.add(optionElement)
        })
      })
    } else {
      this.removeCurrentOptions(this.modelTarget.querySelectorAll('option'))
      this.removeCurrentOptions(this.yearTarget.querySelectorAll('option'))
      this.removeCurrentOptions(this.badgeTarget.querySelectorAll('option'))
      if (this.hasTransmissionTarget) {
        this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
      }
    }
  }

  updateModel() {
    const countryCode = this.makeTarget.dataset.countryCode
    const make = this.makeTarget.value
    const model = this.modelTarget.value

    if (model) {
      this.searchFacets({ country_code: countryCode, make: make, model: model }, (res) => {
        this.removeCurrentOptions(this.yearTarget.querySelectorAll('option'))
        this.removeCurrentOptions(this.badgeTarget.querySelectorAll('option'))
        if (this.hasTransmissionTarget) {
          this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
        }

        res.forEach((option) => {
          let optionElement = document.createElement('option')
          optionElement.text = option
          optionElement.value = option

          this.yearTarget.add(optionElement)
        })

        const unknownYearElement = document.createElement('option')

        unknownYearElement.text = 'Year not listed'
        unknownYearElement.value = 'YEAR_NOT_LISTED'
        this.yearTarget.add(unknownYearElement)
      })
    } else {
      this.removeCurrentOptions(this.yearTarget.querySelectorAll('option'))
      this.removeCurrentOptions(this.badgeTarget.querySelectorAll('option'))
      if (this.hasTransmissionTarget) {
        this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
      }
    }
  }

  updateYear() {
    const countryCode = this.makeTarget.dataset.countryCode
    const make = this.makeTarget.value
    const model = this.modelTarget.value
    const year = this.yearTarget.value

    if (year === 'YEAR_NOT_LISTED') {
      this.selectUnknownYear()
    } else if (year) {
      this.unselectUnknownYear()

      this.searchFacets({ country_code: countryCode, make: make, model: model, year: year }, (res) => {
        this.removeCurrentOptions(this.badgeTarget.querySelectorAll('option'))
        if (this.hasTransmissionTarget) {
          this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
        }

        res.forEach((option) => {
          let optionElement = document.createElement('option')
          optionElement.text = option
          optionElement.value = option

          this.badgeTarget.add(optionElement)
        })
      })
    } else {
      this.removeCurrentOptions(this.badgeTarget.querySelectorAll('option'))
      if (this.hasTransmissionTarget) {
        this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
      }
    }
  }

  updateBadge() {
    if (!this.hasTransmissionTarget) return

    const countryCode = this.makeTarget.dataset.countryCode
    const make = this.makeTarget.value
    const model = this.modelTarget.value
    const year = this.yearTarget.value
    const badge = this.badgeTarget.value

    if (badge) {
      this.searchFacets({ country_code: countryCode, make: make, model: model, year: year, badge: badge }, (res) => {
        this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
        res.forEach((option) => {
          let optionElement = document.createElement('option')
          optionElement.text = option
          optionElement.value = option

          this.transmissionTarget.add(optionElement)
        })
      })
    } else {
      this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
    }
  }

  selectUnknownYear() {
    this.yearInputTarget.disabled = false
    this.yearInputTarget.classList.remove('cnd-hidden')
    this.badgeInputTarget.disabled = false
    this.badgeTarget.classList.add('cnd-hidden')
    this.badgeInputTarget.classList.remove('cnd-hidden')
    if (this.hasTransmissionTarget) {
      this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
      JSON.parse(this.transmissionTarget.dataset.defaultList).forEach((option) => {
        let optionElement = document.createElement('option')
        optionElement.text = option
        optionElement.value = option

        this.transmissionTarget.add(optionElement)
      })
    }
  }

  unselectUnknownYear() {
    this.yearInputTarget.disabled = true
    this.yearInputTarget.value = ''
    this.yearInputTarget.classList.add('cnd-hidden')
    this.badgeInputTarget.disabled = true
    this.badgeInputTarget.value = ''
    this.badgeInputTarget.classList.add('cnd-hidden')
    this.badgeTarget.classList.remove('cnd-hidden')
    if (this.hasTransmissionTarget) {
      this.removeCurrentOptions(this.transmissionTarget.querySelectorAll('option'))
    }
  }

  enableFacets() {
    this.vehicleRegoTarget.classList.add('cnd-hidden')
    this.vehicleFacetsTarget.classList.remove('cnd-hidden')

    this.plateNumberTarget.required = false
    this.plateStateTarget.required = false
    this.plateNumberTarget.value = ''
    this.plateStateTarget.value = ''

    this.makeTarget.required = true
    this.modelTarget.required = true
    this.yearTarget.required = true

    document.dispatchEvent(new CustomEvent('hideEarningsEstimate'))
    this.toggleSetupButton()
  }

  disableFacets() {
    this.vehicleFacetsTarget.classList.add('cnd-hidden')
    this.vehicleRegoTarget.classList.remove('cnd-hidden')
    this.notFoundIconTarget.classList.add('cnd-hidden')
    this.successIconTarget.classList.add('cnd-hidden')

    this.plateNumberTarget.required = true
    this.plateStateTarget.required = true

    this.makeTarget.required = false
    this.modelTarget.required = false
    this.yearTarget.required = false
    this.makeTarget.value = ''
    this.modelTarget.value = ''
    this.yearTarget.value = ''
    this.yearInputTarget.value = ''
    this.badgeTarget.value = ''
    this.badgeInputTarget = ''
    this.transmissionTarget.value = ''

    document.dispatchEvent(new CustomEvent('showEarningsEstimate'))
  }

  searchFacets(params, callback) {
    axios
      .get(`/autograb/facets`, { params })
      .then((res) => {
        callback(res.data)
      })
      .catch((error) => {
        console.log(error)
      })
  }

  removeCurrentOptions(currentOptions) {
    currentOptions.forEach((o) => {
      if (o.value !== '') {
        o.remove()
      }
    })
  }

  toggleBusinessRadio() {
    if (this.businessRadioTarget.checked) {
      this.businessWarningTarget.classList.remove('cnd-hidden')
    } else {
      this.businessWarningTarget.classList.add('cnd-hidden')
    }
  }

  applyPromotion(e) {
    e.preventDefault()

    this.promotionInvalidTarget.classList.add('cnd-hidden')
    this.promotionApplyBtnTarget.disabled = true
    this.promotionApplyBtnTarget.innerHTML = 'Applying...'

    const promoCode = this.promotionInputTarget.value

    axios
      .get(`/members/promotions/${promoCode}/verify`)
      .then((res) => {
        const data = res.data

        this.promotionApplyBtnTarget.disabled = false
        this.promotionApplyBtnTarget.innerHTML = 'Apply'

        if (data.can_be_applied) {
          this.setPromotionCode(promoCode, data)
        } else {
          this.promotionInputTarget.value = ''
          this.promotionInvalidTarget.innerHTML = data.message || 'Sorry - that code is invalid or has expired.'
          this.promotionInvalidTarget.classList.remove('cnd-hidden')
        }
      })
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .catch((_error) => {
        this.promotionInputTarget.value = ''
        this.promotionApplyBtnTarget.disabled = false
        this.promotionApplyBtnTarget.innerHTML = 'Apply'

        this.promotionInvalidTarget.innerHTML = 'Something went wrong, please try again.'
        this.promotionInvalidTarget.classList.remove('cnd-hidden')
      })
  }

  setPromotionCode(promoCode, data) {
    this.promotionInputOverlayTarget.classList.add('cnd-hidden')

    this.promotionInputTarget.value = promoCode.toUpperCase()
    this.promotionTextTarget.innerHTML = promoCode.toUpperCase()

    if (data.message) {
      this.successPanelTarget.querySelector('p').innerHTML = `${data.message}`
    } else {
      this.successPanelTarget.querySelector('p').innerHTML = 'Nice one - your code has been added.'
    }

    this.successPanelTarget.classList.remove('cnd-hidden')
    this.removePromoCodeBtnTarget.classList.remove('cnd-hidden')
    this.addPromoCodeBtnTarget.classList.add('cnd-hidden')
  }

  removePromotion() {
    this.promotionInputTarget.value = ''
    this.successPanelTarget.classList.add('cnd-hidden')
    this.removePromoCodeBtnTarget.classList.add('cnd-hidden')
    this.addPromoCodeBtnTarget.classList.remove('cnd-hidden')
  }

  openPromotionModal() {
    this.promotionInvalidTarget.classList.add('cnd-hidden')
  }

  postCodeChanged() {
    const postCode = this.postCodeTarget.value

    if (!postCode) {
      this.postCodeTarget.value = this.mapboxInputTarget.value
    }
  }

  mobileVerified() {
    this.element.submit()
  }

  toggleSetupButton() {
    if (!this.hasSetupButtonTarget) return

    const isTransmissionValid = !this.hasTransmissionTarget || this.transmissionTarget.value
    const isVehicleValid = this.vehicleFacetsTarget.classList.contains('cnd-hidden')
      ? !this.successIconTarget.classList.contains('cnd-hidden')
      : this.makeTarget.value && this.modelTarget.value && this.yearTarget.value && isTransmissionValid

    const disabled =
      (this.hasFirstNameTarget && !this.firstNameTarget.value) ||
      (this.hasLastNameTarget && !this.lastNameTarget.value) ||
      !isVehicleValid ||
      !this.mapboxValid() ||
      (!this.businessRadioTarget.checked && !this.nonBusinessRadioTarget.checked) ||
      (!this.dedicatedRadioTarget.checked && !this.nonDedicatedRadioTarget.checked) ||
      (this.hasOdometerTagTarget &&
        ['US', 'CA'].includes(this.odometerTagTarget.dataset.countryCode) &&
        (this.aboveOdometerLimit(this.odometerTagTarget.value) || this.odometerTagTarget.value === ''))

    if (disabled) {
      this.setupButtonTarget.setAttribute('disabled', true)
    } else {
      this.setupButtonTarget.removeAttribute('disabled')
    }
  }

  mapboxValid() {
    if (!this.hasMapboxInputTarget) return false
    if (!this.mapboxInputTarget.value) return false

    let valid = true
    this.mapboxErrorTargets.forEach((target) => {
      if (!target.classList.contains('cnd-hidden')) {
        valid = false
      }
    })

    return valid
  }

  aboveOdometerLimit(limit) {
    const countryCode = this.odometerTagTarget.dataset.countryCode

    if (
      (countryCode === 'US' && limit === 'odometer_more_than_200K') ||
      (countryCode === 'CA' && limit === 'odometer_more_than_320K')
    ) {
      return true
    }

    return false
  }

  closeToOdometerLimit(limit) {
    const countryCode = this.odometerTagTarget.dataset.countryCode

    if (
      (countryCode === 'US' && limit === 'odometer_150_to_200K') ||
      (countryCode === 'CA' && limit === 'odometer_240_to_320K')
    ) {
      return true
    }

    return false
  }

  showOdometerRangeWarnings() {
    const odometerRange = this.odometerTagTarget.value
    const popupForCloseValue = this.odometerCloseToLimitTarget
    const popupForAboveValue = this.odometerAboveLimitTarget

    popupForCloseValue.classList.add('cnd-hidden')
    popupForAboveValue.classList.add('cnd-hidden')

    if (this.closeToOdometerLimit(odometerRange)) {
      popupForCloseValue.classList.remove('cnd-hidden')
    }

    if (this.aboveOdometerLimit(odometerRange)) {
      popupForAboveValue.classList.remove('cnd-hidden')
    }
  }
}
