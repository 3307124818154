import { Controller } from 'stimulus'
import moment from 'moment'
import qs from 'qs'

// Utils
import DateUtils from '../../utils/date_utils'
import MobiscrollUtils from '../../utils/mobiscroll_utils'

export default class extends Controller {
  static targets = ['form', 'address', 'latitude', 'longitude', 'startTime', 'endTime', 'submit', 'alert']

  search
  startOfDay
  endOfDay
  startAvailability
  endAvailability
  errors

  initialize() {
    this.search = {}
    this.errors = []

    // set start/end of day
    this.startOfDay = moment().startOf('day')
    this.endOfDay = moment().endOf('day')

    // set start/end availability hours
    this.startAvailability = moment(this.element.dataset.startAvailability, 'HH:mm')
    this.endAvailability = moment(this.element.dataset.endAvailability, 'HH:mm')

    this.initializeForm()

    this.submitForm = this.submitForm.bind(this)
  }

  initializeForm() {
    // parse start/end times from URL or use default
    if (this.element.dataset.startTime === '' || this.element.dataset.endTime === '') {
      this.extendSearch({
        startDatetimeMoment: DateUtils.defaultStartMoment().add(2, 'hours'),
        endDatetimeMoment: DateUtils.defaultEndMoment().add(2, 'hours'),
      })
    } else {
      this.extendSearch({
        startDatetimeMoment: moment(this.element.dataset.startTime),
        endDatetimeMoment: moment(this.element.dataset.endTime),
      })
    }

    // set start/end times
    this.setPickupReturnValues()

    // set availability text
    let unavailabilityHours = null
    let pickupText = 'Pickup {value}'
    let returnText = 'Return {value}'

    if (!!this.element.dataset.endUnavailability && !!this.element.dataset.startUnavailability) {
      unavailabilityHours = [
        {
          start: '00:00',
          end: this.element.dataset.endUnavailability,
        },
        {
          start: this.element.dataset.startUnavailability,
          end: '23:59',
        },
      ]
    }

    if (this.element.dataset.availabilityText) {
      pickupText = `Pickup available ${this.element.dataset.availabilityText || ''}`
      returnText = `Return available ${this.element.dataset.availabilityText || ''}`
    }

    // define Mobiscroll instances
    MobiscrollUtils.newCalendarInstance(this.startTimeTarget, {
      headerText: pickupText,
      defaultValue: this.search.startDatetimeMoment.toDate(),
      invalid: unavailabilityHours,
      onSet: (e, inst) => {
        const startTime = moment(inst.getVal())

        this.extendSearch({
          startDatetimeMoment: startTime,
        })

        if (this.search.endDatetimeMoment.isSameOrBefore(startTime)) {
          const endTime = startTime.clone().add(1, 'hours')

          MobiscrollUtils.setCalendarValue(this.endTimeTarget, endTime.format(DateUtils.DATETIME_FORMAT))

          this.extendSearch({
            endDatetimeMoment: endTime,
          })
        }

        this.checkDatetimeValidity()
      },
    })

    MobiscrollUtils.newCalendarInstance(this.endTimeTarget, {
      headerText: returnText,
      defaultValue: this.search.endDatetimeMoment.toDate(),
      invalid: unavailabilityHours,
      onSet: (e, inst) => {
        this.extendSearch({
          endDatetimeMoment: moment(inst.getVal()),
        })

        this.checkDatetimeValidity()
      },
    })
  }

  locationUpdated(e) {
    const address = e.args

    this.extendSearch({
      location: address.location,
      latitude: address.latitude,
      longitude: address.longitude,
    })

    this.clearError('address')
  }

  submitForm(e) {
    e.preventDefault()

    // assert address, coordinates, pickup, and return times are set prior to submitting form
    if (!this.checkAddressValidity() || !this.checkDatetimeValidity()) {
      return false
    }

    let query = {
      location: this.search.location.replace(/\s/g, '+'),
      latitude: this.search.latitude,
      longitude: this.search.longitude,
      start_time_iso: this.search.startDatetimeMoment.startOf('minute').format(DateUtils.DATETIME_ISO_FORMAT),
      end_time_iso: this.search.endDatetimeMoment.startOf('minute').format(DateUtils.DATETIME_ISO_FORMAT),
    }

    if (this.element.dataset.filters) {
      Object.assign(query, JSON.parse(this.element.dataset.filters))
    }

    if (this.element.dataset.source) {
      query.source = this.element.dataset.source
    }

    query = qs.stringify(query, { arrayFormat: 'brackets' })

    return (window.location.href = `${this.formTarget.action}?${decodeURIComponent(query)}`)
  }

  extendSearch(params) {
    return Object.assign(this.search, params)
  }

  setPickupReturnValues() {
    let pickupMoment = this.search.startDatetimeMoment
    let returnMoment = this.search.endDatetimeMoment

    if (this.isOutsideHours(pickupMoment)) {
      const defaultPickup = DateUtils.defaultStartMoment()

      // `pickupMoment` can be defined by the user so we can't assume that it's
      // the same as `defaultPickup` so check `defaultPickup` explicity.
      if (this.isOutsideHours(defaultPickup)) {
        pickupMoment = this.startAvailability.clone().add(1, 'day')
      } else {
        pickupMoment = defaultPickup
      }

      returnMoment = pickupMoment.clone().add(3, 'hours')
    }

    if (this.isOutsideHours(returnMoment)) {
      const defaultReturn = DateUtils.defaultEndMoment()

      // `returnMoment` can be defined by the user so we can't assume that it's
      // the same as `defaultReturn` so check `defaultReturn` explicity.
      if (this.isOutsideHours(defaultReturn)) {
        returnMoment = this.startAvailability.clone().add(1, 'day')
      } else {
        returnMoment = defaultReturn
      }
    }

    this.extendSearch({
      startDatetimeMoment: pickupMoment,
      endDatetimeMoment: returnMoment,
    })

    this.startTimeTarget.value = pickupMoment.format(DateUtils.DATETIME_FORMAT)
    this.endTimeTarget.value = returnMoment.format(DateUtils.DATETIME_FORMAT)
  }

  checkDatetimeValidity() {
    const now = moment()
    const startTime = this.search.startDatetimeMoment
    let isValid = true

    try {
      if (this.search.startDatetimeMoment.isSameOrBefore(now)) {
        throw new Error('Pickup time cannot be in the past.')
      } else if (this.search.endDatetimeMoment.isSameOrBefore(now)) {
        throw new Error('Return time cannot be in the past.')
      } else if (this.search.endDatetimeMoment.isSameOrBefore(startTime)) {
        throw new Error('Return must occur after pickup time.')
      } else if (this.search.endDatetimeMoment.isBefore(startTime.clone().add(1, 'hour'))) {
        throw new Error('Reservation must be for at least 1 hour.')
      }

      this.clearError('datetime')
    } catch (e) {
      isValid = false
      this.showError('datetime', e.message)
    }

    return isValid
  }

  checkAddressValidity() {
    let isValid = true

    try {
      if (!this.search.location) {
        throw new Error('Please enter a pickup location.')
      } else if (
        !this.search.latitude ||
        this.search.latitude === '0' ||
        !this.search.longitude ||
        this.search.longitude === '0'
      ) {
        throw new Error('Please enter a valid pickup location.')
      }

      this.clearError('address')
    } catch (e) {
      isValid = false
      this.showError('address', e.message)
    }

    return isValid
  }

  showError(type, message) {
    this.alertTarget.innerText = message
    this.alertTarget.classList.remove('cnd-hidden')
    this.submitTarget.setAttribute('disabled', 'disabled')

    this.errors[type] = true
  }

  clearError(type) {
    this.errors[type] = false

    const activeErrors = Object.keys(this.errors).find((key) => {
      return this.errors[key]
    })

    if (!activeErrors) {
      this.alertTarget.classList.add('cnd-hidden')
      this.submitTarget.removeAttribute('disabled')
    }
  }

  isOutsideHours(time) {
    // strip input date information to prevent date range errors
    time = moment(time.format('HH:mm'), 'HH:mm')

    return (
      time.isBetween(this.startOfDay, this.startAvailability, null, '()') ||
      time.isBetween(this.endAvailability, this.endOfDay, null, '()')
    )
  }
}
