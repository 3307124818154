import { Controller } from 'stimulus'

import TargetUtils from '../utils/target_utils'

export default class extends Controller {
  static targets = ['availableBalanceContainer', 'availableBalance', 'creditCard']

  initialize() {
    this.targetUtils = new TargetUtils(this)
    document.addEventListener('estimateUpdated', (e) => {
      this.availableBalance(e.detail.available_balance)
      this.amountToCharge(e.detail.amount_to_charge)
    })
  }

  availableBalance(balance) {
    if (balance) {
      this.targetUtils.show('availableBalanceContainerTarget')
      this.availableBalanceTarget.innerHTML = balance
    } else {
      this.targetUtils.hide('availableBalanceContainerTarget')
    }
  }

  amountToCharge(amount) {
    if (amount) {
      this.targetUtils.show('creditCardTarget')
      if (this.creditCardTarget.cnd.chargeAmount) {
        this.creditCardTarget.cnd.chargeAmount.innerHTML = amount
      }
    } else {
      this.targetUtils.hide('creditCardTarget')
    }
  }
}
