import { Controller } from 'stimulus'
import { ACCEPTED_IMAGE_FILES } from '../../../constants/uploads'

export default class extends Controller {
  static targets = ['offenceDate', 'offenceTime', 'submitButton', 'dropzone', 'fineAmount']

  isFileUploaded

  initialize() {
    if (this.dropzoneTarget.cnd) {
      this.setDropzoneConfig()
    } else {
      this.dropzoneTarget.addEventListener('initializedFileUpload', () => this.setDropzoneConfig())
    }
  }

  setDropzoneConfig() {
    this.dropzoneTarget.cnd.setOptions({ acceptedFiles: ACCEPTED_IMAGE_FILES })

    this.dropzoneTarget.addEventListener('addedfile', () => {
      this.submitButtonTarget.disabled = true
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
    })

    this.dropzoneTarget.addEventListener('directUploadSuccess', () => {
      this.isFileUploaded = true
      this.enableSubmit()
    })
  }

  enableSubmit() {
    const nReqRadio = new Set(Array.from(document.querySelectorAll('input[type="radio"]:required')).map((x) => x.name))
      .size
    const nCheckedRadio = document.querySelectorAll('input[type="radio"]:checked').length
    const allFieldsFilled = Array.from(document.querySelectorAll('[required]')).every((x) => x.validity.valid === true)
    const offenceDatePresent = !!this.offenceDateTarget.value
    const offenceTimePresent = !!this.offenceTimeTarget.value
    const fineAmountPresent = !!this.fineAmountTarget.value

    if (
      nReqRadio === nCheckedRadio &&
      allFieldsFilled &&
      this.isFileUploaded &&
      offenceDatePresent &&
      offenceTimePresent &&
      fineAmountPresent
    ) {
      window.onbeforeunload = null
      this.submitButtonTarget.disabled = false
    } else {
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
      this.submitButtonTarget.disabled = true
    }
  }
}
