import { Controller } from 'stimulus'
import { DirectUpload } from '@rails/activestorage'
import axios from 'axios'

import AxiosUtils from '../../utils/axios_utils'
import { ACCEPTED_IMAGE_FILES } from '../../constants/uploads'

export default class extends Controller {
  static targets = ['formAlert', 'modalAlert', 'editor', 'videoModal', 'videoModalOverlay']

  connect() {
    this.name = this.editorTarget.getAttribute('name')
    this.value = this.editorTarget.getAttribute('value')

    const { directUploadUrl, images, imageInputName, imageMax, video, videoInputName, videoDeleteUrl } =
      this.element.dataset
    this.directUploadUrl = directUploadUrl
    this.video = JSON.parse(video || null)
    this.videoInputName = videoInputName
    this.videoDeleteUrl = videoDeleteUrl
    this.images = JSON.parse(images || '[]').map((imageString) => JSON.parse(imageString))

    this.images.forEach((image) => {
      image.id = image.blob_id
      image.url = image.thumb_url
    })

    this.imageInputName = imageInputName
    this.imageMax = parseInt(imageMax)

    this.appendHiddenInputs()
    this.render()

    this.fileInput = document.createElement('input')
    this.fileInput.style.display = 'none'
    this.fileInput.setAttribute('multiple', true)
    this.fileInput.setAttribute('type', 'file')
    this.fileInput.setAttribute('accept', ACCEPTED_IMAGE_FILES)
    this.fileInput.addEventListener('change', this.onUploadImage)
    this.editorTarget.appendChild(this.fileInput)
  }

  // Event listeners

  onChange = (event = {}) => {
    const { name, value } = event.detail || {}
    if (name === this.name) this.value = value

    this.render()
  }

  onClick = (event) => {
    if (this.editorTarget.getAttribute('loading') === 'true') return

    const { name, imageId } = event.detail

    switch (name) {
      case 'add-video':
        if (this.videoModalOverlayTarget) {
          this.videoModalOverlayTarget.classList.remove('cnd-hidden')
          this.videoModalTarget.setAttribute('value', '')
        }
        break

      case 'remove-video':
        this.removeVideo()
        break

      case 'add-image':
        this.fileInput.click()
        break

      case 'remove-image':
        this.removeImage(imageId)
        break
    }
  }

  onVideoModalClick = (event) => {
    if (this.videoModalTarget.getAttribute('loading') === 'true') return

    const { name, value } = event.detail

    switch (name) {
      case 'embed':
        this.embedVideo(value)
        break

      case 'youtube':
        window.open('https://youtube.com')
        break

      case 'cancel':
        this.videoModalOverlayTarget.classList.add('cnd-hidden')
        break
    }
  }

  // Video

  embedVideo = (url) => {
    this.hideError()
    this.videoModalTarget.setAttribute('loading', true)

    axios
      .get(`/oembed/youtube?url=${url}`, { responseType: 'json' })
      .then((response) => {
        const { data: video } = response
        video.url = url
        this.video = video
        this.render()

        this.videoModalOverlayTarget.classList.add('cnd-hidden')
      })
      .catch((error) => this.showError('modal', error.response.data.error))
      .finally(() => this.videoModalTarget.setAttribute('loading', false))
  }

  removeVideo = () => {
    this.hideError()

    const oldVideo = { ...this.video }
    this.video = null
    this.render()

    if (!oldVideo.id) return

    this.editorTarget.setAttribute('loading', true)

    axios
      .delete(this.videoDeleteUrl, {
        responseType: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
        },
      })
      .catch((error) => {
        this.showError('form', error.message)

        this.video = oldVideo
        this.render()
      })
      .finally(() => this.editorTarget.setAttribute('loading', false))
  }

  // Images

  onUploadImage = (event) => {
    this.hideError()

    let files = event.target['files']
    if (this.imageMax) {
      files = [].slice.call(files, 0, this.imageMax - this.images.length)
    }

    const imageUploadPromises = [].map.call(files, (file) => this.uploadImage(file))

    this.editorTarget.setAttribute('loading', true)
    Promise.all(imageUploadPromises)
      .then((newImages) => {
        this.images = this.images.concat(newImages)
        this.onChange()
      })
      .catch((error) => this.showError('form', error.message))
      .finally(() => {
        this.fileInput.value = null
        this.editorTarget.setAttribute('loading', false)
      })
  }

  uploadImage = (image) =>
    new Promise((resolve, reject) => {
      const directUpload = new DirectUpload(image, this.directUploadUrl)

      directUpload.create((error, blob) => {
        if (error) {
          reject(error)
        } else {
          const reader = new FileReader()

          reader.readAsDataURL(image)
          reader.onload = (e) => {
            blob.url = e.target.result
            blob.new = true

            resolve(blob)
          }
        }
      })
    })

  removeImage = (imageId) => {
    this.hideError()

    const oldImages = this.images.slice(0)
    this.images = this.images.filter((_image) => String(_image.id) !== String(imageId))
    this.render()

    this.editorTarget.setAttribute('loading', true)

    axios
      .delete(`${this.directUploadUrl}\\${imageId}`, {
        responseType: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
        },
      })
      .catch((error) => {
        this.showError('form', error.message)

        this.images = oldImages
        this.render()
      })
      .finally(() => this.editorTarget.setAttribute('loading', false))
  }

  render() {
    this.removeHiddenInputs()
    this.appendHiddenInputs()

    // video
    this.editorTarget.setAttribute('show-add-video', !this.video)
    let editorVideo = this.editorTarget.querySelector('cnd-editor-video')
    if (editorVideo) this.editorTarget.removeChild(editorVideo)
    if (this.video) {
      editorVideo = document.createElement('cnd-editor-video')
      editorVideo.setAttribute('thumbnail', this.video.thumbnail_url)
      editorVideo.setAttribute('title', this.video.title)
      editorVideo.setAttribute('provider', this.video.provider_name)
      editorVideo.addEventListener('cndClick', this.onClick)
      editorVideo.classList.add('cnd-border')
      this.editorTarget.appendChild(editorVideo)
    }

    // images
    ;[].forEach.call(this.editorTarget.querySelectorAll('cnd-editor-image'), (image) =>
      this.editorTarget.removeChild(image)
    )
    this.images.forEach((image) => {
      const editorImage = document.createElement('cnd-editor-image')
      editorImage.setAttribute('id', image.id)
      editorImage.setAttribute('thumbnail', image.url)
      editorImage.addEventListener('cndClick', this.onClick)
      this.editorTarget.appendChild(editorImage)
    })

    if (this.imageMax) {
      this.editorTarget.setAttribute('image-count', this.images.length)
      this.editorTarget.setAttribute('image-max', this.imageMax)
    }
  }

  appendHiddenInputs() {
    this.appendHiddenInput(this.name, this.value)

    if (this.video && !this.video.id) {
      this.appendHiddenInput(`${this.videoInputName}[html]`, this.video.html)
      this.appendHiddenInput(`${this.videoInputName}[provider_name]`, this.video.provider_name)
      this.appendHiddenInput(`${this.videoInputName}[thumbnail_url]`, this.video.thumbnail_url)
      this.appendHiddenInput(`${this.videoInputName}[title]`, this.video.title)
      this.appendHiddenInput(`${this.videoInputName}[url]`, this.video.url)
    }

    this.images
      .filter((image) => image.signed_id)
      .forEach((image) => this.appendHiddenInput(this.imageInputName, image.signed_id))
  }

  appendHiddenInput(name, value) {
    const input = document.createElement('input')
    input.setAttribute('type', 'hidden')
    input.setAttribute('name', name)
    input.setAttribute('value', value)
    input.setAttribute('data-parent', this.name)

    this.editorTarget.appendChild(input)
  }

  removeHiddenInputs() {
    ;[].forEach.call(this.editorTarget.querySelectorAll(`[data-parent="${this.name}"]`), (input) =>
      this.editorTarget.removeChild(input)
    )
  }

  showError(alertType, message) {
    if (this.alertType === 'form') {
      this.formAlertTarget.innerHTML = String(message)
      this.formAlertTarget.classList.remove('cnd-hidden')
    } else {
      this.modalAlertTarget.innerHTML = String(message)
      this.modalAlertTarget.classList.remove('cnd-hidden')
    }
  }

  hideError() {
    this.formAlertTarget.classList.add('cnd-hidden')
    this.modalAlertTarget.classList.add('cnd-hidden')
  }
}
