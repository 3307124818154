import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['banner']

  onClick(event) {
    const applicant = JSON.parse(this.data.get('applicant'))

    switch (event.detail.name) {
      case 'checkout':
        location.href = `/trips/new?vehicle_id=${_.get(applicant, 'trip_request.vehicle_id')}`
        break

      case 'add-id':
      case 'learn-more':
        location.href = '/verification'
        break

      case 'upload':
        location.href = '/members/verification/additional-info'
        break
    }
  }
}
