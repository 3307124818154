import { Controller } from 'stimulus'

import TargetUtils from '../../utils/target_utils'

export default class extends Controller {
  static targets = ['keyLocationOptions', 'otherArea']

  initialize() {
    this.targetUtils = new TargetUtils(this)
    if (this.keyLocationOptions().value == 'other') {
      this.otherArea().classList.remove('cnd-hidden')
    }
  }

  toggleGlovebox() {
    this.otherArea().classList.add('cnd-hidden')
  }

  toggleCentreConsole() {
    this.otherArea().classList.add('cnd-hidden')
  }

  toggleOther() {
    this.otherArea().classList.remove('cnd-hidden')
  }

  keyLocationOptions() {
    return this.keyLocationOptionsTargets.filter((e) => e.checked)[0]
  }

  otherArea() {
    return this.otherAreaTarget
  }
}
