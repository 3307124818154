import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['stat', 'download']

  link

  initialize() {
    this.link = this.downloadTarget.getAttribute('href')
    this.updateLink('this-month')
  }

  changeGranularity(event) {
    const granularity = event.currentTarget.dataset.granularity
    this.statTargets.forEach((target) => {
      target.innerHTML = target.getAttribute(`data-${granularity}`)
    })

    this.updateLink(granularity)
  }

  updateLink(granularity) {
    this.downloadTarget.setAttribute('href', this.link + `?granularity=${granularity}`)
  }
}
