/* eslint-disable no-undef */
import { Controller } from 'stimulus'

import DeviceUtils from '../utils/device_utils'
import axios from 'axios'
import AxiosUtils from '../utils/axios_utils'
import FlashMessageUtils from '../utils/flash_message_utils'

export default class extends Controller {
  initialize() {
    this._setDeviceSpecificClasses()
    this._overlaySiteForRebrand()
    this._updateSingularDeviceProfile()
  }

  _setDeviceSpecificClasses() {
    const device = DeviceUtils.isMobile() ? 'mobile' : 'desktop'

    document.querySelectorAll(`[class-${device}]`).forEach((el) => {
      const deviceClasses = el.getAttribute(`class-${device}`)
      el.classList.add(...deviceClasses.split(/\s+/g))
    })
  }

  _overlaySiteForRebrand() {
    const overlay = document.querySelector('#overlay-rebrand')

    if (overlay && !DeviceUtils.isBot()) {
      const body = document.querySelector('body')

      body.style.height = '100%'
      body.style.overflow = 'hidden'
      overlay.style.display = 'block'
    }
  }

  _updateSingularDeviceProfile() {
    let prevSdid = window.cndVars.singularSdid
    let newSdid = singularSdk.getSingularDeviceId()

    if (typeof singularSdk !== 'undefined' && prevSdid !== newSdid) {
      axios.put('/singular_device_profile', { sdid: newSdid }, AxiosUtils.getHeaders()).catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.message)
      })
    }
  }
}
