// This component is DEPRECATED and will soon replaced by /credit-card_controller.js
import { Controller } from 'stimulus'
import * as axios from 'axios'

import StripeUtils from '../../utils/stripe_utils'
import AxiosUtils from '../../utils/axios_utils'

export default class extends Controller {
  static targets = [
    'messages',
    'errorMessages',
    'existingNumber',
    'existingExpiry',
    'submitButton',
    'cardErrors',
    'expiryErrors',
    'cvcErrors',
    'cardAddress',
    'cardNumber',
    'cardStreet',
    'cardUnitNumber',
    'cardSuburb',
    'cardState',
    'cardCountry',
    'cardPostCode',
    'cardElement',
    'expiryElement',
    'cvcElement',
    'paymentDetailsHeading',
  ]

  cardNumber

  cardExpiry

  cardCvc

  stripe

  initialize() {
    const countryCode = this.data.get('country-code')

    this.clearMessages()
    StripeUtils.getStripeKey(countryCode)
      .then((response) => {
        this.stripe = StripeUtils.initialiseStripe(response.data.stripe_key)
        this.createCardElements()

        this.addEventListeners()

        this.handleFormSubmit()
      })
      .catch((error) => {
        this.messagesTarget.innerHTML = error
      })

    window.autocompleteAddress('card', 'credit_card')
  }

  handleFormSubmit() {
    const THIS = this
    const form = document.getElementById('payment-form')
    form.addEventListener('submit', (event) => {
      event.preventDefault()

      const fullAddress = [this.cardAddressTarget.value, this.cardNumberTarget.value, this.cardStreetTarget.value]
        .filter((el) => el)
        .join(' ')

      const additionalData = {
        name: form.elements.holder.value,
        address_line1: fullAddress,
        address_line2: this.cardUnitNumberTarget.value,
        address_city: this.cardSuburbTarget.value,
        address_country: this.cardCountryTarget.value,
        address_state: this.cardStateTarget.value,
        address_zip: this.cardPostCodeTarget.value,
      }

      THIS.stripe.createToken(THIS.cardNumber, additionalData).then((result) => {
        if (result.error) {
          THIS.errorMessagesTarget.innerHTML = result.error.message
        } else {
          additionalData.holder = result.token.card.name
          additionalData.holder_address = result.token.card.address_line1
          additionalData.number = result.token.id
          additionalData.exp_month = result.token.card.exp_month
          additionalData.exp_year = result.token.card.exp_year
          THIS.updateCard(additionalData)
        }
      })
    })
  }

  updateCard(cardata) {
    this.addButtonSubmitState()
    axios
      .post('/credit_cards', cardata, AxiosUtils.getHeaders())
      .then((response) => {
        this.messagesTarget.innerHTML = 'Card details updated successfully'

        if (this.hasExistingNumberTarget) {
          this.existingNumberTarget.innerHTML = response.data.masked_number
        }

        if (this.hasExistingExpiryTarget) {
          this.existingExpiryTarget.innerHTML = response.data.expiry
        }

        this.removeButtonSubmitState()
        this.dispatchSuccessEvent()
      })
      .catch((error) => {
        this.errorMessagesTarget.innerHTML = error.response.data.error.message
        this.removeButtonSubmitState()
      })
  }

  addButtonSubmitState() {
    this.clearMessages()
    this.submitButtonTarget.classList.add('cc-stripe-submit-button-active')
    this.submitButtonTarget.innerHTML = 'Saving ...'
  }

  removeButtonSubmitState() {
    this.submitButtonTarget.classList.remove('cc-stripe-submit-button-active')
    this.submitButtonTarget.innerHTML = 'Submit'
  }

  clearMessages() {
    this.messagesTarget.innerHTML = ''
    this.errorMessagesTarget.innerHTML = ''
  }

  addEventListeners() {
    StripeUtils.addCardElementEventListener(this.cardNumber, this.cardErrorsTarget)

    StripeUtils.addCardElementEventListener(this.cardExpiry, this.expiryErrorsTarget)

    StripeUtils.addCardElementEventListener(this.cardCvc, this.cvcErrorsTarget)
  }

  createCardElements() {
    const elements = this.stripe.elements()

    this.cardNumber = elements.create('cardNumber', {
      style: StripeUtils.elementStyles,
      classes: StripeUtils.elementClasses,
    })
    this.cardNumber.mount(this.cardElementTarget)

    this.cardExpiry = elements.create('cardExpiry', {
      style: StripeUtils.elementStyles,
      classes: StripeUtils.elementClasses,
    })
    this.cardExpiry.mount(this.expiryElementTarget)

    this.cardCvc = elements.create('cardCvc', {
      style: StripeUtils.elementStyles,
      classes: StripeUtils.elementClasses,
    })
    this.cardCvc.mount(this.cvcElementTarget)
  }

  // TODO: refactor logic when moving `form-helper` and reservation form to stimulus
  dispatchSuccessEvent() {
    var event = new Event('CreditCardFormSuccess')
    if (document.querySelector('.js-reservation-form')) {
      document.querySelector('.js-reservation-form').dispatchEvent(event)
    } else {
      location.assign(window.location.pathname)
    }
  }
}
