import { Controller } from 'stimulus'
import axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
import mapboxgl from '!mapbox-gl'

export default class extends Controller {
  static targets = ['testGps', 'testGpsBody', 'testGpsSubmitButtonGroup', 'testGpsError']

  getLocation(event) {
    const controller = this

    this.hideError()

    this.queryCurrentLocation()
      .then((result) => {
        controller.showMap(result.data)
        controller.hideGetLocationButton(event.target)
      })
      .catch((error) => {
        this.showError(error.response.data.error_message)
      })
  }

  retryLocation() {
    this.hideError()

    const controller = this
    this.queryCurrentLocation()
      .then((result) => {
        controller.showMap(result.data)
      })
      .catch((error) => {
        this.showError(error.response.data.error_message)
      })
  }

  queryCurrentLocation() {
    return axios({
      method: 'get',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      url: `${window.location.pathname}/gps_location`,
    })
  }

  createMap(location) {
    const container = document.createElement('div')
    container.classList.add('cnd-h-64', 'cnd-mb-2')
    var long = parseFloat(location.long)
    var lat = parseFloat(location.lat)
    mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN

    this.map = new mapboxgl.Map({
      container: container,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 17,
      scrollZoom: false,
      center: [long, lat],
      attributionControl: false,
    })

    this.map.addControl(new mapboxgl.AttributionControl(), 'bottom-right')
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right')
    this.map.addControl(new mapboxgl.FullscreenControl(), 'top-right')

    const markerEl = document.createElement('div')
    markerEl.className = 'marker-car'
    this.marker = new mapboxgl.Marker(markerEl).setLngLat([long, lat]).setDraggable(false).addTo(this.map)

    this.testGpsBodyTarget.appendChild(container)
  }

  showMap(location) {
    const isThatYourLocation = document.createElement('p')
    let content = L10n.t('kit-setup-gps-steps.test-gps.is-this-your-cars-location')
    const isThatYourLocationText = document.createTextNode(content)

    this.testGpsBodyTarget.innerHTML = ''
    this.createMap(location)

    isThatYourLocation.appendChild(isThatYourLocationText)
    this.testGpsBodyTarget.appendChild(isThatYourLocation)
  }

  hideGetLocationButton(button) {
    button.classList.add('cnd-hidden')
    this.testGpsSubmitButtonGroupTarget.classList.remove('cnd-hidden')
  }

  hideError() {
    this.testGpsErrorTarget.classList.add('cnd-hidden')
    this.testGpsBodyTarget.classList.remove('cnd-hidden')
  }

  showError(error) {
    this.testGpsBodyTarget.classList.add('cnd-hidden')
    this.testGpsErrorTarget.querySelector('p').innerHTML = error
    this.testGpsErrorTarget.classList.remove('cnd-hidden')
  }
}
