import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'learningAccepted', 'uninstallAccepted', 'submitButton']

  initialize() {
    this.calendlyScheduled = false

    window.addEventListener('message', (e) => {
      if (this.isCalendlyEvent(e)) {
        if (e.data.event === 'calendly.event_scheduled') {
          this.calendlyScheduled = true
          this.enableSubmit()
        }
      }
    })

    this.enableSubmit()
  }

  isCalendlyEvent(e) {
    return e.data.event && e.data.event.indexOf('calendly') === 0
  }

  toggleOption(event) {
    if (!this.hasUninstallAcceptedTarget) {
      return
    }

    let currentElement = event.srcElement
    let isChecked = currentElement.checked

    if (isChecked) {
      if (currentElement.dataset.name === 'learningAccepted') {
        this.uninstallAcceptedTarget.checked = false
      } else {
        this.learningAcceptedTarget.checked = false
      }
    }
  }

  validateForm() {
    if (!this.hasUninstallAcceptedTarget) {
      return true
    }

    if (!this.learningAcceptedTarget.checked && !this.uninstallAcceptedTarget.checked) {
      return false
    }

    return true
  }

  enableSubmit() {
    const isValid = this.validateForm()

    if (isValid) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }
}
