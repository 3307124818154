import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'email', 'success', 'error']

  submit(event) {
    event.preventDefault()

    fetch(`${event.target.action}?` + new URLSearchParams({ email: this.emailTarget.value }), {
      method: 'GET',
      headers: new Headers({
        Accept: 'application/json',
      }),
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        }

        return response.json().then((json) => Promise.reject(json))
      })
      .then((data) => {
        this.formTarget.classList.add('cnd-hidden')
        this.successTarget.classList.remove('cnd-hidden')
        this.successTarget.textContent = data.message
      })
      .catch((error) => {
        this.formTarget.classList.add('cnd-hidden')
        this.errorTarget.classList.remove('cnd-hidden')
        this.errorTarget.textContent = error.message
      })
  }
}
