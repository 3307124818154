import { Controller } from 'stimulus'
import TargetUtils from '../../utils/target_utils'

export default class extends Controller {
  static targets = [
    'cargoDimensions',
    'vanCargoDimensionsTag',
    'vanCargoDimensionsSublabel',
    'bodyType',
    'bodyTypeValue',
    'vehicleHeight',
  ]

  initialize() {
    this.targetUtils = new TargetUtils(this)
    this.changeBodyType()
  }

  changeBodyType() {
    // Return if the feature flag is off
    if (document.getElementById('cargoDimensions') === null) {
      return
    }

    const bodyType = this.getBodyType()

    if (['Van', 'Ute', 'People Mover', 'SUV / 4WD'].indexOf(bodyType) !== -1) {
      this.targetUtils.show('vehicleHeightTarget')
    } else {
      this.targetUtils.hide('vehicleHeightTarget')
    }

    if (['Van', 'Ute'].indexOf(bodyType) !== -1) {
      this.targetUtils.show('vanCargoDimensionsTagTarget')
      this.targetUtils.show('vanCargoDimensionsSublabelTarget')
    } else {
      this.targetUtils.hide('vanCargoDimensionsTagTarget')
      this.targetUtils.hide('vanCargoDimensionsSublabelTarget')
    }
  }

  getBodyType() {
    if (this.hasBodyTypeTarget) {
      return this.bodyTypeTarget.value
    } else if (this.hasBodyTypeValueTarget) {
      return this.bodyTypeValueTarget.dataset.bodyType
    } else {
      return ''
    }
  }
}
