/*global Stripe*/
import * as axios from 'axios/index'

export default class StripeUtils {
  static initialiseStripe(stripeKey) {
    return Stripe(stripeKey)
  }

  static getStripeKey(countryCode = 'au') {
    return axios.get(`/credit-cards/stripe-key?country=${countryCode.toLowerCase() || 'au'}`)
  }

  static addCardElementEventListener(cardElement, errorDisplay) {
    cardElement.addEventListener('change', (event) => {
      if (!event.empty && event.brand !== 'visa' && event.brand !== 'mastercard') {
        errorDisplay.textContent = 'We accept only visa and mastercard'
        errorDisplay.classList.remove('cnd-hidden')
      } else if (!event.empty && event.error) {
        errorDisplay.textContent = event.error.message
        errorDisplay.classList.remove('cnd-hidden')
      } else {
        errorDisplay.textContent = ''
        errorDisplay.classList.add('cnd-hidden')
      }
    })
  }

  static elementStyles = {
    base: {
      color: '#22292f',
      fontWeight: 500,
      fontFamily: '"BrandonText", Helvetica, Arial, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'subpixel-antialiased',
      '::placeholder': {
        color: '#b8c2cc',
      },
    },
    invalid: {
      color: '#DB1921',
    },
  }

  static elementClasses = {
    focus: 'focused',
    empty: 'empty',
    invalid: 'invalid',
  }
}
