import { Controller } from 'stimulus'
import * as axios from 'axios'
import get from 'lodash/get'
import AxiosUtils from '../../utils/axios_utils'
import TargetUtils from '../../utils/target_utils'

export default class extends Controller {
  static targets = ['codeField', 'success', 'error', 'fingerprint', 'memberId', 'twoFactorAuthenticationToken']

  initialize() {
    this.targetUtils = new TargetUtils(this)
  }

  resendCode(event) {
    event.preventDefault()

    const memberId = this.memberIdTarget.value

    this.targetUtils.hide('errorTarget')

    axios
      .post('/two_factor_authentication', { member_id: memberId }, AxiosUtils.getHeaders())
      .then((response) => {
        // show message
        this.successTarget.innerHTML = response.data.message
        this.targetUtils.show('successTarget')
      })
      .catch((error) => {
        this.errorTarget.innerHTML = get(error, 'response.data.message', error.message)
        this.targetUtils.show('errorTarget')
      })
  }

  verifyCode(event) {
    event.preventDefault()

    const memberId = this.memberIdTarget.value
    const fingerprint = this.fingerprintTarget.value
    const code = this.codeFieldTarget.value
    const twoFactorAuthenticationToken = this.twoFactorAuthenticationTokenTarget.value
    const params = {
      code: code,
      member_id: memberId,
      fingerprint: fingerprint,
      two_factor_authentication_token: twoFactorAuthenticationToken,
    }

    axios
      .put('/two_factor_authentication', params, AxiosUtils.getHeaders())
      .then((response) => {
        window.location.href = this.data.get('redirectPath')
      })
      .catch((error) => {
        this.targetUtils.hide('successTarget')
        this.errorTarget.innerHTML = get(error, 'response.data.message', error.message)
        this.targetUtils.show('errorTarget')
      })
  }
}
