import { Controller } from 'stimulus'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'

export default class extends Controller {
  static targets = ['form', 'email', 'notification']

  handleFormSubmit() {
    const THIS = this

    axios({
      method: 'post',
      url: this.formTarget.action,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: {
        members_unsubscribe_from_marketing_form: {
          email: this.emailTarget.value,
        },
      },
    })
      .then((res) => {
        THIS.showNotification(res.data.success, true)
      })
      .catch((error) => {
        THIS.showNotification(error.response.data.message)
      })
  }

  showNotification(message, success) {
    this.notificationTarget.innerHTML = `<div class="cnd-panel ${success ? 'cnd-panel--green' : 'cnd-panel--orange'}">
        <div class="cnd-dismissible" data-controller="components--dismissible">
          ${message}
          <button class="cnd-dismissible__icon" data-action="click->components--dismissible#hide">&#10005;</button>
        </div>
      </div>`
  }
}
