import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'requirementList',
    'accidentDiagramReqs',
    'otherCarsQuestion',
    'otherDriverReqs',
    'otherCarReqs',
    'otherCarsQuestion',
    'smallDamageAlert',
    'multipleDamageAlert',
    'submitButton',
    'form',
  ]

  initialize() {
    this.updateSubmit()
  }

  answerWhoWasDriving() {
    this.updateSubmit()
  }

  answerOtherCars() {
    this.updateSubmit()
  }

  answerHowMuchDamage() {
    this.updateSubmit()
  }

  toggleReqs() {
    this.toggleWhoWasDriving()
    this.toggleOtherCars()
    this.toggleDamage()
  }

  toggleWhoWasDriving() {
    const whoWasDrivingRadio = document.querySelector('input[name="who_was_driving"]:checked')

    if (whoWasDrivingRadio && whoWasDrivingRadio.value !== 'borrower') {
      this.otherCarsQuestionTarget.classList.remove('cnd-hidden')
    }

    if (whoWasDrivingRadio && whoWasDrivingRadio.value === 'someone_else') {
      this.otherDriverReqsTarget.classList.remove('cnd-hidden')
    } else {
      this.otherDriverReqsTarget.classList.add('cnd-hidden')
    }
  }

  toggleOtherCars() {
    const otherCarsRadio = document.querySelector('input[name="other_cars_involved"]:checked')

    if (otherCarsRadio && otherCarsRadio.value === 'true') {
      this.accidentDiagramReqsTarget.classList.remove('cnd-hidden')
      this.otherCarReqsTarget.classList.remove('cnd-hidden')
    } else {
      this.otherCarReqsTarget.classList.add('cnd-hidden')
      this.accidentDiagramReqsTarget.classList.add('cnd-hidden')
    }
  }

  toggleDamage() {
    const howMuchDamageSelect = document.querySelector('select[name="how_much_damage"]')

    if (
      howMuchDamageSelect &&
      ['small', 'key_is_damaged', 'damage_to_tyre_or_rim'].includes(howMuchDamageSelect.value) &&
      this.smallDamageAlertTarget.dataset.forceHidden === 'false'
    ) {
      this.smallDamageAlertTarget.classList.remove('cnd-hidden')
    } else {
      this.smallDamageAlertTarget.classList.add('cnd-hidden')
    }

    if (
      howMuchDamageSelect &&
      howMuchDamageSelect.value === 'multiple' &&
      this.smallDamageAlertTarget.dataset.forceHidden === 'false'
    ) {
      this.multipleDamageAlertTarget.classList.remove('cnd-hidden')
    } else {
      this.multipleDamageAlertTarget.classList.add('cnd-hidden')
    }
  }

  updateSubmit() {
    const otherCarsRadio = document.querySelector('input[name="other_cars_involved"]:checked')
    const whoWasDrivingRadio = document.querySelector('input[name="who_was_driving"]:checked')
    const howMuchDamageSelect = document.querySelector('select[name="how_much_damage"]')

    if (whoWasDrivingRadio && whoWasDrivingRadio.value === 'borrower') {
      this.submitButtonTarget.disabled = !(howMuchDamageSelect.value.length > 0)
    } else {
      this.submitButtonTarget.disabled = !(whoWasDrivingRadio && otherCarsRadio && howMuchDamageSelect.value.length > 0)
    }

    if (this.submitButtonTarget.disabled) {
      this.requirementListTarget.classList.add('cnd-hidden')
    } else {
      this.requirementListTarget.classList.remove('cnd-hidden')
    }

    this.toggleReqs()
  }
}
