import { Controller } from 'stimulus'

export default class extends Controller {
  declare readonly joinFormTarget: Element
  declare readonly sideMenuTarget: Element

  static targets = ['joinForm', 'sideMenu']

  bodyEl: HTMLElement | undefined

  initialize(): void {
    this.bodyEl = document.getElementsByTagName('body')[0]
    window.addEventListener('keyup', this.handleEsc.bind(this))
  }

  showJoinForm(e: Event): void {
    e.preventDefault()

    // firebaseui allows us to have only 1 dom element to be configured with firebase
    // so we'll need to redirect users instead of showing the joinForm modal
    if (
      document.querySelectorAll('[data-controller="signup"]').length +
        document.querySelectorAll('[data-controller="login"]').length >
      1
    ) {
      window.location.href = '/join'
    } else {
      this.joinFormTarget?.classList.remove('cnd-hidden')
    }
  }

  hideJoinForm(e: Event): void {
    e.preventDefault()

    this.joinFormTarget?.classList.add('cnd-hidden')
  }

  showSideMenu(e: Event): void {
    e.preventDefault()

    this.sideMenuTarget?.classList.add('cnd-visible')
    this.bodyEl?.classList.add('cnd-overflow-hidden')
    document.getElementById('sidemenu-open')?.classList.add('cnd-hidden')
    document.getElementById('sidemenu-close')?.classList.remove('cnd-hidden')
  }

  showCarsSideMenu(e: Event): void {
    e.preventDefault()

    document.getElementById('sidemenu-target')?.classList.add('cnd-hidden')
    document.getElementById('sidemenu-cars')?.classList.remove('cnd-hidden')
  }

  showUtesSideMenu(e: Event): void {
    e.preventDefault()

    document.getElementById('sidemenu-target')?.classList.add('cnd-hidden')
    document.getElementById('sidemenu-utes')?.classList.remove('cnd-hidden')
  }

  showVansSideMenu(e: Event): void {
    e.preventDefault()

    document.getElementById('sidemenu-target')?.classList.add('cnd-hidden')
    document.getElementById('sidemenu-vans')?.classList.remove('cnd-hidden')
  }

  hideSideMenu(e: Event): void {
    e.preventDefault()

    this.sideMenuTarget?.classList.remove('cnd-visible')
    this.bodyEl?.classList.remove('cnd-overflow-hidden')
    document.getElementById('sidemenu-open')?.classList.remove('cnd-hidden')
    document.getElementById('sidemenu-close')?.classList.add('cnd-hidden')
  }

  hideCarsSideMenu(e: Event): void {
    e.preventDefault()

    document.getElementById('sidemenu-target')?.classList.remove('cnd-hidden')
    document.getElementById('sidemenu-cars')?.classList.add('cnd-hidden')
  }

  hideUtesSideMenu(e: Event): void {
    e.preventDefault()

    document.getElementById('sidemenu-target')?.classList.remove('cnd-hidden')
    document.getElementById('sidemenu-utes')?.classList.add('cnd-hidden')
  }

  hideVansSideMenu(e: Event): void {
    e.preventDefault()

    document.getElementById('sidemenu-target')?.classList.remove('cnd-hidden')
    document.getElementById('sidemenu-vans')?.classList.add('cnd-hidden')
  }

  highlightParentLink(e: Event): void {
    const eventTarget = e.currentTarget as HTMLElement
    eventTarget.parentElement?.firstElementChild?.classList.add('cnd-menu--active')
  }

  removeParentLinkHighlight(e: Event): void {
    const eventTarget = e.currentTarget as HTMLElement
    eventTarget.parentElement?.firstElementChild?.classList.remove('cnd-menu--active')
  }

  handleEsc(e: KeyboardEvent): void {
    if (e.code === 'Escape') {
      if (!this.joinFormTarget?.classList.contains('cnd-hidden')) {
        this.hideJoinForm(e)
      }

      if (this.sideMenuTarget?.classList.contains('cnd-visible')) {
        this.hideSideMenu(e)
      }
    }
  }
}
