import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['photo', 'lightbox', 'pdf']

  initialize() {
    this.photoTargets.forEach((target, i) => {
      if (target.dataset.showOnClick !== 'false') {
        target.dataset.action = 'click->shared--lightbox#show'
      }
      target.dataset.id = i
    })
    window.addEventListener('keyup', (e) => this.handleEsc(e))
  }

  show(event) {
    event.preventDefault()
    if (this.hasLightboxTarget) {
      this.lightboxTarget.classList.remove('cnd-hidden')
    } else {
      this.createLightbox()
    }

    document.body.classList.add('cnd-overflow-hidden')

    if (event.currentTarget.dataset.id) {
      this.scrollToPhoto(event.currentTarget.dataset.id)
    }
  }

  createLightbox() {
    let div = document.createElement('div')
    div.classList.add('cnd-lightbox', 'spec-lightbox')
    div.dataset.target = 'shared--lightbox.lightbox'
    div.append(this.createHeader())
    div.append(this.createContainer())
    this.element.append(div)
    div.setAttribute('tabindex', '-1')
    div.focus()
  }

  createHeader() {
    let header = document.createElement('div')

    header.classList.add('cnd-lightbox__header')
    header.innerHTML = `<div class="cnd-container cnd-container--tight cnd-dismissible">
        <div><b>${this.data.get('title')}</b><span class="cnd-text-muted cnd-ml-2">
        ${this.headerText()}</span></div>
        <button class="cnd-dismissible__icon" data-action="shared--lightbox#hide">&#10005;</button>
      </div>`
    return header
  }

  createContainer() {
    let container = document.createElement('div')
    container.classList.add('cnd-container', 'cnd-container--tight')
    this.photoTargets.forEach((target, i) => {
      let photo = this.createPhotoElement(target, i)
      container.append(photo)
    })
    this.pdfTargets.forEach((target, i) => {
      let pdf = this.createPdfElement(target, i)
      container.append(pdf)
    })
    return container
  }

  createPhotoElement(target, i) {
    let photo = document.createElement('div')
    photo.id = `photo-${i}`
    photo.classList.add('cnd-pt-24', 'cnd--mt-24')
    let img = target.cloneNode(true)
    if (i == 0) {
      img.classList.add('spec-lightbox-photo')
      img.classList.remove('spec-lightbox-photo-btn')
    }
    photo.append(img)
    return photo
  }

  createPdfElement(target, i) {
    let pdf = document.createElement('div')
    pdf.id = `pdf-${i}`
    pdf.classList.add('cnd-pt-24', 'cnd--mt-24')
    let pdfLink = target.cloneNode(true)
    pdfLink.innerText = pdfLink.innerText + ` ${i + 1}`
    pdf.append(pdfLink)
    return pdf
  }

  headerText() {
    const photoCount = this.countPhoto()
    const pdfCount = this.pdfTargets.length

    let text = `${photoCount} photo${photoCount > 1 ? 's' : ''}`
    if (pdfCount > 0) {
      text = text + `, ${pdfCount} pdf${pdfCount > 1 ? 's' : ''}`
    }
    return text
  }

  countPhoto() {
    let count = 0

    this.photoTargets.forEach((target, i) => {
      if (target.dataset.countable !== 'false') {
        count++
      }
    })

    return count
  }

  scrollToPhoto(i) {
    document.getElementById(`photo-${i}`).scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    })
  }

  hide() {
    this.lightboxTarget.classList.add('cnd-hidden')
    document.body.classList.remove('cnd-overflow-hidden')
  }

  handleEsc(e) {
    if (e.keyCode === 27 && this.hasLightboxTarget) {
      this.hide()
    }
  }
}
