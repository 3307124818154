import { Controller } from 'stimulus'
import * as intlTelInput from 'intl-tel-input'
import 'intl-tel-input/build/css/intlTelInput.css'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'
import TargetUtils from '../../utils/target_utils'
import VerificationUtils from '../../utils/verification_utils'

export default class extends Controller {
  static targets = [
    'select',
    'sendSmsButton',
    'codeInputFields',
    'verifyCode',
    'codeField',
    'verified',
    'error',
    'success',
    'redirectTo',
  ]

  targetUtils
  checkoutController

  isDialog = false

  initialize() {
    const input = document.querySelector('#phone')
    this.iti = intlTelInput(input, {
      utilsScript: 'https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.11/build/js/utils.js',
      initialCountry: 'au',
    })
    this.targetUtils = new TargetUtils(this)

    if (!this.verifiedTarget.classList.contains('cnd-hidden')) {
      this.targetUtils.hide('sendSmsButtonTarget')
    } else if (this.selectTarget.value.length > 7) {
      this.targetUtils.show('sendSmsButtonTarget')
    }

    // For checkout page
    if (this.data.has('submit')) {
      this.checkoutController = this.getControllerByIdentifier('checkout')
    }

    this.isDialog = this.data.get('dialog') === 'true'
  }

  // Used to access external controller
  getControllerByIdentifier(identifier) {
    return this.application.controllers.find((controller) => {
      return controller.context.identifier === identifier
    })
  }

  sendCode(event) {
    event.preventDefault()

    this.targetUtils.hide('errorTarget')
    const params = {
      mobile: this.iti.getNumber(),
      country: event.target.dataset.country,
    }

    axios
      .post('/mobile_verification', params, AxiosUtils.getHeaders())
      .then((response) => {
        this.selectTarget.innerHTML = response.data.mobile
        this.targetUtils.hide('sendSmsButtonTarget')
        this.targetUtils.show('codeInputFieldsTarget')
      })
      .catch((error) => {
        const data = error.response.data
        this.sendSmsButtonTarget.innerHTML = 'Resend verification SMS'

        if (this.isDialog) {
          this.errorTarget.innerHTML = data.message
          this.targetUtils.show('errorTarget')
        } else {
          FlashMessageUtils.showWarning(data.message)
        }
      })
  }

  showSendSmsButton() {
    if (!this.verifiedTarget.classList.contains('cnd-hidden')) {
      this.targetUtils.hide('verifiedTarget')
    }
    this.selectTarget.value.length >= 7
      ? this.targetUtils.show('sendSmsButtonTarget')
      : this.targetUtils.hide('sendSmsButtonTarget')
  }

  autoTabToNextInput(event) {
    VerificationUtils.autoTabToNextInput(event)
  }

  verifyCode(event) {
    event.preventDefault()

    const code = this.codeFieldTargets.map((f) => f.value).join('')
    const params = { code: code }

    axios
      .put('/mobile_verification', params, AxiosUtils.getHeaders())
      .then((response) => {
        // Show that mobile is verified
        this.selectTarget.innerHTML = response.data.mobile
        this.targetUtils.hide('codeInputFieldsTarget')
        this.targetUtils.show('verifiedTarget')
        this.clearEnteredCode()
        this.element.dispatchEvent(new Event('mobileverified', { bubbles: true }))
        if (this.checkoutController) {
          this.successTarget.innerHTML = 'Thanks for verifying! Please wait while we process your booking.'
          this.targetUtils.show('successTarget')
          this.selectTarget.setAttribute('disabled', 'disabled')
          this.checkoutController.submitAndDisable()
        }
        if (this.hasRedirectToTarget) {
          location.href = this.redirectToTarget.dataset.path
        }
      })
      .catch((error) => {
        // Flash em
        const data = error.response.data
        this.errorTarget.innerHTML = data.message
        this.targetUtils.show('errorTarget')

        // Show the Resend SMS button but not the input
        this.targetUtils.hide('codeInputFieldsTarget')
        this.sendSmsButtonTarget.innerHTML = 'Resend verification SMS'
        this.targetUtils.show('sendSmsButtonTarget')
        this.clearEnteredCode()
      })
  }

  clearEnteredCode() {
    this.codeFieldTargets.forEach((field) => (field.value = ''))
  }
}
