import Cookies from 'js-cookie'

export default class EnvironmentUtils {
  static isStagingEnvironment() {
    return window.location.href.includes('staging.ubercarshare.com')
  }

  static isPreviewEnvironment() {
    return window.location.href.includes('preview-') && window.location.href.includes('.ubercarshare.com')
  }

  static isCypressProcess() {
    return Boolean(Object.keys(window.Cypress).length)
  }

  static hasTestToken() {
    return Cookies.get('test_token') === Cypress.env('TEST_TOKEN')
  }

  static isAnEndToEndTestingProcess() {
    // Return false if window does not have Cypress or library Cypress is not initialized
    if (!window.Cypress || !Cypress) return false

    return (
      this.hasTestToken() && (this.isStagingEnvironment() || this.isPreviewEnvironment() || this.isCypressProcess())
    )
  }
}
