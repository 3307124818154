import { Controller } from 'stimulus'

import axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'

export default class extends Controller {
  static targets = [
    'form',
    'description',
    'description',
    'feature',
    'hourlyRate',
    'dailyRate',
    'perKm',
    'minDuration',
    'maxDuration',
    'minNotice',
    'alert',
  ]

  payload

  dismissItem() {
    const parent = this.element.parentElement

    if (parent.querySelectorAll('[data-controller~="manage--onboarding-checklist"]').length === 1) {
      document.querySelectorAll('[data-target="manage--onboarding-checklist.header"]').forEach((el) => {
        if (el.parentElement.contains(this.element)) {
          el.remove()
        }
      })
    }

    this.element.remove()

    this.post(this.element.dataset.url, 'delete', {
      item: this.element.dataset.form,
    })
  }

  submit(e) {
    e.preventDefault()

    switch (this.element.dataset.form) {
      case 'parking_instructions':
        this.validateParkingInstructions()
        break
      case 'description':
        this.validateCarDescription()
        break
      case 'rates':
        this.validateRates()
        break
      case 'booking_preferences':
        this.validateBookingPreferences()
        break
    }
  }

  validateParkingInstructions() {
    if (this.hasDescriptionTarget && this.descriptionTarget.value) {
      this.post(this.formTarget.action, this.formTarget._method ? this.formTarget._method.value : 'post', {
        manage_cars_parking_form: {
          description: this.descriptionTarget.value,
        },
      }).finally(() => {
        this.dismissModal()
        this.dismissItem()
      })
    } else {
      this.alert('Please enter a valid parking parking description.')
    }
  }

  validateCarDescription() {
    if (this.hasDescriptionTarget && this.descriptionTarget.value) {
      let features = []

      if (this.hasFeatureTarget) {
        this.featureTargets.forEach((el) => {
          if (el.checked) {
            features.push(el.value)
          }
        })
      }

      this.post(this.formTarget.action, this.formTarget._method ? this.formTarget._method.value : 'post', {
        manage_cars_about_form: {
          description: this.descriptionTarget.value,
          features: features,
        },
      }).finally(() => {
        this.dismissModal()
        this.dismissItem()
      })
    } else {
      this.alert('Please enter a description of your car.')
    }
  }

  validateRates() {
    let isValid = true

    if (!this.hasHourlyRateTarget || parseInt(this.hourlyRateTarget.value) < 1) {
      isValid = false
    }

    if (!this.hasDailyRateTarget || parseInt(this.dailyRateTarget.value) < 1) {
      isValid = false
    }

    let perKmTarget

    if (
      !this.perKmTargets ||
      !(perKmTarget = this.perKmTargets.find((el) => {
        return el.checked
      }))
    ) {
      isValid = false
    }

    if (isValid) {
      this.post(this.formTarget.action, this.formTarget._method ? this.formTarget._method.value : 'post', {
        manage_cars_rates_form: {
          hourly_rate: this.hourlyRateTarget.value,
          daily_rate: this.dailyRateTarget.value,
          per_km: perKmTarget.value,
        },
      }).finally(() => {
        this.dismissModal()
        this.dismissItem()
      })
    } else {
      this.alert('Please enter ensure all fields have been filled out.')
    }
  }

  validateBookingPreferences() {
    let isValid = true

    if (!this.hasMinDurationTarget || parseInt(this.minDurationTarget.value) < 1) {
      isValid = false
    }

    if (!this.hasMaxDurationTarget || parseInt(this.maxDurationTarget.value) < 1) {
      isValid = false
    }

    if (!this.hasMinNoticeTarget || !this.minNoticeTarget.value) {
      isValid = false
    }

    if (isValid) {
      this.post(this.formTarget.action, this.formTarget._method ? this.formTarget._method.value : 'post', {
        manage_cars_preferences_form: {
          min_reservation_duration_hours: this.minDurationTarget.value,
          max_reservation_duration_hours: this.maxDurationTarget.value,
          minimum_notice_hours: this.minNoticeTarget.value,
        },
      }).finally(() => {
        this.dismissModal()
        this.dismissItem()
      })
    } else {
      this.alert('Please enter ensure all fields have been filled out.')
    }
  }

  alert(message, type) {
    if (this.hasAlertTarget) {
      this.alertTarget.innerText = message
      this.alertTarget.classList.add(type === 'success' ? 'cnd-panel--green' : 'cnd-panel--orange')
      this.alertTarget.classList.remove('cnd-hidden')
    }
  }

  dismissModal() {
    let esc = new Event('keyup')
    esc.keyCode = 27
    window.dispatchEvent(esc)
  }

  post(url, method, data) {
    return axios({
      url: url,
      method: method,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: data,
    })
  }
}
