import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['lowerRange, upperRange', 'lowerThumb', 'upperThumb', 'range', 'lowerInputText', 'upperInputText']

  initialize() {
    this.setLowerValue()
    this.setUpperValue()
  }

  setLowerValue() {
    var _this = this.lowerInputTarget,
      min = parseInt(_this.min),
      max = parseInt(_this.max)

    _this.value = Math.min(parseInt(_this.value), parseInt(this.upperInputTarget.value) - 1)

    var percent = ((_this.value - min) / (max - min)) * 100

    this.lowerThumbTarget.style.left = percent + '%'
    this.rangeTarget.style.left = percent + '%'
    this.lowerInputTextTarget.innerHTML = _this.value
  }

  setUpperValue() {
    var _this = this.upperInputTarget,
      min = parseInt(_this.min),
      max = parseInt(_this.max)

    _this.value = Math.max(parseInt(_this.value), parseInt(this.lowerInputTarget.value) + 1)

    var percent = ((_this.value - min) / (max - min)) * 100

    this.upperThumbTarget.style.right = 100 - percent + '%'
    this.rangeTarget.style.right = 100 - percent + '%'

    // Let's not mutate _this.value and only append plus at the the
    // innerHTML level
    if (_this.value == _this.max) {
      this.upperInputTextTarget.innerHTML = _this.value + '+'
    } else {
      this.upperInputTextTarget.innerHTML = _this.value
    }
  }

  get lowerInputTarget() {
    return this.element.querySelector('#lower')
  }

  get upperInputTarget() {
    return this.element.querySelector('#upper')
  }
}
