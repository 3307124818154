import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['image', 'radio']

  initialize() {
    const self = this
    this.radioTargets.forEach((target) => {
      if (target.checked) {
        self.setImage(target.getAttribute('data-id'))
      }
    })
  }

  showPack(event) {
    this.setImage(event.currentTarget.getAttribute('data-id'))
  }

  setImage(id) {
    this.imageTargets.forEach((target) => {
      if (target.getAttribute('data-id') == id) {
        target.classList.remove('cnd-hidden')
      } else {
        target.classList.add('cnd-hidden')
      }
    })
  }
}
