import { Controller } from 'stimulus'
import get from 'lodash/get'

export default class extends Controller {
  static targets = ['mobile', 'mobileVerification']

  initialize() {}

  get getMobileVerificationController() {
    return this.application.getControllerForElementAndIdentifier(
      this.mobileVerificationTarget,
      'components--mobile-verification'
    )
  }

  formatMobileNumber() {
    this.mobileTarget.value = this.getMobileVerificationController.iti.getNumber()
  }
}
