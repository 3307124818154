import { isLoggedInAsAdmin } from './is_admin'

export const trackEvent = ({
  category,
  event,
  properties,
}: {
  category: string
  event: string
  properties: Record<string, unknown>
}): void => {
  if (!analytics || isLoggedInAsAdmin()) return
  analytics.track(`${category}:${event}`, properties)
}
