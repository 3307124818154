import { Controller } from 'stimulus'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
export default class extends Controller {
  static targets = ['joinForm', 'actionButton']

  showJoinForm(e) {
    const favoriteVehicleId = e.currentTarget.dataset.vehicleId
    const signupForm = this.joinFormTarget.querySelector('form')
    const formUrl = new URL(signupForm.action)
    const searchParams = formUrl.searchParams
    searchParams.set('favorite_vehicle_id', favoriteVehicleId)
    searchParams.set('referer', document.location.href)
    signupForm.action = `${formUrl.pathname}${formUrl.search}`
    e.preventDefault()

    this.joinFormTarget.classList.remove('cnd-hidden')
  }

  hideJoinForm(e) {
    e.preventDefault()

    this.joinFormTarget.classList.add('cnd-hidden')
  }

  favoriteVehicle(vehicleId, target) {
    const controller = this

    axios({
      method: 'POST',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      url: `/vehicles/${vehicleId}/favorites`,
    })
      .then((result) => {
        controller.toggleFavorite(result.data.favorite_id, target)
      })
      .catch((error) => Sentry.captureMessage(error.response.data.message))
  }

  unfavoriteVehicle(vehicleId, favoriteId, target) {
    const controller = this

    axios({
      method: 'DELETE',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      url: `/vehicles/${vehicleId}/favorites/${favoriteId}`,
    })
      .then((_result) => {
        controller.toggleFavorite(null, target)
        if (this.favoriteFilterEnabled) {
          this.getVehicleSearchController.hideVehicle()
        }
      })
      .catch((error) => Sentry.captureMessage(error.response.data.message))
  }

  toggleFavorite(favoriteId = null, target) {
    const favoriteButton = target.querySelector('.cnd-favorite')
    const unfavoriteButton = target.querySelector('.cnd-unfavorite')
    if (favoriteId === null) {
      favoriteButton.classList.remove('cnd-hidden')
      unfavoriteButton.classList.add('cnd-hidden')
    } else {
      favoriteButton.classList.add('cnd-hidden')
      unfavoriteButton.classList.remove('cnd-hidden')
    }
    target.dataset.favoriteId = favoriteId
  }

  updateButton(e) {
    let currentTarget = e.currentTarget
    const { vehicleId, favoriteId } = currentTarget.dataset

    e.preventDefault()

    if (favoriteId !== 'null') {
      this.unfavoriteVehicle(vehicleId, favoriteId, e.currentTarget)
    } else {
      this.favoriteVehicle(vehicleId, e.currentTarget)
    }
  }

  get favoriteFilterEnabled() {
    const searchParams = new URLSearchParams(window.location.search)
    return searchParams.get('favorite') === 'true'
  }

  get getVehicleSearchController() {
    return this.application.getControllerForElementAndIdentifier(
      document.getElementById('vehicle-search-container'),
      'search--vehicle-search'
    )
  }
}
