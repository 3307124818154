import { Controller } from 'stimulus'

import * as axios from 'axios'

export default class extends Controller {
  static targets = ['button', 'imgPhoneToTower', 'imgPhoneToCar', 'imgComplete', 'success', 'error']

  vehicleId

  initialize() {
    this.vehicleId = this.element.getAttribute('data-vehicle-id')
  }

  unlockVehicle() {
    this.clearState()
    this.showConnectingToAutolink()

    this.sendRequest(1)
      .then((response) => {
        this.showConnectingToCar()

        this.pollStatus(() => this.getStatus(response.data.message), 30000, 1000)
          .then((data) => {
            this.showSuccess()
          })
          .catch(() => {
            this.showError()
          })
      })
      .catch((error) => {
        this.showError()
      })
  }

  lockVehicle() {
    this.clearState()
    this.showConnectingToAutolink()

    this.sendRequest(0)
      .then((response) => {
        this.showConnectingToCar()

        this.pollStatus(() => this.getStatus(response.data.message), 30000, 1000)
          .then((data) => {
            this.showSuccess()
          })
          .catch(() => {
            this.showError()
          })
      })
      .catch((error) => {
        this.showError()
      })
  }

  clearState() {
    this.imgPhoneToTowerTarget.classList.add('cnd-hidden')
    this.imgPhoneToCarTarget.classList.add('cnd-hidden')
    this.imgCompleteTarget.classList.add('cnd-hidden')

    this.successTarget.classList.remove('cnd-flex')
    this.errorTarget.classList.remove('cnd-flex')

    this.buttonTarget.disabled = false
  }

  showConnectingToAutolink() {
    this.buttonTarget.disabled = true
    this.imgPhoneToTowerTarget.classList.remove('cnd-hidden')
  }

  showConnectingToCar() {
    this.imgPhoneToTowerTarget.classList.add('cnd-hidden')
    this.imgPhoneToCarTarget.classList.remove('cnd-hidden')
  }

  showSuccess() {
    this.imgPhoneToCarTarget.classList.add('cnd-hidden')
    this.imgCompleteTarget.classList.remove('cnd-hidden')

    setTimeout(() => {
      this.imgCompleteTarget.classList.add('cnd-hidden')
      this.successTarget.classList.add('cnd-flex')
    }, 2000)
  }

  showError() {
    this.clearState()
    this.errorTarget.classList.add('cnd-flex')
  }

  sendRequest(action) {
    return axios
      .post('/autolink/doors.json', {
        vehicle_id: `${this.vehicleId}`,
        control_type: `${action}`,
      })
      .then((response) => response)
      .catch((error) => Promise.reject(error))
  }

  endReservation() {
    axios
      .put('/trips/' + `${this.reservationId}` + '/close', {})
      .then((response) => response)
      .catch((error) => Promise.reject(error))
  }

  getStatus(guid) {
    return axios
      .post('/autolink/control_statuses.json', {
        vehicle_id: `${this.vehicleId}`,
        guid: `${guid}`,
      })
      .then((response) => response)
      .catch((error) => Promise.reject(error))
  }

  // @ref: https://davidwalsh.name/javascript-polling
  pollStatus(fn, timeout, interval) {
    const endTime = Number(new Date()) + (timeout || 2000)
    interval = interval || 100

    var checkCondition = function (resolve, reject) {
      const result = fn()

      result.then(function (response) {
        if (response.data.text_status == 'Success') {
          resolve(response.data)
        } else if (Number(new Date()) < endTime) {
          setTimeout(checkCondition, interval, resolve, reject)
        } else {
          reject(new Error(`timed out for ${fn}: ${arguments}`))
        }
      })
    }

    return new Promise(checkCondition)
  }
}
