import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['container', 'container1']

  initialize() {
    window.addEventListener('keyup', (e) => this.handleEsc(e))
  }

  showOverlay(e) {
    e.preventDefault()
    this.containerTarget.classList.remove('cnd-hidden')
  }

  hideOverlay(e) {
    e.preventDefault()
    this.containerTarget.classList.add('cnd-hidden')
  }

  handleEsc(e) {
    if (e.keyCode === 27 && this.hasContainerTarget) {
      this.hideOverlay(e)
    }
  }
}
