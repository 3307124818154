export default class AxiosUtils {
  static getHeaders() {
    return {
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': this.getMetaValue('csrf-token'),
      },
    }
  }

  static getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    if (!element) return ''
    return element.getAttribute('content')
  }
}
