import { Controller } from 'stimulus'
import { ACCEPTED_IMAGE_FILES } from '../../../constants/uploads'
import MobiscrollUtils from '../../../utils/mobiscroll_utils'

export default class extends Controller {
  static targets = [
    'thirdPartyDetails',
    'witnessDetails',
    'witnessList',
    'witnessTemplate',
    'whoseFaultDetails',
    'otherCarDetails',
    'thirdPartyList',
    'thirdPartyTemplate',
    'propertyDamageDetails',
    'policeDetails',
    'ridesharingDetails',
    'drugsOrAlcoholDetails',
    'incidentDateTime',
    'submitButton',
    'dropzone',
    'howMuchDamage',
    'personalCarInsurance',
    'passengerDetails',
    'passengerList',
    'passengerTemplate',
    'otherCarPassengerDetails',
    'otherCarPassengerList',
  ]

  isFileUploaded

  initialize() {
    this.isFileUploaded = this.howMuchDamageTarget.value === 'theft_or_loss'

    if (this.dropzoneTarget.cnd) {
      this.setDropzoneConfig()
    } else {
      this.dropzoneTarget.addEventListener('initializedFileUpload', () => this.setDropzoneConfig())
    }

    if (this.incidentDateTimeTargets.length > 0) {
      MobiscrollUtils.newCalendarInstance(this.incidentDateTimeTarget, {
        headerText: 'Time and date of the incident',
        min: window.cndUtils.xYearsBeforeCurrentDate(10),
        max: window.cndUtils.currentDate(),
      })
    }

    // Needed because the change event on the form might not trigger if users don't click outside the input
    document.querySelectorAll('input[type="text"]:required, textarea:required').forEach((el) => {
      el.addEventListener('keyup', (e) => this.enableSubmit())
    })
  }

  setDropzoneConfig() {
    this.dropzoneTarget.cnd.setOptions({ acceptedFiles: ACCEPTED_IMAGE_FILES })

    this.dropzoneTarget.addEventListener('addedfile', () => {
      this.submitButtonTarget.disabled = true
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
    })

    this.dropzoneTarget.addEventListener('directUploadSuccess', () => {
      this.isFileUploaded = true
      this.enableSubmit()
    })
  }

  enableSubmit() {
    const required = Array.from(document.querySelectorAll('input[type="radio"]:required')).map((x) => x.name)
    const checked = Array.from(document.querySelectorAll('input[type="radio"]:checked')).map((x) => x.name)

    const allRequiredChecked = required.every((input) => checked.includes(input))

    const allFieldsFilled = Array.from(document.querySelectorAll('[required]')).every((x) => x.validity.valid === true)
    if (allRequiredChecked && allFieldsFilled && this.isFileUploaded) {
      window.onbeforeunload = null
      this.submitButtonTarget.disabled = false
    } else {
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
      this.submitButtonTarget.disabled = true
    }
  }

  addWitness(_event) {
    this.witnessListTarget.append(this.witnessTemplateTarget.firstElementChild.cloneNode(true))
  }

  addThirdParty(_event) {
    this.thirdPartyListTarget.append(this.thirdPartyTemplateTarget.firstElementChild.cloneNode(true))
  }

  addPassenger(_event) {
    this.passengerListTarget.append(this.passengerTemplateTarget.firstElementChild.cloneNode(true))
  }

  addOtherCarPassenger(_event) {
    this.otherCarPassengerListTarget.append(this.passengerTemplateTarget.firstElementChild.cloneNode(true))
  }

  toggle(event) {
    let div = event.target.dataset.div
    if (event.srcElement.value === 'true') {
      this[div + 'Target'].classList.remove('cnd-hidden')
    } else {
      this[div + 'Target'].classList.add('cnd-hidden')
    }
  }

  toggleWhoseFault(event) {
    if (event.srcElement.value === 'third_party') {
      this.whoseFaultDetailsTarget.classList.remove('cnd-hidden')
    } else {
      this.whoseFaultDetailsTarget.classList.add('cnd-hidden')
    }
  }

  toggleInjuriesDetails(event) {
    let wrapper = event.target.closest('.injuryDetailsWrapper')
    let el = wrapper.getElementsByClassName('injuryDetails')[0]
    el.classList.toggle('cnd-hidden', event.srcElement.value !== 'true')
  }
}
