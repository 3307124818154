import { Controller } from 'stimulus'
import Dropzone from 'dropzone'
import DirectUploadUtils from '../../utils/direct_upload_utils'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
import { ACCEPTED_IMAGE_FILES } from '../../constants/uploads'

export default class extends Controller {
  dropzone
  photoInputName
  photoBlobInput
  uploadUrl
  directUploadSucceeded = []

  initialize() {
    Dropzone.autoDiscover = false

    this.photoInputName = this.element.dataset.photoInputName
    this.photoBlobInput = this.element.querySelector(`input[name="${this.photoInputName}"]`)
    this.uploadUrl = this.element.dataset.uploadUrl
    this.deleteUrl = this.element.dataset.deleteUrl
    this.element.querySelector('.dz-message').style.flexFlow = 'nowrap'

    if (!this.photoInputName || !this.uploadUrl) {
      this.element.classList.add('cnd-hidden')
    }

    if (!this.element.dropzone) {
      this.initializeDropzone()
    }
  }

  initializeDropzone() {
    let previewNode = this.element.querySelector('#preview-template')
    previewNode.id = ''
    let previewTemplate = previewNode.innerHTML
    previewNode.parentNode.removeChild(previewNode)

    const config = {
      acceptedFiles: ACCEPTED_IMAGE_FILES,
      autoProcessQueue: true,
      maxFiles: 1,
      maxFilesize: 15, // MB
      parallelUploads: 100,
      previewTemplate: previewTemplate,
      resizeWidth: 1400,
      thumbnailHeight: 64,
      thumbnailWidth: 64,
      url: this.uploadUrl,
    }

    this.dropzone = new Dropzone(this.element, config)
    this.dropzone._uploadData = this.handleUpload.bind(this)

    this.element.classList.add('dropzone')

    // Expose Dropzone events
    this.dropzone.on('addedfile', (file) => this.handleAddFile(file))
    this.dropzone.on('removedfile', (file) => this.handleRemoveFile(file))
    this.dropzone.on('error', (file) => {
      file.previewElement.querySelector('.dz-image img').src = this.element.querySelector('.dz-default-image').src
    })

    this.element.dispatchEvent(new Event('initializedFileUpload'))
  }

  handleUpload(files, dataBlocks) {
    dataBlocks.forEach((dataBlock, i) => {
      if (!dataBlock.data.name) {
        dataBlock.data.name = files[i].name
      }

      this.uploadFile(dataBlock.data, this.uploadUrl, files[i].previewElement)
        .then((blob) => {
          this.dropzone._finished(files, '', null)

          // Assign the signed id of the uploaded file to the blob input so that the containing form knows what
          // files were uploaded via the DirectUpload library.
          this.photoBlobInput.setAttribute('value', blob.signed_id)
          this.photoBlobInput.dispatchEvent(new Event('change'))
          this.directUploadSucceeded.push(files[i])

          if (this.getAddedFiles().length === this.directUploadSucceeded.length) {
            this.element.dispatchEvent(new Event('directUploadSuccess'))
          }
        })
        .catch((error) => {
          if (error.indexOf('401') !== -1) {
            location.reload()
          } else {
            console.error(error)
          }
        })
    })
  }

  uploadFile(file, uploadUrl, element) {
    const directUpload = new DirectUploadUtils(file, uploadUrl, element)
    return directUpload.upload()
  }

  handleRemoveFile() {
    if (this.photoBlobInput.value != '' && this.photoBlobInput.value != null) {
      axios
        .delete(this.deleteUrl, {
          responseType: 'json',
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
          },
          params: {
            blob_signed_id: this.photoBlobInput.value,
          },
        })
        .then(() => {
          this.element.dispatchEvent(new Event('removedfile'))
          this.resetDropzonePanel()
        })
        .catch((error) => {
          console.log(error)
          this.resetDropzonePanel()
        })
    } else {
      this.resetDropzonePanel()
    }
  }

  handleAddFile(file) {
    this.toggleUploadButtons()

    // Since we are using the DirectUpload library (via the Uploader utility) we don't know the status of the file
    // upload as that's been abstracted away. So we rely on the DirectUpload utility to send progress events to us via
    // its own custom "uploadProgress" event. In order to listen for "uploadProgress" events we bind an event listener
    // to the preview thumbnail div. This creates a 1-1 relationship between the file upload and our div so multiple
    // files can be uploaded at the same time, but each div will only listen to a single upload.
    file.previewElement.addEventListener('uploadProgress', (e) => {
      // DirectUpload doesn't let you cancel uploads, so hide the "remove" button
      if (file.previewElement.querySelector('.dz-remove')) {
        // file.previewElement.querySelector('.dz-remove').classList.add('cnd-hidden')
      }

      // add upload bar styling
      file.previewElement.classList.add('dz-processing')
      file.previewElement.querySelector('.dz-progress').classList.remove('cnd-hidden')
      this.dropzone.emit('uploadprogress', file, e.detail.percentComplete, e.detail.bytesSent)
    })

    this.element.dispatchEvent(new Event('addedfile'))
  }

  toggleUploadButtons() {
    // show/hide browse files text
    this.element.querySelector('.dz-message').classList.toggle('cnd-hidden', this.dropzone.files.length > 0)
  }

  resetDropzonePanel() {
    this.photoBlobInput.setAttribute('value', '')
    this.photoBlobInput.dispatchEvent(new Event('change'))
    this.toggleUploadButtons()
  }

  getAddedFiles() {
    return this.dropzone.getAcceptedFiles()
  }
}
