export default class SearchUtils {
  static DEFAULT_SEARCH_LOCATIONS = {
    AU: [
      { location: 'Sydney NSW 2000, Australia', latitude: -33.870846, longitude: 151.20733 },
      { location: 'Melbourne VIC 3004, Australia', latitude: -37.813611, longitude: 144.963056 },
      { location: 'Perth WA 6000, Australia', latitude: -31.9505, longitude: 115.8605 },
      { location: 'Brisbane QLD 4000, Australia', latitude: -27.469771, longitude: 153.025124 },
    ],
    US: [{ location: 'Boston, MA, USA', latitude: 42.360058, longitude: -71.058883 }],
    CA: [{ location: 'Toronto, ON, Canada', latitude: 43.653135, longitude: -79.383187 }],
  }

  static getDefaultLocations(countryCode) {
    return SearchUtils.DEFAULT_SEARCH_LOCATIONS[countryCode]
  }
}
