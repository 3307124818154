import { Controller } from 'stimulus'

import * as axios from 'axios'
import FlashMessageUtils from '../utils/flash_message_utils'

export default class extends Controller {
  static targets = ['input', 'submit', 'content', 'ineligible', 'instantKeys', 'keyHandover', 'guarantee']

  initialize() {
    this.eligibilityParams = {}
  }

  checkEligibility(event) {
    if (event instanceof Event) {
      event.preventDefault()
      this.eligibilityParams = {
        post_code: this.inputTarget.value,
      }
    } else {
      this.eligibilityParams = {
        post_code: event,
      }
    }

    axios
      .get('/target_suburbs', { params: this.eligibilityParams })
      .then((res) => {
        let data = res.data
        this.showTargets(this.ineligibleTargets, data.target_status == 'out_of_target')
        this.showTargets(this.instantKeysTargets, data.target_status == 'key_handover_or_instant_keys')
        this.showTargets(this.keyHandoverTargets, data.target_status == 'key_handover')
        this.showTargets(this.guaranteeTargets, data.guarantee)
        this.showContent()
      })
      .catch((error) => {
        if (this.hasInputTarget) {
          let data = error.response.data
          this.contentTarget.classList.add('cnd-hidden')
        }
      })
      .finally(() => {
        if (this.hasSubmitTarget) {
          this.submitTarget.removeAttribute('disabled')
          this.submitTarget.value = 'Check'
        }
      })
  }

  showContent() {
    this.contentTarget.classList.remove('cnd-hidden')
    document.querySelector('.js-member-post-code').value = this.inputTarget.value
  }

  showTargets(targets, condition) {
    if (condition) {
      targets.forEach((target) => {
        target.classList.remove('cnd-hidden')
      })
    } else {
      targets.forEach((target) => {
        target.classList.add('cnd-hidden')
      })
    }
  }
}
