import { Controller } from 'stimulus'

import * as axios from 'axios'

import FirebaseAuthUtils from '../utils/firebase_auth_utils'
import AxiosUtils from '../utils/axios_utils'
import LoaderUtils from '../utils/loader_utils'

export default class extends Controller {
  static targets = ['ssoContainer', 'ssoErrors', 'owner']

  initialize() {
    if (this.hasSsoContainerTarget) {
      this.initializeSSO()
    }
  }

  initializeSSO() {
    FirebaseAuthUtils.initialiseFirebaseUI({
      el: this.ssoContainerTarget,
      onSuccess: this.successCallback,
    })
  }

  successCallback = (authResult, redirectUrl) => {
    LoaderUtils.showPageLoader()

    const handleTurboAuthError = this.handleTurboAuthError.bind(this)
    var url = new URL(window.location.href)

    var signupPath
    if (this.hasOwnerTarget) {
      signupPath = '/start-sharing'
    } else {
      signupPath = '/join'
    }

    authResult.user.getIdToken().then(function (idToken) {
      const params = {
        email: authResult.user.email || authResult.user.providerData.email,
        firebase_login_token: idToken,
        fingerprint: window.cndBrowserFingerprint,
        referral_code: url.searchParams.get('referral_code'),
        promo_code: url.searchParams.get('promo_code'),
      }

      axios({
        method: 'post',
        url: signupPath,
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
        },
        params: params,
      })
        .then(function (res) {
          window.location.replace(res.data.path)
        })
        .catch(function (error) {
          handleTurboAuthError(error)
        })
    })

    // Return false to prevent automatic redirect to signInSuccessUrl
    // (use the redirect path from the api response instead)
    false
  }

  handleTurboAuthError(error) {
    const message = error.response.data.message
    if (message) {
      this.ssoErrorsTarget.innerText = message
    }
    this.initializeSSO()
  }
}
