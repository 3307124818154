import { Controller } from 'stimulus'

import DateUtils from '../../utils/date_utils'
import MobiscrollUtils from '../../utils/mobiscroll_utils'
import TargetUtils from '../../utils/target_utils'

const moment = require('moment')

export default class extends Controller {
  static targets = [
    'onboarding',
    'idealParkText',
    'parkingTypeSelect',
    'parkingTypeStreetValue',
    'parkingTypeText',
    'parkingInstructionsInput',
    'parkingInstructionsExample',
    'parkingInstructionsText',
    'latitude',
    'longitude',
    'startTime',
    'startTimeOffset',
    'startTimePicker',
    'minimumPeriod',
    'cancellationWarning',
    'submit',
    'dedicatedInstructions',
    'streetInstructions',
    'parkingPermitted',
    'parkingLocked',
    'permitArea',
    'accessInstruction',
    'goodParkingPlace',
    'ngParkingPlace',
    'permitAreaInput',
    'accessInstructionInput',
    'carManualEnabled',
  ]

  isOnboarding

  initialize() {
    this.targetUtils = new TargetUtils(this)
    this.carManualEnabled = this.carManualEnabledTarget.value === 'true'

    this.toggleParkingType()

    if (this.carManualEnabled) {
      this.toggleParkingPermitted()
      this.toggleParkingLocked()
    }

    this.setLocationChangeTime()
    this.isOnboarding = this.hasOnboardingTarget && this.onboardingTarget.value === 'true'
    this.toggleSubmit = this.toggleSubmit.bind(this)
    this.element.addEventListener('click', this.toggleSubmit)
  }

  toggleParkingType() {
    if (this.carManualEnabled) {
      this.toggleInstructions()
    }

    this.toggleParkingText()
  }

  toggleInstructions() {
    if (!this.hasParkingTypeSelectTarget) {
      return
    }

    if (this.parkingTypeSelectTarget.value === this.parkingTypeStreetValueTarget.value) {
      this.targetUtils.hide('dedicatedInstructionsTarget')
      this.targetUtils.show('streetInstructionsTarget')
    } else {
      this.targetUtils.hide('streetInstructionsTarget')
      this.targetUtils.show('dedicatedInstructionsTarget')
    }
  }

  toggleParkingText() {
    if (!this.hasParkingTypeSelectTarget) {
      return
    }

    if (this.parkingTypeSelectTarget.value === this.parkingTypeStreetValueTarget.value) {
      this.parkingTypeTextTarget.innerHTML =
        'Be sure that the ‘Ideal parking address’ you provide has no parking restrictions.'
      this.idealParkTextTarget.innerHTML =
        'Borrowers will be asked to return the car as close to this spot as possible ' +
        'but can go as far as 500 metres away if parking is tight.'

      if (this.carManualEnabled) {
        this.goodParkingPlaceTarget.placeholder = 'e.g. The north side of Victoria St'
        this.ngParkingPlaceTarget.placeholder = 'e.g. Avoid timed parking on Edward St'

        // Enable/Disable input fields
        this.parkingPermittedTargets.forEach((target) => (target.disabled = false))
        this.parkingLockedTargets.forEach((target) => (target.disabled = true))
        this.permitAreaInputTarget.disabled = false
        this.accessInstructionInputTarget.disabled = true
      } else {
        this.parkingInstructionsInputTarget.placeholder =
          'Where are the best streets to find parking? Are there any parking restrictions or areas to avoid?'
        this.parkingInstructionsTextTarget.innerHTML = 'avoid fines.'
        this.parkingInstructionsExampleTarget.innerHTML =
          `You must park in areas labelled "Zone 42". It's usually easy to find a park on Carrington Ave. ` +
          `Avoid timed parking on Alviston St.`
      }
    } else {
      this.parkingTypeTextTarget.innerHTML =
        'Be sure that Borrowers will have access to the car or that you can secure the lockbox in a publicly ' +
        'accessible location.'
      this.idealParkTextTarget.innerHTML = 'Borrowers will be asked to return the car to this exact spot'

      if (this.carManualEnabled) {
        this.goodParkingPlaceTarget.placeholder = 'e.g. Reverse into parking spot 501'
        this.ngParkingPlaceTarget.placeholder = "e.g. Don't park in guest parking"

        // Enable/Disable input fields
        this.parkingPermittedTargets.forEach((target) => (target.disabled = true))
        this.parkingLockedTargets.forEach((target) => (target.disabled = false))
        this.permitAreaInputTarget.disabled = true
        this.accessInstructionInputTarget.disabled = false
      } else {
        this.parkingInstructionsInputTarget.placeholder = 'Is there a code to get into the garage?'
        this.parkingInstructionsTextTarget.innerHTML = 'access your parking spot.'
        this.parkingInstructionsExampleTarget.innerHTML =
          'Only park in the space marked "unit 14". Make sure the car is well within the marked lines.'
      }
    }
  }

  setLocationChangeTime() {
    if (!this.isOnboarding) {
      MobiscrollUtils.newCalendarInstance(this.startTimePickerTarget, {
        headerText: 'Start {value}',
        defaultValue: DateUtils.defaultStartMoment().toDate(),
        onSet: (e, inst) => {
          const startTime = moment(inst.getVal())

          let offsetInMinutes = this.startTimeOffsetTarget.value
            ? this.startTimeOffsetTarget.value / 60
            : moment().utcOffset()

          this.startTimeTarget.value = startTime.utcOffset(offsetInMinutes, true).format()
        },
        dateFormat: 'd M yyy',
      })
    }
  }

  toggleParkingPermitted() {
    const parkingPermitted = this.parkingPermittedTargets.filter((e) => e.checked)[0]

    if (parkingPermitted.value == 'true') {
      this.targetUtils.show('permitAreaTarget')
    } else {
      this.targetUtils.hide('permitAreaTarget')
    }
  }

  toggleParkingLocked() {
    const parkingLocked = this.parkingLockedTargets.filter((e) => e.checked)[0]

    if (parkingLocked.value == 'true') {
      this.targetUtils.show('accessInstructionTarget')
    } else {
      this.targetUtils.hide('accessInstructionTarget')
    }
  }

  toggleSubmit() {
    if (!this.hasParkingTypeSelectTarget) {
      return
    }

    if (!this.isOnboarding) {
      let disabled = false

      if (!this.parkingTypeSelectTarget.value) {
        disabled = true
      }

      if (!this.latitudeTarget.value || !this.longitudeTarget.value) {
        disabled = true
      }

      if (!this.startTimePickerTarget.value) {
        disabled = true
      }

      if (!this.minimumPeriodTarget.checked || !this.cancellationWarningTarget.checked) {
        disabled = true
      }

      if (disabled) {
        this.submitTarget.setAttribute('disabled', true)
      } else {
        this.submitTarget.removeAttribute('disabled')
      }
    }
  }
}
