import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['carsList', 'caretIcon']

  toggleOfflineCars() {
    this.carsListTarget.classList.toggle('cnd-hidden')
    this.caretIconTarget.classList.toggle('fa-caret-up')
    this.caretIconTarget.classList.toggle('fa-caret-down')
  }
}
