import { Controller } from 'stimulus'

import * as axios from 'axios'
import qs from 'qs'

// Utils
import DateUtils from '../../utils/date_utils'
import EventUtils from '../../utils/event_utils'
import MobiscrollUtils from '../../utils/mobiscroll_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'
import TargetUtils from '../../utils/target_utils'
import AxiosUtils from '../../utils/axios_utils'
import FormatUtils from '../../utils/format_utils'

export default class extends Controller {
  static targets = [
    'map',
    'overlay',
    'carsList',
    'carsListOverlay',
    'lockboxCode',
    'lockboxModal',
    'locationModal',
    'blockoutModal',
    'blockoutLabel',
    'mobiscroll',
    'customBlockoutModal',
    'fallbackLockboxContent',
    'fallbackLockboxCode',
  ]

  count
  vehicleId
  bookingUrl
  currentReservation
  targetUtils

  initialize() {
    this.count = parseInt(this.element.getAttribute('data-vehicle-count'))
    this.vehicleId = parseInt(this.element.getAttribute('data-vehicle-id'))
    this.currentReservation = this.element.getAttribute('data-current-reservation')
    this.bookingUrl = this.hasBlockoutLabelTarget ? this.blockoutLabelTarget.getAttribute('data-booking-url') : ''
    this.targetUtils = new TargetUtils(this)

    this.hideAll()
  }

  _flashMessage(error) {
    if (!(error && error.response && error.response.data)) return
    FlashMessageUtils.showWarning(error.response.data.message)
  }

  hideOverlay() {
    if (this.hasOverlayTarget) {
      this.targetUtils.hide('overlayTarget')
    }
  }

  hideCarsListOverlay() {
    this.targetUtils.hide('carsListOverlayTarget')
  }

  hideCarsList() {
    this.targetUtils.hide('carsListTarget')
  }

  hideLockboxModal() {
    if (this.hasLockboxModalTarget) {
      this.targetUtils.hide('lockboxModalTarget')
    }
  }

  hideLocationModal() {
    if (this.hasLocationModalTarget) {
      this.targetUtils.hide('locationModalTarget')
    }
  }

  hideBlockoutModal() {
    if (this.hasBlockoutModalTarget) {
      this.targetUtils.hide('blockoutModalTarget')
    }
  }

  hideCustomBlockoutModal() {
    if (this.hasCustomBlockoutModalTarget) {
      this.targetUtils.hide('customBlockoutModalTarget')
    }
  }

  hideAll() {
    this.hideOverlay()
    this.hideLockboxModal()
    this.hideLocationModal()
    this.hideBlockoutModal()
    this.hideCustomBlockoutModal()

    if (this.hasCarsListTarget) {
      this.hideCarsList()
      this.hideCarsListOverlay()
    }

    if (this.blockoutRangeInst && this.blockoutRangeInst.isVisible()) {
      this.blockoutRangeInst.cancel()
    }
  }

  viewProfile(e) {
    window.open(`/trips/new?vehicle_id=${this.vehicleId}`, '_blank')
  }

  toggleOverlay() {
    this.targetUtils.toggle('overlayTarget')
  }

  toggleCarsListOverlay() {
    this.targetUtils.toggle('carsListOverlayTarget')
  }

  toggleCars() {
    if (this.count <= 1) return
    this.toggleCarsListOverlay()
    this.targetUtils.toggle('carsListTarget')
  }

  getLockboxCode() {
    this.toggleOverlay()
    this.targetUtils.toggle('lockboxModalTarget')

    const THIS = this
    axios
      .get('/lockbox', {
        params: {
          vehicle_id: this.vehicleId,
        },
      })
      .then(function (response) {
        THIS.lockboxCodeTarget.innerHTML = THIS.formatLockboxCode(response.data[0])

        if (response.data[0]['provider'] == 'sentrilock' && response.data.length > 1) {
          THIS.fallbackLockboxContentTarget.classList.remove('cnd-hidden')
          THIS.fallbackLockboxCodeTarget.innerHTML = THIS.formatLockboxCode(response.data[1])
        } else {
          THIS.fallbackLockboxContentTarget.classList.add('cnd-hidden')
        }
      })
      .catch(function (error) {
        THIS._flashMessage(error)
      })
  }

  lockCar() {
    this.controlDoors('lock')
  }

  unlockCar() {
    this.controlDoors('unlock')
  }

  controlDoors(controlType) {
    const THIS = this
    const data = {
      car_id: this.vehicleId,
      control_type: controlType,
    }
    FlashMessageUtils.showSuccess('Sending request..')

    axios({
      method: 'post',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: qs.stringify(data),
      url: '/otoplug/doors',
    })
      .then((res) => {
        FlashMessageUtils.showSuccess(res.data.message)
      })
      .catch((error) => {
        THIS._flashMessage(error)
      })
  }

  doneLockbox(e) {
    e.preventDefault()
    this.hideAll()
  }

  doneLocation(e) {
    e.preventDefault()
    this.hideAll()
  }

  getLocation() {
    if (this.currentReservation.length === 0) {
      this.requestLocation()
    } else {
      FlashMessageUtils.showWarning(
        'Your car is out working for you! ' +
          "You'll be able to see its location here when the Borrower's booking is finished. " +
          "See the calendar below for details of your car's bookings."
      )
    }
  }

  requestLocation() {
    this.targetUtils.show('overlayTarget')
    this.targetUtils.show('locationModalTarget')

    const THIS = this
    axios
      .get('/vehicle/location', {
        params: {
          vehicle_id: this.vehicleId,
        },
      })
      .then(function (response) {
        let data = response.data
        EventUtils.dispatch(THIS.mapTarget, 'locationUpdated', {
          latitude: data.lat ? parseFloat(data.lat) : 0,
          longitude: data.long ? parseFloat(data.long) : 0,
        })
      })
      .catch(function (error) {
        THIS._flashMessage(error)
      })
  }

  toggleBlockout(e) {
    e.preventDefault()
    this.toggleOverlay()
    this.targetUtils.toggle('blockoutModalTarget')
  }

  blockoutOneHour() {
    this.addBlockout(DateUtils.newDatetimeFormatted(), DateUtils.newDatetimeFormatted({ hoursToAdd: 1 }))
  }

  blockoutThreeHours() {
    this.addBlockout(DateUtils.newDatetimeFormatted(), DateUtils.newDatetimeFormatted({ hoursToAdd: 3 }))
  }

  blockoutOneDay() {
    this.addBlockout(DateUtils.newDatetimeFormatted(), DateUtils.newDatetimeFormatted({ hoursToAdd: 24 }))
  }

  blockoutCustom() {
    const THIS = this
    this.hideBlockoutModal()
    this.targetUtils.show('customBlockoutModalTarget')
    let currentDatetime = DateUtils.newDatetimeNative()
    this.blockoutRangeInst = MobiscrollUtils.newRangeInstance(this.mobiscrollTarget, {
      fromText: 'Blockout start',
      toText: 'Blockout end',
      min: currentDatetime,
      defaultValue: [currentDatetime, currentDatetime],
      onSet: function (e, inst) {
        let val = inst.getVal()
        THIS.addBlockout(DateUtils.format(val[0]), DateUtils.format(val[1]))
        inst.destroy()
        THIS.hideAll()
      },
      onCancel: function (e, inst) {
        inst.destroy()
        THIS.hideAll()
      },
    })
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="${name}"]`)
    return element.getAttribute('content')
  }

  addBlockout(start, end) {
    this.hideAll()

    const THIS = this
    axios({
      method: 'post',
      url: '/calendars/add',
      data: {
        vehicle_id: this.vehicleId,
        start_time: start,
        end_time: end,
        recurring: false,
      },
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': this.getMetaValue('csrf-token'),
      },
    })
      .then(function () {
        EventUtils.dispatch(window, 'CND:UpdateCalendarData')
        FlashMessageUtils.showSuccess('Your car has been blocked out successfully.')
      })
      .catch(function (error) {
        THIS._flashMessage(error)
      })
  }

  formatLockboxCode(data) {
    if (data.provider == 'sentrilock') {
      return data.code
    } else {
      return FormatUtils.numberWithSpaces(data.code, 3)
    }
  }
}
