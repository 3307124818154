import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['checkboxGroup']

  initialize() {
    this.allCheckboxes.forEach((checkbox) => {
      checkbox.addEventListener('change', (event) => {
        const checkedState = event.currentTarget.checked
        this.allCheckboxes.forEach((checkbox) => {
          checkbox.checked = false
        })
        event.currentTarget.checked = checkedState
      })
    })
  }

  get allCheckboxes() {
    return this.checkboxGroupTarget.querySelectorAll("input[type='checkbox']")
  }
}
