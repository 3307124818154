import { Controller } from 'stimulus'
import * as axios from 'axios'

export default class extends Controller {
  static targets = [
    'cancelBtn',
    'bookingFee',
    'costDistance',
    'costTime',
    'distanceInput',
    'distanceButton',
    'estimatedTotal',
    'startTime',
    'endTime',
    'vehicleId',
    'dclText',
    'dclCost',
  ]

  id

  initialize() {
    if (this.hasVehicleIdTarget) {
      if (this.vehicleIdTarget) this.id = parseInt(this.vehicleIdTarget.innerText)
      this.calculateTripCost()
    }
  }

  showDistanceInput() {
    const editableField = this.distanceInputTarget
    const button = this.distanceButtonTarget
    const costDistance = this.costDistanceTarget
    const estimatedTotal = this.estimatedTotalTarget
    editableField.classList.remove('cnd-hidden')
    editableField.focus()
    button.classList.add('cnd-hidden')

    editableField.addEventListener('blur', () => {
      button.innerText = editableField.value && !isNaN(editableField.value) ? editableField.value : 0

      editableField.classList.add('cnd-hidden')
      button.classList.remove('cnd-hidden')

      button.innerText = editableField.value || 0
      editableField.classList.add('cnd-hidden')
      button.classList.remove('cnd-hidden')

      estimatedTotal.innerText = `$${(
        parseFloat(this.costTimeTarget.innerText.substr(1)) + parseFloat(costDistance.innerText.substr(1))
      ).toFixed(2)}`
    })

    editableField.addEventListener('keyup', (e) => {
      if (editableField.value.length > 4) {
        editableField.classList.add('cnd-text-red')
        return editableField.value.slice(0, 5)
      }
      if (editableField.classList.contains('cnd-text-red')) {
        editableField.classList.remove('cnd-text-red')
        editableField.classList.add('cnd-text-green')
      } else {
        editableField.classList.remove('cnd-text-red')
      }

      const key = e.which || e.keyCode

      costDistance.innerText = !isNaN(editableField.value) ? `$${(editableField.value * 0.33).toFixed(2)}` : 0
      estimatedTotal.innerText = `$${(
        parseFloat(this.costTimeTarget.innerText.substr(1)) + parseFloat(costDistance.innerText.substr(1))
      ).toFixed(2)}`

      // if key is enter or escape
      if (key === 13 || key === 27) {
        button.innerText = editableField.value || 0
        editableField.classList.add('cnd-hidden')
        button.classList.remove('cnd-hidden')
      }
    })
  }

  calculateTripCost() {
    axios
      .get('/cost-estimates/display', {
        params: {
          start_time_iso: this.startTimeTarget.innerText,
          end_time_iso: this.endTimeTarget.innerText,
          vehicle_id: this.id,
        },
      })
      .then((res) => {
        this.dclTextTarget.innerText = res.data.dcl_text
        this.dclCostTarget.innerText = res.data.dcl

        const dcl = isNaN(parseFloat(this.dclCostTarget.innerText))
          ? 0
          : parseFloat(this.dclCostTarget.innerText.substr(1))
        const bookingFee = isNaN(this.bookingFeeTarget.innerText.trim())
          ? 0
          : parseFloat(this.bookingFeeTarget.innerText.substr(1))
        const estimatedTotal = parseFloat(this.estimatedTotalTarget.innerText.substr(1))

        this.estimatedTotalTarget.innerText = `$${parseFloat(dcl + bookingFee + estimatedTotal).toFixed(2)}`
      })
  }
}
