import { Controller } from 'stimulus'
import { ACCEPTED_IMAGE_FILES } from '../../../constants/uploads'

export default class extends Controller {
  static targets = ['issueDescription', 'submitButton', 'dropzone']

  initialize() {
    this.isFileUploaded = false
    if (this.dropzoneTarget.cnd) {
      this.setDropzoneConfig()
    } else {
      this.dropzoneTarget.addEventListener('initializedFileUpload', () => this.setDropzoneConfig())
    }

    // Needed because the change event on the form might not trigger if users don't click outside the input
    document.querySelectorAll('input[type="text"]:required, textarea:required').forEach((el) => {
      el.addEventListener('keyup', () => this.enableSubmit())
    })
  }

  setDropzoneConfig() {
    this.dropzoneTarget.cnd.setOptions({ acceptedFiles: ACCEPTED_IMAGE_FILES })

    this.dropzoneTarget.addEventListener('addedfile', () => {
      this.submitButtonTarget.disabled = true
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
    })

    this.dropzoneTarget.addEventListener('directUploadSuccess', () => {
      this.isFileUploaded = true
      this.enableSubmit()
    })
  }

  enableSubmit() {
    if (this.issueDescriptionTarget.value != '' && this.isFileUploaded) {
      window.onbeforeunload = null
      this.submitButtonTarget.disabled = false
    } else {
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
      this.submitButtonTarget.disabled = true
    }
  }
}
