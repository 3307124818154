import { Controller } from 'stimulus'

import TargetUtils from '../../utils/target_utils'

export default class extends Controller {
  static targets = [
    'parkingTypeSelect',
    'dedicatedInstructions',
    'streetInstructions',
    'parkingPermitted',
    'parkingLocked',
    'permitArea',
    'accessInstruction',
    'goodParkingPlace',
    'ngParkingPlace',
    'permitAreaInput',
    'accessInstructionInput',
  ]

  initialize() {
    this.targetUtils = new TargetUtils(this)
    this.toggleParkingType()
    this.toggleParkingPermitted()
    this.toggleParkingLocked()
  }

  toggleParkingType() {
    this.toggleInstructions()
    this.toggleParkingText()
  }

  toggleInstructions() {
    if (this.parkingTypeSelectTarget.value === '0') {
      this.targetUtils.hide('dedicatedInstructionsTarget')
      this.targetUtils.show('streetInstructionsTarget')
    } else {
      this.targetUtils.hide('streetInstructionsTarget')
      this.targetUtils.show('dedicatedInstructionsTarget')
    }
  }

  toggleParkingText() {
    if (this.parkingTypeSelectTarget.value === '0') {
      this.goodParkingPlaceTarget.placeholder = 'e.g. The north side of Victoria St'
      this.ngParkingPlaceTarget.placeholder = 'e.g. Avoid timed parking on Edward St'

      // Enable/Disable input fields
      this.parkingPermittedTargets.forEach((target) => (target.disabled = false))
      this.parkingLockedTargets.forEach((target) => (target.disabled = true))
      this.permitAreaInputTarget.disabled = false
      this.accessInstructionInputTarget.disabled = true
    } else {
      this.goodParkingPlaceTarget.placeholder = 'e.g. Reverse into parking spot 501'
      this.ngParkingPlaceTarget.placeholder = "e.g. Don't park in guest parking"

      // Enable/Disable input fields
      this.parkingPermittedTargets.forEach((target) => (target.disabled = true))
      this.parkingLockedTargets.forEach((target) => (target.disabled = false))
      this.permitAreaInputTarget.disabled = true
      this.accessInstructionInputTarget.disabled = false
    }
  }

  toggleParkingPermitted() {
    const parkingPermitted = this.parkingPermittedTargets.filter((e) => e.checked)[0]

    if (parkingPermitted.value == 'true') {
      this.targetUtils.show('permitAreaTarget')
    } else {
      this.targetUtils.hide('permitAreaTarget')
    }
  }

  toggleParkingLocked() {
    const parkingLocked = this.parkingLockedTargets.filter((e) => e.checked)[0]

    if (parkingLocked.value == 'true') {
      this.targetUtils.show('accessInstructionTarget')
    } else {
      this.targetUtils.hide('accessInstructionTarget')
    }
  }
}
