import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['iframeMessage', 'message']

  initialize() {
    const isIframeContext = window.self !== window.top

    if (isIframeContext) {
      this.messageTarget.classList.add('cnd-hidden')
      this.iframeMessageTarget.classList.remove('cnd-hidden')
    } else {
      this.messageTarget.classList.remove('cnd-hidden')
      this.iframeMessageTarget.classList.add('cnd-hidden')
    }
  }
}
