const FaceTecStrings = {
  FaceTec_accessibility_cancel_button: 'Cancel',

  FaceTec_feedback_center_face: 'Centre your face',
  FaceTec_feedback_face_not_found: 'Frame your face',
  FaceTec_feedback_move_phone_away: 'Move away',
  FaceTec_feedback_move_away_web: 'Move away',
  FaceTec_feedback_move_phone_closer: 'Move closer',
  FaceTec_feedback_move_phone_to_eye_level: 'Raise camera up to eye level',
  FaceTec_feedback_move_to_eye_level_web: 'Raise camera up to eye level',
  FaceTec_feedback_face_not_looking_straight_ahead: 'Look at the camera',
  FaceTec_feedback_face_not_upright: 'Hold your head straight',
  FaceTec_feedback_face_not_upright_mobile: 'Look at the camera',
  FaceTec_feedback_hold_steady: 'Hold steady',
  FaceTec_feedback_move_web_closer: 'Move closer',
  FaceTec_feedback_move_web_even_closer: 'Even closer',
  FaceTec_feedback_use_even_lighting: 'Light your face more evenly',

  FaceTec_instructions_header_ready_desktop: 'Get ready for your video selfie',
  FaceTec_instructions_header_ready_1_mobile: 'Get ready for',
  FaceTec_instructions_header_ready_2_mobile: 'your video selfie',
  FaceTec_instructions_message_ready_desktop: "You'll need your photo ID next, have it handy and don't leave the app.",
  FaceTec_instructions_message_ready_1_mobile: "You'll need your photo ID next,",
  FaceTec_instructions_message_ready_2_mobile: "have it handy and don't leave the app.",

  FaceTec_action_im_ready: "I'm ready",
  FaceTec_presession_frame_your_face: 'Frame your face',
  FaceTec_presession_look_straight_ahead: 'Look at the camera',
  FaceTec_presession_hold_steady3: 'Hold steady for: 3',
  FaceTec_presession_hold_steady2: 'Hold steady for: 2',
  FaceTec_presession_hold_steady1: 'Hold steady for: 1',
  FaceTec_presession_eyes_straight_ahead: 'Look at the camera',
  FaceTec_presession_remove_dark_glasses: 'Remove dark glasses',
  FaceTec_presession_neutral_expression: 'Keep a neutral expression, no smiling',
  FaceTec_presession_conditions_too_bright: 'Conditions too bright',
  FaceTec_presession_brighten_your_environment: 'Brighten your environment',

  FaceTec_result_facescan_upload_message: 'Uploading your encrypted selfie',
  FaceTec_result_idscan_upload_message: 'Uploading your encrypted ID scan',

  FaceTec_retry_header: "Let's try that again",
  FaceTec_retry_subheader_message:
    'But first, take a look at your photo vs the sample photo and correct your environment.',
  FaceTec_retry_your_image_label: 'Your selfie',
  FaceTec_retry_ideal_image_label: 'Ideal pose',
  FaceTec_retry_instruction_message_1: 'Keep your expression neutral - no smiling',
  FaceTec_retry_instruction_message_2: 'Needs to be well-lit with no glare',
  FaceTec_retry_instruction_message_3: 'Too blurry - clean camera and keep face in focus',
  FaceTec_action_try_again: 'Try again',

  FaceTec_camera_feed_issue_header: '<b>Issue encrypting camera feed</b>',
  FaceTec_camera_feed_issue_subheader_message: 'This system cannot be verified due to the following:',
  FaceTec_camera_feed_issue_table_header_1: 'Possible issue',
  FaceTec_camera_feed_issue_table_header_2: 'Fix',
  FaceTec_camera_feed_issue_table_row_1_cell_1_firefox_permissions_error:
    'Camera permissions not remembered in Firefox.',
  FaceTec_camera_feed_issue_table_row_1_cell_2_firefox_permissions_error: 'Check remember permissions.',
  FaceTec_camera_feed_issue_table_row_1_cell_1: 'Camera already in use by another app.',
  FaceTec_camera_feed_issue_table_row_1_cell_2: 'Close the other app.',
  FaceTec_camera_feed_issue_table_row_2_cell_1: 'A 3rd-party app is modifying the video.',
  FaceTec_camera_feed_issue_table_row_2_cell_2: 'Close / uninstall the other app.',
  FaceTec_camera_feed_issue_table_row_3_cell_1: 'Hardware not capable of being secured.',
  FaceTec_camera_feed_issue_table_row_3_cell_2: 'Use a different device.',
  FaceTec_camera_feed_issue_subtable_message:
    'This app blocks suspicious webcam configurations. <a href="https://livenesscheckhelp.com/" target="_blank" style="text-decoration:underline; pointer-events:all;">Learn More Here</a>.',
  FaceTec_camera_feed_issue_action: 'Try again anyway',
  FaceTec_camera_feed_issue_action_firefox_permissions_error: 'Ok',

  FaceTec_camera_permission_header: 'Enable camera',
  FaceTec_camera_permission_message: 'Your camera permissions are disabled. Tap below to edit your settings.',
  FaceTec_action_ok: 'Ok',

  FaceTec_enter_fullscreen_header: 'Full screen selfie mode',
  FaceTec_enter_fullscreen_message: 'Before we begin, please click the button below to open full screen mode',
  FaceTec_enter_fullscreen_action: 'Open full screen',

  FaceTec_initializing_camera: 'Encrypting camera feed...',
  FaceTec_initializing_camera_still_loading: 'Encrypting camera feed...',

  FaceTec_idscan_type_selection_header: 'Please select your<br/>photo ID type',
  FaceTec_action_continue: 'Continue',
  FaceTec_idscan_capture_id_front_instruction_message: 'Show front of driver licence',
  FaceTec_idscan_capture_id_back_instruction_message: 'Show back of driver licence',
  FaceTec_action_take_photo: 'Take photo',
  FaceTec_idscan_review_id_card_front_instruction_message: 'Are all four corners and text visible?',
  FaceTec_idscan_review_id_card_back_instruction_message: 'Are all four corners and text visible?',
  FaceTec_action_accept_photo: 'Accept',
  FaceTec_action_retake_photo: 'Retake',
  FaceTec_idscan_ocr_confirmation_main_header: 'Review and confirm',
  FaceTec_action_confirm: 'Confirm info',

  FaceTec_result_idscan_unsuccess_message: 'Your ID photo did not match your selfie',

  FaceTec_result_idscan_success_front_side: 'Your ID photo matched your selfie',
  FaceTec_result_idscan_success_front_side_with_back_side_next: 'Your ID photo matched your selfie',
  FaceTec_result_idscan_success_back_side: 'Success',
  FaceTec_result_idscan_retry_card_not_fully_visible: 'ID photo not fully visible',
  FaceTec_result_idscan_retry_ocr_results_not_good_enough: 'ID photo not readable\r\nPlease try again',

  FaceTec_result_success_message: 'Success',
}

export default FaceTecStrings
