import Config from './config'

let latestNetworkRequest

export default class NetworkingHelpers {
  static getSessionToken() {
    return new Promise((resolve, reject) => {
      latestNetworkRequest = new XMLHttpRequest()
      latestNetworkRequest.open('GET', `${Config.BaseURL}/session-token`)
      latestNetworkRequest.setRequestHeader('X-Device-Key', Config.DeviceKeyIdentifier)
      latestNetworkRequest.setRequestHeader('X-User-Agent', FaceTecSDK.createFaceTecAPIUserAgentString(''))
      latestNetworkRequest.onreadystatechange = function () {
        if (this.readyState === XMLHttpRequest.DONE) {
          let sessionToken = ''
          try {
            // Attempt to get the sessionToken from the response object.
            sessionToken = JSON.parse(this.responseText).sessionToken
            // Something went wrong in parsing the response. Return an error.
            if (typeof sessionToken !== 'string') {
              reject()
              return
            }
          } catch (_a) {
            // Something went wrong in parsing the response. Return an error.
            reject()
            return
          }
          resolve(sessionToken)
        }
      }
      latestNetworkRequest.onerror = () => reject()
      latestNetworkRequest.send()
    })
  }

  static getEnrollmentResponseFromZoomServer(sessionResult, faceScanResultCallback) {
    return new Promise((resolve, reject) => {
      const parameters = {
        sessionId: sessionResult.sessionId,
        faceScan: sessionResult.faceScan,
        externalDatabaseRefID: Config.userId,
        lowQualityAuditTrailImage: sessionResult.lowQualityAuditTrail[0],
        auditTrailImage: sessionResult.auditTrail[0],
      }
      //
      // Part 5:  Make the Networking Call to Your Servers.  Below is just example code, you are free to customize based on how your own API works.
      //
      latestNetworkRequest = new XMLHttpRequest()
      latestNetworkRequest.open('POST', `${Config.BaseURL}/enrollment-3d`)
      latestNetworkRequest.setRequestHeader('Content-Type', 'application/json')
      // TODO: Make this prettier and more consolidated and perhaps less things to do here.
      latestNetworkRequest.setRequestHeader('X-Device-Key', Config.DeviceKeyIdentifier)
      latestNetworkRequest.setRequestHeader(
        'X-User-Agent',
        FaceTecSDK.createFaceTecAPIUserAgentString(sessionResult.sessionId)
      )
      latestNetworkRequest.onreadystatechange = () => {
        //
        // Part 6:  In our Sample, we evaluate a boolean response and treat true as success, false as 'User Needs to Retry',
        // and handle all other non-nominal responses by cancelling out.  You may have different paradigms in your own API and are free to customize based on these.
        //
        if (latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
          try {
            resolve(JSON.parse(latestNetworkRequest.responseText))
            // if (responseJSON.success === true) {
            //   // CASE:  Success!  The Enrollment was performed and the User Proved Liveness.
            //   // Demonstrates dynamically setting the Success Screen Message.
            //   FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage('Liveness\r\nConfirmed');
            //   faceScanResultCallback.succeed();
            // } else if (responseJSON.success === false) {
            //   // CASE:  In our Sample code, 'success' being present and false means that the User Needs to Retry.
            //   // Real Users will likely succeed on subsequent attempts after following on-screen guidance.
            //   // Attackers/Fraudsters will continue to get rejected.
            //   console.log('User needs to retry, invoking retry.');
            //   var retryScreenType = responseJSON.retryScreenEnumInt === 1
            //     ? FaceTecSDK.FaceTecRetryScreen.ShowCameraFeedIssueScreenIfRejected
            //     : FaceTecSDK.FaceTecRetryScreen.ShowStandardRetryScreenIfRejected;
            //   faceScanResultCallback.retry(retryScreenType);
            // }
            // else {
            //   // CASE:  UNEXPECTED response from API.  Our Sample Code keys of a success boolean on the root of the JSON object --> You define your own API contracts with yourself and may choose to do something different here based on the error.
            //   console.log('Unexpected API response, cancelling out.');
            //   faceScanResultCallback.cancel();
            // }
          } catch (_a) {
            // CASE:  Parsing the response into JSON failed --> You define your own API contracts with yourself and may choose to do something different here based on the error.  Solid server-side code should ensure you don't get to this case.
            console.log('Exception while handling API response, cancelling out.')
            faceScanResultCallback.cancel()
          }
        }
      }

      latestNetworkRequest.onerror = function () {
        // CASE:  Network Request itself is erroring --> You define your own API contracts with yourself and may choose to do something different here based on the error.
        console.log('XHR error, cancelling.')
        faceScanResultCallback.cancel()
      }
      //
      // Part 7:  Demonstrates updating the Progress Bar based on the progress event.
      //
      latestNetworkRequest.upload.onprogress = function name(event) {
        var progress = event.loaded / event.total
        faceScanResultCallback.uploadProgress(progress)
      }
      //
      // Part 8:  Actually send the request.
      //
      var jsonStringToUpload = JSON.stringify(parameters)
      latestNetworkRequest.send(jsonStringToUpload)
      //
      // Part 9:  For better UX, update the User if the upload is taking a while.  You are free to customize and enhance this behavior to your liking.
      //
      window.setTimeout(() => {
        if (latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
          return
        }
        faceScanResultCallback.uploadMessageOverride('Still Uploading...')
      }, 6000)
    })
  }

  static getPhotoIDMatchResponseFromZoomServer(idScanResult, idScanResultCallback) {
    return new Promise((resolve, reject) => {
      const MinMatchLevel = 3
      //
      // Part 12:  Get essential data off the FaceTecIDScanResult
      //
      const parameters = {
        sessionId: idScanResult.sessionId,
        externalDatabaseRefID: Config.userId,
        idScan: idScanResult.idScan,
        idScanFrontImage: idScanResult.frontImages[0],
        minMatchLevel: MinMatchLevel,
      }
      if (idScanResult.backImages[0]) {
        parameters.idScanBackImage = idScanResult.backImages[0]
      }
      //
      // Part 13:  Make the Networking Call to Your Servers.  Below is just example code, you are free to customize based on how your own API works.
      //
      latestNetworkRequest = new XMLHttpRequest()
      latestNetworkRequest.open('POST', `${Config.BaseURL}/match-3d-2d-idscan`)
      latestNetworkRequest.setRequestHeader('Content-Type', 'application/json')
      latestNetworkRequest.setRequestHeader('X-Device-Key', Config.DeviceKeyIdentifier)
      latestNetworkRequest.setRequestHeader(
        'X-User-Agent',
        FaceTecSDK.createFaceTecAPIUserAgentString(idScanResult.sessionId)
      )
      latestNetworkRequest.onreadystatechange = function () {
        //
        // Part 14:  In our Sample, we evaluate a boolean response and treat true as success, false as 'User Needs to Retry',
        // and handle all other non-nominal responses by cancelling out.  You may have different paradigms in your own API and are free to customize based on these.
        //
        if (latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
          try {
            resolve(JSON.parse(latestNetworkRequest.responseText))
          } catch (_a) {
            // CASE:  Parsing the response into JSON failed --> You define your own API contracts with yourself and may choose to do something different here based on the error.  Solid server-side code should ensure you don't get to this case.
            console.log('Exception while handling API response, cancelling out.')
            idScanResultCallback.cancel()
          }
        }
      }
      latestNetworkRequest.onerror = function () {
        reject('XHR error, cancelling.')
      }
      //
      // Part 15:  Demonstrates updating the Progress Bar based on the progress event.
      //
      latestNetworkRequest.upload.onprogress = function name(event) {
        var progress = event.loaded / event.total
        idScanResultCallback.uploadProgress(progress)
      }
      //
      // Part 16:  Actually send the request.
      //
      const jsonStringToUpload = JSON.stringify(parameters)
      latestNetworkRequest.send(jsonStringToUpload)
      //
      // Part 17:  For better UX, update the User if the upload is taking a while.  You are free to customize and enhance this behavior to your liking.
      //
      window.setTimeout(() => {
        if (latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
          return
        }
        idScanResultCallback.uploadMessageOverride('Still Uploading...')
      }, 6000)
    })
  }

  static cancelPendingRequests() {
    if (!latestNetworkRequest) return

    latestNetworkRequest.abort()
    latestNetworkRequest = null
  }
}
