export const REVIEW_TYPES = {
  vehicle: 'Vehicle',
  borrower: 'Member',
}

export const ISSUES_NEW = {
  vehicle: [
    {
      name: 'smelling_good',
      text: 'Vehicle smelled bad',
    },
    {
      name: 'clean',
      text: 'Vehicle was not clean',
    },
    {
      name: 'easy_to_park',
      text: 'Difficult to find parking',
    },
    {
      name: 'easy_to_find_lockbox',
      text: 'Lockbox was hard to find',
    },
    {
      name: 'mechanically_issue_free',
      text: 'Vehicle had mechanical issues',
    },
    {
      name: 'accurate_description',
      text: 'Vehicle description was not accurate',
    },
    {
      name: 'adequate_level_of_fuel',
      text: 'Vehicle had less than 1/4 tank of fuel',
    },
    {
      name: 'safety_concern',
      text: 'Vehicle had a safety issue',
    },
    {
      name: 'find_parking',
      text: 'Difficult to find parking',
    },
  ],
  borrower: [
    {
      name: 'clean',
      text: 'Vehicle was returned messy',
    },
    {
      name: 'parked_correctly',
      text: 'Vehicle was parked illegally on return',
    },
    {
      name: 'locked_safely',
      text: 'Vehicle or lockbox was not locked properly',
    },
    {
      name: 'adequate_level_of_fuel',
      text: 'Vehicle was returned with less than 1/4 tank of fuel',
    },
  ],
}

export const ISSUES_MAP = {
  vehicle: {
    clean: 'Vehicle was clean',
    easy_to_park: 'Easy to find parking',
    accurate_description: 'Vehicle description accurate',
    smelling_good: 'Vehicle smelled good',
    easy_to_find_lockbox: 'Easy to find Lockbox',
    mechanically_issue_free: 'Vehicle was free of mechanical issues',
    adequate_level_of_fuel: L10n.t('web-owner-bookings-borrower.vehicle-had-more-than-14-tank-of'),
    safety_concern: 'No safety concerns',
    find_parking: 'Parking was easy to find',
  },
  borrower: {
    clean: 'Vehicle was returned clean',
    parked_correctly: 'Vehicle was parked correctly',
    locked_safely: 'Vehicle and lockbox were locked properly',
    adequate_level_of_fuel: L10n.t('web-owner-bookings-borrower.vehicle-was-returned-with-at-lea'),
  },
}
