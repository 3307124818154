import { Controller } from 'stimulus'

import * as axios from 'axios'
import qs from 'qs'

import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'

export default class extends Controller {
  static targets = [
    'form',
    'creditCardContainer',
    'creditCard',
    'downgradeWarning',
    'dclOption',
    'coverOptions',
    'coverOption',
    'changeCoverButton',
  ]

  initialize() {
    this.creditCardTarget.addEventListener('cardupdated', () => this.upgrade())
    this.creditCardTarget.addEventListener('disablebutton', () => (this.changeCoverButtonTarget.disabled = true))
    this.creditCardTarget.addEventListener('enablebutton', () => (this.changeCoverButtonTarget.disabled = false))
  }

  updateCoverSelection(e) {
    const newSelectionValue = e.target.dataset.value
    if (this.coverOptionsTarget.dataset.value == newSelectionValue) {
      return
    }
    this.coverOptionsTarget.dataset.value = newSelectionValue
    this.coverOptionTargets.forEach((target) => target.classList.remove('checked'))
    e.target.classList.add('checked')

    if (this.coverOptionsTarget.dataset.initialValue === '0') {
      if (newSelectionValue === '0') {
        this.creditCardContainerTarget.classList.add('cnd-hidden')
        this.changeCoverButtonTarget.classList.add('cnd-hidden')
      } else {
        this.creditCardContainerTarget.classList.remove('cnd-hidden')
        this.changeCoverButtonTarget.classList.remove('cnd-hidden')
      }
    } else {
      if (newSelectionValue === '0') {
        this.downgradeWarningTarget.classList.remove('cnd-hidden')
        this.changeCoverButtonTarget.classList.remove('cnd-hidden')
      } else {
        this.downgradeWarningTarget.classList.add('cnd-hidden')
        this.changeCoverButtonTarget.classList.add('cnd-hidden')
      }
    }
  }

  changeCover(e) {
    e.preventDefault()

    this.changeCoverButtonTarget.disabled = true

    if (this.coverOptionsTarget.dataset.value !== '0') {
      this.creditCardTarget.cnd.cardSubmit(e)

      return true
    } else {
      this.downgrade()
    }
  }

  upgrade() {
    const data = { dcl_reduction_option_id: this.dclOptionTarget.value }

    axios({
      method: 'put',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: qs.stringify(data),
      url: this.formTarget.dataset.url,
    })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        this.changeCoverButtonTarget.disabled = false
        FlashMessageUtils.showWarning(error.response.data.errors)
        window.scrollTo(0, 0)
      })
  }

  downgrade() {
    axios
      .delete(this.formTarget.dataset.url, {
        responseType: 'json',
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
        },
      })
      .then(() => {
        window.location.reload()
      })
      .catch((error) => {
        this.changeCoverButtonTarget.disabled = false
        FlashMessageUtils.showWarning(error.response.data.errors)
        window.scrollTo(0, 0)
      })
  }
}
