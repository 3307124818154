import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['toggleButton']

  initialize() {
    this.activeEl = this.element.querySelector('.active')
    if (this.activeEl) this.toggleButtonTarget.innerHTML = this.activeEl.innerHTML
  }

  toggle() {
    this.element.classList.toggle('cnd-stacked-nav--responsive--open')
  }

  setActive(event) {
    if (event.target === this.activeEl) return
    this.activeEl.classList.remove('active')
    this.activeEl = event.target
    this.activeEl.classList.add('active')
    this.toggleButtonTarget.innerHTML = this.activeEl.innerHTML
    this.toggle()
  }
}
