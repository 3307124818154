import { Controller } from 'stimulus'
import * as axios from 'axios'

import AxiosUtils from '../../utils/axios_utils'

export default class extends Controller {
  static targets = ['emailBookingPreference', 'bookingPreference']

  preferenceParam
  params

  initialize() {
    this.setNotificationCheckboxes()
  }

  toggleNotificationPreference(event) {
    const notificationPreference = event.currentTarget.id
    const notificationPreferenceValue = event.currentTarget.checked

    this.updateNotificationPreference(notificationPreference, notificationPreferenceValue)
  }

  updateNotificationPreference(preference, value) {
    this.preferenceParam = preference

    if (this.preferenceParam == 'booking_updates') {
      const array = this.bookingPreferenceTargets.map((bookingPreference) => bookingPreference.id)
      this.params = this.constructNotificationPreferenceParams(array, value)
      this.toggleBookingPreferences(this.params)
    } else {
      this.params = this.constructNotificationPreferenceParams(this.preferenceParam, value)
    }

    let element = document.getElementById('notification_preferences_new_23_09')
    let notificationPreferencesNew = element.getAttribute('data-notification_preferences_new_23_09')
    if (notificationPreferencesNew == 'true') {
      let showSpinner = document.getElementById('show-spinner')
      showSpinner.classList.remove('cnd-hidden')
      let disabledContent = document.getElementById('disabled-content')
      disabledContent.classList.add('cnd-pointer-events-none')
    }

    axios
      .put('/notification_preferences', this.params, AxiosUtils.getHeaders())
      .then((response) => {
        if (notificationPreferencesNew == 'true') {
          window.location.reload()
        } else {
          this.setBookingNotificationCheckbox()
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  constructNotificationPreferenceParams(type, value) {
    const obj = {}
    if (type.constructor === Array) {
      for (const key of type) {
        obj[key] = value
      }
      return obj
    } else {
      if (type == 'unsubscribed_from_marketing') {
        return {
          unsubscribed_from_marketing: !value,
          discounts_and_promotions: value,
          email_discounts_and_promotions: value,
          owner_tips_and_tricks: value,
          email_owner_tips_and_tricks: value,
          new_cars_near_you: value,
          email_new_cars_near_you: value,
          newsletter_and_updates: value,
          email_newsletter_and_updates: value,
        }
      } else {
        obj[type] = value
        return obj
      }
    }
  }

  setNotificationCheckboxes() {
    axios
      .get('/notification_preferences', AxiosUtils.getHeaders())
      .then((response) => {
        const data = response.data

        this.emailBookingPreferenceTargets.concat(this.bookingPreferenceTargets).forEach((preference) => {
          if (preference.id == 'unsubscribed_from_marketing') {
            preference.checked = !data[preference.id]
          } else if (preference.id) {
            // assign each checkbox state with it's corresponding database value
            preference.checked = data[preference.id]
          } else {
            // sets checkbox to checked as we do not pass in an id for account preferences
            preference.checked = true
          }
        })
        this.setBookingNotificationCheckbox()
      })
      .catch((error) => {
        console.log(error)
      })
  }

  toggleBookingPreferences(data) {
    const preferenceData = data

    this.bookingPreferenceTargets.forEach((preference) => {
      for (const data in preferenceData) {
        preference.checked = preferenceData[data]
      }
    })
  }

  setBookingNotificationCheckbox() {
    const bookingPreferences = this.bookingPreferenceTargets.map((preference) => preference.checked)
    let bookingNotificationState = this.emailBookingPreferenceTargets.find((x) => x.id === 'booking_updates')

    return (bookingNotificationState.checked = bookingPreferences.includes(true))
  }
}
