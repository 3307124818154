import { Controller } from 'stimulus'

import TargetUtils from '../../utils/target_utils'

import * as axios from 'axios'

// Utils
import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'

export default class extends Controller {
  static targets = [
    'form',
    'submit',
    'content',
    'shackleCode',
    'lockboxLocationOptions',
    'addressSearch',
    'carManualEnabled',
  ]

  initialize() {
    this.targetUtils = new TargetUtils(this)

    if (this.carManualEnabledTarget.value === 'true') {
      this.toggleAddress()
    }
  }

  disableForm() {
    this.submitTargets.forEach((target) => {
      target.setAttribute('disabled', 'true')
      if (target.dataset.disableWith) {
        target.value = target.dataset.disableWith
      }
    })
  }

  enableForm() {
    this.submitTargets.forEach((target) => {
      target.removeAttribute('disabled')
      if (target.dataset.value) {
        target.value = target.dataset.value
      }
    })
  }

  validateForm() {
    let isValid = true
    let streetAddressSearch = this.addressSearchTarget.querySelector('.cnd-autocomplete')

    if (streetAddressSearch.value == '' && this.lockboxOnProperty().value === 'On property') {
      streetAddressSearch.required = true
      isValid = false
    } else {
      streetAddressSearch.required = false
    }

    return isValid
  }

  showShackleCode(e) {
    let button = e.srcElement
    button.value = 'Generating shackle code...'
    axios
      .post('lockbox_shackle_code', null, AxiosUtils.getHeaders())
      .then((res) => {
        this.shackleCodeTarget.innerHTML = res.data
        this.contentTarget.classList.remove('cnd-hidden')
        button.classList.add('cnd-hidden')
      })
      .catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.message)
      })
      .finally(() => {
        this.enableForm()
      })
  }

  toggleAddress() {
    if (this.lockboxOnProperty().value === 'On property') {
      this.targetUtils.show('addressSearchTarget')
      this.addressSearchTarget.querySelector('.cnd-autocomplete').setAttribute('required', true)
    } else {
      this.targetUtils.hide('addressSearchTarget')
      this.addressSearchTarget.querySelector('.cnd-autocomplete').removeAttribute('required')
    }
  }

  lockboxOnProperty() {
    return this.lockboxLocationOptionsTargets.filter((e) => e.checked)[0]
  }

  submitForm(e) {
    if (!this.validateForm()) {
      return
    }

    e.preventDefault()

    this.disableForm()
    let formData = new FormData(this.formTarget)

    axios
      .post('./lockbox', formData, AxiosUtils.getHeaders())
      .then(() => {
        FlashMessageUtils.showSuccess('Changes saved. Generating shackle code...')
        this.showShackleCode(e)
      })
      .catch((error) => {
        let data = error.response.data
        FlashMessageUtils.showWarning(data.message)
        this.enableForm()
      })
  }
}
