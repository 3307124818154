import { Controller } from 'stimulus'

import * as axios from 'axios'
import AxiosUtils from '../../../utils/axios_utils'

export default class extends Controller {
  static targets = [
    'form',
    'reason',
    'issues',
    'otherIssues',
    'date',
    'sigfoxMessage',
    'homezoneOffset',
    'submitButton',
  ]

  toggleChildOptions(event) {
    let currentElement = event.srcElement
    let isChecked = currentElement.checked
    let childOptionsWrapper = currentElement.parentElement.nextElementSibling

    if (!childOptionsWrapper) {
      return
    }

    if (isChecked) {
      childOptionsWrapper.classList.remove('cnd-hidden')
    } else {
      childOptionsWrapper.classList.add('cnd-hidden')
      childOptionsWrapper.querySelectorAll('input').forEach((input) => {
        input.checked = false
      })
    }
  }

  validateForm() {
    if (this.reasonTarget.value === '') {
      return false
    }

    if (this.dateTarget.value === '') {
      return false
    }

    return true
  }

  enableSubmit() {
    let isValid = this.validateForm()

    if (isValid) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  trackLinkClick(e) {
    const helpRequestId = e.currentTarget.dataset.helpRequestId
    axios.get(`/help_requests/${helpRequestId}/help_flows/delisting/track_click_info`, AxiosUtils.getHeaders())

    window.open(`${window.cndVars.zendeskBaseUrl}/articles/360001118506`, '_self')
  }
}
