import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'submitBtn']

  submitForm() {
    window.onbeforeunload = null
    this.formTarget.submit()
  }

  enableSubmitBtn() {
    const allPhotosUploaded = Array.from(
      document.querySelectorAll('input[required="true"][class="dz-photo-blob-id-input"]')
    ).every((x) => x.value != '')

    this.submitBtnTarget.disabled = !allPhotosUploaded
  }

  addNewPhotoUploader(e) {
    const dropzonePanel = e.target.parentNode.parentNode
    if (e.target.value != '') {
      const templateNode = document.querySelector(`[data-template="${e.target.dataset.template}"]`)
      const newDropzonePanelContent = templateNode.innerHTML
      dropzonePanel.insertAdjacentHTML('afterend', newDropzonePanelContent)
    } else {
      if (dropzonePanel.nextElementSibling) {
        dropzonePanel.parentNode.removeChild(dropzonePanel)
      }
    }
  }
}
