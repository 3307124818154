import { Controller } from 'stimulus'

// Utils
import TargetUtils from '../../utils/target_utils'

export default class extends Controller {
  static targets = ['list', 'listOverlay']

  targetUtils

  initialize() {
    this.targetUtils = new TargetUtils(this)

    this.hideAll()
  }

  hideListOverlay() {
    this.targetUtils.hide('listOverlayTarget')
  }

  hideList() {
    this.targetUtils.hide('listTarget')
  }

  hideAll() {
    this.hideList()
    this.hideListOverlay()
  }

  toggleListOverlay() {
    this.targetUtils.toggle('listOverlayTarget')
  }

  toggleList() {
    this.toggleListOverlay()
    this.targetUtils.toggle('listTarget')
  }
}
