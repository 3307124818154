import { Controller } from 'stimulus'
import moment from 'moment'
import * as axios from 'axios'
import qs from 'qs'
import _ from 'lodash'
import mapboxgl from '!mapbox-gl'
import mbxClient from '@mapbox/mapbox-sdk'
import mbxGeocoding from '@mapbox/mapbox-sdk/services/geocoding'

// Utils
import { stripHtml } from '../../utils/strip_html'
import DateUtils from '../../utils/date_utils'
import DeviceUtils from '../../utils/device_utils'
import LocationUtils from '../../utils/location_utils'
import MobiscrollUtils from '../../utils/mobiscroll_utils'
import SearchUtils from '../../utils/search_utils'
import ConversionUtils from '../../utils/conversion_utils'

export default class extends Controller {
  static targets = [
    'loading',
    'overlay',
    'mapContainer',
    'map',
    'searchAreaBtn',
    'fullscreenBtn',
    'toggleMapBtn',
    'formContainer',
    'mapbox',
    'address',
    'clearAddress',
    'latitude',
    'longitude',
    'locationSuggestions',
    'startDate',
    'endDate',
    'startTime',
    'startTimeIcon',
    'endTime',
    'endTimeIcon',
    'alert',
    'submit',
    'filters',
    'availabilityFilter',
    'transmissionFilter',
    'seatsFilter',
    'doorsFilter',
    'parkingFilter',
    'bodyTypeFilter',
    'featureFilter',
    'keyTypeFilter',
    'error',
    'bookSoon',
    'results',
    'pagination',
    'disclaimer',
    'pickupDateLabel',
    'returnDateLabel',
    'startTimeLabel',
    'endTimeLabel',
    'fuzzyFilterOptions',
    'fuzzyFilter',
    'fuzzyFilterCard',
    'favoriteFilter',
    'verificationBanner',
    'dateTimeFilterText',
    'guestTutorial',
    'mainBodyTypeFilter',
    'mainFeatureFilter',
    'safetyFilter',
    'mainSafetyFilter',
    'mainLowerPriceRange',
    'mainUpperPriceRange',
    'lowerPriceRange',
    'upperPriceRange',
    'additionalFilter',
    'dateTimeFilter',
    'greenCarsFilter',
  ]

  pageNumber
  loggedIn

  startOfDay
  endOfDay

  startAvailability
  endAvailability

  startDateSet
  endDateSet
  endDateUnset
  datesSetOnFirstSelect

  defaultEndTime

  map
  markers
  search
  vehicles
  vehiclesPerPage

  initialize() {
    this.markers = []
    this.search = {}
    this.vehicles = []
    this.vehiclesPerPage = 12
    this.scrollToTop = true

    // Mobiscroll instances for start time / end time
    this.startTimeInstance = null
    this.endTimeInstance = null

    // check if logged in
    this.loggedIn = window.cndVars.loggedIn && window.cndVars.isApproved

    // set start/end of day
    this.startOfDay = moment().startOf('day')
    this.endOfDay = moment().endOf('day')

    // set start/end availability hours
    this.startAvailability = moment(this.element.dataset.startAvailability, 'HH:mm')
    this.endAvailability = moment(this.element.dataset.endAvailability, 'HH:mm')

    this.isFuzzyFilterCardDismissed = false

    this.initializeForm()

    this.setFilterValues()
    this.displayTimeInput()

    if (this.hasMapTarget) {
      const baseClient = mbxClient({
        accessToken: process.env.MAPBOX_ACCESS_TOKEN,
      })

      this.geocoder = mbxGeocoding(baseClient)
      this.initializeMap()
      this.getSearchResults(this.element.dataset.page)
      this.addEventListeners()
    }

    this.submitForm = this.submitForm.bind(this)
    this.onPopState = this.onPopState.bind(this)
  }

  initializeForm(type) {
    const latitude = parseFloat(this.element.dataset.latitude)
    const longitude = parseFloat(this.element.dataset.longitude)

    // parse location from URL or use default
    if (isNaN(latitude) || isNaN(longitude) || (latitude === 0 && longitude === 0)) {
      this.extendSearch(SearchUtils.getDefaultLocations(this.element.dataset.country)[0])
    } else {
      this.extendSearch({
        latitude: latitude,
        longitude: longitude,
        location: this.element.dataset.location,
      })
    }

    // update value of all location fields
    this.setLocationValue(this.search.location)

    // parse start/end times from URL or use default
    if (this.element.dataset.startTime !== '' && this.element.dataset.endTime !== '') {
      this.extendSearch({
        startDatetimeMoment: moment(this.element.dataset.startTime),
        endDatetimeMoment: moment(this.element.dataset.endTime),
      })
    } else {
      this.extendSearch({
        startDatetimeMoment: '',
        endDatetimeMoment: '',
      })
    }

    // set availability text
    let unavailabilityHours = null
    let pickupText = 'Pickup {value}'
    let returnText = 'Return {value}'

    if (!!this.element.dataset.endUnavailability && !!this.element.dataset.startUnavailability) {
      unavailabilityHours = [
        {
          start: '00:00',
          end: this.element.dataset.endUnavailability,
        },
        {
          start: this.element.dataset.startUnavailability,
          end: '23:59',
        },
      ]
    }

    if (this.element.dataset.availabilityText) {
      pickupText = `Pickup available ${this.element.dataset.availabilityText || ''}`
      returnText = `Return available ${this.element.dataset.availabilityText || ''}`
    }

    // define Mobiscroll instances
    this.initializeEnhancedDatePickers(unavailabilityHours, type)
  }

  extendSearch(params) {
    Object.assign(this.search, params)
    this.isStartEndSet = this.search.startDatetimeMoment && this.search.endDatetimeMoment
  }

  setLocationValue(value) {
    const mapboxInputField = this.mapboxTarget.getElementsByClassName('cnd-autocomplete')[0]

    this.addressTarget.value = value

    if (mapboxInputField) {
      mapboxInputField.value = value
    }

    if (this.hasClearAddressTarget) {
      if (value === '') {
        this.clearAddressTarget.classList.add('cnd-hidden')
      } else {
        this.clearAddressTarget.classList.remove('cnd-hidden')
      }
    }
  }

  setPickupReturnValues() {
    const { pickupMoment, returnMoment } = this.getPickupReturnMoment(
      this.search.startDatetimeMoment,
      this.search.endDatetimeMoment
    )

    this.extendSearch({
      startDatetimeMoment: pickupMoment,
      endDatetimeMoment: returnMoment,
    })

    this.startTimeTarget.value = pickupMoment.format(DateUtils.DATETIME_FORMAT)
    this.endTimeTarget.value = returnMoment.format(DateUtils.DATETIME_FORMAT)
  }

  checkDatetimeValidity() {
    if (this.search.startDatetimeMoment === '' || this.search.endDatetimeMoment === '') {
      return false
    }

    const now = moment()
    const startTime = this.search.startDatetimeMoment
    let errorMsg

    if (this.search.startDatetimeMoment.isSameOrBefore(now)) {
      errorMsg = 'Pickup time cannot be in the past.'
    } else if (this.search.endDatetimeMoment.isSameOrBefore(now)) {
      errorMsg = 'Return time cannot be in the past.'
    } else if (this.search.endDatetimeMoment.isSameOrBefore(startTime)) {
      errorMsg = 'Return must occur after pickup time.'
    } else if (this.search.endDatetimeMoment.isBefore(startTime.clone().add(1, 'hour'))) {
      errorMsg = 'Reservation must be for at least 1 hour.'
    }

    if (errorMsg) {
      // display error message
      this.alertTarget.innerText = errorMsg
      this.alertTarget.classList.remove('cnd-hidden')

      // disable submit button
      this.submitTarget.setAttribute('disabled', 'disabled')

      return false
    }

    this.clearErrorsAndEnableButtons()

    return true
  }

  clearErrorsAndEnableButtons() {
    this.loadingTarget.classList.add('cnd-hidden')
    this.alertTarget.classList.add('cnd-hidden')

    if (this.search.startDatetimeMoment !== '' || this.search.endDatetimeMoment !== '') {
      this.submitTarget.removeAttribute('disabled')
    }
  }

  onFuzzyFilterChange(event) {
    const { value } = event.detail

    this.element.dataset.fuzzyFilter = value
    this.fuzzyFilterTarget.value = value
    this.fuzzyFilterOptionsTarget.value = value
  }

  onFuzzyFilterClick(event) {
    const { name } = event.detail

    switch (event.detail.name) {
      case 'see-more':
        this.getSearchResults(1)
        break

      case 'close':
        this.isFuzzyFilterCardDismissed = true
        this.updateResults()
        break
    }
  }

  addLocationSuggestions() {
    const THIS = this
    let divEl

    if (navigator.geolocation) {
      divEl = document.createElement('div')

      divEl.classList.add('cnd-vehicle-search__suggestion', 'spec-location-suggestion')

      divEl.innerHTML = '<span class="cnd-map-pin-icon cnd-h-3 cnd-w-3"></span> <span>Current location</span>'

      divEl.addEventListener('click', (e) => {
        e.preventDefault()

        THIS.loadingTarget.classList.remove('cnd-hidden')

        return navigator.geolocation.getCurrentPosition(
          (position) => {
            THIS.geocodeAndSearch(position.coords.latitude, position.coords.longitude)
          },
          (error) => {
            // User denied Geolocation
            if (error.code === 1) {
              divEl.classList.add('cnd-hidden')
            }

            THIS.alertTarget.innerText = error.message
            THIS.alertTarget.classList.remove('cnd-hidden')

            THIS.loadingTarget.classList.add('cnd-hidden')
          }
        )
      })

      this.locationSuggestionsTarget.appendChild(divEl)
    }

    const defaultSearchLocations = SearchUtils.getDefaultLocations(this.element.dataset.country)

    for (let index = 0; index < defaultSearchLocations.length; index++) {
      const suggestion = defaultSearchLocations[index]

      divEl = document.createElement('div')

      divEl.classList.add('cnd-vehicle-search__suggestion', 'spec-location-suggestion')
      divEl.dataset.index = index

      divEl.innerHTML = `<span class="cnd-map-pin-icon cnd-h-3 cnd-w-3"></span>
        <span>${suggestion.location.split(' ')[0]}</span>`

      divEl.addEventListener('click', (e) => {
        e.preventDefault()

        const i = e.currentTarget.dataset.index

        THIS.extendSearch(defaultSearchLocations[i])
        THIS.setLocationValue(THIS.search.location)
        THIS.getSearchResults(1)
      })

      this.locationSuggestionsTarget.appendChild(divEl)
    }
  }

  setFilterValues() {
    const checkByValues = function (elements, values) {
      if (!values) {
        return false
      }

      if (!Array.isArray(values)) {
        values = [values]
      }

      return elements.map((el) => {
        el.checked = values.includes(el.getAttribute('value'))
        el.setAttribute('selected', el.checked)
      })
    }

    const query = qs.parse(window.location.search.slice(1))

    checkByValues(this.availabilityFilterTargets, query.show_available)

    checkByValues(this.transmissionFilterTargets, query.transmission)

    if (query.number_of_seats) {
      this.seatsFilterTarget.value = query.number_of_seats
    }

    if (query.favorite) {
      this.favoriteFilterTarget.dataset.value = query.favorite || 'false'
    }

    if (query.number_of_doors) {
      this.doorsFilterTarget.value = query.number_of_doors
    }

    if (query.lower_price_range) {
      this.setPriceRangeValue('Lower', query.lower_price_range)
      this.lowerPriceRangeTarget.dataset.dirty = true
    } else if (this.hasLowerPriceRangeTarget) {
      this.lowerPriceRangeTarget.dataset.dirty = false
    }

    if (query.upper_price_range) {
      this.setPriceRangeValue('Upper', query.upper_price_range)
      this.upperPriceRangeTarget.dataset.dirty = true
    } else if (this.hasUpperPriceRangeTarget) {
      this.upperPriceRangeTarget.dataset.dirty = false
    }

    checkByValues(this.parkingFilterTargets, query.parking_types)
    checkByValues(this.bodyTypeFilterTargets, query.body_types)
    checkByValues(this.mainBodyTypeFilterTargets, query.body_types)
    checkByValues(this.mainFeatureFilterTargets, query.features)
    checkByValues(this.mainSafetyFilterTargets, query.features)
    checkByValues(this.greenCarsFilterTargets, query.green_cars)
    checkByValues(this.featureFilterTargets, query.features)
    checkByValues(this.keyTypeFilterTargets, query.key_type)
  }

  clearAddress() {
    this.extendSearch({
      location: '',
      latitude: null,
      longitude: null,
    })

    this.setLocationValue('')
  }

  toggleFilters() {
    this.overlayTarget.classList.toggle('cnd-hidden')
    this.filtersTarget.classList.toggle('cnd-hidden')
  }

  locationUpdated(e) {
    const address = e.args

    this.extendSearch({
      location: address.location,
      latitude: address.latitude,
      longitude: address.longitude,
    })

    this.setLocationValue(this.search.location)
    this.getSearchResults(1)
  }

  submitForm(e) {
    e.preventDefault()

    // assert address and coordinates are updated prior to submitting form
    if (!this.checkAddressValidity() || !this.checkCoordinatesValidity()) {
      this.addressTarget.addEventListener('locationUpdated', this.submitForm)
      return false
    }

    this.getSearchResults(1)

    return false
  }

  checkAddressValidity() {
    if (this.search.location === '') {
      // display error message
      this.alertTarget.innerText = 'Please enter a pickup location.'
      this.alertTarget.classList.remove('cnd-hidden')

      // disable submit button
      this.submitTarget.setAttribute('disabled', 'disabled')

      return false
    }

    return true
  }

  checkCoordinatesValidity() {
    if (
      this.search.latitude === '' ||
      this.search.latitude === '0' ||
      this.search.longitude === '' ||
      this.search.longitude === '0'
    ) {
      return false
    }

    return true
  }

  initializeMap() {
    const defaultZoom = 13
    mapboxgl.accessToken = process.env.MAPBOX_ACCESS_TOKEN

    const mapOptions = {
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: defaultZoom,
      scrollZoom: false,
      center: [this.search.longitude, this.search.latitude],
      attributionControl: false,
    }

    if (!this.loggedIn) {
      mapOptions.maxZoom = defaultZoom
    }

    this.map = new mapboxgl.Map(mapOptions)

    this.map.addControl(new mapboxgl.AttributionControl(), 'bottom-right')
    this.map.addControl(new mapboxgl.NavigationControl({ showCompass: false }), 'bottom-right')
    this.map.addControl(new mapboxgl.FullscreenControl(), 'top-right')

    this.rootMarker = this.addMarker('root', [this.search.longitude, this.search.latitude])
  }

  addMarker(name, latLng, content = false) {
    const markerEl = document.createElement('div')
    markerEl.className = `marker-${name}`
    const marker = new mapboxgl.Marker(markerEl).setLngLat(latLng).setDraggable(false).addTo(this.map)

    if (content) {
      const popup = new mapboxgl.Popup().setHTML(content)

      popup._closeButton.remove()
      popup._content.classList.add('cnd-p-0', 'cnd-w-96')

      marker.setPopup(popup)

      this.addPopupEvents(popup)
    }

    return marker
  }

  getSearchResults(currentPage, scrollToTop = true) {
    let pageNumber = currentPage
    this.scrollToTop = scrollToTop

    if (!pageNumber) {
      const query = qs.parse(window.location.search.slice(1))
      pageNumber = query.page || this.element.dataset.page
    }

    pageNumber = parseInt(pageNumber, 10)

    this.loadingTarget.classList.remove('cnd-hidden')
    this.submitTarget.setAttribute('disabled', 'disabled')

    this.scrollToMap()
    this.clearMap()
    this.setRootMarkerPosition()
    this.getSearchParams(pageNumber)

    if (currentPage > 0) {
      this.updateQuerystring()
    }

    this.querySearchAPI()
  }

  scrollToMap() {
    this.scrollToTop &&
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
  }

  clearMap() {
    while (this.markers.length > 0) {
      let marker = this.markers.pop()
      marker.remove()

      this.vehicles.pop()
    }
  }

  setRootMarkerPosition() {
    const lngLat = new mapboxgl.LngLat(this.search.longitude, this.search.latitude)

    this.rootMarker.setLngLat(lngLat)
    this.map.panTo(lngLat)
  }

  getSearchParams(pageNumber) {
    const getCheckedValues = function (fieldset) {
      const checked = []

      for (const field of fieldset) {
        if (field.checked) {
          checked.push(field.getAttribute('value'))
        }
      }

      return checked
    }

    const getSelectedValues = function (fieldset) {
      const selected = []
      for (const field of fieldset) {
        if (field.getAttribute('selected') === 'true') {
          selected.push(field.getAttribute('value'))
        }
      }
      return selected.length === 1 ? selected[0] : 'all'
    }

    this.searchParams = {
      location: (this.search.location || '').replace(/\s/g, '+'),
      latitude: this.search.latitude,
      longitude: this.search.longitude,
      show_available: this.hasAvailabilityFilterTarget && this.availabilityFilterTarget.checked ? 1 : undefined,
      key_type: getSelectedValues(this.keyTypeFilterTargets),
      transmission: getCheckedValues(this.transmissionFilterTargets),
      parking_types: getCheckedValues(this.parkingFilterTargets),
      body_types: getCheckedValues(this.bodyTypeFilterTargets),
      features: getCheckedValues(this.featureFilterTargets),
      green_cars: getCheckedValues(this.greenCarsFilterTargets),
      favorite: this.favoriteFilterValue,
      lower_price_range: this.hasLowerPriceRangeTarget ? this.priceRangeValue(this.lowerPriceRangeTarget) : undefined,
      upper_price_range: this.hasUpperPriceRangeTarget ? this.priceRangeValue(this.upperPriceRangeTarget) : undefined,
      page: pageNumber > 0 ? pageNumber : 1,
      number_of_seats: this.search.number_of_seats,
      number_of_doors: this.search.number_of_doors,
      unava_request: this.element.dataset.unavaRequest,
    }

    if (this.search.startDatetimeMoment) {
      this.searchParams.start_time_iso = this.search.startDatetimeMoment
        .startOf('minute')
        .format(DateUtils.DATETIME_ISO_FORMAT)
    }

    if (this.search.endDatetimeMoment) {
      this.searchParams.end_time_iso = this.search.endDatetimeMoment
        .startOf('minute')
        .format(DateUtils.DATETIME_ISO_FORMAT)
    }

    if (this.seatsFilterTarget.value.length) {
      this.searchParams.number_of_seats = this.seatsFilterTarget.value
    }

    if (this.doorsFilterTarget.value.length) {
      this.searchParams.number_of_doors = this.doorsFilterTarget.value
    }

    if (this.fuzzyFilterTarget.value.length) {
      this.searchParams.fuzzy_filter = this.fuzzyFilterTarget.value
    }
  }

  updateQuerystring() {
    let query = qs.parse(window.location.search.slice(1))
    delete query.fuzzy_filter

    query = Object.assign(query, this.searchParams)
    query = qs.stringify(query, { arrayFormat: 'brackets' })

    window.history.pushState(this.searchParams, '', `?${decodeURIComponent(query)}`)
  }

  querySearchAPI() {
    // The "page" parameter in "searchParams" is for the front-end pagination
    // logic only. On the back-end we're not using pagination so we set the
    // "page" parameter to "1" to get the proper results.
    const params = Object.assign({}, this.searchParams)
    params.page = 1

    const THIS = this

    axios
      .get('/search/results', {
        responseType: 'json',
        headers: { 'X-Requested-With': 'XMLHttpRequest' },
        params,
      })
      .then((response) => {
        if (response.data.scarce) {
          this.bookSoonTarget.classList.remove('cnd-hidden')
        } else {
          this.bookSoonTarget.classList.add('cnd-hidden')
        }

        THIS.vehicles = response.data.search_results

        PubSub.publish(window.PUBSUB_EVENTS.SEARCH_RESULTS, { vehicles: THIS.vehicles })

        if (!THIS.vehicles.length) {
          $(THIS.paginationTarget).twbsPagination('destroy')
          THIS.updateResults()
          THIS.disclaimerTarget.innerText = ''

          return false
        }

        THIS.disclaimerTarget.innerText = `Showing max. ${response.data.max_results} cars within
        ${L10n.t('web-search-results.navigation.km-of-your-search-location', {
          search_radius: this.fetchRegionBasedRadius(response.data.radius_km),
        })}`

        this.element.dataset.unavaRequest = false

        THIS.createVehicleTemplates()
        THIS.updatePagination()
        THIS.notifySegment()
      })
      .catch((error) => {
        if (_.get(error, 'response.status') === 422) {
          THIS.errorTarget.textContent = error.response.data.message
        } else {
          THIS.errorTarget.textContent = `An error occurred. Try searching again or refreshing the page.
            Please contact us if the error persists.`
        }

        THIS.errorTarget.classList.remove('cnd-hidden')

        PubSub.publish(window.PUBSUB_EVENTS.SEARCH_RESULTS, { vehicles: [], error: true })
        return false
      })
      .then(() => {
        THIS.clearErrorsAndEnableButtons()
      })
  }

  createVehicleTemplates() {
    const vehicleTemplate = document.getElementById('vehicle-search-result').textContent.trim()
    const popupTemplate = document.getElementById('vehicle-search-popup').textContent.trim()

    for (let index = 0; index < this.vehicles.length; index++) {
      const vehicle = this.vehicles[index]
      vehicle.index = index
      vehicle.url = decodeURIComponent(vehicle.url) // Pretty print for URI
      vehicle.html = this.createSearchResult(vehicle, vehicleTemplate)

      const popupContent = this.createSearchResult(vehicle, popupTemplate)
      const marker = this.createVehicleMarker(vehicle, popupContent)
      this.markers.push(marker)
    }
  }

  createVehicleMarker(vehicle, content) {
    let name = `vehicle-${vehicle.available ? 'available' : 'unavailable'}`

    const location = LocationUtils.offsetLocation(vehicle.lat, vehicle.lng, this.loggedIn)
    const marker = this.addMarker(name, [location.longitude, location.latitude], content)

    return marker
  }

  createBadge(b) {
    let display_name = b.name == 'serviced' ? 'Serviced' : b.display_name
    let badge = {
      name: b.name.replace(/_/g, '-'),
      display_name: display_name,
      slot: b.slot || 'badges',
    }
    const badgeTemplate = document.getElementById('vehicle-search-badge').textContent.trim()
    return eval(`\`${badgeTemplate}\``)
  }

  createFavorite(vehicle_id, favorite_id) {
    const favoriteTemplate = document.getElementById('vehicle-search-favorite').textContent.trim()
    return eval(`\`${favoriteTemplate}\``)
  }

  createSearchResult(vehicle, template) {
    let badges = ''
    let keyTypeBadge = ''
    const favorite = this.createFavorite(vehicle.id, vehicle.favorite_id)
    const isStartEndSet = this.isStartEndSet
    const duration =
      isStartEndSet && this.search.startDatetimeMoment.isValid() && this.search.endDatetimeMoment.isValid()
        ? window.cndUtils.getDurationFormat(this.search.startDatetimeMoment, this.search.endDatetimeMoment)
        : void 0

    let keyTypeText = 'Instant Keys'
    let keyTypeName = 'instant_keys'

    if (vehicle.key_handover) {
      keyTypeText = 'Key Handover'
      keyTypeName = 'key_handover'
    }

    if (vehicle.key_handover || vehicle.country_code == 'AU') {
      keyTypeBadge = `${this.createBadge({ name: keyTypeName, display_name: keyTypeText })}`

      badges += keyTypeBadge
    }

    vehicle.badges.forEach((b) => (badges += this.createBadge(b)))

    let availabilityTag = ''

    if (!vehicle.available) {
      availabilityTag = `<div class="cnd-card__tag cnd-card__tag--left">
          <span class="cnd-card__tag__icon cnd-clock-icon"></span>
          <span>Unavailable</span>
        </div>`
    }

    let availabilityText = 'Exact match'
    let fuzzyDisplayRange = 'test'

    if (vehicle.closest_availability) {
      const { start_time: startTime, end_time: endTime } = vehicle.closest_availability
      fuzzyDisplayRange = DateUtils.displayRange(startTime, endTime)
      availabilityText = 'Close match'
    }

    const fuzzyFilterValue = this.fuzzyFilterTarget.value
    const searchLocation = stripHtml((this.search.location || '').split(',')[0])
    const { startDatetimeMoment, endDatetimeMoment } = this.search
    const searchDatetimeDisplay = DateUtils.displayRange(startDatetimeMoment, endDatetimeMoment)

    return eval(`\`${template}\``)
  }

  addPopupEvents(popup) {
    popup.on('open', () => {
      let pan = [0, 0]
      let point = this.map.project(popup._lngLat)
      let popupSize = {
        width: popup._container.clientWidth,
        height: popup._container.clientHeight,
      }
      let popupRect = popup._container.getBoundingClientRect()
      let mapRect = popup._container.parentElement.getBoundingClientRect()

      // Center point left horizontally to make the popup visible, if needed
      let popupClippedLeftHorizontally = popupRect.left < 0 || mapRect.width - popupRect.left - popupSize.width < 0
      if (popupClippedLeftHorizontally) {
        pan[0] = point.x - mapRect.width / 32
      }

      // Center point right horizontally to make the popup visible, if needed
      let popupClippedRightHorizontally = popupRect.right < 0 || mapRect.width - popupRect.right - popupSize.width < 0
      if (popupClippedRightHorizontally) {
        pan[0] = point.x - mapRect.width / 32
      }

      // Move point to the top to make the popup visible, if needed
      let popupClippedTopVertically = popupRect.top < 0 || mapRect.height - popupRect.top - popupSize.height < 0
      if (popupClippedTopVertically) {
        pan[1] = point.y - (mapRect.height - popupRect.height) / 32
      }

      // Move point to the bottom to make the popup visible, if needed
      let popupClippedBottomVertically =
        popupRect.bottom < 0 || mapRect.height - popupRect.bottom - popupSize.height < 0
      if (popupClippedBottomVertically) {
        pan[1] = point.y - (mapRect.height - popupRect.height) / 32
      }

      if (pan !== [0, 0]) {
        this.map.panBy(pan)
      }
    })
    popup.on('close', () => {
      this.map.panBy([0, 0])
    })
  }

  updatePagination() {
    const THIS = this

    $(THIS.paginationTarget).twbsPagination('destroy')

    $(THIS.paginationTarget).twbsPagination({
      prev: 'Prev',
      next: 'Next',
      last: false,
      first: false,
      visiblePages: 3,
      startPage: THIS.searchParams.page,
      hideOnlyOnePage: true,
      totalPages: Math.ceil(THIS.vehicles.length / this.vehiclesPerPage),
      paginationClass: 'cnd-tabs cnd-justify-center cnd-mt-4 cnd-w-full',
      pageClass: 'cnd-tabs__tab cnd-bg-white',
      nextClass: 'cnd-tabs__tab cnd-bg-white',
      prevClass: 'cnd-tabs__tab cnd-bg-white',
      anchorClass: 'cnd-tabs__tab__link cyp-pagination-link',
      onPageClick: (e, page) => {
        // styleguide expects "a" tag to have "active" class, not "li"
        const activeTab = document.querySelector('.cnd-tabs__tab.active')

        if (activeTab) {
          activeTab.firstChild.classList.add('active')
        }

        if (THIS.pageNumber) {
          THIS.searchParams.page = page
          THIS.updateQuerystring()
        }

        THIS.pageNumber = page

        THIS.scrollToMap()
        THIS.updateResults()
      },
    })
  }

  updateResults() {
    const begin = (this.pageNumber - 1) * this.vehiclesPerPage
    const vehicles = this.vehicles.slice(begin, begin + this.vehiclesPerPage)
    const fragment = document.createDocumentFragment()
    let el

    if (vehicles.length > 0) {
      for (const [i, vehicle] of vehicles.entries()) {
        if (i === 4 && this.isStartEndSet && !this.fuzzyFilterTarget.value && !this.isFuzzyFilterCardDismissed) {
          const fuzzyFilterCard = document.createElement('cnd-fuzzy-filter-card')
          fuzzyFilterCard.className = 'cnd-p-0 cnd-mb-8 cnd-h-50 md:cnd-h-86'
          fuzzyFilterCard.setAttribute('show-options', true)
          fuzzyFilterCard.setAttribute('multi-day', this.startDate != this.endDate)
          fuzzyFilterCard.setAttribute('value', this.fuzzyFilterTarget.value)
          fuzzyFilterCard.dataset['target'] = 'search--vehicle-search.fuzzyFilterCard'
          fuzzyFilterCard.dataset['action'] =
            'cndChange->search--vehicle-search#onFuzzyFilterChange cndClick->search--vehicle-search#onFuzzyFilterClick'

          const col = document.createElement('div')
          col.className = 'cnd-col cnd-w-full md:cnd-w-1/2 lg:cnd-w-1/3'

          col.appendChild(fuzzyFilterCard)
          fragment.appendChild(col)
        }

        el = document.createElement('div')
        el.classList.add('cnd-col', 'cnd-w-full', 'md:cnd-w-1/2', 'lg:cnd-w-1/3')
        el.innerHTML = vehicle.html
        fragment.appendChild(el)
      }
    } else {
      el = document.createElement('div')
      el.classList.add('cnd-panel', 'cnd-panel--blue', 'cnd-w-full', 'cyp-no-vehicles')
      el.innerText = this.noVehicleFlash()
      fragment.appendChild(el)
    }

    // clear existing results
    while (this.resultsTarget.firstChild) {
      this.resultsTarget.removeChild(this.resultsTarget.firstChild)
    }

    this.resultsTarget.appendChild(fragment)
    this.showFavoriteLayout()
  }

  addEventListeners() {
    // Show 'Search Area' button
    this.map.on('dragend', () => {
      this.searchAreaBtnTarget.classList.remove('cnd-hidden')
    })

    PubSub.subscribe(window.PUBSUB_EVENTS.SEARCH_RESULTS, (msg, data) => {
      window.gtmDataLayer.push({
        event: msg,
        eventData: {
          error: data.error,
          // For some reason, GTM doesn't always correctly update array variables :(
          // Conversion to JSON seems to be a reasonable workaround for now.
          vehicleJson: JSON.stringify(data.vehicles),
        },
      })
    })

    window.onpopstate = (e) => {
      this.onPopState.call(this, e)
    }
  }

  searchArea() {
    this.searchAreaBtnTarget.classList.add('cnd-hidden')
    this.loadingTarget.classList.remove('cnd-hidden')

    const latLng = this.map.getCenter()
    this.geocodeAndSearch(latLng.lat, latLng.lng)
  }

  geocodeAndSearch(lat, lng) {
    this.geocoder
      .reverseGeocode({
        query: [lng, lat],
        limit: 1,
      })
      .send()
      .then((response) => {
        const address = LocationUtils.parseMapboxResults(response.body.features[0])

        this.extendSearch({
          location: address.formattedAddress,
          latitude: address.latitude,
          longitude: address.longitude,
        })

        this.setLocationValue(this.search.location)
        this.getSearchResults(1)
      })
  }

  toggleFullscreen() {
    const fullscreen = this.mapContainerTarget.classList.toggle('cnd-vehicle-map--fullscreen')
    this.fullscreenBtnTarget.innerText = fullscreen ? 'Smaller map' : 'Fullscreen map'
    this.map.resize()
  }

  toggleMap() {
    if (this.toggleMapBtnTarget.innerText === 'Show map') {
      this.mapContainerTarget.classList.add('cnd-block')
      this.toggleMapBtnTarget.innerText = 'Hide map'

      this.scrollToMap()
    } else {
      this.mapContainerTarget.classList.remove('cnd-block')
      this.toggleMapBtnTarget.innerText = 'Show map'
    }

    this.setRootMarkerPosition()
  }

  viewOnMap(e) {
    e.preventDefault()
    this.scrollToMap()
    // TODO Remove along with `app/views/searches/_results_template_control.html.erb`
    google.maps.event.trigger(this.markers[e.currentTarget.dataset.index], 'click')
  }

  // listens for browser forward/backward navigation
  onPopState(event) {
    // if event.state exists and/or we have a query string, use the URL params
    // directly instead of whatever rails thinks we should be using.
    if (event.state || window.location.search) {
      // sometimes "onpopstate" fires but the URL doesn't change so just
      // ignore duplicates to prevent unnescessary reloads.
      if (_.isEqual(event.state, this.searchParams)) {
        return
      }

      const query = qs.parse(window.location.search.slice(1))

      this.search = Object.assign(
        {},
        {
          startDatetimeMoment: moment(query.start_time_iso),
          endDatetimeMoment: moment(query.end_time_iso),
        },
        query
      )

      this.setLocationValue(this.search.location)
      this.setPickupReturnValues()
    } else {
      this.initializeForm()
    }

    // if you don't set "pageNumber" and getSearchResults to "0" the query
    // string will get updated and we don't want that to happen here.
    this.pageNumber = 0
    this.getSearchResults(0)
  }

  isOutsideHours(time) {
    // strip input date information to prevent date range errors
    time = moment(time.format('HH:mm'), 'HH:mm')

    return (
      time.isBetween(this.startOfDay, this.startAvailability, null, '[]') ||
      time.isBetween(this.endAvailability, this.endOfDay, null, '[]')
    )
  }

  initializeEnhancedDatePickers(unavailabilityHours, initType) {
    const type = initType === undefined ? 'getInst' : 'clear'

    // do not reinitialize when selecting a new start date
    if (initType !== 'resetTime') {
      this.setPickupReturnValues()

      MobiscrollUtils.newDateRangeInstance(
        this.startDateTarget,
        {
          defaultValue: [this.search.startDatetimeMoment.toDate(), this.search.endDatetimeMoment.toDate()],
          months: DeviceUtils.isTablet() ? 2 : 1,
          onInit: (e, inst) => {
            this.startDate = this.search.startDatetimeMoment.format('DD/MM/YYYY')
            this.endDate = this.search.endDatetimeMoment.format('DD/MM/YYYY')
            this.defineSetDates()
            this.toggleMultiDays(this.startDate, this.endDate)
            this.highlightSelectedDate()
          },
          onSet: (e, inst) => {
            this.resetTime()

            const { pickupMoment, returnMoment } = this.getPickupReturnMoment(
              moment(inst.startVal, 'DD/MM/YYYY'),
              moment(inst.endVal, 'DD/MM/YYYY')
            )
            // Set times
            this.startTimeInstance.setVal(pickupMoment.format('HH:mm'), true)
            this.endTimeInstance.setVal(returnMoment.format('HH:mm'), true)

            this.startDate = pickupMoment.format('DD/MM/YYYY')
            this.endDate = returnMoment.format('DD/MM/YYYY')
            this.startTime = pickupMoment.format('HH:mm')
            this.endTime = returnMoment.format('HH:mm')

            // Update datetime input fields
            this.startTimeTarget.value = pickupMoment.format(DateUtils.DATETIME_FORMAT)
            this.endTimeTarget.value = returnMoment.format(DateUtils.DATETIME_FORMAT)

            this.datesSetOnFirstSelect = this.startDate === this.endDate

            this.defineSetDates()
            this.toggleMultiDays(this.startDate, this.endDate)
            this.setDatetimeLabel()
            this.mergeDateTimes()
          },
        },
        type
      )
    }

    const startDatetimeMoment = this.search.startDatetimeMoment
      ? this.search.startDatetimeMoment
      : DateUtils.defaultStartMoment()

    MobiscrollUtils.newTimeInstance(
      this.startTimeTarget,
      {
        headerText: DeviceUtils.isMobile()
          ? `<strong>Pickup</strong> ${startDatetimeMoment.format('DD MMM YYYY')}`
          : '',
        defaultValue: startDatetimeMoment.format('HH:mm'),
        onInit: (e, inst) => {
          this.startTimeInstance = inst

          if (this.search.startDatetimeMoment !== '') {
            inst.setVal(this.search.startDatetimeMoment.format('HH:mm'), true)
            this.startTime = this.search.startDatetimeMoment.format('HH:mm')
          } else {
            inst.setVal(DateUtils.newDatetimeMoment().format('HH:mm'), true)
            this.startTime = DateUtils.newDatetimeMoment().format('HH:mm')
          }
          this.setDatetimeLabel()
        },
        display: this.setDisplay(),
        invalid: unavailabilityHours,
        onSet: (e, inst) => {
          this.startTime = inst._value

          this.setDatetimeLabel()
          this.mergeDateTimes()
        },
      },
      type
    )

    MobiscrollUtils.newTimeInstance(
      this.endTimeTarget,
      {
        headerText: DeviceUtils.isMobile() ? 'Available from 6am - 11pm' : '',
        defaultValue: this.search.endDatetimeMoment ? this.search.endDatetimeMoment.toDate() : this.defaultEndTime,
        onInit: (e, inst) => {
          this.endTimeInstance = inst

          if (this.search.endDatetimeMoment !== '') {
            inst.setVal(this.search.endDatetimeMoment.format('HH:mm'), true)
            this.endTime = this.search.endDatetimeMoment.format('HH:mm')
          } else {
            inst.setVal(DateUtils.defaultEndMoment().format('HH:mm'), true)
            this.endTime = DateUtils.defaultEndMoment().format('HH:mm')
          }
          this.setDatetimeLabel()
        },
        display: this.setDisplay(),
        invalid: unavailabilityHours,
        onSet: (e, inst) => {
          ;(this.endTime = inst._value), this.setDatetimeLabel()
          this.mergeDateTimes()
        },
      },
      type
    )
  }

  getPickupReturnMoment(pickupDateTimeMoment, returnDateTimeMoment) {
    let pickupMoment = pickupDateTimeMoment || DateUtils.defaultStartMoment()
    const startAvailability = this.element.dataset.startAvailability

    if (this.isOutsideHours(pickupMoment)) {
      if (pickupMoment.hours() >= 23) {
        pickupMoment = pickupMoment.clone().add(1, 'day')
      }

      pickupMoment = moment(pickupMoment.format('DD/MM/YYYY') + ' ' + startAvailability, DateUtils.DATETIME_FORMAT)
    }

    let returnMoment = null

    // Add 3 hours if return date and pickup date are the same
    if (
      !returnDateTimeMoment ||
      !pickupDateTimeMoment ||
      !returnDateTimeMoment._isValid ||
      !pickupDateTimeMoment._isValid ||
      pickupDateTimeMoment.isSame(returnDateTimeMoment, 'day')
    ) {
      returnMoment = pickupMoment.clone().add(3, 'hours')
    } else {
      returnMoment = returnDateTimeMoment
    }

    if (this.isOutsideHours(returnMoment)) {
      if (pickupMoment.hours() >= 23) {
        returnMoment = returnMoment.clone().add(1, 'day')
      }

      returnMoment = moment(returnMoment.format('DD/MM/YYYY') + ' ' + startAvailability, DateUtils.DATETIME_FORMAT)
    }

    return {
      pickupMoment: pickupMoment,
      returnMoment: returnMoment,
    }
  }

  toggleMultiDays(startDate, endDate) {
    if (this.hasFuzzyFilterOptionsTarget) {
      this.fuzzyFilterOptionsTarget.setAttribute('multi-day', startDate != endDate)
    }

    if (this.hasFuzzyFilterCardTarget) {
      this.fuzzyFilterCardTarget.setAttribute('multi-day', startDate != endDate)
    }
  }

  mergeDateTimes() {
    if (this.startDate !== undefined && this.startTime !== '') {
      let formattedStartDate = this.startDate.split('/').reverse().join('-')
      const startDatetime = formattedStartDate + 'T' + this.startTime + ':00'

      this.extendSearch({
        startDatetimeMoment: moment(startDatetime),
      })
    }

    if (this.endDate !== undefined && this.endTime !== '') {
      let formattedEndDate = this.endDate.split('/').reverse().join('-')
      const endDatetime = formattedEndDate + 'T' + this.endTime + ':00'

      this.extendSearch({
        endDatetimeMoment: moment(endDatetime),
      })
    }
    if (this.startTime && this.startDate && this.endTime && this.endDate) {
      this.checkDatetimeValidity()
    }
  }

  setDefaultEndDatetime() {
    if (this.search.endDatetimeMoment === '') {
      let formattedEndDate = this.endDate.split('/').reverse().join('-')
      const endDatetime = formattedEndDate + 'T' + this.endTime + ':00'

      this.extendSearch({
        endDatetimeMoment: moment(endDatetime),
      })
    }
  }

  setDatetimeLabel() {
    if (this.startDate !== '' && this.startDate !== undefined) {
      let startDate = this.startDate.split('/').reverse().join('-')
      let formattedStartDate = moment(startDate).format(DateUtils.DATETIME_LABEL_FORMAT)

      this.pickupDateLabelTargets.forEach((el) => {
        el.innerHTML = '<strong>Pickup</strong>'
      })
    }
    if (this.endDate !== '' && this.endDate !== undefined) {
      let endDate = this.endDate.split('/').reverse().join('-')
      let formattedEndDate = moment(endDate).format(DateUtils.DATETIME_LABEL_FORMAT)

      this.returnDateLabelTargets.forEach((el) => {
        el.innerHTML = '<strong>Return</strong>'
      })
    }
  }

  setDisplay() {
    if (DeviceUtils.isMobile()) {
      return 'center'
    } else {
      return 'inline'
    }
  }

  displayTimeInput() {
    if (DeviceUtils.isMobile()) {
      // toggle input
      this.startTimeTarget.classList.toggle('cnd-hidden')
      this.startTimeIconTarget.classList.toggle('cnd-hidden')
      this.endTimeTarget.classList.toggle('cnd-hidden')
      this.endTimeIconTarget.classList.toggle('cnd-hidden')
      // toggle input labels
      this.startTimeLabelTargets.forEach((el) => {
        el.classList.toggle('cnd-hidden')
      })
      this.endTimeLabelTargets.forEach((el) => {
        el.classList.toggle('cnd-hidden')
      })
    } else if (DeviceUtils.isTablet()) {
      this.startTimeLabelTargets.forEach((el) => {
        el.classList.toggle('cnd-hidden')
      })
      this.endTimeLabelTargets.forEach((el) => {
        el.classList.toggle('cnd-hidden')
      })
    }
  }

  resetDatetimes() {
    //reset pickers
    this.resetDatetimeValues()
    this.initializeForm()
    //reset date and time labels
    this.resetDatetimeLabels()
    // clear errors and disable submit button
    this.alertTarget.classList.add('cnd-hidden')
    this.submitTarget.setAttribute('disabled', 'disabled')
    // update url and search without params
    this.updateQuerystring()
    this.getSearchResults()
  }

  resetTime() {
    //reset date and time labels
    this.resetDatetimeLabels()
    //reset pickers
    this.resetDatetimeValues()
    this.initializeForm('resetTime')

    // clear errors and disable submit button
    this.alertTarget.classList.add('cnd-hidden')
    this.submitTarget.setAttribute('disabled', 'disabled')
  }

  resetDatetimeLabels() {
    this.pickupDateLabelTarget.innerText = 'Pickup'
    this.returnDateLabelTarget.innerText = 'Return'
  }

  resetDatetimeValues() {
    this.startDate = ''
    this.startTime = undefined
    this.endDate = ''
    this.endTime = undefined
  }

  defineSetDates() {
    // determine if start and end dates are set
    this.startDateSet = this.startDate !== '' && this.startDate !== undefined
    this.endDateSet = this.endDate !== '' && this.endDate !== undefined
    this.endDateUnset = this.endDate === '' || this.endDate === undefined
  }

  highlightSelectedDate() {
    const element = document.getElementsByClassName('mbsc-btn-e')

    Array.from(element).forEach((el) => {
      el.addEventListener('mouseenter', (event) => {
        this.pickupDateLabelTarget.classList.add('cnd-date-selection')

        if (this.startDateSet && this.endDateSet && this.startDate !== this.endDate) {
          return this.pickupDateLabelTarget.classList.add('cnd-date-selection')
        }

        if ((this.startDateSet && this.endDateUnset) || this.datesSetOnFirstSelect) {
          this.pickupDateLabelTarget.classList.remove('cnd-date-selection')
          this.returnDateLabelTarget.classList.add('cnd-date-selection')
          return
        }
      })

      el.addEventListener('mouseleave', (event) => {
        if (this.startDateSet && this.endDateSet) {
          this.pickupDateLabelTarget.classList.remove('cnd-date-selection')
          this.returnDateLabelTarget.classList.remove('cnd-date-selection')
        }

        if ((this.startDateSet && this.endDateUnset) || this.datesSetOnFirstSelect) {
          this.returnDateLabelTarget.classList.remove('cnd-date-selection')
        }
      })
    })
  }

  downloadApp(event) {
    const appUrl = _.get(event, 'target.dataset.appUrl')

    if (appUrl && DeviceUtils.isMobile()) {
      window.open(appUrl, '_blank')
    }
  }

  get favoriteFilterValue() {
    return this.favoriteFilterTarget.dataset.value
  }

  get anyFiltersApplied() {
    return this.additionalFilterTarget.classList.contains('cnd-bg-blue-lightest') || this.dateRangeFilterApplied
  }

  get dateRangeFilterApplied() {
    return this.search.startDatetimeMoment && this.search.endDatetimeMoment
  }

  priceRangeValue(target) {
    return target.dataset.dirty === 'true' ? target.value : undefined
  }

  noVehicleFlash() {
    let message = 'No vehicles found. Please try searching in a different area or changing the filters.'
    if (this.favoriteFilterValue === 'true') {
      if (this.anyFiltersApplied) {
        message = L10n.t('web-search-favourites.no-favourites-yet')
      } else {
        message = L10n.t('web-search-favourites.you-dont-have-any-favourites-yet')
      }
    }
    return message
  }

  showFavoriteLayout() {
    const isFavoriteSearch = this.favoriteFilterValue === 'true'
    this.mapContainerTarget.classList.toggle('cnd-hidden', isFavoriteSearch)
    this.hasDisclaimerTarget && this.disclaimerTarget.classList.toggle('cnd-hidden', isFavoriteSearch)
    this.hasVerificationBannerTarget && this.verificationBannerTarget.classList.toggle('cnd-hidden', isFavoriteSearch)
    !this.dateRangeFilterApplied && this.dateTimeFilterTextTarget.classList.toggle('cnd-hidden', isFavoriteSearch)
    this.toggleMapBtnTarget.classList.toggle('cnd-hidden', isFavoriteSearch)
    this.hasGuestTutorialTarget && this.guestTutorialTarget.classList.toggle('cnd-hidden', isFavoriteSearch)
  }

  hideVehicle() {
    let pageNumber
    const lastPage = Math.ceil(this.vehicles.length / this.vehiclesPerPage)

    // when current page is last one and only one vehicle is left in the page
    if (this.searchParams.page === lastPage && this.vehicles.length % this.vehiclesPerPage === 1) {
      pageNumber = this.searchParams.page - 1
    }

    this.getSearchResults(pageNumber, false)
  }

  setPriceRangeValue(type, value) {
    const priceRange = eval(`this.${type.toLowerCase()}PriceRangeTarget`)
    const mainPriceRange = eval(`this.main${type}PriceRangeTarget`)
    ;[priceRange, mainPriceRange].forEach((range) => {
      range.value = value
      range.dispatchEvent(new Event('input'))
    })
  }

  notifySegment() {
    let now = performance.now()
    let [timing] = performance.getEntriesByType('navigation') // returns PerformanceNavigationTiming

    if (typeof timing === 'undefined') {
      timing = performance.timing // returns PerformanceTiming
      now = new Date().getTime()
    }

    if (typeof analytics === 'undefined') {
      return
    }
    if (typeof timing === 'undefined') {
      return
    }

    let properties = {
      member_id: window.cndVars.currentMemberId,
      now: now,
      domInteractive: now - timing.domInteractive,
      fetchStart: now - timing.fetchStart,
      totalLoadTime: now - timing.redirectStart,
      domContentLoaded: timing.domContentLoadedEventEnd - timing.domContentLoadedEventStart,
      loadEventStart: now - timing.loadEventStart,
      responseTime: timing.responseEnd - timing.requestStart,
      renderTime: timing.domComplete - timing.domInteractive,
      rawData: timing.toJSON(),
    }

    // eslint-disable-next-line no-undef
    analytics.track('Search:Client Page Load End', properties)
  }

  fetchRegionBasedRadius(radius_km) {
    if (this.element.dataset.country === 'US') {
      const miles = ConversionUtils.convertKmToMi(radius_km)
      return Math.round(miles / 10) * 10
    } else {
      return radius_km
    }
  }

  trackPricingTooltip() {
    this.trackUserClickFor('PricingTooltip')
  }

  trackVerificationInfo() {
    this.trackUserClickFor('Banner Clicked', 'verification')
  }

  trackUserClickFor(eventName, bannerType = null) {
    let params = Object.assign({}, this.searchParams)
    Object.assign(params, { event_name: eventName, banner_type: bannerType })

    axios.get('/search/track_click_info', {
      responseType: 'json',
      headers: { 'X-Requested-With': 'XMLHttpRequest' },
      params,
    })
  }
}
