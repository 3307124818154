import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'selectInput',
    'invoiceLink',
    'itemizedTransactionSelect',
    'itemizedTransactionSubmit',
    'tax1099KFormSelect',
    'tax1099KFormSubmit',
    'selectEofyCountryCode',
    'downloadCsvUrl',
    'downloadCsvButton',
    'eofyStatementText',
    'eofyStatementLink',
  ]

  selectedCountryCode = null

  selectInvoice() {
    const selectVal = this.selectInputTarget.value
    this.invoiceLinkTarget.disabled = !selectVal
  }

  selectItemizedTransaction() {
    this.itemizedTransactionSubmitTarget.disabled = !this.itemizedTransactionSelectTarget.value
  }

  selectTax1099KForm() {
    this.tax1099KFormSubmitTarget.disabled = !this.tax1099KFormSelectTarget.value
  }

  getFinancialYearSummaryLink() {
    const countryCodeVal = this.selectEofyCountryCodeTarget.value
    const year = this._getLastCompletedFinancialYear(countryCodeVal)

    if (countryCodeVal === 'us' || countryCodeVal == 'ca') {
      this.eofyStatementTextTarget.innerHTML = `${year} Financial year summary`
    } else {
      this.eofyStatementTextTarget.innerHTML = `${year - 1} - ${year} Financial year summary`
    }

    this.downloadCsvUrlTarget.action = `/fy_summary/${year}/${countryCodeVal}`

    if (['au', 'ca', 'us'].includes(countryCodeVal)) this.downloadCsvButtonTarget.disabled = false
    else this.downloadCsvButtonTarget.disabled = true
  }

  _getLastCompletedFinancialYear(countryCode) {
    const financialCalendar = this.element.getAttribute('data-controller-financial-calendar')
    const parsedFinancialCalendar = JSON.parse(financialCalendar)
    const countryFinancialCalendar = parsedFinancialCalendar[countryCode]
    const now = new Date()
    const year = now.getFullYear()
    const currentEofy = new Date(year, countryFinancialCalendar.end.month - 1, countryFinancialCalendar.end.day)

    return now > currentEofy ? year : year - 1
  }
}
