import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'extendButton', 'creditCard', 'buttonContainer']

  initialize() {
    if (this.hasCreditCardTarget) {
      this.creditCardTarget.addEventListener('disablebutton', () => (this.extendButtonTarget.disabled = true))
      this.creditCardTarget.addEventListener('enablebutton', () => (this.extendButtonTarget.disabled = false))

      this.buttonContainerTarget.addEventListener('mouseover', () => {
        if (this.extendButtonTarget.disabled) {
          this.creditCardTarget.cnd.panel.classList.add('cnd-shadow-outline')
        }
      })
      this.buttonContainerTarget.addEventListener('mouseleave', () => {
        this.creditCardTarget.cnd.panel.classList.remove('cnd-shadow-outline')
      })
    }
  }

  submitAndDisable(e) {
    // This is to address an old Microsoft Edge bug where disabled buttons
    // accept clicks if they contain any HTML elements (eg a <span> like here).
    if (this.extendButtonTarget.disabled == true) {
      e.preventDefault()
      return false
    } else {
      this.extendButtonTarget.disabled = true

      // Method fired by the extend booking form element
      if (this.hasCreditCardTarget) {
        this.creditCardTarget.cnd.cardSubmit(e)
      } else {
        this.submit()
      }

      return true
    }
  }

  submit(e) {
    this.creditCardTarget.cnd.cardSubmit(e)
  }
}
