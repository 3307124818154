import { Controller } from 'stimulus'
import TargetUtils from '../../utils/target_utils'

export default class extends Controller {
  static targets = [
    'submitBtn',
    'usePeakRatesSelected',
    'usePeakRatesUnSelected',
    'peakRatesInformation',
    'peakDaysHint',
    'togglePeakRates',
    'useLongBookingDiscountSelected',
    'useLongBookingDiscountUnSelected',
    'LongBookingDiscountInformation',
    'longBookingDiscountInformation',
    'toggleLongBookingDiscount',
    'togglePeakDaySelect',
    'peakSelectedDays',
    'rateChanged',
    'hourlyRatesInformation',
    'hourlyMinRatesInformation',
    'hourlyMaxRatesInformation',
  ]

  // Event listeners

  initialize() {
    this.targetUtils = new TargetUtils(this)
  }

  rateChanged(event = {}) {
    const value = parseFloat(event.target.value)
    const minThreshold = parseFloat(event.target.getAttribute('data-min'))
    const maxThreshold = parseFloat(event.target.getAttribute('data-max'))
    const rate = event.target.getAttribute('data-rate')
    const hintParentElement = document.querySelector('.' + rate + '-rate-information')
    const minHintElement = document.querySelector('.' + rate + '-rate-min-information')
    const maxHintElement = document.querySelector('.' + rate + '-rate-max-information')
    if (value < minThreshold) {
      hintParentElement.classList.remove('cnd-hidden')
      minHintElement.classList.remove('cnd-hidden')
      maxHintElement.classList.add('cnd-hidden')
    } else if (value > maxThreshold) {
      hintParentElement.classList.remove('cnd-hidden')
      minHintElement.classList.add('cnd-hidden')
      maxHintElement.classList.remove('cnd-hidden')
    } else {
      hintParentElement.classList.add('cnd-hidden')
      minHintElement.classList.add('cnd-hidden')
      maxHintElement.classList.add('cnd-hidden')
    }
    this.submitBtnTarget.classList.remove('cnd-hidden')
  }

  togglePeakDaySelect(event = {}) {
    const peakDays = JSON.stringify(event.detail.value)
    const parsedPeakDays = JSON.parse(peakDays)

    this.peakSelectedDaysTarget.value = peakDays

    if (parsedPeakDays.sat === false || parsedPeakDays.sun === false) {
      this.peakDaysHintTarget.classList.remove('cnd-hidden')
    } else {
      this.peakDaysHintTarget.classList.add('cnd-hidden')
    }
  }

  toggleLongBookingDiscount(event = {}) {
    const checked = event.detail.value

    if (checked) {
      this.useLongBookingDiscountSelectedTarget.checked = true
      this.longBookingDiscountInformationTarget.classList.remove('cnd-hidden')
    } else {
      this.useLongBookingDiscountUnSelectedTarget.checked = true
      this.longBookingDiscountInformationTarget.classList.add('cnd-hidden')
    }
  }

  togglePeakRates(event = {}) {
    const checked = event.detail.value

    if (checked) {
      this.usePeakRatesSelectedTarget.checked = true
      this.peakRatesInformationTarget.classList.remove('cnd-hidden')
    } else {
      this.usePeakRatesUnSelectedTarget.checked = true
      this.peakRatesInformationTarget.classList.add('cnd-hidden')
    }
  }
}
