import { Controller } from 'stimulus'
import * as axios from 'axios'
import get from 'lodash/get'

import StripeUtils from '../../utils/stripe_utils'
import AxiosUtils from '../../utils/axios_utils'
import TargetUtils from '../../utils/target_utils'

import brandontext from 'styles/assets/fonts/brandontext/brandontext-regular.woff'

export default class extends Controller {
  static targets = [
    'messages',
    'errorMessages',
    'existingNumber',
    'existingExpiry',
    'cardErrors',
    'cardHolder',
    'cardAddressInput',
    'cardAddress',
    'cardNumber',
    'cardStreet',
    'cardUnitNumber',
    'cardSuburb',
    'cardState',
    'cardCountry',
    'cardPostCode',
    'cardElement',
    'existingCard',
    'updateCardButton',
    'cardFields',
    'cardFieldsLabel',
    'panel',
    'cancelBtn',
    'chargeAmount',
  ]

  card

  stripe

  initialize() {
    this.targetUtils = new TargetUtils(this)
    this.clearMessages()
    const countryCode = this.data.get('country-code')

    StripeUtils.getStripeKey(countryCode)
      .then((response) => {
        this.stripe = StripeUtils.initialiseStripe(response.data.stripe_key)

        this.createCardElements()

        this.addEventListeners()
      })
      .catch((error) => {
        this.errorMessagesTarget.innerHTML = error
        this.targetUtils.show('errorMessagesTarget')
      })

    if (this.cardFieldsTarget.classList.contains('cnd-hidden')) {
      this.element.dispatchEvent(new Event('enablebutton'))
    }

    window.autocompleteAddress('card', 'credit_card')

    // Expose credit card submit method, panel and charge amount targets
    this.element.cnd = {
      cardSubmit: this.cardSubmit.bind(this),
      panel: this.panelTarget,
    }

    if (this.hasChargeAmountTarget) {
      this.element.cnd.chargeAmount = this.chargeAmountTarget
    }
  }

  changeCard(e) {
    e.preventDefault()
    this.element.dispatchEvent(new Event('disablebutton'))
    this.targetUtils.show('cardFieldsTarget')
    if (this.hasCardFieldsLabelTarget) {
      this.targetUtils.show('cardFieldsLabelTarget')
    }
    this.cardAddressInputTarget.required = true
    this.targetUtils.hide('existingCardTarget')
    this.targetUtils.show('cancelBtnTarget')
  }

  cardSubmit(e) {
    this.targetUtils.hide('cancelBtnTarget')
    if (this.cardFieldsTarget.classList.contains('cnd-hidden')) {
      this.element.dispatchEvent(new Event('cardupdated'))
      return
    }
    if (e) e.preventDefault()
    const THIS = this
    const fullAddress = [this.cardAddressTarget.value, this.cardNumberTarget.value, this.cardStreetTarget.value]
      .filter((el) => el)
      .join(' ')

    const additionalData = {
      name: this.cardHolderTarget.value,
      address_line1: fullAddress,
      address_line2: this.cardUnitNumberTarget.value,
      address_city: this.cardSuburbTarget.value,
      address_country: this.cardCountryTarget.value,
      address_state: this.cardStateTarget.value,
      address_zip: this.cardPostCodeTarget.value,
    }

    this.clearMessages()

    THIS.stripe.createToken(THIS.card, additionalData).then((result) => {
      if (result.error) {
        THIS.errorMessagesTarget.innerHTML = result.error.message
        this.targetUtils.show('errorMessagesTarget')
        this.element.dispatchEvent(new Event('carderror'))
      } else {
        additionalData.number = result.token.id
        additionalData.exp_month = result.token.card.exp_month
        additionalData.exp_year = result.token.card.exp_year
        additionalData.holder = result.token.card.name
        THIS.updateCard(additionalData)
      }
    })
  }

  updateCard(cardata) {
    const countryCode = this.data.get('country-code')

    axios
      .post(`/credit_cards?country=${countryCode.toLowerCase() || 'au'}`, cardata, AxiosUtils.getHeaders())
      .then((response) => {
        this.messagesTarget.innerHTML = 'Card details updated successfully'
        this.targetUtils.show('messagesTarget')

        if (this.hasExistingNumberTarget) {
          this.existingNumberTarget.innerHTML = response.data.masked_number
        }

        if (this.hasExistingExpiryTarget) {
          this.existingExpiryTarget.innerHTML = response.data.expiry
        }
        this.element.dispatchEvent(new Event('cardupdated'))
      })
      .catch((error) => {
        this.targetUtils.show('errorMessagesTarget')
        this.errorMessagesTarget.innerHTML = get(error, 'response.data.error.message', error.message)
      })
  }

  clearMessages() {
    this.messagesTarget.innerHTML = ''
    this.errorMessagesTarget.innerHTML = ''
    this.targetUtils.hide('messagesTarget')
    this.targetUtils.hide('errorMessagesTarget')
  }

  addEventListeners() {
    if (this.hasCardElementTarget) {
      StripeUtils.addCardElementEventListener(this.card, this.cardErrorsTarget)

      this.card.addEventListener('change', (e) => {
        if (e.complete && !e.error) {
          this.element.dispatchEvent(new Event('enablebutton'))
        } else {
          this.element.dispatchEvent(new Event('disablebutton'))
        }
      })
    }
  }

  createCardElements() {
    const elements = this.stripe.elements({
      fonts: [
        {
          family: 'BrandonText',
          src: `url('${brandontext}')`,
        },
      ],
    })

    if (this.hasCardElementTarget) {
      this.card = elements.create('card', {
        hidePostalCode: true,
        style: StripeUtils.elementStyles,
        classes: StripeUtils.elementClasses,
      })
      this.card.mount(this.cardElementTarget)
    }
  }

  cancelChanges() {
    this.targetUtils.hide('cancelBtnTarget')
    this.targetUtils.hide('cardFieldsTarget')
    if (this.hasCardFieldsLabelTarget) {
      this.targetUtils.hide('cardFieldsLabelTarget')
    }
    this.targetUtils.show('existingCardTarget')
    this.element.dispatchEvent(new Event('enablebutton'))
  }
}
