import { Controller } from 'stimulus'

export default class extends Controller {
  initialize() {
    this.click = this.click.bind(this)
  }

  connect() {
    this.element.addEventListener('click', this.click)
  }

  disconnect() {
    this.element.removeEventListener('click', this.click)
  }

  click() {
    if (this.data.get('event')) {
      let properties = {}
      try {
        properties = JSON.parse(this.data.get('properties'))
      } catch (e) {
        /* invalid JSON string */
      }
      if (typeof analytics !== 'undefined') {
        analytics.track(this.data.get('event'), properties)
      }
    }

    if (this.data.get('url')) window.location.href = this.data.get('url')
  }
}
