import { Controller } from 'stimulus'
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'
import FlashMessageUtils from '../../utils/flash_message_utils'
import { ACCEPTED_IMAGE_FILES } from '../../constants/uploads'

export default class extends Controller {
  static targets = [
    'eventName',
    'eventType',
    'dropzone',
    'form',
    'logType',
    'reservationId',
    'uploaderRole',
    'vehicleId',
    'submitBtn',
  ]

  eventName
  hasAddedFiles

  initialize() {
    if (this.dropzoneTarget.cnd) {
      this.setDropzoneConfig()
    } else {
      this.dropzoneTarget.addEventListener('initializedFileUpload', () => this.setDropzoneConfig())
    }
  }

  setDropzoneConfig() {
    this.dropzoneTarget.cnd.setOptions({ acceptedFiles: ACCEPTED_IMAGE_FILES })

    this.dropzoneTarget.addEventListener('addedfile', () => {
      this.submitBtnTarget.disabled = true
      window.onbeforeunload = (e) => (e ? (e.returnValue = '') : '')
    })

    this.dropzoneTarget.addEventListener('queuecomplete', () => {
      this.isFileUploaded = true
      this.enableSubmitBtn()
    })
  }

  submitForm() {
    window.onbeforeunload = null
    this.formTarget.submit()
  }

  handleSubmit(e) {
    e.preventDefault()

    if (!this.eventName) {
      this.showAlert('Please let us know if you are uploading "Pickup" or "Return" photos')
      return false
    }

    if (!this.isFileUploaded) {
      this.showAlert('Please add at least one photo before continuing.')
      return false
    }

    this.createVehicleLog()
  }

  getEventName() {
    let eventName

    if (this.hasEventNameTarget && this.eventNameTarget.value) {
      eventName = this.eventNameTarget.value
    }

    if (this.hasEventTypeTarget) {
      const eventType = this.eventTypeTargets.find((el) => el.checked)
      if (eventType) eventName = eventType.value
    }

    return eventName
  }

  showAlert(message) {
    FlashMessageUtils.showWarning(message)
  }

  createVehicleLog() {
    const photos = this.dropzoneTarget.cnd.getUploadedFiles()

    if (photos.length < 1) {
      FlashMessageUtils.showWarning('An error occured while processing your request. Please try again.')
      return false
    }

    const signedIds = []
    photos.forEach((el) => signedIds.push(el.value))

    axios({
      method: 'post',
      url: this.formTarget.action,
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
      data: {
        vehicle_log: {
          event: this.eventName,
          log_type: this.logTypeTarget.value,
          photos: signedIds,
          reservation_id: this.reservationIdTarget.value,
          uploader_role: this.uploaderRoleTarget.value,
          vehicle_id: this.vehicleIdTarget.value,
        },
      },
    })
      .then((response) => {
        if (response.status === 200) {
          FlashMessageUtils.showSuccess(response.data.notice)
          window.onbeforeunload = null

          if (this.hasEventTypeTarget) {
            this.eventTypeTargets.forEach((el) => (el.checked = false))
          }

          this.dropzoneTarget.cnd.reset()

          this.displayPhotos(`${this.eventName}_damage_photos`, response.data.photo_urls)
          this.submitBtnTarget.disabled = true
        } else {
          this.showAlert(response.data.notice)
        }
      })
      .catch((error) => {
        this.showAlert(error)
        this.submitBtnTarget.disabled = true
      })
  }

  displayPhotos(containerId, urls) {
    const damagePhotosEl = document.getElementById(containerId)

    if (damagePhotosEl) {
      const photos = urls.map((url) => {
        return `<a href="${url}" target="_blank" class="cnd-border-none cnd-mr-2 cnd-mb-2 cnd-w-24 cnd-h-24">
          <img src="${url}" class="cnd-object-cover cnd-w-full cnd-h-full"></a>`
      })

      damagePhotosEl.insertAdjacentHTML(
        'beforeend',
        `<div class="cnd-panel">
          <div class="cnd-dropdown cnd-dropdown--single" data-controller="components--dropdown">
            <button class="cnd-dropdown__title" data-target="components--dropdown.button">
              ${urls.length} condition ${urls.length > 1 ? 'photos' : 'photo'} uploaded
            </button>
            <div class="cnd-dropdown__content">
              <div class="cnd-flex cnd-flex-wrap">
                ${photos.join('')}
              </div>
            </div>
          </div>
        </div>`
      )
    }
  }

  enableSubmitBtn() {
    if (!this.eventName) this.eventName = this.getEventName()
    this.submitBtnTarget.disabled = !this.eventName || !this.isFileUploaded
  }
}
