import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [
    'form',
    'minNotice',
    'minNoticeAlert',
    'magicBlockoutsSelected',
    'magicBlockoutsUnselected',
    'toggleMagicBlockouts',
    'useOurRoadsideSelected',
    'useOurRoadsideUnselected',
    'roadsideInformation',
    'toggleRoadside',
  ]

  initialize() {
    if (this.hasMinNoticeTarget) {
      this.minNoticeAlert()
    }
  }

  submit(e) {
    e.preventDefault()
    this.formTarget.submit()
  }

  toggleMagicBlockouts(event = {}) {
    const checked = event.detail.value

    if (checked) {
      this.magicBlockoutsSelectedTarget.checked = true
    } else {
      this.magicBlockoutsUnselectedTarget.checked = true
    }
  }

  toggleRoadside(event = {}) {
    const checked = event.detail.value

    if (checked) {
      this.useOurRoadsideSelectedTarget.checked = true
      this.roadsideInformationTarget.classList.add('cnd-hidden')
    } else {
      this.useOurRoadsideUnselectedTarget.checked = true
      this.roadsideInformationTarget.classList.remove('cnd-hidden')
    }
  }

  minNoticeAlert() {
    let header
    let content

    if (this.minNoticeTarget.value === '') {
      return this.minNoticeAlertTarget.classList.add('cnd-hidden')
    }

    if (this.minNoticeTarget.value == 0) {
      header = L10n.t('owner-listing-availability.your-car-wont-require-any-advance-notice')
      content =
        L10n.t('owner-listing-availability.with-this-setting-you-may-only-have-minutes') +
        `<a target=_blank href=${window.cndVars.zendeskBaseUrl}/articles/` +
        `360021491812#setting-minimum-advance-notice-period>${L10n.t('owner-listing-availability.a-lockbox')}</a>` +
        L10n.t('owner-listing-availability.or-some-other-way-to-handover-you')
    } else {
      let notice
      let percentage

      switch (this.minNoticeTarget.value) {
        case '1':
          notice = '1 hours'
          percentage = 30
          break
        case '2':
          notice = '2 hours'
          percentage = 38
          break
        case '3':
          notice = '3 hours'
          percentage = 42
          break
        case '6':
          notice = '6 hours'
          percentage = 45
          break
        case '12':
          notice = '12 hours'
          percentage = 55
          break
        case '24':
          notice = '1 day'
          percentage = 75
          break
        case '48':
          notice = '2 days'
          percentage = 80
          break
      }

      header = L10n.t('owner-listing-availability.instant-keys-prompt-message', {
        trips_percentage: percentage,
        notice_period: notice,
      })
      content =
        L10n.t('owner-listing-availability.notice.maximise-your-bookings') +
        `<a target=_blank href=${window.cndVars.zendeskBaseUrl}/articles/360021491812` +
        `#setting-minimum-advance-notice-period>${L10n.t('owner-listing-availability.getting-a-lockbox')}</a>` +
        L10n.t('owner-listing-availability.or-some-other-way-to-hand-over-yo')
    }

    this.minNoticeAlertTarget.innerHTML = `<div class="cnd-panel__header">${header}</div><div>${content}</div>`
    this.minNoticeAlertTarget.classList.remove('cnd-hidden')
  }

  showTodayCalendar() {
    $('.fc-today-button').trigger('click')
  }
}
