import { Controller } from 'stimulus'
import * as axios from 'axios'

import AxiosUtils from '../../utils/axios_utils'

export default class extends Controller {
  static targets = ['unSubscribeAllPrefBtn']

  initialize() {
    this.setMemberEmail()
  }

  disableActionBtns() {
    this.unSubscribeAllPrefBtnTarget.style.pointerEvents = 'none'
  }

  setMemberEmail() {
    let queryString = location.search
    let urlParams = new URLSearchParams(queryString)
    this.email = urlParams.get('email')
  }

  constructNotificationPreferenceParams() {
    let obj = {
      email: this.email,
      email_newsletter_and_updates: false,
      email_new_cars_near_you: false,
      email_owner_tips_and_tricks: false,
      email_discounts_and_promotions: false,
      unsubscribed_from_marketing: false,
      email_booking_updates: false,
    }

    return new URLSearchParams(obj).toString()
  }

  unSubscribeAllPreferences() {
    this.disableActionBtns()
    this.params = this.constructNotificationPreferenceParams()
    axios.get(`/unsubscribe_notification_preference?${this.params}`, AxiosUtils.getHeaders()).then(() => {
      document.getElementById('unsubscribeBlock').remove()
      document.getElementById('unsubscribeAllBlock').classList.remove('cnd-hidden')
    })
  }
}
