import { Controller } from 'stimulus'

// Axios
import * as axios from 'axios'
import AxiosUtils from '../../utils/axios_utils'

// Constants
import { REVIEW_TYPES, ISSUES_NEW } from '../../constants/reviews'

let targetsWithBinding = ['reviewOf', 'reviewFor']

export default class extends Controller {
  static targets = targetsWithBinding.concat([
    'form',
    'alert',
    'details',
    'issueNotice',

    'rating',
    'ratingText',
    'ratingInfoText',

    'issuesText',
    'issues',
    'issue',

    'publicFeedback',
    'privateFeedbackButton',
    'privateFeedbackSection',
    'privateFeedback',
    'publicFeedbackText',

    'submitButton',
    'refreshButton',
  ])

  initialize() {
    let dataset = this.element.dataset

    this.reservationId = JSON.parse(dataset.reservationId)

    this.reviewOf = dataset.reviewOf

    this.isReviewOfVehicle = this.reviewOf === 'vehicle'
    this.isReviewOfBorrower = this.reviewOf === 'borrower'

    this.reviewFor = this.isReviewOfVehicle ? 'owner' : 'borrower'

    this.setupIssues()
    this.updateTargetsWithBinding()
  }

  hide(target) {
    target.classList.add('cnd-hidden')
  }

  show(target) {
    target.classList.remove('cnd-hidden')
  }

  disableSubmit() {
    if (!this.rating) {
      this.disableSubmitForNegative()
    } else {
      this.submitButtonTarget.disabled = false
    }
  }

  disableSubmitForNegative() {
    let issueChecked = this.issueTargets.some((issue) => {
      return issue.checked
    })

    if (issueChecked || this.privateFeedbackTarget.value || this.publicFeedbackTarget.value) {
      this.submitButtonTarget.disabled = false
    } else {
      this.submitButtonTarget.disabled = true
    }
  }

  updateHTML(target, html) {
    target.innerHTML = html
  }

  showAlert(text, isWarning = false) {
    this.updateHTML(this.alertTarget, text)
    this.alertTarget.classList.toggle('warning', isWarning)
    this.alertTarget.classList.toggle('success', !isWarning)
    this.show(this.alertTarget)
  }

  hideAlert() {
    this.updateHTML(this.alertTarget, '')
    this.alertTarget.classList.remove('warning', 'success')
    this.alertTarget.classList.remove('warning', 'success')
    this.hide(this.alertTarget)
  }

  selectRating(e) {
    this.rating = JSON.parse(e.currentTarget.dataset.val)

    let ratingText = ''
    let ratingInfoText = ''
    let issuesText = ''
    let publicFeedbackText = ''

    if (this.rating) {
      ratingText = "That's great! We'd love to know more."
      issuesText = 'Any issues? (Optional)'
    } else {
      ratingText = "We're sorry to hear that."
      issuesText = 'What were the issues?'

      if (this.isReviewOfBorrower) {
        ratingInfoText = 'By submitting this review, the borrower will be prevented from renting your car in future.'
      } else if (this.isReviewOfVehicle) {
        ratingInfoText = `Your thumbs-down review will be public on the ${this.reviewOf}'s profile.`
        publicFeedbackText = `Your review will be shown on the ${this.reviewOf}'s public profile.`
      }
    }

    this.updateHTML(this.ratingTextTarget, ratingText)
    this.updateHTML(this.ratingInfoTextTarget, ratingInfoText)
    this.updateHTML(this.publicFeedbackTextTarget, publicFeedbackText)
    this.updateHTML(this.issuesTextTarget, issuesText)

    this.disableSubmit()
    this.toggleIssueNotice()

    this.ratingTargets.forEach((el) => {
      el.classList.toggle('up', this.rating)
      el.classList.toggle('down', !this.rating)
    })
    this.show(this.detailsTarget)
  }

  toggleIssueNotice() {
    if (!this.rating) {
      this.show(this.issueNoticeTarget)
    } else {
      this.hide(this.issueNoticeTarget)
    }
  }

  updateTargetsWithBinding() {
    targetsWithBinding.forEach((target) => {
      this[`${target}Targets`].forEach((el) => {
        this[target] ? this.updateHTML(el, this[target]) : el.classList.add('muted')
      })
    })
  }

  getIssuesHTML() {
    let html = ''
    ISSUES_NEW[this.reviewOf].forEach((issue) => {
      html += `<label class="cnd-rc-wrapper cnd-rc-wrapper--red issue">
          <input type="checkbox" data-target="reviews--form.issue" data-action="change->reviews--form#disableSubmit" value="${issue.name}" name="${issue.name}">
          <span class="cnd-rc-wrapper__label">${issue.text}</span>
        </label>`
    })
    return html
  }

  setupIssues() {
    this.updateHTML(this.issuesTarget, this.getIssuesHTML())
  }

  submit(e) {
    e.preventDefault()
    if (this.submitting) return

    this.submitting = true
    this.submitButtonTarget.disabled = true

    let data = {
      rating: this.rating ? 1 : 0,
      public_feedback: this.publicFeedbackTarget.value,
      private_feedback: this.privateFeedbackTarget.value,
      issues: this.issueTargets.map((issue) => {
        return (issue.checked ? 'not_' : '') + issue.value
      }),
      reviewable_type: REVIEW_TYPES[this.reviewOf],
    }

    axios({
      url: `/trips/${this.reservationId}/reviews`,
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
        'X-CSRF-TOKEN': AxiosUtils.getMetaValue('csrf-token'),
      },
    })
      .then(
        () => {
          this.hide(this.formTarget)
          this.showAlert('Thanks! Your review helps others find their perfect car.')
          this.show(this.refreshButtonTarget)
        },
        () => {
          this.showAlert(`Unable to submit form. Please try again.`, true)
        }
      )
      .finally(() => {
        this.submitting = false
        this.submitButtonTarget.disabled = false
      })
  }

  refreshPage() {
    location.reload()
  }

  showPrivateFeedback() {
    this.privateFeedbackButtonTarget.classList.add('cnd-hidden')
    this.privateFeedbackSectionTarget.classList.remove('cnd-hidden')
  }
}
